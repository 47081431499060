<template>
  <div @keydown.enter.prevent>
    <div class="row">
      <div class="col-sm-12 col-xs-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive">{{placeholder}}</label>
          <v-select
            :multiple="true"
            :placeholder="fieldPlaceholder"
            :options="options"
            :class="[{'vs-error': v$.fieldValues.$error}]"
            ref="selectField"
            label="name"
            @option:selected="selectOption"
            @option:deselected="deselectOption"
          />
          <div v-if="v$.fieldValues.$error" class="error">This field is required</div>
          <input
            v-for="(fieldValue, key) in fieldValues"
            :key="key"
            type="hidden"
            :value="fieldValue.value || fieldValue"
            :name="name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  components: {
    vSelect
  },

  props: {
    name: String,
    value: Array,
    options: Array,
    placeholder: {
      type: String,
      default: "Select options"
    },
    shouldBeValidated: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      fieldValues: []
    }
  },

  validations () {
    return {
      fieldValues: {
        conditionalyRequired: isRequired(this.shouldBeValidated)
      }
    }
  },

  mounted: function() {
    if (this.value) {
      for(var item in this.value) {
        const itemValue = this.value[item];

        this.fieldValues.push(itemValue);
      }
      
      if (this.fieldValues && this.fieldValues.length > 0) {
        this.$refs.selectField.updateValue(this.fieldValues);
      }
    }
  },

  computed: {
    fieldPlaceholder: function() {
      return "Select " + this.placeholder
    },

    isValid: function() {
      this.validate();

      return !this.v$.fieldValues.$error;
    }
  },

  methods: {
    selectOption: function(opt) {
      this.v$.fieldValues.$reset();

      this.fieldValues = opt ? opt : [];

      this.$emit("selected", this.fieldValues, this);
    },

    deselectOption: function(opt) {
      this.v$.fieldValues.$reset();

      this.fieldValues = this.fieldValues.filter(item => item !== opt);

      this.$emit("deselected", this.fieldValues, this);
    },

    validate: function() {
      this.v$.fieldValues.$touch();
    }
  }
};
</script>
