<template>
  <li 
    v-if="currentPage < totalPageCount"
    class="page-item page-next"
  >
    <a
      href="#"
      class="page-link" 
      aria-label="Previous"
      @click.prevent="$emit('navigate-to', currentPage + 1)"
    >
      <span aria-hidden="true" class="icon" data-icon="navigate_next"></span>
    </a>
  </li>

  <li 
    v-else 
    class="page-item page-next disabled"
  >
    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
      <span aria-hidden="true" class="icon" data-icon="navigate_next"></span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },

    totalPageCount: {
      type: Number,
      required: true
    }
  }
}
</script>
