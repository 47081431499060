<template>
  <a 
    :title="title" 
    :class="['btn-expandable', 'btn-expandable-primary', css]" 
    @click="showModal"
  >
    <span class="icon" data-icon="qrcode"></span>
    <span v-if="this.label">{{ label }}</span>
  </a>
</template>

<script>

export default {
  props: {
    label: String,
    title: String,
    css: String,
    physicalAssetId: String,
    physicalAssetName: String,
    siteName: String,
  },

  created: function () {
    
  },

  computed: {
    
  },

  methods: {
    showModal: function() {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'physicalAssetQR',
        title: 'Asset QR Label',
        style: {
          class: 'no-scroll'
        },
        args: {
          asset: {
            id: vm.physicalAssetId,
            name: vm.physicalAssetName,
            site: {
              name: vm.siteName
            },
            qrLabels: []
          },
          callback: () => {
            window.location.reload(true)
          }
        }
      })
    }
  }
}
</script>