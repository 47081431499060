<template>
  <div @keydown.enter.prevent>
    <div class="row">
      <div class="col-sm-12 col-xs-12">
        <div class="form-group">
          <label class="placeholder placeholder-inactive">{{placeholder}}</label>
          <v-select
            :multiple="multiple"
            :placeholder="fieldPlaceholder"
            :options="options"
            :class="[{'vs-error': v$.fieldValue.$error}]"
            ref="selectField"
            label="name"
            @update:modelValue="setOption"
          />
          <div v-if="v$.fieldValue.$error" class="error">This field is required</div>
          <input
            v-if="fieldIsVisible"
            type="hidden" 
            :id="id"
            :name="name"  
            :value="fieldValue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  components: {
    vSelect
  },

  props: {
    id: String,
    name: String,
    value: String,
    options: Array,
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Select option"
    },
    shouldBeValidated: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      fieldValue: this.value
    }
  },

  validations () {
    return {
      fieldValue: {
        conditionalyRequired: isRequired(this.shouldBeValidated)
      }
    }
  },

  mounted: function() {
    if (this.value) {
      this.$refs.selectField.updateValue(this.value);

      this.fieldValue = this.value;
    }
  },

  computed: {
    fieldPlaceholder: function() {
      return "Select " + this.placeholder
    },

    isValid: function() {
      this.validate();

      return !this.v$.fieldValue.$error;
    },

    fieldIsVisible: function() {
      return (this.value && this.value != '') || (this.fieldValue && this.fieldValue != '');
    }
  },

  methods: {
    setOption: function(opt) {
      this.v$.fieldValue.$reset();

      if (opt) {
        this.fieldValue = opt;

        this.$emit("selected", this.fieldValue, this);
      }
      else {
        this.fieldValue = '';

        this.$emit("deselected", this.fieldValue, this);
      }
    },
    
    validate: function() {
      this.v$.fieldValue.$touch();
    }
  }
};
</script>
