<template>
  <tr style="vertical-align: middle">
    <td 
      class="text-center"
      style="width: 40px; vertical-align: middle"
    >
      <DamageRating
        :rating="clientRemediatedRating" 
      />
    </td>

    <td>
      <template v-if="showFindingNameColumn">
        <DetailsButton
          title="Related Finding Details"
          entity-type="findingDetails"
          :track-history="false"
          :entity="id"
        >
          {{component.name}}: <a
            :href="findingUrl"
            title="Finding"
          >{{displayName}}</a>
          <div class="note">
            <div>{{findingType.name}}</div>
            <div><strong>Project:</strong> {{inspection.project.name}}</div>
          </div>
        </DetailsButton>
      </template>
      <template v-else>
        {{findingType.name}}
      </template>
    </td>
    <td style="width: 40px">
      <CellAction
        action="delete"
        title="Remove from the list"
        icon="delete"
        @clickAction="removeFinding"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import DetailsButton from "../shared/grid/details_button";
import CellAction from "../shared/grid/cell_action";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    DetailsButton,
    CellAction,
    DamageRating
  },

  props: {
    filter: Object,
    clientRemediatedRating: Number,
    id: String,
    displayName: String,
    component: Object,
    findingType: Object,
    inspection: Object,
    status: String,
    showStatus: Boolean,
    showFindingNameColumn: Boolean,
    photoCount: Number,
    index: Number
  },

  computed: {
    findingUrl: function () {
      return "/findings/" + this.id
    }
  },

  methods: {
    removeFinding: function() {
      this.$emit("remove-finding", this);
    }
  }
}
</script>
