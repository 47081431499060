<template>
  <tr>
    <td>
      {{name}}
    </td>

    <td>
      {{ h$.formatDate.short(createdAt) }}
    </td>

    <td class="text-center">
      <template v-if="isActive">
        <span class="item-status-active">active</span>
      </template>
      <template v-else>
        <span class="item-status-deactivated">inactive</span>
      </template>
    </td>
    
    <td class="table-row-actions">
      <RowAction
        v-if="policyUpdate"
        action="update"
        @click.prevent="updateInspector"
      />

      <RowAction
        v-if="policyUpdate"
        :action="toggleInspectorAction"
        @click.prevent="toggleInspector"
      />

      <RowAction
        v-if="policyUpdate"
        action="remove"
        @click.prevent="removeInspector"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction
  },

  props: {
    id: String,
    name: String,
    createdAt: String,
    active: Boolean,
    index: Number
  },

  data: function () {
    return {
      isActive: this.active
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    toggleInspectorAction: function() {
      return this.isActive ? "block" : "unblock"
    }
  },

  methods: {
    removeInspector: function() {
      app.graphql.get(
        "delete.inspector",

        {
          input: {
            id: this.id
          }
        },

        (data) => {
          const response = data.data.deleteInspector;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to remove Inspector",
                message: error
              })
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Inspector",
              message: "Inspector <strong>" + response.inspector.name + "</strong> was successfully removed!"
            })

            this.$parent.refresh();
          }
        }
      );
    },

    toggleInspector: function() {
      let vm = this;

      app.graphql.get(
        "update.inspector",

        {
          input: {
            id: vm.id,
            active: !vm.isActive
          }
        },

        (data) => {
          const response = data.data.updateInspector;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to " + (vm.isActive ? 'deactivate' : 'activate') + " Inspector",
                message: error
              })
            })
          } else {
            vm.isActive = response.inspector.active;
            
            app.ui.toast.add({
              priority: "success",
              title: "Inspector",
              message: "Inspector <strong>" + vm.name + "</strong> was successfully <strong>" + (vm.isActive ? 'activated' : 'deactivated') + "</strong>!"
            });
          }
        }
      );
    },

    updateInspector: function() {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'inspectorNewModal',
        title: 'Inspector',
        style: {
          size: 'md',
          verticalyCentered: true
        },
        args: {
          id: vm.id,
          name: vm.name,
          callback: () => {
            window.location.reload(true)
          }
        }
      })
    }
  }
}
</script>