<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 400px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive" for="user_agreement_name">User Agreement name</label>
            <input
              :class="['form-control', {'error': v$.userAgreementName.$error}]"
              placeholder="Name"
              type="text"
              name="user_agreement[name]"
              id="user_agreement_name"
              v-model.trim="userAgreementName"
            />
            <div v-if="v$.userAgreementName.$error" class="error">User Agreement name is required</div>
          </div>
        </div>
        <div class="col-sm-8 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive" for="user_agreement_title">User Agreement title</label>
            <input
              :class="['form-control', {'error': v$.userAgreementName.$error}]"
              placeholder="Title"
              type="text"
              name="user_agreement[title]"
              id="user_agreement_title"
              v-model.trim="userAgreementTitle"
            />
            <div v-if="v$.userAgreementTitle.$error" class="error">User Agreement title is required</div>
          </div>
        </div>
      </div>

      <div class="row" @keydown.enter.prevent>
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static" for="user_agreement_content">User Agreement content</label>
            <textarea
              class="form-control"
              style="height: 50vh; min-height: 400px"
              placeholder="Content" 
              name="user_agreement[content]" 
              id="user_agreement_content"
              v-model.trim="userAgreementContent"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
              <input 
                type="checkbox"
                name="user_agreement[publish]"
                id="user_agreement_publish"
                value="1"
              >
              <label for="user_agreement_publish">
                <span>Publish User Agreement</span><span></span>
              </label>
            </div>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"

import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    formButton
  },

  props: {
    formId: String,
    userAgreementId: String,
    previousUserAgreementId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      userAgreementName: "",
      userAgreementTitle: "",
      userAgreementContent: ""
    }
  },

  validations () {
    return {
      userAgreementName: {
        required
      },
      userAgreementTitle: {
        required
      }
    }
  },

  mounted: function() {
    if (this.isExistingUserAgreement || this.previousUserAgreementId) {
      this.fetchUserAgreement();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  computed: {
    submitButtonLabel: function() {
      return (this.userAgreementId) ? "Update User Agreement" : "Create User Agreement";
    },

    isExistingUserAgreement: function() {
      return this.userAgreementId != "";
    }
  },

  methods: {
    fetchUserAgreement: function() {
      app.graphql.get("details.userAgreement",
        {
          filter: {
            id: [this.userAgreementId || this.previousUserAgreementId]
          }
        },
        (data) => {
          let currentUserAgreement = data.data.userAgreements.edges[0].node;

          this.userAgreementName = currentUserAgreement.name;
          this.userAgreementTitle = currentUserAgreement.title;
          this.userAgreementContent = currentUserAgreement.content;

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch User Agreement!"
          });
        }
      );
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.userAgreementName.$touch();
      this.v$.userAgreementTitle.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>