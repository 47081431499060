<template>
  <div :class="['btn-details', css]">
    <div
      :title="title || 'Details'"
      class="btn-details-icon"
      @click="onClick"
    ></div>
    <div
      class="btn-details-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Details'
    },
    action: String,
    entity: String,
    entityType: {
      type: String,
      default: 'blankDetails'
    },
    trackHistory: {
      type: Boolean,
      default: false
    },
    args: {
      type: Object,
      default: null
    },
    css: {
      type: String,
      default: ''
    }
  },

  methods: {
    onClick: function() {
      if (!this.$attrs.onClick) {
        // show details by default if there is no @click function on component level
        if (vmDetailsPane) {
          vmDetailsPane.$refs.detailsPane.open({
            title: this.title,
            entity: this.entity,
            entityType: this.entityType,
            trackHistory: this.trackHistory,
            args: this.args,
          });

          if (this.$parent.rowRef) {
            app.ui.grid.markRow(JSON.parse(this.$parent.rowRef));
          }

          app.ui.set();
        }
      }
    }
  }
}
</script>