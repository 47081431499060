<template>
  <div
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}</h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      
      <div class="chart-blade">

        <svg width="100%" height="107px" viewBox="0 0 756 107" preserveAspectRatio="none" ill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M220.477 15.2704C88.4773 -18.7295 35.0158 9.56673 12.477 46.2704C-10.0617 82.9741 2.97687 98.2705 12.477 102.77C21.9772 107.27 747.977 102.77 747.977 102.77C758.411 102.682 758.477 85.2704 747.977 85.2704C737.477 85.2704 418.477 58.2704 394.977 53.7704C371.477 49.2705 352.477 49.2704 220.477 15.2704Z" fill="#E9E9E9"/>
          <path d="M275.9 55.0163C158.016 37.1678 110.271 52.0221 90.1428 71.29C70.0143 90.5579 81.6585 98.5878 90.1428 100.95C98.627 103.312 746.989 100.95 746.989 100.95C756.308 100.904 756.366 91.7633 746.989 91.7633C737.612 91.7633 452.725 77.5895 431.739 75.2272C410.752 72.8649 393.783 72.8648 275.9 55.0163Z" fill="#DDDDDD"/>
        </svg>

        <div
          v-if="pressure > 0"
          class="damage-location location-pressure"
          :style="'--dimension: ' + percentange(pressure) + 'px'"
          ref="location-pressure"
          @mouseover="hoverAction('Pressure: ' + pressure + ' / ' + percentange(pressure) + '%', $event)"
        ></div>
        <div 
        v-if="suction > 0"
          class="damage-location location-suction" 
          :style="'--dimension: ' + percentange(suction) + 'px'"
          ref="location-suction"
          @mouseover="hoverAction('Suction: ' + suction + ' / ' +  percentange(suction) + '%', $event)"
        ></div>
        <div
          v-if="trailing > 0"
          class="damage-location location-trailing" 
          :style="'--dimension: ' + percentange(trailing) + 'px'"
          ref="location-trailing"
          @mouseover="hoverAction('Trailing Edge: ' + trailing + ' / ' +  percentange(trailing) + '%', $event)"
        ></div>
        <div
          v-if="leading > 0"
          class="damage-location location-leading" 
          :style="'--dimension: ' + percentange(leading) + 'px'"
          ref="location-leading"
          @mouseover="hoverAction('Leading Edge: ' + leading + ' / ' +  percentange(leading) + '%', $event)"
        ></div>
      
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {
    
  },

  props: {
    title: String,
    data: Object,
    css: {
      type: String,
      default: ''
    },
    showTooltips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      pressure: 0,
      suction: 0,
      trailing: 0,
      leading: 0,
      totalDamages: 0
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {
        this.setData()
      }, 
      immediate: true
    }
  },

  mounted: function() {
    this.setData()
  },

  methods: {
    markSize: function(damages) {
      // return Math.round((damages * 1.1) + 20)
      return Math.round(damages / this.totalDamages * 100)
    },

    percentange: function(damages) {
      return Math.round(damages / this.totalDamages * 100)
    },

    setData: function() {
      this.pressure = this.data.pressure || 0
      this.suction = this.data.suction || 0
      this.trailing = this.data.trailingedge || 0
      this.leading = this.data.leadingedge || 0 

      this.totalDamages = this.pressure + this.suction + this.trailing + this.leading
    },

    hoverAction: function(stat, e) {
      if (this.showTooltips) {
        const tooltip = new bootstrap.Tooltip(e.target, {
          title: stat,
          html: true,
          placement: 'top',
          container: 'body'
        })

        tooltip.show()
      }
    }
  }
}

</script>