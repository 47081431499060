<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table remediations-revisions-list">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 60px;"
              :current-sort="orderBy"
              :sort-by="'REVISED_RATING'"
              @sort="reorder"
            >
              Rating
            </SortableHeader>

            <SortableHeader
              class="text-center"
              :current-sort="orderBy"
              :sort-by="'ORIGINAL_RATING'"
              @sort="reorder"
            >
              Original rating
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REMEDIATION_TYPE_NAME'"
              @sort="reorder"
            >
              Remediation
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'CATEGORY'"
              @sort="reorder"
            >
              Revision
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REVISED_BY'"
              @sort="reorder"
            >
              Revised By
            </SortableHeader>

            <SortableHeader
              class="text-right"
              style="width: 120px"
              :current-sort="orderBy"
              :sort-by="'CREATED_AT'"
              @sort="reorder"
            >Date</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Revision
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="There is no related revisions"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="revisions"
        @navigate-to="paginate"
      />

    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Revision from "./revision"

export default {
  extends: Grid,

  components: {
    Revision
  },

  props: {
    remediationId: String
  },

  data: function () {
    return {
      items: [],

      grid: "remediation-revisions",

      defaultOrderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      orderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      return {
        remediationId: this.remediationId,
        revisionsOnly: true,
        event: "UPDATE"
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.remediation.remediationVersions",
        params,

        (data) => {
          let remediations = data.data.remediationVersions,
              pageInfo = remediations.pageInfo;

          success(remediations, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch revisions!"
          })
        }
      )
    }
  }
}
</script>
