<template>
    <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :rating="currentClassification" 
      />
    </td>

    <td class="text-center">
      <DamageRating
        v-if="changes.classification"
        :rating="original.classification" 
      />
    </td>

    <td>
      <DetailsButton
        title="Remediation Details"
        entity-type="remediationDetails"
        :entity="remediation.id"
      >
        <a
          :href="remediationUrl"
          title="Remediation"
        >{{remediation.displayName}}</a>
      </DetailsButton>
    </td>

    <td>
      <DetailsButton
        title="Revision Details"
        style="max-width: 350px"
        entity-type="remediationRevisionDetails"
        :entity="id"
      >
        {{category}}
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td>
      {{user.name}}
    </td>

    <td class="text-right">
      {{ h$.formatDate.short(createdAt) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="remediationUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationUrl + '/edit'"
        action="update"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    remediation: Object,
    createdAt: String,
    description: String,
    category: String,
    user: Object,
    objectChanges: String,
    originalObject: String
  },

  data: function () {
    return {
      changes: {},
      original: {}
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  created: function () {
    this.changes = JSON.parse(this.objectChanges);
    this.original = JSON.parse(this.originalObject);
  },

  methods: {
    ratingClass: function (rating) {
      return "rating-" + rating;
    }
  },

  computed: {
    remediationUrl: function () {
      return "/remediations/" + this.remediation.id;
    },

    currentClassification: function() {
      return (this.changes.classification) ? this.changes.classification[1] : this.original.classification;
    }
  }
}
</script>