<template>
  <li 
    v-if="currentPage > Math.ceil(showPages / 2)"
    class="page-item page-prev-block"
  >
    <a
      href="#"
      :title="'- ' + showPages"
      class="page-link"
      @click.prevent="$emit('navigate-to', linkPage)"
    >
      <span aria-hidden="true" class="icon" data-icon="more_horiz"></span>
    </a>
  </li>

  <li 
    v-else 
    class="page-item page-prev-block disabled"
  >
    <a class="page-link" href="#" aria-disabled="true">
      <span aria-hidden="true" class="icon" data-icon="more_horiz"></span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },

    showPages: {
      type: Number,
      required: true
    }
  },

  computed: {
    linkPage: function () {
      return Math.max(this.currentPage - this.showPages, 1)
    }
  }
}
</script>
