<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="findingTypeUrl"
      >{{name}}</a>
      <div v-if="description" class="note">{{description}}</div>
      <span v-if="archived" class="label label-warning">archived</span>
    </td>

    <td>
      <Details 
        :details="details" 
        :archived="archived"
      />
    </td>

    <td>
      <a
        :href="componentTypeUrl"
      >{{componentType.name}}</a>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="findingTypeUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingTypeUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingTypeUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";

export default {
  extends: Row,

  components: {
    RowAction,
    Details
  },

  props: {
    id: String,
    name: String,
    description: String,
    details: String,
    componentType: Object,
    archived: Boolean
  },

  computed: {
    findingTypeUrl: function () {
      return "/finding_types/" + this.id;
    },

    componentTypeUrl: function () {
      return "/component_types/" + this.componentType.id;
    }
  }
}
</script>
