<template>
  <div class="modal-body">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">

        <form 
            :ref="formId"
            :id="formId" 
            :action="formUrl" 
            enctype="multipart/form-data"
            accept-charset="UTF-8"
            method="post"
            role="form" 
            autocomplete="off"
            novalidate="novalidate"
          >
          <input type="hidden" name="utf8" value="✓">
          <input type="hidden" name="_method" value="patch">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="placeholder placeholder-inactive">Name</label>
                <input
                  name="user[name]"
                  id="user_name"
                  type="text"
                  :class="['form-control', {'error': v$.userName.$error}]"
                  placeholder="User" 
                  v-model.trim="userName"
                  @keydown.enter.prevent="submitForm"
                >
                <div v-if="v$.userName.$error" class="error">User name is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="placeholder placeholder-inactive">Email</label>
                <input
                  name="user[email]"
                  id="user_email"
                  type="text"
                  :class="['form-control', {'error': v$.userEmail.$error}]"
                  placeholder="user@example.com" 
                  v-model.trim="userEmail"
                  @keydown.enter.prevent="submitForm"
                >
                <div v-if="v$.userEmail.$error" class="error">Correct email is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <form-button
                  ref="submitButton"
                  label="Update user data"
                  class="btn btn-primary"
                  @submit="submitForm"
                ></form-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      formId: "",
      formUrl: "",
      userName: "",
      userEmail: "",
    }
  },

  validations () {
    return {
      userName: {
        required
      },
      userEmail: {
        required,
        email
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formId = this.args.formId;
    this.formUrl = this.args.formUrl;
    this.userName = this.args.userName;
    this.userEmail = this.args.userEmail;

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.userName.$touch();
      this.v$.userEmail.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>