<template>
  <div class="table-wrapper filtered-items">
    <div class="table-responsive table-scroll">

      <table class="table">
        <thead>
          <tr>
            <th 
              class="text-center no-label"
              style="width: 30px"
            >
              <input 
                type="checkbox"
                id="chkSelectAll"
                ref="selectAll"
                @change="toggleFindings"
                ><label class="checkbox" style="opacity: 0" for="chkSelectAll"></label>
            </th>
            
            <SortableHeader
              class="text-center no-label"
              style="width: 40px"
              :current-sort="orderBy"
              :sort-by="'RATING'"
              :update-ui-state="false"
              @sort="reorder"
            ></SortableHeader>

            <SortableHeader
              v-if="showFindingNameColumn"
              :current-sort="orderBy"
              :sort-by="'DISPLAY_NAME'"
              :update-ui-state="false"
              @sort="reorder"
            >Finding</SortableHeader>
          </tr>
        </thead>
      </table>

      <div
        :class="['table-body', { 'spinner-is-visible': isSpinnerVisible }]"
        style="height: 310px"
      >
        <table class="table">
          <tbody
            v-if="items.length > 0"
          >
            <Finding
              v-for="(item, index) in items"
              v-bind="item"
              :key="item.id"
              :index="index"
              :show-finding-name-column="showFindingNameColumn"
              @toggle-finding="toggleFinding(item)"
            />
          </tbody>
        </table>

        <div class="spinner"></div>

        <NoRecords 
          label="According to filter criteria there is no findings found"
        />
      </div>

      <Pagination
        v-bind="pageInfo"
        @navigate-to="paginate"
        :update-ui-state="false"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Grid from "../shared/grid/grid";
import SortableHeader from "../shared/grid/sortable_header"
import Finding from "./form_filtered_finding"
import Pagination from "../shared/pagination"

export default {
  extends: Grid,

  components: {
    SortableHeader,
    Finding,
    Pagination
  },

  props: {
    showFindingNameColumn: Boolean,
    findingsFilter: Object,
    defaultFindings: Array,
    listedFindings: Array
  },

  data: function () {
    return {
      items: [],

      grid: "findings",

      defaultOrderBy: {
        field: "RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 15,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      showAllFindings: false,

      isSpinnerVisible: true,

      isAllFindingsSelected: false,

      isInitialized: false,
      isLoaded: false
    }
  },

  computed: {
    filterParams: function () {
      let filterParams = {
        status: ["review", "draft", "approved"]
      };

      if (this.findingsFilter) {
        if (this.findingsFilter.remediationId) {
          filterParams.remediationId = this.findingsFilter.remediationId;
        }

        if (this.findingsFilter.remediationTypeId) {
          filterParams.possibleRemediationTypeId = this.findingsFilter.remediationTypeId;
        }

        if (this.findingsFilter.physicalAssetId) {
          filterParams.physicalAssetId = this.findingsFilter.physicalAssetId;
        }
      }

      return filterParams;
    }
  },

  watch: {
    findingsFilter: function(filter) {      
      if (filter.physicalAssetId || filter.remediationTypeId) {
        this.isSpinnerVisible = true;

        setTimeout(() => {
          this.getItems();
        }, 200);
      }
      else {
        this.items = [];
      }
    },    
    
    items: function() {
      // Initial findings listing
      if (!this.isInitialized) {
        this.items.forEach((item) => {
          let isDefaultFinding = this.defaultFindings.includes(item.id);
          
          item.selected = false;

          if (isDefaultFinding) {
            item.selected = true;

            this.newRemediationListFinidng(item);
          }
        });

        this.isInitialized = true;
        this.isLoaded = true;
      }

      // Once when findings are initialy listed
      if (this.isLoaded) {
        this.items.forEach((item) => {
          item.selected =  this.listedFindings.findIndex(x => x.id === item.id) > -1;
        });
      }
    },

    listedFindings: function() {
      this.items.forEach((item, index) => {
        item.selected = this.isSelectedFinding(item);

        this.items["index"] = item;
      });
    }
  },

  methods: {
    isSelectedFinding: function(finding) {
      return this.listedFindings.findIndex(x => x.id === finding.id) > -1;
    },
    
    toggleFinding: function(finding) {
      this.$emit("toggle-finding", finding);
    },

    newRemediationListFinidng: function(finding) {
      if (!this.findingsFilter.remediationId) {
        // List Finding if new Remediation is created directly from Finding
        this.toggleFinding(finding);
      }
    },

    toggleFindings: function() {
      
    },

    checkSelectedFindings: function() {
      this.isAllFindingsSelected = this.listedFindings.length == this.pageInfo.totalCount;

      this.$refs.selectAll.checked = this.isAllFindingsSelected;
    },

    fetch: function (params, success) {
      app.graphql.get("grid.findings",
        params,

        (data) => {
          let findings = data.data.findings,
              pageInfo = findings.pageInfo;

          success(findings, pageInfo);

          this.checkSelectedFindings();
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch findings!"
          })
        }
      )
    },

    reorder: function (newOrder) {
      this.isSpinnerVisible = true;
      this.orderBy = newOrder;

      setTimeout(() => {
        if (this.showAllFindings) {
          this.getAllFindings();
        }
        else {
          this.getItems(1);
        }
      }, 200);
    },

    paginate: function (page) {
      this.isSpinnerVisible = true;

      setTimeout(() => {
        this.getItems(page);
      }, 200);
    },

    getAllFindings: function () {
      this.items = [];

      let currentPage = 1,
        maxPage = 100,
        getNext = (after) => {
          this.fetch(
            {
              cursor: after,
              pageSize: 100,
              orderBy: this.orderBy,
              filter: this.filterParams
            },

            (findings, pageInfo) => {
              findings.edges.forEach((edge) => this.items.push(edge.node))

              if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
                Promise.resolve(getNext(pageInfo.endCursor))
              }
            }
          )
        }

      getNext(window.btoa(0));

      this.isSpinnerVisible = false;
    },

    toggleAllFindings: function () {
      this.showAllFindings = !this.showAllFindings;
      
      if (this.showAllFindings) {
        this.getAllFindings();
      } 
      else {
        this.getItems(1);
      }
    }
  }
}
</script>