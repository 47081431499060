<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-4 col-sm-6 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for QR labels by label number"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              style="width: 120px"
              :current-sort="orderBy"
              :sort-by="'LABEL_NUMBER'"
              @sort="reorder"
            >Label #</SortableHeader>

            <SortableHeader
              v-if="!batchId"
              style="width: 120px"
              :current-sort="orderBy"
              :sort-by="'BATCH_NUMBER'"
              @sort="reorder"
            >Batch #</SortableHeader>

            <SortableHeader
              style="width: 250px"
              :current-sort="orderBy"
              :sort-by="'CLIENT_COMPANY_NAME'"
              @sort="reorder"
            >Client</SortableHeader>

            <SortableHeader
              style="width: 250px"
              :current-sort="orderBy"
              :sort-by="'SITE_NAME'"
              @sort="reorder"
            >Site</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'ASSET_NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <QRLabel
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :show-batch-column="!batchId"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there are no QR labels found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="labels"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import QRLabel from "./label";

export default {
  extends: Grid,

  components: {
    QRLabel
  },

  props: {
    batchId: String
  },

  data: function () {
    return {
      items: [],

      grid: "qr-labels",

      textSearch: '',

      defaultOrderBy: {
        field: "LABEL_NUMBER",
        direction: "ASC"
      },

      orderBy: {
        field: "LABEL_NUMBER",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    labelNumber: function () {
      if (this.textSearch) {
        return parseInt(this.textSearch);
      }
    }
  },

  methods: {
    getItems: function (page, pageSize) {
      let currentPage = page || this.pageInfo.currentPage,
          cursor = window.btoa((currentPage - 1) * (pageSize || this.pageInfo.pageSize));

      this.fetch(
        {
          cursor: cursor,
          pageSize: pageSize || this.pageInfo.pageSize,
          orderBy: this.orderBy,
          batchId: this.batchId,
          labelNumber: this.labelNumber,
        },

        (items) => {
          this.items = items.edges.map((edge) => { return edge.node });

          this.pageInfo = {
            pageSize: pageSize || this.pageInfo.pageSize,
            currentPage: currentPage,
            totalCount: items.totalCount,
            totalPageCount: items.totalPageCount
          }

          this.isSpinnerVisible = false;
        }
      )
    },

    fetch: function (params, success) {
      app.graphql.get("grid.qr.labels",
        params,

        (data) => {
          let labels = data.data.qrLabels,
              pageInfo = labels.pageInfo;

          success(labels, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch QR Labels!"
          })
        }
      )
    }
  }
}
</script>
