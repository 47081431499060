<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-5 col-xs-12">
          <fieldset>
            <legend>User</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="user_name" class="placeholder placeholder-static">Name</label>
                  <input 
                    :class="['form-control', {'error': v$.userName.$error}]"
                    placeholder="User name" 
                    type="text" 
                    name="user[name]" 
                    id="user_name"
                    v-model.trim="userName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.userName.$error" class="error">User name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="user_email" class="placeholder placeholder-static">Email</label>
                  <input 
                    :class="['form-control', {'error': v$.userEmail.$error}]"
                    placeholder="User e-mail" 
                    type="text" 
                    name="user[email]" 
                    id="email"
                    v-model.trim="userEmail"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.userEmail.$error" class="error">Correct user email is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">User Role</label>
                  <v-select
                    placeholder="Select user role"
                    ref="userRoles"
                    :class="[{'vs-error': v$.userRole.$error}]"
                    :options="userRoles"
                    label="value"
                    @update:modelValue="setUserRole"
                  />
                  <div v-if="v$.userRole.$error" class="error">User role is required</div>
                  <input 
                    type="hidden" 
                    id="user_role" 
                    name="user[role]"
                    :value="userRole"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-7 col-xs-12">
          <fieldset>
            <legend>User Client Roles</legend>
            <form-user-client-roles
              ref="userClientRoles"
              :user-client-roles="userClientRoles"
            />
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from "vue-select";

import formUserClientRoles from "./form_user_client_roles";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formUserClientRoles,
    formButton
  },

  props: {
    formId: String,
    userId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userName: "",
      userEmail: "",
      userRole: "",
      userRoles: [
        {key: "admin", value: "Admin"},
        {key: "client", value: "Client"},
        {key: "staff", value: "Staff"}
      ],
      userActivationState: "",
      userClientRoles: [],
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  validations () {
    return {
      userName: {
        required
      },
      userEmail: {
        required,
        email
      },
      userRole: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {
      return (this.isExistinguser) ? "Update User" : "Create User";
    },

    isExistinguser: function() {
      return this.userId != "";
    }
  },

  mounted: function() {
    if (this.isExistinguser) {
      this.fetchUser();
      this.fetchUserClientRoles();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  methods: {
    fetchUser: function() {
      app.graphql.get("details.user",
        {
          filter: {
            id: [this.userId]
          }
        },
        (data) => {
          let currentUser = data.data.users.edges[0].node;

          this.userName = currentUser.name;
          this.userEmail = currentUser.email;
          this.userRole = currentUser.role.toLowerCase();
          this.userActivationState = currentUser.activationState;

          let currentUserRole = this.userRoles.find(role => {
            return role.key === currentUser.role.toLowerCase();
          });

          this.$refs.userRoles.updateValue(currentUserRole);

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch User!"
          });
        }
      );
    },

    fetchUserClientRoles: function () {
      let fetchParams = {
         orderBy: {
          field: "CLIENT_COMPANY_NAME",
          direction: "ASC",
        },
        filter: {
          userId: this.userId
        }
      };

      this.userClientRoles = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.user.clientRoles",
          fetchParams,

          (data) => {
            let clientRoles = data.data.clientRoles,
                pageInfo = clientRoles.pageInfo;

            success(clientRoles, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch User Client Roles!"
            });
          }
        )
      };

      let getClientRoles = (cursor) => {
        fetch(
          cursor,

          (clientRoles, pageInfo) => {
            clientRoles.edges.forEach((edge) => this.userClientRoles.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientRoles(pageInfo.endCursor))
            }
          }
        )
      };

      getClientRoles(window.btoa(0));
    },

    setUserRole: function(opt) {
      this.userRole  = (opt) ? opt.key : "";
    },
    
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.$touch();

      let userClientRolesAreValid = this.$refs.userClientRoles.isValid;

      if (form && this.v$.$errors.length <= 0 && userClientRolesAreValid) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}
</script>
