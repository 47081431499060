<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div v-if="(!isFilterInPreviewMode && asset.latestSummary.approvedDate) || (isFilterInPreviewMode && asset.latestSummary.previewDate)">
            <DamageRating
              :rating="isFilterInPreviewMode ? asset.latestSummary.remediationPreviewRating : asset.latestSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            <a
              :href="'/physical_assets/' + asset.id"
              tile="Physical Asset"
            >{{asset.name}}</a>
            <div v-if="asset.description" class="note">{{asset.description}}</div>
          </div>
        </header>
        <header v-if="(!isFilterInPreviewMode && asset.latestLeadingEdgeSummary.approvedDate) || (isFilterInPreviewMode && asset.latestLeadingEdgeSummary.previewDate)">
          <div>
            <DamageRating
              :rating="isFilterInPreviewMode ? asset.latestLeadingEdgeSummary.remediationPreviewRating : asset.latestLeadingEdgeSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            Leading Edge Condition
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">GPS Location</label>
          {{asset.latitude}}, {{asset.longitude}}
        </div>
        <div v-if="asset.windZone" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Wind Zone (Vmph)</label>
          {{asset.windZone}}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Created on</label>
          {{ h$.formatDate.short(asset.createdAt) }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last update</label>
          {{ h$.formatDate.short(asset.updatedAt) }}
        </div>
        <div v-if="lastActivityDate" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last activity</label>
          {{ h$.formatDate.short(lastActivityDate) }}
        </div>
      </section>

      <section 
        v-if="asset.qrLabels.length"
        class="asset-entity-details"
      >
        <header>
          <div>
            <h3>QR Labels</h3>
          </div>
        </header>
        <ul class="asset-qr-labels">
          <li
            v-for="(label, index) in asset.qrLabels"
            :key="index"
            class="qr-label"
          >
            <template v-if="label.primary">
              <div>
                <strong>{{label.labelNumber}}</strong>
              </div>
            </template>
            <template v-else>
              <div>
                <span>{{label.labelNumber}}</span>
              </div>
            </template>
          </li>
        </ul>
      </section>
      
      <div class="entity-details-actions">
        <div class="d-grid">
          <a
            :href="'/physical_assets/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating
  },

  data() {
    return {
      asset: {
        physicalAssetType: {},
        qrLabel: {},
        qrLabels: [],
        latestSummary: {},
        latestLeadingEdgeSummary: {},
        site: {}
      },
      progress: 0,
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    entityId: function() {
      this.fetchAssetDetails();
    }
  },

  mounted: function() {
    this.fetchAssetDetails();
  },

  computed: {
    lastActivityDate: function() {
      return this.isFilterInPreviewMode ? this.asset.latestSummary.previewDate : this.asset.latestSummary.approvedDate;
    },
  },

  methods: {
    fetchAssetDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.asset",

        {
          filter: {
            id: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true

          this.asset = data.data.physicalAssets.edges[0].node

          // Set QR Labels
          let assetQRLabels = [];

          if (this.asset.qrLabels.edges.length > 0) {
            assetQRLabels = this.asset.qrLabels.edges.map(function(qrLabel) {
              return qrLabel.node;
            });
          }

          this.asset.qrLabels = assetQRLabels;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Physical Asset Details",
            message : "Something went wrong! Asset cannot be found or doesn't exist!"
          });
        }
      );
    }
  }
}

</script>
