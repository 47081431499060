<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <DetailsButton
        title="Site Details"
        entity-type="siteDetails"
        :entity="id"
      >
        <a
          :href="siteUrlWithFilter"
        >{{name}}</a>
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td v-if="showStatus">
      <div class="d-flex justify-content-end">
        <template v-if="findingStatusCounts.approved > 0 && (findingStatusCounts.review == 0 && findingStatusCounts.draft == 0)">
          <div class="item-status-approved">All Approved</div>
        </template>
        <template v-else>
          <div 
            v-if="findingStatusCounts.review > 0"
            class="item-status-review ml-1">{{findingStatusReviewLabel}}</div>
          <div 
            v-if="findingStatusCounts.draft > 0"
            class="item-status-draft ml-1">{{findingStatusDraftLabel}}</div>
        </template>
      </div>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="siteUrlWithFilter"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="siteUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="siteUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";

export default {
  extends: Row,

  components: {
    RowAction,
    DetailsButton
  },

  props: {
    id: String,
    name: String,
    description: String,
    showStatus: Boolean,
    findingStatusCounts: Object,
    projectId: String
  },

  computed: {
    siteUrl: function () {
      return "/sites/" + this.id;
    },

    siteUrlWithFilter: function () {
      return '/sites/' + this.id + '?v-filter={"projectId":["' + this.projectId + '"], "historical":true}';
    },

    findingStatusReviewLabel: function() {
      return this.findingStatusCounts.review + " pending review"; 
    },
    findingStatusDraftLabel: function() {
      return this.findingStatusCounts.draft + " in draft"; 
    },
  }
}
</script>
