<template>
  <div class="row" @keydown.enter.prevent>
    <div class="col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="placeholder placeholder-static">Remediators</label>
        <v-select
          multiple
          placeholder="Select remediators"
          ref="remediators"
          :class="[{'vs-error': v$.selectedRemediators.$error}]"
          :options="remediators"
          label="name"
          @option:selected="selectRemediators"
          @option:deselected="deselectRemediators"
        />
        <div v-if="v$.selectedRemediators.$error" class="error">At least one remediator should be defined</div>
        <input
          v-for="(remediator, index) in selectedRemediators"
          v-bind="remediator"
          :key="remediator.id"
          :index="index"
          type="hidden"
          name="remediation[inspector_ids][]"
          :value="remediator.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
  },

  props: {
    defaultRemediators: Array
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      remediators: [],
      selectedRemediators: this.defaultRemediators
    }
  },

  validations () {
    return {
      selectedRemediators: {
        required
      }
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.$error;
    }
  },

  mounted: function() {
    this.fetchRemediators();

    if (this.selectedRemediators && this.selectedRemediators.length > 0) {
      this.$refs.remediators.updateValue(this.selectedRemediators);
    }
  },

  methods: {
    fetchRemediators: function() {
      let fetchParams = {
        pageSize: 100,
        filter: {
          active: true
        },
        orderBy: {
          field: "NAME"
        }
      };

      this.remediators = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.inspectors",
          fetchParams,

          (data) => {
            let remediators = data.data.inspectors,
                pageInfo = remediators.pageInfo;

            success(remediators, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch remediators!"
            });
          }
        )
      };

      let getRemediators = (cursor) => {
        fetch(
          cursor,

          (remediators, pageInfo) => {
            remediators.edges.forEach((edge) => this.remediators.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getRemediators(pageInfo.endCursor));
            }

            this.isLoaded = true;
          
            setTimeout(() => {
              this.isSpinnerVisible = false;
            }, 1000);
          }
        )
      };

      getRemediators(window.btoa(0));
    },

    selectRemediators: function(opt) {
      this.v$.$reset();

      this.selectedRemediators = opt ? opt : [];
    },

    deselectRemediators: function(opt) {
      this.selectedRemediators = this.selectedRemediators.filter(remediator => remediator !== opt);
    },

    validate: function() {
      this.v$.selectedRemediators.$touch();
    }
  }
}

</script>
