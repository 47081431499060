<template>
<div class="form-field--element is-draggable">
  <div class="element--form-group">
    <div class="element--label">
      <span class="label label-active">{{ componentType.physicalAssetType.name }}</span>
    </div>
    <div class="element--form-control">
      <v-select
        placeholder="Set component type"
        ref="componentTypes"
        :class="[{'vs-error': v$.componentTypeId.$error}]"
        :options="componentTypes"
        label="name"
        @update:modelValue="setComponentType"
      />
      <div v-if="v$.componentTypeId.$error" class="error">Component type is required</div>
      <input 
        type="hidden" 
        id="component_type_ids_" 
        name="component_type_ids[]"
        :value="componentTypeId"
      />
    </div>
    <div class="element--actions">
      <div>
        <button
          type="button" 
          class="btn-expandable btn-expandable-default btn-expandable-sm"
          title="Remove"
          @click="removeComponentType"
        >−</button>
      </div>
      <div>
        <button 
          type="button" 
          class="btn-expandable btn-expandable-add btn-expandable-sm"
          title="Add"
          @click="addComponentType"
        >＋</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect
  },

  props: {
    index: Number,
    componentType: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      componentTypes: [],
      componentTypeId: ""
    }
  },

  validations () {
    return {
      componentTypeId: {
        required
      }
    }
  },

  watch: {
    componentType: function() {
      if (this.componentType.id) {
        this.$refs.componentTypes.updateValue(this.componentType);
      }
      else {
        this.$refs.componentTypes.clearSelection();
      }
    }
  },

  computed: {
    isValid: function() {
      this.validate()

      return !this.v$.componentTypeId.$error;
    }
  },

  mounted: function() {
    this.fetchComponentTypes();
  },

  methods: {
    validate: function() {
      this.v$.componentTypeId.$touch();
    },

    setComponentType: function(opt) {
      if (opt) {
        this.componentTypeId = opt.id;

        this.$emit("update", {
          index: this.index,
          id: opt.id,
          name: opt.name,
          physicalAssetType: this.componentType.physicalAssetType
        });
      }
      else {
        this.componentTypeId = "";

        this.$emit("update", {
          index: this.index,
          id: "",
          name: "",
          physicalAssetType: this.componentType.physicalAssetType
        });
      }
    },

    addComponentType: function() {      
      this.$emit("add");
    },

    removeComponentType: function() {
      this.$emit("remove")
    },

    fetchComponentTypes: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: {
          field: "NAME", 
          direction: "ASC"
        },
        filter: {
          physicalAssetTypeId: this.componentType.physicalAssetType.id
        }
      };

      this.componentTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.componentTypes",
          fetchParams,

          (data) => {
            let componentTypes = data.data.componentTypes,
                pageInfo = componentTypes.pageInfo;

            success(componentTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch component types!"
            });
          }
        )
      };

      let getComponentTypes = (cursor) => {
        fetch(
          cursor,

          (componentTypes, pageInfo) => {
            componentTypes.edges.forEach((edge) => this.componentTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getComponentTypes(pageInfo.endCursor))
            }
          }
        )
      };

      getComponentTypes(window.btoa(0));
    },
  }
};
</script>
