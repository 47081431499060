<template>
  <div class="table-wrapper">
    <table
      class="table"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <thead>
        <tr>
          <SortableHeader
            style="width: 120px"
            :current-sort="orderBy"
            :sort-by="'BATCH_NUMBER'"
            @sort="reorder"
          >Batch #</SortableHeader>

          <SortableHeader
            :current-sort="orderBy"
            :sort-by="'NOTES'"
            @sort="reorder"
          >Notes</SortableHeader>

          <SortableHeader
            style="width: 150px"
            class="text-center"
            :current-sort="orderBy"
            :sort-by="'LABEL_COUNT'"
            @sort="reorder"
          >Labels in Batch</SortableHeader>

          <SortableHeader
            style="width: 200px"
            :current-sort="orderBy"
            :sort-by="'CREATED_BY_EMAIL'"
            @sort="reorder"
          >Created By</SortableHeader>

          <SortableHeader
            style="width: 120px"
            :current-sort="orderBy"
            :sort-by="'CREATED_AT'"
            @sort="reorder"
          >Created At</SortableHeader>

          <th data-sorter="false"></th>
        </tr>
      </thead>

      <tbody>
        <QRBatch
          v-for="(item, index) in items"
          v-bind="item"
          :key="item.id"
          :index="index"
        />
      </tbody>
    </table>

    <div class="spinner"></div>

    <NoRecords 
      label="There is no QR batches found"
    />

    <Pagination
      v-bind="pageInfo"
      entity-type="batches"
      @navigate-to="paginate"
    />
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import QRBatch from "./batch";

export default {
  extends: Grid,

  components: {
    QRBatch
  },

  data: function () {
    return {
      items: [],

      grid: "qr-batches",

      defaultOrderBy: {
        field: "BATCH_NUMBER",
        direction: "ASC"
      },

      orderBy: {
        field: "BATCH_NUMBER",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.qr.batches",
        params,

        (data) => {
          let batches = data.data.qrLabelBatches,
              pageInfo = batches.pageInfo;

          success(batches, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch QR Batches!"
          })
        }
      )
    }
  }
}
</script>
