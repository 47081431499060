<template>
  <a
    :href="href"
    :class="['btn-expandable', { 'spinner-is-visible': isSpinnerVisible }, sizeClass, actionClass, css]"
    :title="title"
    @click="clickActionCall"
  >
    <div 
      class="spinner"
    ></div>
    <span
      v-if="label"
      class="label"
    >{{label}}</span>
    <span
      v-if="icon"
      class="icon"
      :data-icon="icon"
    ></span>
    <span
      v-if="text"
    >{{text}}</span>
  </a>
</template>

<script>
export default {
  props: {
    text: String,
    title: String,
    label: String,
    icon: String,
    css: String,
    confirm: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: '#'
    },
    size: {
      type: String,
      default: 'sm',
      validator: function (value) {
        return [
          'sm',
          'md',
          'xs'
        ].indexOf(value) !== -1
      }
    },
    action: {
      type: String,
      default: 'default',
      validator: function (value) {
        return [
          'default',
          'primary',
          'add',
          'preview',
          'delete',
          'edit',
          'approve',
          'review'
        ].indexOf(value) !== -1
      }
    },
    initialyLoading: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      isSpinnerVisible: false,
      isLoaded: false
    }
  },

  created: function() {
    this.isSpinnerVisible = this.initialyLoading;
  },

  mounted: function() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 500);
  },

  computed: {
    actionClass: function () {
      return "btn-expandable-" + this.action;
    },

    sizeClass: function() {
      return "btn-expandable-" + this.size;
    }
  },

  methods: {
    clickActionCall: function (event) {
      if (this.href == "#") {
        event.preventDefault();

        let approve = true;

        if (this.action == "delete" || (this.confirm.length > 0)) {
          approve = confirm(this.confirm.length > 0 ? this.confirm : "Are you sure?");
        }

        if (approve == true) {
					this.$emit("clickAction", event);
				}
        else {
          this.isSpinnerVisible = false;
        }
      }
    }
  }
}
</script>