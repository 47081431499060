<template>
  <a 
    :title="title" 
    :class="['btn-expandable', 'btn-expandable-primary', css]" 
    @click="showModal"
  >
    <span class="icon" data-icon="cloud_download"></span>
    <span v-if="this.label">{{ label }}</span>
  </a>
</template>

<script>

export default {
  props: {
    label: String,
    title: String,
    siteImportTemplateUrl: String,
    siteImportTemplateFormId: String,
    css: String
  },

  created: function () {
    
  },

  computed: {
    
  },

  methods: {
    showModal: function() {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'siteImportTemplateModal',
        title: 'Site Import Template',
        style: {
          class: 'no-scroll'
        },
        args: {
          siteImportTemplateFormId: vm.siteImportTemplateFormId,
          siteImportTemplateUrl: vm.siteImportTemplateUrl,
          callback: () => {
            
          }
        }
      })
    }
  }
}
</script>