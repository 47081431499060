<template>
<div :class="['form-field--data-type', 'is-draggable', {'is-archived': archived || entityTypeArchived}]">
  <div class="data-type--form-group">
    <div class="data-type--label">
      <span :class="['label', typeLabelClass]">{{ typeName }}</span>
    </div>
    <div class="data-type--form-control">
      <template v-if="isDataTypeDefined">
        <div class="data-type--name">
          <div>
            <input 
              :class="['form-control', {'disabled': archived || entityTypeArchived}, {'error': v$.name.$error}]"
              type="text"
              placeholder="Detail name"
              :readonly="existing"
              :name="formFieldName('name')"
              :id="formFieldId('name')"
              :value="name"
              @input="setName"
            />
          </div>
          <div v-if="dataFulcrumDataName || (!archived && !entityTypeArchived)">
            <input
              :class="['form-control', {'disabled': archived || entityTypeArchived}, {'error': v$.dataFulcrumDataName.$error}]"
              type="text"
              placeholder="Fulcrum Data name"
              :readonly="archived || entityTypeArchived"
              :name="fulcrumFormFieldName"
              :id="fulcrumFormFieldId"
              :value="dataFulcrumDataName"
              @input="setFulcrumName"
            />
          </div>
        </div>

        <input 
          type="hidden"
          :name="formFieldName('type')"
          :id="formFieldId('type')"
          :value="type"
        />

        <input 
          v-if="format"
          type="hidden"
          :name="formFieldName('format')"
          :id="formFieldId('format')"
          :value="format"
        />

        <input 
          v-if="isArchived"
          type="hidden"
          :name="formFieldName('archived')"
          :id="formFieldId('archived')"
          value="true"
        />

        <input 
          v-if="multiple"
          type="hidden"
          :name="formFieldName('multiple')"
          :id="formFieldId('multiple')"
          :value="multiple"
        />
      </template>
      <template v-else>
        <v-select
          placeholder="Set detail type"
          :class="[{'vs-error': v$.name.$error}]"
          :options="dataTypes"
          label="key"
          @update:modelValue="setDataType"
        />
      </template>
    </div>
    <div class="data-type--actions">
      <div v-if="!entityTypeArchived">
        <template v-if="!archived">
          <button
            type="button" 
            class="btn-expandable btn-expandable-default btn-expandable-sm"
            :title="removeDataTypeDetailButtonLabel"
            @click="removeDataTypeDetail"
          >−</button>
        </template>
        <template v-else>
          <button
            type="button" 
            class="btn-expandable btn-expandable-delete btn-expandable-sm"
            title="Unarchive detail"
            @click="unarchiveDataTypeDetail"
          ><span class="icon" data-icon="replay"></span></button>
        </template>
      </div>
      <div v-if="!entityTypeArchived">
        <button 
          type="button" 
          class="btn-expandable btn-expandable-add btn-expandable-sm"
          title="Add detail"
          @click="addDataTypeDetail"
        >＋</button>
      </div>
    </div>
  </div>
  <div 
    v-if="options"
    class="data-type--options"
  >
    <div class="options-container">
      <draggable 
        ref="optionsList" 
        group="options"
        :disabled="archived || entityTypeArchived"
        @start="drag=true" 
        @end="drag=false"
      >
        <data-type-detail
          v-for="(option, index) in options" 
          v-bind="option"
          :key="index"
          :index="index"
          :entity-type="entityType"
          :data-type-index="dataTypeIndex"
          :data-type-archived="archived || entityTypeArchived"
          ref="dataTypeDetail"
          @update="updateDataTypeDetailOption"
          @remove="removeDataTypeDetailOption(index)"
        />
      </draggable>
    </div>

    <div 
      class="options-footer"
      v-if="!archived && !entityTypeArchived"
    >
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm"
        @click="addDataTypeDetailOption()"
      >
        <span class="icon" data-icon="add"></span>
        <span>Add Option</span>
      </button>
    </div>
  </div>
</div>
</template>

<script>
import vSelect from 'vue-select';
import dataTypeDetail from './form_data_type_detail';

import { VueDraggableNext } from 'vue-draggable-next'
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

const noBlankSpace = helpers.regex(/^\S*$/);

export default {
  components: {
    vSelect,
    dataTypeDetail,
    draggable: VueDraggableNext
  },

  props: {
    index: Number,
    name: String,
    fulcrumDataName: String,
    typeName: String,
    type: String,
    format: String,
    options: Array,
    multiple: Boolean,
    existing: Boolean,
    archived: Boolean,
    entityType: String,
    entityTypeArchived: Boolean
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      dataType: "",
      dataTypeIndex: this.index, 
      dataFulcrumDataName: "",
      dataTypes: [
        {key: "Date", type: "string", format: "date"},
        {key: "Decimal Number", type: "number"},
        {key: "Rounded Number", type: "integer"},
        {key: "Select", type: "string", options: [], multiple: false},
        {key: "Multiselect", type: "string", options: [], multiple: true},
        {key: "Text", type: "string"},
        {key: "True/False", type: "boolean"},
      ],
      areOptionsValid: true
    }
  },

  validations () {
    return {
      dataType: {
        required
      },
      name: {
        required
      },
      dataFulcrumDataName: {
        noBlankSpace
      }
    }
  },

  created: function() {
    if (this.type != "undefined") {
      this.dataType = this.type
    }

    if (this.fulcrumDataName != "undefined") {
      this.dataFulcrumDataName = this.fulcrumDataName
    }
  },

  computed: {
    isDataTypeDefined: function() {
      return this.type != "undefined"
    },

    typeLabelClass: function() {
      return (this.type == "undefined" || this.archived || this.entityTypeArchived) ? "label-undefined" : "label-active"
    },

    isValid: function() {
      this.validate()

      return !this.v$.name.$error && !this.v$.dataType.$error && this.areOptionsValid
    },

    isArchived: function() {
      return this.archived ? true : false
    },

    removeDataTypeDetailButtonLabel: function() {
      return this.existing ? "Archive detail" : "Remove detail"
    },

    fulcrumFormFieldName: function() {
      return this.dataFulcrumDataName ? this.entityType + "[detail_schemas_attributes][" + this.index + "][fulcrumDataName]" : "";
    },

    fulcrumFormFieldId: function() {
      return this.dataFulcrumDataName ? this.entityType + "_detail_schemas_attributes_" + this.index + "_fulcrumDataName" : "";
    },
  },

  methods: {
    validate: function() {      
      this.areOptionsValid = true;

      this.v$.name.$touch();
      this.v$.dataType.$touch();

      if (this.$refs.dataTypeDetail) {
        this.$refs.dataTypeDetail.forEach(control => {
          if (control.v$) {
            control.v$.$touch();

            if (control.v$.$error) this.areOptionsValid = false;
          }
        })
      }

      this.$emit("validate-data-type");
    },

    setArchive: function(archived) {
      this.$emit("update", {
        index: this.index,
        archived: archived,
      });
    },

    setName: function(event) {
      this.$emit("update", {
        index: this.index,
        name: event.target.value,
      });
    },

    setFulcrumName: function(event) {
      this.dataFulcrumDataName = event.target.value

      this.$emit("update", {
        index: this.index,
        fulcrumDataName: event.target.value,
      });
    },

    setDataType: function(opt) {
      this.dataType = opt.type;

      this.$emit("update", {
        index: this.index,
        type: opt.type,
        options: opt.options,
        format: opt.format,
        multiple: opt.multiple
      });
    },

    addDataTypeDetail: function() {      
      this.$emit("add");
    },

    removeDataTypeDetail: function() {
      if (this.existing) {
        this.setArchive(true)
      }
      else {
        this.$emit("remove")
      }
    },

    unarchiveDataTypeDetail: function() {
      this.setArchive(false)
    },

    addDataTypeDetailOption: function() {
      let options = Object.assign([], this.options)

      options.push({
        name: "",
      })

      this.$emit("update", {
        index: this.index,
        options: options,
      })
    },

    removeDataTypeDetailOption: function(index) {
      let options = Object.assign([], this.options);

      if (options[index].existing) {
        options[index].archived = true;
      }
      else {
        options.splice(index, 1);
      }

      this.$emit("update", {
        index: this.index,
        options: options,
      });
    },

    updateDataTypeDetailOption: function(detailOptionObj) {
      let options = Object.assign([], this.options);
      let option = options[detailOptionObj.index];

      option.name = detailOptionObj.name;
      option.archived = detailOptionObj.archived || false;

      this.$emit("update", {
        index: this.index,
        options: options,
      });
    },

    formFieldName: function(name) {
      return this.entityType + "[detail_schemas_attributes][" + this.index + "][" + name + "]";
    },

    formFieldId: function(id) {
      return this.entityType + "_detail_schemas_attributes_" + this.index + "_" + id;
    },
  }
};
</script>
