<template>
<div 
  v-if="hasStats"
  class="site-statistics"
>
  
  <div class="stats-charts-full">

    <div class="row">
      <div class="col-sm-12">
        <ColumnChart
          ref="damageTypesDistributionBars"
          title="Damage Types"
          :data="damageTypesDistributionBars.data"
          columns-min-height="10px"
        />
      </div>
    </div>
    <div 
      v-if="hasDefectTypeFrequencyStats"
      class="row"
    >
      <div class="col-sm-6">
        <RowChart
          ref="ratingFrequencyBars"
          title="Rating Frequency"
          :data="ratingFrequencyBars.data"
          bars-min-width="5px"
        />
      </div>
      <div class="col-sm-6">
        <RowChart
          ref="worstCaseDamageFrequencyBars"
          title="Worst Case Damage Across The Site"
          :data="worstCaseDamageFrequencyBars.data"
          bars-min-width="5px"
        />
      </div>
    </div>
    <div 
      v-if="isWindTurbine && hasLocationFrequencyStats"
      class="row"
    >
      <div class="col-sm-12">
        <BladeChart
          ref="siteDamageFrequencyChart"
          title="Damage Locations"
          :data="damageLocationFrequencyChart.data"
        />
      </div>
    </div>
    <div 
      v-if="timeline.data.length < 0"
      class="row"
    >
      <div class="col-sm-12">
        <Timeline
          ref="siteActivitiesTimeline"
          title="Inspections Through Time"
          :data="timeline.data"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Filter from "../shared/filter/findings_filter_core";

import RowChart from "../shared/stats/row_chart";
import ColumnChart from "../shared/stats/column_chart";
import Timeline from "../shared/stats/timeline";
import BladeChart from "../shared/stats/blade_chart";

import helpers from "../../helpers.js";

export default {
  extends: Filter,

  components: {
    RowChart,
    ColumnChart,
    Timeline,
    BladeChart
  },

  props: {
    siteId: String,
    isWindTurbine: Boolean
  },

  data: function () {
    return {
      filter: {},
      statistics: {},

      ratingFrequencyBars: {
        data: []
      },

      worstCaseDamageFrequencyBars: {
        data: []
      },

      damageTypesDistributionBars: {
        data: []
      },

      damageLocationFrequencyChart: {
        data: {}
      },

      timeline: {
        data: []
      },

      isSpinnerVisible: true
    }
  },

  watch: {
    filter: function() {
      this.fetchStats();
    }
  },

  created: function() {
    let vmFilter = this;

    this.initStateFilter();

    this.filter.siteId = this.siteId;
    this.filter.historical = this.filter.historical || false;

    // Listen for the event.
    window.addEventListener("updatestate", (event) => {
      let tmpFilter = vmFilter.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmFilter.filter = Object.assign({}, tmpFilter);
      }
    });

    // Handle browser history change
    window.addEventListener("popstate", (event) => {
      const shouldRefresh = (event.state || event.isTrusted) && event.state === null;

      if (shouldRefresh) {
        this.refresh();
      }
    });
  },

  mounted: function() {
    this.fetchSiteTimeline();
  },

  computed: {
    isFilterEmpty: function() {    
      return (Object.keys(this.filter).filter(x => !["historical", "siteId"].includes(x))).length === 0;
    },

    filterParams: function () {
      let params = {
        filter: {}
      };

      if (!this.isFilterEmpty) {
        params.filter = this.filter;
      }
      else {
        params.filter = {
          siteId: this.siteId,
          historical: this.filter.historical || true
        }
      }

      return params;
    },

    hasRatingFrequencyStats: function() {
      return this.ratingFrequencyBars.data.length > 1;
    },

    hasWorstCaseDamageFrequencyStats: function() {
      return this.worstCaseDamageFrequencyBars.data.length > 1;
    },

    hasDefectTypeFrequencyStats: function() {
      return this.damageTypesDistributionBars.data.length > 1;
    },

    hasLocationFrequencyStats: function() {
      return this.damageLocationFrequencyChart.data.length > 1;
    },

    hasStats: function() {
      return this.hasRatingFrequencyStat || this.hasWorstCaseDamageFrequencyStats || this.hasDefectTypeFrequencyStats || this.hasLocationFrequencyStats;
    }
  },

  methods: {
    setDamageLocationFrequencyChart(stats) {
      let locationFrequency = Object.entries(JSON.parse(stats));
      
      locationFrequency.forEach((s) => {
        const key = s[0].toLowerCase().replace(/ /g,'')

        this.damageLocationFrequencyChart.data[key] = s[1]
      })
    },

    setRatingFrequencyBars(stats) {
      let ratingFrequency = Object.entries(JSON.parse(stats))

      let total = 0

      ratingFrequency.forEach((r) => {
        total += r[1]
      })

      ratingFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(helpers.damageMarks[r[0]])
        r.push(percentage)
      });

      this.ratingFrequencyBars.data = ratingFrequency;
    },

    setWorstCaseDamageFrequencyBars(stats) {
      let worstCaseDamageFrequency = Object.entries(JSON.parse(stats))

      let total = 0

      worstCaseDamageFrequency.forEach((r) => {
        total += r[1]
      })

      worstCaseDamageFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(helpers.damageMarks[r[0]])
        r.push(percentage)
      });

      this.worstCaseDamageFrequencyBars.data = worstCaseDamageFrequency;
    },

    setDamageTypesDistributionBars(stats) {
      let defectTypeFrequency = Object.entries(JSON.parse(stats));

      let total = 0

      defectTypeFrequency.forEach((r) => {
        total += r[1]
      })

      defectTypeFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(percentage)
      });

      this.damageTypesDistributionBars.data = defectTypeFrequency;
    },

    getCharts: function(statistics) {
      // Set rating frequency bars props
      this.setRatingFrequencyBars(statistics.ratingFrequency);

      // Set worst case damage frequency bars props
      this.setWorstCaseDamageFrequencyBars(statistics.worstCaseDamageFrequency);

      // Set worst case damage frequency bars props
      this.setDamageTypesDistributionBars(statistics.defectTypeFrequency);

      // Set damage location frequency chart props
      this.setDamageLocationFrequencyChart(statistics.locationFrequency);

      this.isSpinnerVisible = false;
    },

    fetchStats: function() {
      app.graphql.get("statistics.finding",
        this.filterParams,
        
        (data) => {
          this.getCharts(data.data.findings.statistics);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch site findings!"
          });
        }
      )
    },

    fetchSiteTimeline: function() {
      app.graphql.get("grid.inspections",
        {
          orderBy: {
            field: "DATE",
            direction: "ASC"
          },
          filter: {
            siteId: this.siteId 
          }
        },
        
        (data) => {
          this.setTimeline(data.data.inspections)
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch site timeline!"
          })
        }
      )
    },

    setTimeline: function(site) {
      let marks = []

      const firstDay = site.edges[0].node.date
      const lastDay = site.edges[[site.edges.length - 1]].node.date
      const totalDays = moment(lastDay).diff(moment(firstDay), 'days')

      site.edges.forEach(element => {
        const days = moment(element.node.date).diff(moment(firstDay), 'days')

        let mark = [
          helpers.formatDate.short(element.node.date),
          'Inspection',
          element.node.worstCaseRatings.clientRemediatedRating,
          days / totalDays * 100,
          element.node.project.id
        ]

        marks.push(mark)
      });

      this.timeline.data = marks
    },
  }
}
</script>