<template>
  <div class="table-wrapper">
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              style="width: 300px"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Component</SortableHeader>

            <th style="width: 300px">Asset</th>
            
            <th>Details</th>

            <SortableHeader
              class="text-center"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="'CLIENT_REMEDIATED_RATING'"
              @sort="reorder"
            >
              <InfoBadge
                placement="bottom"
                content="<strong>Worse Case Damage:</strong> This value is calculated for each component of an asset based on the worst rating among the approved findings for the most recent project for that component. The overall asset rating is then calculated based on the worst rating among those components.<br><br>Note: If one (or more) projects are included in the filter, the calculation is the same but the ratings will only be based on those filtered projects."
              />
              Worst Case Damage
            </SortableHeader>

            <SortableHeader
              class="text-right"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="'APPROVED_ACTIVITY_DATE'"
              @sort="reorder"
            >Last Activity</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <AssetComponent
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :filter="filter"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there are no components found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="components"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import AssetComponent from "./component";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Grid,

  components: {
    AssetComponent,
    InfoBadge
  },

  data: function () {
    return {
      items: [],

      grid: "components",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["historical", "siteId", "physicalAssetId"].includes(x))).length === 0;
    },

    filterParams: function () {
      let params = {};

      if (!this.isFilterEmpty) {
        params.findings = this.filter;
      };

      return params;
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.components",
        params,

        (data) => {
          let components = data.data.components,
              pageInfo = components.pageInfo;

          success(components, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch components!"
          })
        }
      )
    }
  }
}
</script>
