export default {
  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data: function () {
    return {
      filter: {},
      stateParams: [],
      isLoaded: false,
      isSpinnerVisible: true,
      isFilterInHistoricalMode: false,
      isFilterInPreviewMode: false
    }
  },

  watch: {
    filter: function() {
      this.isFilterInHistoricalMode = this.filter && this.filter.historical && this.filter.historical === true;
      this.isFilterInPreviewMode = this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;

      // Hide sidebar details on filter change
      if (this.isLoaded) {
        vmDetailsPane.$refs.detailsPane.close();
      }
    },
  },

  created: function () {
    this.stateParams = new URLSearchParams(window.location.search);

    // Handle default state filter
    let stateFilter = this.getFilterState();

    if (stateFilter) {
      stateFilter = stateFilter.replace("findingDetails", "details");

      stateFilter = JSON.parse(stateFilter);

      if (stateFilter.details) {
        if (stateFilter.details.constructor === Object && Object.keys(stateFilter.details).length >= 0) {
          stateFilter.details = JSON.stringify(stateFilter.details);
        }
      }
    }

    this.filter = (stateFilter) ? stateFilter : this.defaultFilter || {};

    this.filter.historical = this.filter.historical || false;

    // Listen for the event.
    window.addEventListener("updatestate", (eventt) => {
      let tmpFilter = this.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        this.filter = Object.assign({}, tmpFilter);
      }
    });
  },

  methods: {
    getFilterState: function() {
      return this.getStateParam("v-filter");
    },

    getStateParam: function(param) {
      this.stateParams = new URLSearchParams(window.location.search);

      return this.stateParams.get(param);
    },
  }
}
