<template>
<div>
<div class="row">
  <div class="col-sm-12">
    <draggable 
      ref="dataTypesList"
      v-model="dataTypes" 
      :disabled="entityTypeArchived"
      group="dataTypes" 
      @start="drag=true" 
      @end="drag=false"
    >
      <data-type
        v-for="(dataType, index) in dataTypes" 
        v-bind="dataType"
        :index="index"
        :key="index"
        :entity-type="entityType"
        :entity-type-archived="entityTypeArchived"
        :fulcrumDataName=dataType.fulcrumDataName
        ref="dataType"
        @update="updateDataType"
        @add="addDataTypeDetail(index + 1)"
        @remove="removeDataTypeDetail(index)"
      />
    </draggable>
  </div>
</div>
<div 
  class="row"
  v-if="!entityTypeArchived"
>
  <div class="col-sm-12">
    <button 
      type="button"
      class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
      @click="addDataTypeDetail()"
    >
      <span class="icon" data-icon="add"></span>
      <span>Add Detail</span>
    </button>
  </div>
</div>
</div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'

import dataType from "./form_data_type";

export default {
  components: {
    dataType,
    draggable: VueDraggableNext,
  },

  props: {
    defaultDataTypes: Array,
    entityType: String,
    entityTypeArchived: Boolean
  },

  data() {
    return {
      dataTypes: [],
      areDataTypesValid: true
    }
  },

  watch: {
    defaultDataTypes: function() {
      this.dataTypes = this.defaultDataTypes.map(element => {
        element.typeName = this.dataTypeName(element.type, element.format, element.options, element.multiple);

        return element;
      });
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return this.areDataTypesValid;
    }
  },

  created: function() {
    if (this.defaultDataTypes) {
      this.dataTypes = this.defaultDataTypes.map(element => {
        element.typeName = this.dataTypeName(element.type, element.format, element.options, element.multiple);

        return element;
      });
    }
  },

  methods: {
    validate: function() {      
      this.areDataTypesValid = true;

      if (this.$refs.dataType) {
        this.$refs.dataType.forEach(control => {
          if (control.v$) {
            control.v$.$touch();

            if (control.v$.$error) this.areDataTypesValid = false;
          }
        })
      }

      this.$emit("validate-data-types");
    },

    addDataTypeDetail: function(index) {          
      if (index) {
        this.dataTypes.splice(index, 0, {
          index: index,
          type: "undefined",
          typeName: "undefined"
        });
      }
      else {
        this.dataTypes.push({
          type: "undefined",
          typeName: "undefined"
        });
      }
    },

    removeDataTypeDetail: function(index) {          
      this.dataTypes.splice(index, 1);
    },

    dataTypeName: function(dataType, dataFormat, dataEnum, multuple) {
      let type;

      switch(dataType) {
        case "string":
          if (dataFormat && dataFormat == "date") {
            type = "date";
          }
          else if (dataEnum) {
            type = multuple ? "multiselect" : "select";
          }
          else {
            type = "text";
          }
          break;
        case "integer":
            type = "rounded number";
          break;
        case "number":
            type = "decimal number";
          break;
        case "boolean":
            type = "true / false";
          break;
      }

      return type;
    },
  
    updateDataType: function (dataTypeObj) {
      if (dataTypeObj.hasOwnProperty('index')) {
        let dataType = this.dataTypes[dataTypeObj.index];

        if (dataTypeObj.name) {
          dataType.name = dataTypeObj.name;
        }  

        if (dataTypeObj.fulcrumDataName) {
          dataType.fulcrumDataName = dataTypeObj.fulcrumDataName;
        }  
        
        if (dataTypeObj.format) {
          dataType.format = dataTypeObj.format;
        } 

        if (dataTypeObj.type) {
          dataType.type = dataTypeObj.type;
          dataType.typeName = this.dataTypeName(dataTypeObj.type, dataTypeObj.format, dataTypeObj.options, dataTypeObj.multiple);
        }
        
        if (dataTypeObj.options) {
          dataType.options = dataTypeObj.options;
        }

        if (dataTypeObj.multiple) {
          dataType.multiple = dataTypeObj.multiple;
        }

        dataType.archived = dataTypeObj.archived || false;

        let tmp = Object.assign([], this.dataTypes);

        this['dataTypes'] = tmp
      }
    }
  }
};
</script>
