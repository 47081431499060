<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for sites by name or description"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-left"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Site</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Site
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there are no related sites found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="sites"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Site from "./site";

export default {
  extends: Grid,

  components: {
    Site
  },

  props: {
    clientCompanyId: String
  },

  data: function () {
    return {
      items: [],

      grid: "client-sites",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      let params = {
        clientCompanyId: this.clientCompanyId
      };

      if (this.textSearch) {
        params.text = {
          value: this.textSearch,
          fields: this.textSearchFields
        };
      };

      return params;
    },

    textSearchFields: function () {
      return ["NAME", "DESCRIPTION"];
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.sites",
        params,

        (data) => {
          let sites = data.data.sites,
              pageInfo = sites.pageInfo;

          success(sites, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch sites!"
          })
        }
      )
    }
  }
}
</script>
