<template>
  <div class="d-flex">
    <div class="d-flex" v-if="showFindingStatus">
      <template v-if="findingStatusCounts.approved > 0 && (findingStatusCounts.review == 0 && findingStatusCounts.draft == 0)">
        <div class="item-status-approved">All Findings Approved</div>
      </template>
      <template v-else>
        <div 
          v-if="findingStatusCounts.review > 0"
          class="item-status-review mr-1">{{findingStatusReviewLabel}}</div>
        <div 
          v-if="findingStatusCounts.draft > 0"
          class="item-status-draft mr-1">{{findingStatusDraftLabel}}</div>
      </template>
    </div>
    <div class="d-flex" v-if="showRemediationStatus">
      <template v-if="remediationStatusCounts.approved > 0 && (remediationStatusCounts.review == 0 && remediationStatusCounts.draft == 0)">
        <div class="item-status-approved">All Remediations Approved</div>
      </template>
      <template v-else>
        <div 
          v-if="remediationStatusCounts.review > 0"
          class="item-status-review mr-1">{{remediationStatusReviewLabel}}</div>
        <div 
          v-if="remediationStatusCounts.draft > 0"
          class="item-status-draft mr-1">{{remediationStatusDraftLabel}}</div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default {
  components: {
    
  },

  props: {
    projectId: String,
    showFindingStatus: {
      type: Boolean,
      default: false
    },
    showRemediationStatus: {
      type: Boolean,
      default: false
    },
    loadOnInit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      findingStatusCounts: {
        approved: 0,
        draft: 0,
        review: 0
      },
      remediationStatusCounts: {
        approved: 0,
        draft: 0,
        review: 0
      },
      shouldLoad: false
    }
  },

  created: function () {
    this.shouldLoad = this.loadOnInit;
  },

  mounted: function() {
    if (this.shouldLoad && this.projectId != "") {
      this.fetchProject();
    }
  },

  computed: {
    findingStatusReviewLabel: function() {
      return this.findingStatusCounts.review + " pending review"; 
    },
    findingStatusDraftLabel: function() {
      return this.findingStatusCounts.draft + " in draft"; 
    },
    remediationStatusReviewLabel: function() {
      return this.remediationStatusCounts.review + " pending review"; 
    },
    remediationStatusDraftLabel: function() {
      return this.remediationStatusCounts.draft + " in draft"; 
    },
  },

  methods: {
    fetchProject: function() {
      app.graphql.get("details.project",
        {
          filter: {
            id: [this.projectId]
          }
        },
        (data) => {
          let currentProject = data.data.projects.edges[0].node;

          this.id = this.projectId;
          this.findingStatusCounts = currentProject.findingStatusCounts;
          this.remediationStatusCounts = currentProject.remediationStatusCounts;

          this.$emit("update", {
            findingsInReview: this.findingStatusCounts.review,
            remediationsInReview: this.remediationStatusCounts.review,
            findingsInDraft: this.findingStatusCounts.draft,
            remediationsInDraft: this.remediationStatusCounts.draft
          });
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch project!"
          });
        }
      );
    },
    
    refresh: function() {
      if (this.shouldLoad && this.projectId != "") {
        this.fetchProject();
      }
    },
  }
}

</script>
