<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive" for="client_company_name">Project name</label>
            <input
              :class="['form-control', {'error': v$.projectName.$error}]"
              placeholder="Name"
              type="text"
              name="project[name]"
              id="client_company_name"
              v-model.trim="projectName"
              @keydown.enter.prevent="submitForm"
            />
            <div v-if="v$.projectName.$error" class="error">Project name is required</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Client Company</label>
            <v-select
              placeholder="Select client company"
              ref="clientCompanies"
              :class="[{'vs-error': v$.projectClientCompanyId.$error}]"
              :options="clientCompanies"
              label="name"
              @update:modelValue="setClientCompany"
            />
            <div v-if="v$.projectClientCompanyId.$error" class="error">Client Company is required</div>
            <input 
              type="hidden" 
              id="project_client_company_id" 
              name="project[client_company_id]"
              v-model="projectClientCompanyId"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static" for="project_description">Project description</label>
            <textarea 
              rows="4" 
              class="form-control" 
              placeholder="Description" 
              name="project[description]" 
              id="project_description"
              v-model.trim="projectDescription"
            ></textarea>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    formId: String,
    currentProjectId: String,
    currentProjectName: String,
    currentProjectDescription: String,
    currentProjectClientCompany: Object,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      projectName: "",
      projectDescription: "",
      projectClientCompany: null,
      projectClientCompanyId: "",
      clientCompanies: this.clientCompanies,
    }
  },

  validations () {
    return {
      projectName: {
        required
      },
      projectClientCompanyId: {
        required
      }
    }
  },

  created: function() {
    this.projectName = this.currentProjectName;
    this.projectDescription = this.currentProjectDescription;

    if (this.currentProjectClientCompany && Object.keys(this.currentProjectClientCompany).length > 0) {
      this.projectClientCompany = this.currentProjectClientCompany;
      this.projectClientCompanyId = this.currentProjectClientCompany.id;
    }
  },

  mounted: function() {
    if (this.currentProjectClientCompany && Object.keys(this.currentProjectClientCompany).length > 0) {
      this.$refs.clientCompanies.updateValue(this.currentProjectClientCompany);
    }

    this.fetchClientCompanies();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  computed: {
    submitButtonLabel: function() {
      return (this.currentProjectId) ? "Update Project" : "Create Project";
    }
  },

  methods: {
    setClientCompany: function(opt) {
      this.projectClientCompany = (opt) ? opt : null;
      this.projectClientCompanyId = (opt) ? opt.id : "";
    },

    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.projectName.$touch();
      this.v$.projectClientCompanyId.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>