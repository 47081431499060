<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for sites by name or description"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 100px"
              :current-sort="orderBy"
              :sort-by="'RISK_FACTOR'"
              @sort="reorder"
            >
              <InfoBadge
                content="Risk Factor calculation description"
                placement="right"
              />
              Risk
            </SortableHeader>

            <SortableHeader
              class="text-left"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Site</SortableHeader>
          </tr>
        </thead>

        <tbody>
          <Site
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
            :clientStatistics="clientStatistics"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there are no related sites found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="sites"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Site from "./high_risk_site";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Grid,

  components: {
    Site,
    InfoBadge
  },

  props: {
    clientCompanyId: String
  },

  data: function () {
    return {
      items: [],
      clientStatistics: {
        totalRatedAssets: 0,
        minAssets: 0,
        maxAssets: 0,
      },

      grid: "client-sites-overview",

      textSearch: '',

      defaultOrderBy: {
        field: "RISK_FACTOR",
        direction: "DESC"
      },

      orderBy: {
        field: "RISK_FACTOR",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      let params = {
        clientCompanyId: this.clientCompanyId,
        findings: { 
          rating: [4,5], 
          historical: false 
        },
      };

      if (this.textSearch) {
        params.text = {
          value: this.textSearch,
          fields: this.textSearchFields
        };
      };

      return params;
    },

    textSearchFields: function () {
      return ["NAME", "DESCRIPTION"];
    }
  },

  methods: {
    getItems: function (page, pageSize) {
      let currentPage = page || this.pageInfo.currentPage,
          cursor = window.btoa((currentPage - 1) * (pageSize || this.pageInfo.pageSize));

      this.fetch(
        {
          cursor: cursor,
          pageSize: pageSize || this.pageInfo.pageSize,
          orderBy: this.queryOrder,
          filter: this.filterParams,
          includeStatistics: true
        },

        (items) => {
          if (items) {
            this.items = items.edges.map((edge) => { return edge.node });

            this.pageInfo = {
              pageSize: pageSize || this.pageInfo.pageSize,
              currentPage: currentPage,
              totalCount: items.totalCount,
              totalPageCount: items.totalPageCount
            }
          }

          this.isSpinnerVisible = false;

          this.onGetItems(this.items);
        }
      )
    },

    fetch: function (params, success) {
      app.graphql.get("grid.sites",
        params,

        (data) => {
          let sites = data.data.sites,
              pageInfo = sites.pageInfo;

          success(sites, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch sites!"
          })
        }
      )
    },

    onGetItems: function (sites) {
      sites.forEach((site) => {
        const worstCaseDamageFrequency = Object.entries(JSON.parse(site.statistics.worstCaseDamageFrequency))
        const siteTotalRatedAssets = worstCaseDamageFrequency.reduce((partialSum, a) => partialSum + a[1], 0);

        this.clientStatistics.totalRatedAssets += siteTotalRatedAssets;
        
        this.clientStatistics.minAssets = (this.clientStatistics.minAssets == 0 || this.clientStatistics.minAssets > siteTotalRatedAssets) ? siteTotalRatedAssets : this.clientStatistics.minAssets;
        this.clientStatistics.maxAssets = this.clientStatistics.maxAssets < siteTotalRatedAssets ? siteTotalRatedAssets : this.clientStatistics.maxAssets;
        
        site.statistics.totalRatedAssets = siteTotalRatedAssets;
      });

      this.$emit("get-items", sites);
    },
  }
}
</script>
