<template>
  <button 
    type="button"
    :class="['btn btn-primary', { 'spinner-is-visible': isSpinnerVisible }]"
    @click="onClick"
  >
    <div 
      class="spinner"
      v-if="isLoaded"
    ></div>
    {{label}}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Submit"
    },
    initialyLoading: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      isSpinnerVisible: true,
      isLoaded: false
    }
  },

  created: function() {
    this.isSpinnerVisible = this.initialyLoading;
  },

  mounted: function() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 500);
  },

  methods: {
    onClick: function(event) {
      this.$emit("submit");
    },

    loadingOn: function() {
      this.isSpinnerVisible = true;
    },

    loadingOff: function() {
      this.isSpinnerVisible = false;
    }
  }
}
</script>