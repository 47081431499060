<template>
<div>
<div class="row">
  <div class="col-sm-12">
    <draggable 
      ref="componentTypesList"
      v-model="componentTypes"
      group="componentTypes" 
      @start="drag=true" 
      @end="drag=false"
    >
      <component-type
        v-for="(componentType, index) in componentTypes" 
        :index="index"
        v-bind:componentType="componentType"
        :key="index"
        ref="componentType"
        @update="updateComponentType"
        @add="addComponentType(index + 1)"
        @remove="removeComponentType(index)"
      />
    </draggable>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <button 
      type="button"
      class="btn-expandable btn-expandable-add btn-expandable-sm float-end"
      :disabled="!currentPhysicalAssetType"
      @click="addComponentType()"
    >
      <span class="icon" data-icon="add"></span>
      <span>Add Component Type</span>
    </button>
  </div>
</div>
</div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'

import componentType from "./form_component_type";

export default {
  components: {
    draggable: VueDraggableNext,
    componentType
  },

  props: {
    currentPhysicalAssetType: Object
  },

  data() {
    return {
      componentTypes: [],
      componentTypesAreValid: true
    }
  },

  watch: {
    currentPhysicalAssetType: function() {
      this.componentTypes = [];

      this.componentTypesAreValid = true;
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return this.componentTypesAreValid;
    }
  },

  methods: {
    validate: function() {
      this.componentTypesAreValid = true;
 
      if (this.$refs.componentType) { 
        this.$refs.componentType.forEach(control => {
          if (control.v$) {
            control.v$.$touch();

            if (control.v$.$error) this.componentTypesAreValid = false;
          }
        })
      }

      this.$emit("validate-form");
    },


    addComponentType: function(index) {
      const ct = {
        index: index || this.componentTypes.length,
        id: "",
        name: "",
        physicalAssetType: this.currentPhysicalAssetType
      }
      
      if (index) {
        this.componentTypes.splice(index, 0, ct);
      }
      else {
        this.componentTypes.push(ct);
      }
    },

    removeComponentType: function(index) {          
      this.componentTypes.splice(index, 1);
    },
  
    updateComponentType: function (componentTypeOby) {
      let componentType = this.componentTypes[componentTypeOby.index];

      componentType.id = componentTypeOby.id;
      componentType.name = componentTypeOby.name;

      let tmp = Object.assign([], this.componentTypes);

      this['componentTypes'] = tmp
    }
  }
};
</script>
