<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div class="progress entity-details-progress">
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <photos
          ref="photos"
          :photos="photos"
          :gallery="entityId"
          :user-can-update="userCanUpdate"
          @update-photo="fetchFindingPhotos"
          @progress="uploadProgress"
        ></photos>
      </section>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue";
import EntityDetails from "./entity_details";
import Photos from "./photos";

export default {
  extends: EntityDetails,

  components: {
    Photos
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data() {
    return {
      finding: {},
      progress: 0,
      photos: [],
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  watch: {
    entityId: function() {
      this.fetchFindingPhotos();
    }
  },

  mounted: function() {
    this.fetchFindingPhotos();
  },

  methods: {
    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchFindingPhotos: function() {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.findingPhotos",

        {
          filter: {
            findingId: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true;

          this.photos = data.data.findingPhotos.edges.map((photo) => {return photo.node});

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding photos",
            message : "Something went wrong! Finding photos cannot be found!"
          });
        }
      );
    }
  }
}

</script>