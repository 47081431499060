<template>
  <div v-bind:class="['photo-gallery', {'spinner-is-visible': isSpinnerVisible }]">
    <div
      v-if="progress > 0"
      class="progress"
    >
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="{'hidden': !isLoaded }">
      <section>
        <photos
          ref="photos"
          :photos="photos"
          :gallery="entityId"
          :user-can-update="userCanUpdate"
          :entity-type="entityType"
          :view-mode="viewMode"
          :enable-edit-mode="enableEditMode"
          @update-photo="refresh"
          @progress="uploadProgress"
        ></photos>
      </section>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Photos from "./details/photos";

export default {
  components: {
    Photos
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean,
    entityType: {
      type: String,
      default: "finding"
    },
    enableEditMode: {
      type: Boolean,
      default: false
    },
    enableViewMode: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: String,
      default: "icons",
      validator: function (value) {
        return [
          "icons",
          "list",
          "tiles"
        ].indexOf(value) !== -1
      }
    },
    loadOnInit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      progress: 0,
      photos: [],
      s3UploadUrl: window._s3UploadUrl,
      isLoaded: false,
      isSpinnerVisible: true,
      shouldLoad: false
    }
  },

  watch: {
    entityId: function() {
      this.fetchPhotos();
    }
  },

  created: function () {
    this.shouldLoad = this.loadOnInit;
  },

  mounted: function() {
    if (this.shouldLoad) {
      this.fetchPhotos();
    }
  },

  methods: {
    refresh: function() {
      this.fetchPhotos();
    },

    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchPhotos: function() {
      app.graphql.get(
        "details." + this.entityType + "Photos",

        {
          filter: {
            [this.entityType + "Id"]: this.entityId
          }
        },

        (data) => {
          this.photos = data.data[this.entityType + "Photos"].edges.map((photo) => {return photo.node});

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Photos",
            message : "Something went wrong! Photos cannot be found!"
          });
        }
      );
    }
  }
}

</script>
