<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div
      v-if="progress > 0"
      class="progress entity-details-progress"
    >
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>
            <DamageRating
              :rating="currentRating"
            />
          </div>
          <div>
            {{finding.displayName}}
          </div>
        </header>
        <div 
          v-if="finding.findingType"
          class="entity-detail form-group" 
        >
          <label class="placeholder placeholder-static">Finding Type</label>
          <a :href="'/finding_types/' + finding.findingType.id" tile="Details">{{finding.findingType.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity</label>
          <a :href="'/inspections/' + finding.inspection.id" tile="Details">{{finding.inspection.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity date</label>
          {{ h$.formatDate.short(finding.inspection.date) }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Asset</label>
          <a
            :href="'/physical_assets/' + finding.component.physicalAsset.id"
            tile="Details"
          >{{finding.component.physicalAsset.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Component</label>
          <a :href="'/components/' + finding.component.id" tile="Details">{{finding.component.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity date</label>
          {{ h$.formatDate.short(finding.inspection.date) }}
        </div>
        <div class="row entity-detail form-group">
          <div v-if="userCanUpdate && (finding.reportedBy && finding.reportedBy.name)" class="col-sm-6">
            <div class="entity-detail form-group">
              <label class="placeholder placeholder-static">Reported by</label>
              {{finding.reportedBy.name}}
            </div>
          </div>
          <div class="col-sm-6">
            <div v-if="finding.inspectorNames.length > 0" class="entity-detail form-group">
              <label class="placeholder placeholder-static">Inspected by</label>
              <div v-for="(inspector, index) in finding.inspectorNames" :key="index">{{inspector}}</div>
            </div>
          </div>
        </div>
        <div v-if="userCanUpdate && (finding.reviewedBy || finding.approvedBy)" class="row entity-detail form-group">
          <div v-if="finding.reviewedBy && finding.reviewedBy.name" class="col-sm-6">
            <div class="entity-detail form-group">
              <label class="placeholder placeholder-static">Reviewed by</label>
              {{finding.reviewedBy.name}}
            </div>
          </div>
          <div v-if="finding.approvedBy && finding.approvedBy.name" class="col-sm-6">
            <div class="entity-detail form-group">
              <label class="placeholder placeholder-static">Approved by</label>
              {{finding.approvedBy.name}}
            </div>
          </div>
        </div>
      </section>
      
      <section>
        <div
          class="entity-detail form-group"
          v-for="(value, key) in finding.details"
          :key="key"
        >
          <label class='placeholder placeholder-static'>{{key}}</label>
          {{detailFormat(value)}}
        </div>
      </section>

      <section v-if="finding.remediation">
        <div
          class="entity-detail form-group"
        >
          <label class='placeholder placeholder-static'>Remediation</label>
          <DetailsButton
            title="Related Remediation Details"
            entity-type="remediationDetails"
            :track-history="true"
            :entity="finding.remediation.id"
          >
            <a
              :href="'/remediations/' + finding.remediation.id"
              title="Remediation"
            >{{finding.remediation.displayName}}</a>
          </DetailsButton>
        </div>
      </section>

      <section>
        <h3 style="padding-bottom: 7px;">Photos</h3>
        <photos
          ref="photos"
          :photos="photos"
          :gallery="entityId"
          :user-can-update="userCanUpdate"
          @update-photo="fetchFindingPhotos"
          @progress="uploadProgress"
        ></photos>
      </section>

      <section
        v-if="userCanUpdate && showActivity"
        class="entity-details-activities"
      >
        <h3 style="padding-bottom: 7px;">Activity Log</h3>
        <ol>
          <li
            v-for="(activity, index) in finding.activities"
            :key="index"
          >
            <ul>
              <li><strong>{{activity.user.name}}</strong> <span v-html="logEntry(activity.objectChanges.changes)"></span></li>
            </ul>
            <time :datetime="activity.createdAt">{{ h$.formatDate.full(activity.createdAt) }}</time>
          </li>
        </ol>
      </section>

      <div class="entity-details-actions">
        <div>
          <a
            :href="'/findings/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/findings/' + entityId + '/edit'"
            title="Edit"
            class="btn-expandable btn-expandable-edit btn-expandable-sm"
          >
            <span class="icon" data-icon="edit"></span>
            <span>Edit</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/findings/' + entityId"
            title="Remove"
            class="btn-expandable btn-expandable-delete btn-expandable-sm"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <span class="icon" data-icon="delete"></span>
            <span>Remove</span>
          </a>
        </div>
      </div>

    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating";
import Photos from "./photos";
import DetailsButton from "../../shared/grid/details_button";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating,
    Photos,
    DetailsButton
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean,
    args: Object,
  },

  data() {
    return {
      finding: {
        component: {
          physicalAsset: {
            site: {}
          }
        },
        inspection: {},
        activities: [],
        reportedBy: {},
        reviewedBy: {},
        approvedBy: {},
        inspectorNames: '',
        remediation: {}
      },
      progress: 0,
      photos: [],
      s3UploadUrl: window._s3UploadUrl,
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  watch: {
    entityId: function() {
      this.resetPhotoUplad();

      this.fetchFindingDetails();
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  mounted: function() {
    this.resetPhotoUplad();

    this.fetchFindingDetails();
  },

  computed: {
    showActivity: function() {
      return this.finding.activities && this.finding.activities.length > 0;
    },

    showInPreviewMode: function() {
      return (this.args && this.args.hasOwnProperty('showInPreviewMode')) ? this.args.showInPreviewMode : false;
    },

    currentRating: function() {
      let rating = null;

      if (Number.isInteger(this.finding.clientRemediatedRating) || Number.isInteger(this.finding.remediationPreviewRating)) {
        rating = this.showInPreviewMode ? this.finding.remediationPreviewRating : this.finding.clientRemediatedRating;
      }

      return rating;
    }
  },

  methods: {
    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchFindingDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.finding",

        {
          filter: {
            id: [this.entityId],
            status: ["draft", "review", "approved"]
          }
        },

        (data) => {
          this.isLoaded = true;

          this.finding = data.data.findings.edges[0].node;

          this.finding.details = JSON.parse(this.finding.details);

          this.finding.displayName = !this.userCanUpdate ? this.finding.findingType.name : this.finding.displayName;

          // Remove finding description in a case of insufficient rights
          if (!this.userCanUpdate && this.finding.details.hasOwnProperty("Description")) {
            delete this.finding.details.Description;
          }

          this.photos = this.finding.findingPhotos.edges.map((photo) => {return photo.node});

          this.fetchFindingHistory();
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding details",
            message : "Something went wrong! Finding cannot be found or doesn't exist!"
          });
        }
      );
    },

    resetPhotoUplad: function() {
      if ('photos' in this.$refs) {
        if ('resetPhotoUpload' in this.$refs.photos) {
          this.$refs.photos.resetPhotoUpload()
        }
      }
    },

    fetchFindingPhotos: function() {
      app.graphql.get(
        "details.findingPhotos",

        {
          filter: {
            findingId: this.entityId
          }
        },

        (data) => {
          this.photos = data.data.findingPhotos.edges.map((photo) => {return photo.node});
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding photos",
            message : "Something went wrong! Finding photos cannot be found!"
          });
        }
      );
    },

    fetchFindingHistory: function() {
      // Match and remove [null,""] changes
      var r = /,?\"[\w]+\":\[null,""\]/g;

      // Get finding versions
      app.graphql.get(
        "details.findingVersions",

        {
          filter: {
            findingId: this.entityId
          }
        },

        (versions) => {
          var findingVersions = versions.data.findingVersions.edges;

          let findingActivities = versions.data.findingVersions.edges.map((findingVersion) => {
            let activity = {
              createdAt: findingVersion.node.createdAt,
              objectChanges: JSON.parse(findingVersion.node.objectChanges.replace(r, "").replace("{,", "{")),
              originalObject: JSON.parse(findingVersion.node.originalObject),
              user: findingVersion.node.user
            };

            let findingHistoryActivitites = [];

            Object.keys(activity.objectChanges).forEach((key) => {
              if (!['updated_at', 'status'].includes(key)) findingHistoryActivitites.push(key);

              if (key == "status") {
                findingHistoryActivitites.push({status: activity.objectChanges[key]});
              }
            });

            activity.objectChanges.changes = findingHistoryActivitites;

            return activity;
          });

          // Sort changes
          findingActivities.reverse();

          this.finding['activities'] = findingActivities

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding versions",
            message : "Something went wrong! Finding history data cannot be found or doesn't exist!"
          });
        }
      );
    },

    detailFormat: function(value) {
      if (Array.isArray(value)) {
        return value.join(", ");
      }
      
      if (/(\d{4}-\d{2}-\d{2})/.test(value)) {
        return this.h$.formatDate.short(value);
      }

      return value;
    },
    
    change: function (prop) {
      const changes = {
        created_at: 'create date',
        component_id: 'component',
        inspection_id: 'activity',
        reported_by_id: 'reporter',
        reviewed_by_id: 'reviewer',
        approved_by_id: 'approver',
        created_by_id: 'created',
        finding_type_id: 'finding type',
        remediation_id: 'remediation',
        fulcrum_record_id: 'Fulcrum record'
      }

      if (changes.hasOwnProperty(prop)){
       return changes[prop];
      }
      else{
        return prop;
      }
    },

    logEntry: function(changes) {
      let logEntry = '';

      if (changes.includes('id')) {
        logEntry = '<strong>created finding</strong>';
      }
      else {
        logEntry = 'changed finding ';
        
        for (let i = 0; i < changes.length; i++) {
          if (typeof changes[i] == 'object') {
            logEntry = logEntry + '<strong>status from ' + changes[i].status[0] + ' to ' + changes[i].status[1] + '</strong>';
          }
          else {
            logEntry = logEntry + '<strong>' + this.change(changes[i]) + '</strong>';
          }

          if (i == 0 && changes.length > 1 && i+2 < changes.length) {
            logEntry = logEntry + ', ';
          }
          else if (i+2 < changes.length) {
            logEntry = logEntry + ', ';
          }
          else if (i+2 == changes.length) {
            logEntry = logEntry + ' and ';
          }
        }
      }
    
      return logEntry; 
    }
  }
}

</script>
