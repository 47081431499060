<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="clientCompanyUrl"
        :title="name"
        :data-entity="id"
      >{{name}}</a>
      <div v-if="description" class="note">{{description}}</div>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="clientCompanyUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="clientCompanyUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="clientCompanyUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";

export default {
  extends: Row,

  components: {
    RowAction
  },

  props: {
    id: String,
    name: String,
    description: String
  },

  computed: {
    clientCompanyUrl: function () {
      return "/client_companies/" + this.id;
    }
  }
}
</script>
