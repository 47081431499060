<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="userAgreementUrl"
      >{{name}}</a>
    </td>

    <td>
      {{title}}
    </td>

    <td style="text-align: right">
      {{version}}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="userAgreementUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="userAgreementUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="userAgreementUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";

export default {
  extends: Row,

  components: {
    RowAction,
    Details
  },

  props: {
    id: String,
    name: String,
    title: String,
    version: Number
  },

  computed: {
    userAgreementUrl: function () {
      return "/user_agreements/" + this.id;
    }
  }
}
</script>
