<template>
  <div class="table-wrapper related-items">
    <div 
      v-if="pageInfo.totalCount > 0"
      class="table-responsive table-scroll"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center no-label"
              style="width: 60px"
              :current-sort="orderBy"
              :sort-by="'CLIENT_REMEDIATED_RATING'"
              :update-ui-state="false"
              @sort="reorder"
            >
              <InfoBadge
                content="<strong>Worse Case Damage:</strong> This value is calculated for each component of an asset based on the worst rating among the approved findings for the most recent project for that component. The overall asset rating is then calculated based on the worst rating among those components."
                placement="left"
              />
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              :update-ui-state="false"
              @sort="reorder"
            >Component</SortableHeader>
          </tr>
        </thead>
      </table>

      <div
        :class="['table-body', { 'spinner-is-visible': isSpinnerVisible }]"
        style="max-height: 200px;"
      >
        <table class="table">
          <tbody>
            <AssetComponent
              v-for="(item, index) in items"
              v-bind="item"
              :key="item.id"
              :filter="filter"
              :index="index"
              :policy-update="policyUpdate"
              :show-in-preview-mode="isFilterInPrevievMode"
              :ref="item.id"
            />
          </tbody>
        </table>

        <div class="spinner"></div>
      </div>

      <nav class="pagination-wrapper" style="padding-top: 5px">
        <small class="pagination-counter"> Showing <span>{{ pageInfo.totalCount }}</span> components</small>
      </nav>

    </div>

    <p 
      v-if="pageInfo.totalCount <= 0"
      style="padding-top: 15px;"
    ><small>There are no related damaged components</small></p>
  </div>
</template>

<script>
import Grid from "../grid/grid";

import AssetComponent from "./related_component";
import InfoBadge from "../info_badge";

export default {
  extends: Grid,

  components: {
    AssetComponent,
    InfoBadge
  },

  props: {
    physicalAssetId: String,
    defaultFilter: Object,
    trackHistory: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      items: [],

      grid: "components",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  watch: {
    defaultFilter: {
      handler(newValue, oldValue) {
        this.filter = this.defaultFilter;
      }, 
      immediate: true
    }
  },

  computed: {
    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    filterParams: function () {
      let params = {
        physicalAssetId: this.physicalAssetId,
      };

      params.findings = this.filter;

      params.findings.physicalAssetId = this.physicalAssetId;
      params.findings.historical = this.filter.historical || false;

      return params;
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get('grid.asset.components',
        params,

        (data) => {
          let components = data.data.components,
              pageInfo = components.pageInfo;

          success(components, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch components!"
          })
        }
      )
    }
  }
}
</script>
