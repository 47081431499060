<template>
  <a
    :title="title"
    :class="['table-row-action', actionClass]"
    :data-confirm="confirmMessage"
    :data-method="dataMethod"
  >
  </a>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
      validator: function (value) {
        return [
          "read",
          "update",
          "delete",
          "upload",
          "approve",
          "import",
          "download",
          "remove",
          "block",
          "unblock",
          "show",
          "hide",
          "revoke"
        ].indexOf(value) !== -1
      }
    }
  },

  computed: {
    actionClass: function () {
      return "action-" + this.action
    },

    confirmMessage: function () {
      if (["delete", "remove", "block", "revoke"].includes(this.action)) {
        return "Are you sure?"
      }
    },

    dataMethod: function () {
      if (["delete", "block"].includes(this.action)) {
        return "delete"
      } else if (this.action === "unblock") {
        return "put"
      }
    },

    title: function () {
      return {
        read: "Details",
        update: "Edit",
        delete: "Remove",
        remove: "Remove",
        block: "Block",
        unblock: "Unblock",
        approve: "Approve",
        revoke: "Revoke"
      }[this.action]
    }
  }
}
</script>
