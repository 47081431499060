<template>
  <div @keydown.enter.prevent>
    <fieldset>
      <legend>Remediation Type</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Project</label>
            <v-select
              placeholder="Select project"
              ref="projects"
              :class="[{'vs-error': v$.projectId.$error}]"
              :options="projects"
              label="name"
              @update:modelValue="setProject"
            />
            <div v-if="v$.projectId.$error" class="error">Project is required</div>
            <input 
              type="hidden" 
              id="remediation_project_id"
              name="remediation[project_id]"
              :value="projectId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Activity</label>
            <v-select
              placeholder="Select activity"
              ref="inspections"
              :class="[{'vs-error': v$.inspectionId.$error && projectId}]"
              :disabled="!projectId"
              :options="inspections"
              :get-option-label="getLabel"
              label="name"
              @update:modelValue="setInspection"
            />
            <div v-if="v$.inspectionId.$error && projectId" class="error">Activity is required</div>
            <input 
              type="hidden" 
              id="remediation_inspection_id" 
              name="remediation[inspection_id]"
              :value="inspectionId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Site</label>
            <input 
              placeholder="Acivity site"
              class="form-control"
              type="text"
              readonly
              :disabled="!inspectionId"
              :value="siteName"
            />
            <input 
              type="hidden" 
              id="remediation_site_id" 
              name="remediation[site_id]"
              :value="siteId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Asset</label>
            <v-select
              placeholder="Select asset"
              ref="assets"
              :class="[{'vs-error': v$.physicalAssetId.$error && siteId}]"
              :disabled="!siteId"
              :options="assets"
              label="name"
              @update:modelValue="setPhysicalAsset"
            />
            <div v-if="v$.physicalAssetId.$error && siteId" class="error">Physical Asset is required</div>
            <input
              type="hidden"
              id="remediation_physical_asset_id"
              name="remediation[physical_asset_id]"
              :value="physicalAssetId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-static">Remediation Type</label>
            <v-select
              placeholder="Select remediation type"
              ref="remediationTypes"
              :class="[{'vs-error': v$.remediationTypeId.$error && physicalAssetTypeId}]"
              :disabled="!physicalAssetTypeId"
              :options="remediationTypes"
              label="name"
              @update:modelValue="setRemediationType"
            />
            <div v-if="v$.remediationTypeId.$error && physicalAssetTypeId" class="error">Remediation type is required</div>
            <input
              type="hidden"
              id="remediation_remediation_type_id"
              name="remediation[remediation_type_id]"
              :value="remediationTypeId"
            />
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect
  },

  props: {
    remediationId: String,
    defaultClientCompany: Object,
    defaultProject: Object,
    defaultInspection: Object,
    defaultSite: Object,
    defaultPhysicalAsset: Object,
    defaultRemediationType: Object,
    newProjectId: String,
    newInspectionId: String,
    newFindingId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      projects: this.projects,
      inspections: this.inspections,
      sites: this.sites,
      assets: this.assets,
      remediationTypes: this.remediationTypes,
      remediationTypeId: null,
      clientCompanyId: null,
      projectId: null,
      inspectionId: null,
      siteId: null,
      siteName: null,
      physicalAssetId: null,
      physicalAssetTypeId: null
    }
  },

  validations () {
    return {
      projectId: {
        required
      },
      inspectionId: {
        required
      },
      physicalAssetId: {
        required
      },
      remediationTypeId: {
        required
      }
    }
  },

  computed: {
    sortOrder: function() {
      return {
        field: "NAME",
        direction: "ASC"
      }
    },

    isValid: function() {
      this.validate();

      return !this.v$.$error;
    }
  },

  mounted: function () {
    let vm = this;

    this.fetchProjects();

    if (this.remediationId.length > 0) {
      if (Object.keys(this.defaultClientCompany).length > 0) {
        this.clientCompanyId = this.defaultClientCompany.id;
      }
  
      if (Object.keys(this.defaultProject).length > 0) {
        this.projectId = this.defaultProject.id;
      }

      if (Object.keys(this.defaultInspection).length > 0) {
        this.inspectionId = this.defaultInspection.id;

        this.siteId = this.defaultSite.id;
        this.siteName = this.defaultSite.name;

        this.defaultInspection.site = {
          id: this.defaultSite.id,
          name: this.defaultSite.name
        };
      }

      if (Object.keys(this.defaultRemediationType).length > 0) {
        this.remediationTypeId = this.defaultRemediationType.id;
      }

      if (Object.keys(this.defaultPhysicalAsset).length > 0) {
        this.physicalAssetId = this.defaultPhysicalAsset.id;
        this.defaultPhysicalAsset.physicalAssetType = {
          id: this.defaultPhysicalAsset.physical_asset_type_id
        };
      }

      vm.$refs.projects.updateValue(vm.defaultProject);
      vm.$refs.inspections.updateValue(vm.defaultInspection);
      vm.$refs.assets.updateValue(vm.defaultPhysicalAsset);
      vm.$refs.remediationTypes.updateValue(vm.defaultRemediationType);
    }
    else {
      if (this.newProjectId.length > 0) {
        this.getSingleProject();
      }

      if (this.newInspectionId.length > 0) {
        this.getSingleInspection();
      }

      if (this.newFindingId.length > 0) {
        this.getSingleFinding();
      }
    }
  },

  methods: {
    getLabel: function(obj) {
      return obj.name;
    },

    setProject: function(opt) {
      if (opt) {
        this.projectId = opt.id;

        this.fetchInspections();
      }
      else {
        this.projectId = null;
        this.inspectionId = null;
        this.siteId = null;
        this.siteName = null;

        this.inspections = [];
      }

      this.$refs.inspections.clearSelection();

      this.v$.$reset();
    },

    setInspection: function(opt) {
      if (opt) {
        this.inspectionId = opt.id;

        if (opt.site && (this.siteId != opt.site.id)) {
          this.siteId = opt.site.id;
          this.siteName = opt.site.name;
        }

        this.fetchAssets();
      }
      else {
        this.inspectionId = null;
        this.siteId = null;
        this.siteName = null;

        this.assets = [];
      }

      this.$refs.assets.clearSelection();

      this.v$.$reset();
    },

    setPhysicalAsset: function(opt) {
      this.$refs.remediationTypes.clearSelection();

      if (opt) {
        this.physicalAssetId = opt.id;

        if (opt.physicalAssetType && (this.physicalAssetTypeId != opt.physicalAssetType.id)) {
          this.physicalAssetTypeId = opt.physicalAssetType.id;
        }

        this.fetchRemediationTypes();
      }
      else {
        this.physicalAssetId = null;
        this.physicalAssetTypeId = null;
        this.remediationTypeId = null;
      }

      this.setFindingsFilter();
    },

    setRemediationType: function(opt) {
      if (opt) {
        this.remediationTypeId = opt.id;
      }
      else {
        this.remediationTypeId = null;
      }

      this.$emit("change-remediation-type", opt);

      this.setFindingsFilter();
    },

    setFindingsFilter: function() {
      this.$emit("change-findings-filter", {
        physicalAssetId: this.physicalAssetId,
        remediationTypeId: this.remediationTypeId
      });
    },

    getSingleProject: function () {
      app.graphql.get("select.projects",
        {
          filter: {
            id: this.newProjectId
          }
        },

        (data) => {
          if (data.data.projects.totalCount > 0) {
            let project = data.data.projects.edges[0].node;
            
            this.projectId = project.id;
            this.$refs.projects.updateValue(project);
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch project!"
          });
        }
      )
    },

    fetchProjects: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder
      };

      if (this.clientCompanyId) {
        fetchParams.filter = {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.projects = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.projects",
          fetchParams,

          (data) => {
            let projects = data.data.projects,
                pageInfo = projects.pageInfo;

            success(projects, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch projects!"
            });
          }
        )
      };

      let getProjects = (cursor) => {
        fetch(
          cursor,

          (projects, pageInfo) => {
            projects.edges.forEach((edge) => this.projects.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getProjects(pageInfo.endCursor))
            }
          }
        )
      };

      getProjects(window.btoa(0));
    },

    getSingleInspection: function () {
      app.graphql.get("select.inspections",
        {
          filter: {
            id: this.newInspectionId
          }
        },

        (data) => {
          if (data.data.inspections.totalCount > 0) {
            let inspection = data.data.inspections.edges[0].node;
            
            this.projectId = inspection.project.id;
            this.$refs.projects.updateValue(inspection.project);

            this.inspectionId = inspection.project.id;
            this.$refs.inspections.updateValue({
              id: inspection.id,
              name: inspection.name,
              site: {
                id: inspection.site.id,
                name: inspection.site.name
              }
            });
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch inspection!"
          });
        }
      )
    },

    fetchInspections: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        filter: {
          projectId: this.projectId
        }
      };

      this.inspections = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.inspections",
          fetchParams,

          (data) => {
            let inspections = data.data.inspections,
                pageInfo = inspections.pageInfo;

            success(inspections, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch project activities!"
            });
          }
        )
      };

      let getInspections = (cursor) => {
        fetch(
          cursor,

          (inspections, pageInfo) => {
            inspections.edges.forEach((edge) => this.inspections.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getInspections(pageInfo.endCursor))
            }
          }
        )
      };

      if (this.projectId) {
        getInspections(window.btoa(0));
      }
    },

    fetchAssets: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        filter: {
          siteId: this.siteId
        }
      };

      this.assets = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assets",
          fetchParams,

          (data) => {
            let assets = data.data.physicalAssets,
                pageInfo = assets.pageInfo;

            success(assets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site assets!"
            });
          }
        )
      };

      let getAssets = (cursor) => {
        fetch(
          cursor,

          (assets, pageInfo) => {
            assets.edges.forEach((edge) => this.assets.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getAssets(pageInfo.endCursor))
            }
          }
        )
      };

      if (this.siteId) {
        getAssets(window.btoa(0));
      }
    },

    fetchRemediationTypes: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder
      };

      if (this.physicalAssetTypeId) {
        fetchParams.filter = {
          physicalAssetTypeId: this.physicalAssetTypeId
        }
      };

      this.remediationTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.remediationTypes",
          fetchParams,

          (data) => {
            let remediationTypes = data.data.remediationTypes,
                pageInfo = remediationTypes.pageInfo;

            success(remediationTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Remediation Types!"
            });
          }
        )
      };

      let getRemediationTypes = (cursor) => {
        fetch(
          cursor,

          (remediationTypes, pageInfo) => {
            if (currentPage == 1) {
              this.remediationTypes = [];
            }

            remediationTypes.edges.forEach((edge) => this.remediationTypes.push(edge.node));
            
            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getRemediationTypes(pageInfo.endCursor));
            }
          }
        )
      };

      if (this.physicalAssetTypeId) {
        getRemediationTypes(window.btoa(0));
      }
    },

    getSingleFinding: function () {
      app.graphql.get("select.findings",
        {
          filter: {
            id: this.newFindingId
          }
        },

        (data) => {
          if (data.data.findings.totalCount > 0) {
            let finding = data.data.findings.edges[0].node;

            this.projectId = finding.inspection.project.id;
            this.$refs.projects.updateValue(finding.inspection.project);

            this.inspectionId = finding.inspection.id;
            this.$refs.inspections.updateValue({
              id: finding.inspection.id,
              name: finding.inspection.name,
              site: {
                id: finding.component.physicalAsset.site.id,
                name: finding.component.physicalAsset.site.name
              }
            });

            this.physicalAssetId = finding.component.physicalAsset.id;
            this.$refs.assets.updateValue(finding.component.physicalAsset);
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch finding!"
          });
        }
      )
    },

    validate: function() {
      this.v$.projectId.$touch();
      this.v$.inspectionId.$touch();
      this.v$.physicalAssetId.$touch();
      this.v$.remediationTypeId.$touch();
    }
  }
}
</script>
