<template>
  <tr>
    <td>
      <a
        title="Preview"
        :href="remediationTypeUrl"
      >
        {{name}}
      </a>
      <div v-if="description" class="note">{{description}}</div>
    </td>

    <td class="text-center">
      <span 
        :class="categoryStyle"
      >{{category}}</span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="remediationTypeUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationTypeUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationTypeUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import RowAction from "../shared/grid/row_action";
import Helpers from "../../helpers";

export default {
  components: {
    RowAction
  },

  props: {
    id: String,
    name: String,
    category: String,
    description: String,
    policyUpdate: Boolean
  },

  computed: {
    categoryStyle: function() {
      return (this.category == "replace") ? "label-unset" : "label-default";
    },

    remediationTypeUrl: function () {
      return "/remediation_types/" + this.id;
    }
  }
}
</script>
