<template>
  <tr>
    <td 
      class="text-center"
      style="width: 40px; vertical-align: middle"
    >
      <DamageRating
        :rating="clientRemediatedRating" 
      />
    </td>

    <td>
      <template v-if="showFindingNameColumn">
        <DetailsButton
          title="Related Finding Details"
          entity-type="findingDetails"
          :track-history="trackHistory"
          :entity="id"
        >
          {{component.name}}: <a
            :href="findingUrl"
            title="Finding"
          >{{displayName}}</a>
          <div class="note">
            <div>{{findingType.name}}</div>
            <div><strong>Project:</strong> {{inspection.project.name}}</div>
          </div>
        </DetailsButton>
      </template>
      <template v-else>
        {{findingType.name}}
      </template>
    </td>
  </tr>
</template>

<script>
import Row from "../../shared/grid/row";
import DetailsButton from "../../shared/grid/details_button";
import DamageRating from "../../shared/damage_rating";

export default {
  extends: Row,

  components: {
    DetailsButton,
    DamageRating
  },

  props: {
    filter: Object,
    clientRemediatedRating: Number,
    id: String,
    displayName: String,
    component: Object,
    findingType: Object,
    inspection: Object,
    status: String,
    showStatus: Boolean,
    showFindingNameColumn: Boolean,
    photoCount: Number,
    index: Number,
    trackHistory: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    findingUrl: function () {
      return "/findings/" + this.id
    }
  }
}
</script>
