<template>
  <ActionButton
    ref="actionButton"
    action="approve"
    icon="done_all"
    :text="text"
    :title="title"
    :confirm="confirm"
    :size="size"
    :css="css"
    @clickAction="approveFindings()"
  />
</template>

<script>
import ActionButton from "../shared/action_button";

export default {
  components: {
    ActionButton
  },

  props: {
    inspectionId: String,
    projectId: String,
    text: {
      type: String,
      default: 'Approve Findings'
    },
    title: {
      type: String,
      default: 'Approve All Findings'
    },
    confirm: {
      type: String,
      default: 'All Findings will be approved?'
    },
    size: {
      type: String,
      default: 'sm'
    },
    css: {
      type: String,
      default: ''
    }
  },

  methods: {
    approveInspectionFindings: function() {
      this.$refs.actionButton.isSpinnerVisible = true;

      app.graphql.get(
        "update.inspection.approveFindings",
        {
					input: {
						inspectionId: this.inspectionId
					}
        },
    
        (data) => {
        	const response = data.data.approveInspectionFindings;
    
					if (response.errors) {
						response.errors.forEach((error) => {
							app.ui.toast.add({
								priority: "warning",
								title: "Unable to update finding",
								message: error
							});

              this.$refs.actionButton.isSpinnerVisible = false;
          	});
        	} else {
						app.ui.toast.add({
							priority: "success",
							title: "Inspection Findings",
							message: "All inspection findings from <strong>review</strong> status set to <strong>approved</strong>"
						});

            setTimeout(() => {
              this.$refs.actionButton.isSpinnerVisible = false;

              this.$emit("approved");
            }, 1000);
					}
        }
			);
    },

    approveProjectFindings: function() {
      this.$refs.actionButton.isSpinnerVisible = true;

			app.graphql.get(
        "update.project.approveFindings",
        {
					input: {
						projectId: this.projectId
					}
        },
    
        (data) => {
        	const response = data.data.approveProjectFindings;
    
					if (response.errors) {
						response.errors.forEach((error) => {
							app.ui.toast.add({
								priority: "warning",
								title: "Unable to update finding",
								message: error
							});

              this.$refs.actionButton.isSpinnerVisible = false;
          	});
        	} else {
						app.ui.toast.add({
							priority: "success",
							title: "Project Findings",
							message: "All project findings from <strong>review</strong> status set to <strong>approved</strong>"
						});

            setTimeout(() => {
              this.$refs.actionButton.isSpinnerVisible = false;

              this.$emit("approved");
            }, 1000);
					}
        }
			);
    },

    approveFindings: function() {
      if (this.inspectionId) {
        this.approveInspectionFindings()
      }

      if (this.projectId) {
        this.approveProjectFindings()
      }
      
    }
  }
}
</script>
