<template>
<div class="form-group">
  <label
    class="placeholder placeholder-inactive"
  >
    {{placeholder}}
  </label>

  <div class="checkbox-group ratings-group">
    <div 
      v-for="(classification, index) in classifications" 
      v-bind:key="classification.value"
      class="rating-group"
    >
      <input 
        type="checkbox"
        ref="rating"
        :value="classification.value" 
        :id="'chk-rating-' + index"
        :checked="isDefaultRating(classification.value)"
        :disabled="!isEnabled(index)"
        @change="onChange(classification.value, $event.target.checked)"
      >
      <label 
        :for="'chk-rating-' + index" 
        :class="['checkbox rating-' + index, {'disabled' : !isEnabled(index)}]"
      >
        {{classification.label}}
      </label>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    defaultRating: Array,
    ratings: Array,
    id: String,
    name: String,
    value: String,
    type: {
      type: String,
      default: "checkbox"
    },
    placeholder: {
      type: String,
      default: "Ratings"
    }
  },

  mounted: function() {
    this.selectedRating = this.defaultRating;
  },

  data() {
    return {
      selectedRating: [],
      classifications: [
        { label: '-', value: 0 },
        { label: 'A', value: 1 },
        { label: 'B', value: 2 },
        { label: 'C', value: 3 },
        { label: 'D', value: 4 },
        { label: 'F', value: 5 }
      ]
    }
  },

  methods: {
    onChange: function(val, checked) {
      if (checked) {
        if (!this.selectedRating.includes(val)) {
          this.selectedRating.push(val);
        }
      }
      else {
        if (this.selectedRating.includes(val)) {
          this.selectedRating = this.selectedRating.filter(e => e !== val)
        }
      }

      this.$emit("change-rating", this.selectedRating);
    },
    
    isDefaultRating: function(rating) {
      return this.defaultRating.includes(parseInt(rating));
    },

    isEnabled: function(rating) {
      return this.ratings.includes(parseInt(rating)) || this.selectedRating.includes(rating);
    },

    reset: function() {
      this.selectedRating = [];

      this.$refs.rating.forEach((r) => { r.checked = false });
    }
  }
};
</script>
