<template>
  <ActionButton
    ref="actionButton"
    action="approve"
    icon="done_all"
    :text="text"
    :title="title"
    :confirm="confirm"
    :size="size"
    :css="css"
    @clickAction="approveRemediations()"
  />
</template>

<script>
import ActionButton from "../shared/action_button";

export default {
  components: {
    ActionButton
  },

  props: {
    inspectionId: String,
    projectId: String,
    text: {
      type: String,
      default: 'Approve Remediations'
    },
    title: {
      type: String,
      default: 'Approve All Remediations'
    },
    confirm: {
      type: String,
      default: 'All Remediations will be approved?'
    },
    size: {
      type: String,
      default: 'sm'
    },
    css: {
      type: String,
      default: ''
    }
  },

  methods: {
    approveInspectionRemediations: function() {
      this.$refs.actionButton.isSpinnerVisible = true;

      app.graphql.get(
        "update.inspection.approveRemediations",
        {
					input: {
						inspectionId: this.inspectionId
					}
        },
    
        (data) => {
        	const response = data.data.approveInspectionRemediations;
    
					if (response.errors) {
						response.errors.forEach((error) => {
							app.ui.toast.add({
								priority: "warning",
								title: "Unable to update remediation",
								message: error
							});

              this.$refs.actionButton.isSpinnerVisible = false;
          	});
        	} else {
						app.ui.toast.add({
							priority: "success",
							title: "Inspection Remediations",
							message: "All inspection remediations from <strong>review</strong> status set to <strong>approved</strong>"
						});

            setTimeout(() => {
              this.$refs.actionButton.isSpinnerVisible = false;

              this.$emit("approved");
            }, 1000);
					}
        }
			);
    },

    approveProjectRemediations: function() {
      this.$refs.actionButton.isSpinnerVisible = true;

			app.graphql.get(
        "update.project.approveRemediations",
        {
					input: {
						projectId: this.projectId
					}
        },
    
        (data) => {
        	const response = data.data.approveProjectRemediations;
    
					if (response.errors) {
						response.errors.forEach((error) => {
							app.ui.toast.add({
								priority: "warning",
								title: "Unable to update remediation",
								message: error
							});

              this.$refs.actionButton.isSpinnerVisible = false;
          	});
        	} else {
						app.ui.toast.add({
							priority: "success",
							title: "Project Remediations",
							message: "All project remediations from <strong>review</strong> status set to <strong>approved</strong>"
						});

            setTimeout(() => {
              this.$refs.actionButton.isSpinnerVisible = false;

              this.$emit("approved");
            }, 1000);
					}
        }
			);
    },

    approveRemediations: function() {
      if (this.inspectionId) {
        this.approveInspectionRemediations()
      }

      if (this.projectId) {
        this.approveProjectRemediations()
      }
      
    }
  }
}
</script>
