<template>
  <tr>
    <td 
      class="text-center"
      style="width: 60px; vertical-align: middle"
    >
      <DamageRating
        v-if="(!showInPreviewMode && latestSummary.approvedDate) || (showInPreviewMode && latestSummary.previewDate)"
        :rating="showInPreviewMode ? latestSummary.remediationPreviewRating : latestSummary.clientRemediatedRating" 
      />
    </td>
    <td>
      <a
        :href="componentUrl + filterQueryParams"
        :title="name"
        :data-entity="id"
      >{{name}}</a>
    </td>
  </tr>
</template>

<script>
import Row from "../grid/row";
import DamageRating from "../damage_rating";
import DetailsButton from "../grid/details_button";

export default {
  extends: Row,

  components: {
    DamageRating,
    DetailsButton
  },

  props: {
    filter: Object,
    id: String,
    name: String,
    description: String,
    latestSummary: Object,
    showInPreviewMode: Boolean
  },

  computed: {
    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["siteId", "physicalAssetId"].includes(x))).length === 0;
    },

    filterQueryParams: function() {
      let filterParams = Object.assign({}, this.filter);
      
      delete filterParams.siteId;
      delete filterParams.physicalAssetId;

      filterParams.componentId = this.id;
      
      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(filterParams) : "";
    },

    componentUrl: function () {
      return "/components/" + this.id;
    }
  }
}
</script>
