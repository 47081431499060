<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>
            <DamageRating
              :rating="revision.currentClassification"
            />
          </div>
          <div>
            {{revision.finding.displayName}}
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Category</label>
          {{revision.category}}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Description</label>
          {{revision.description}}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Revised at</label>
          {{ h$.formatDate.full(revision.createdAt) }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Revised by</label>
          {{revision.user.name}}
        </div>
      </section>

      <section
        v-bind:style="{'padding-bottom': revision.changes.description || revision.changes.details ? '' : '0'}"
      >
        <header v-if="revision.changes.classification">
          <div>
            <DamageRating
              :rating="revision.changes.classification[0]"
            />
          </div>
          <div>
            Original rating
          </div>
        </header>

        <template v-if="revision.changes.description">
          <h3>Description</h3>
          <div class="entity-detail form-group">
            <label class="placeholder placeholder-static">Original description</label>
            {{revision.changes.description[0]}}
          </div>
          <div class="entity-detail form-group">
            <label class="placeholder placeholder-static">Changed description</label>
            {{revision.changes.description[1]}}
          </div>
        </template>

        <template v-if="revision.changes.details">
          <h3>Original details</h3>
          <div
            class="entity-detail form-group"
            v-for="(value, name, index) in revision.changes.details.original"
            :key="'o-' + index"
          >
            <label class="placeholder placeholder-static">{{name}}</label>
            {{value}}
          </div>
          <h3>Changed details</h3>
          <div
            class="entity-detail form-group"
            v-for="(value, name, index) in revision.changes.details.changed"
            :key="'c-' + index"
          >
            <label class="placeholder placeholder-static">{{name}}</label>
            {{value}}
          </div>
        </template>

      </section>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data() {
    return {
      revision: {
        changes: {},
        original: {},
        finding: {},
        user: {}
      },
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    entityId: function() {
      this.fetchRevisionDetails();
    }
  },

  mounted: function() {
    this.fetchRevisionDetails();
  },

  methods: {
    fetchRevisionDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.findingVersions",

        {
          filter: {
            id: [this.entityId]
          }
        },

        (data) => {
          this.isLoaded = true

          this.revision = data.data.findingVersions.edges[0].node

          // Match and remove [null,""] changes
          const r = /,?\"[\w]+\":\[null,""\]/g

          let changes = JSON.parse(this.revision.objectChanges.replace(r, "").replace("{,", "{"))
          let original = JSON.parse(this.revision.originalObject)

          if (changes.details) {
            changes.details.original = {}
            changes.details.changed = {}

            Object.keys(changes.details[0]).forEach((key) => {
              if (!changes.details[1].hasOwnProperty(key)) {
                Object.assign(changes.details.original, {[key]: changes.details[0][key]});
              }
            })

            Object.keys(changes.details[1]).forEach((key) => {
              if (!changes.details[0].hasOwnProperty(key)) {
                Object.assign(changes.details.changed, {[key]: changes.details[1][key]});
              }
            })

            Object.keys(changes.details[0]).forEach((key) => {
              let orig = changes.details[0][key]

              if (changes.details[1].hasOwnProperty(key)) {
                let chng = changes.details[1][key]

                if (orig != chng) {
                  Object.assign(changes.details.original, {[key]: orig});
                  Object.assign(changes.details.changed, {[key]: chng});
                }
              }
            })
          }

          this.revision.currentClassification = (changes.classification) ? changes.classification[1] : original.classification

          changes.changes = $.map(changes, function(change, key) {
            return (key != "updated_at") ? key : null
          })

          this.revision.changes = changes;
          this.revision.original = original;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding revision details",
            message : "Something went wrong! Revision cannot be found or doesn't exist!"
          });
        }
      );
    }
  }
}

</script>
