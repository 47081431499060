<template>
  <div class="modal-body p-0">
    <div 
      v-if="userCanUpdate"
      class="media-gallery-edit-actions">
      <a
        href="#"
        title="Edit photo"
        class="icon"
        data-icon="edit"
        @click.stop.prevent="edit"
      ></a>
      <a
        href="#"
        title="Remove Photo"
        class="icon"
        data-icon="delete"
        data-message="Are you sure that you want to remove photo?"
        @click.stop.prevent="delete"
      ></a>
    </div>
    
    <div id="media-gallery-carousel" :class="carouselCss" :data-id="id">
      <div
        :class="['carousel-inner', { 'spinner-is-visible': isSpinnerVisible }]"
      >
        <div
          v-for="(galleryItem, index) in galleryItems"
          :class="['carousel-item', { active: index == args.currentItem }]"
          style="min-height: 100px"
        >
          <div style="position: relative;">
            <img
              :src="galleryItem[1]"
              loading="lazy"
            />
            <img
              :src="galleryItem[2]"
              style="position: absolute; width: 0px; height: 0px;"
              loading="lazy"
            />
          </div>
        </div>

        <div class="spinner" style="z-index: 10"></div>
      </div>
    </div>

    <div 
      v-if="galleryItems.length > 1" 
      class="media-gallery-nav"
    >
      <button
        class="carousel-control carousel-control-prev"
        type="button"
        @click.stop.prevent="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>

      <button
        class="carousel-control carousel-control-next"
        type="button"
        @click.stop.prevent="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="media-gallery-download-actions">Download <a :href="previewPhotoSrc" target="_blank" download="download" rel="noreferrer nofollow" title="Download Preview Photo">Preview</a> or <a :href="fullPhotoSrc" target="_blank" download="download" rel="noreferrer nofollow" title="Download Full Size Photo">Full Size</a> Photo</div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    args: Object,
  },

  data() {
    return {
      id: "",
      settings: {
        constrain: false,
        maxWidth: window.innerWidth - 120,
        maxHeight: window.innerHeight - 160
      },
      entityType: "",
      gallery: "",
      galleryItems: [],
      galleryCarousel: null,
      currentCarouselItem: {
        slide: null,
        index: 0,
        galleryItem: null,
        lowResImg: null,
        highResImg: null,
        imgWidth: null,
        imgHeight: null,
      },

      isSpinnerVisible: true,
      userCanUpdate: false
    };
  },

  computed: {
    carouselCss: function() {
      let classes = "carousel slide carousel-fade";

      if (this.settings.size && this.settings.size === "fullscreen") {
        classes += " position-absolute w-100 translate-middle top-50 start-50";
      }

      return classes;
    },

    imgClasses: function() {
      return this.settings.constrain
        ? "mw-100 mh-100 h-auto w-auto m-auto top-0 end-0 bottom-0 start-0"
        : "h-100 w-100";
    },

    previewPhotoSrc: function() {
      return (this.currentCarouselItem && this.currentCarouselItem.galleryItem) ? this.currentCarouselItem.galleryItem[1] : '' 
    },

    fullPhotoSrc: function() {
      return (this.currentCarouselItem && this.currentCarouselItem.galleryItem) ? this.currentCarouselItem.galleryItem[2] : '' 
    }
  },

  created: function () {
    this.id = "mediaGalleryCarousel-" + this.randomHash();

    this.entityType = this.args.entityType;
    this.galleryItems = this.args.galleryItems;
    this.gallery = this.args.gallery;
    this.userCanUpdate = this.args.userCanUpdate;
  },

  mounted: function () {
    const vm = this;

    this.galleryCarousel = new bootstrap.Carousel("#media-gallery-carousel", {
      interval: false,
    });

    this.galleryCarousel._element.addEventListener(
      "slide.bs.carousel",
      (event) => {
        vm.showSlide(event.relatedTarget, event.to);
      }
    );

    this.currentCarouselItem = {
      slide:
        this.galleryCarousel._element.querySelectorAll(".carousel-item")[
          this.args.currentItem
        ],
      index: this.args.currentItem,
    };

    // Initialize selected gallery item
    this.showSlide(
      this.currentCarouselItem.slide,
      this.currentCarouselItem.index
    );
  },

  methods: {
    randomHash: function (length = 8) {
      return Array.from({ length }, () =>
        Math.floor(Math.random() * 36).toString(36)
      ).join("");
    },

    showSlide: function (slide, i) {
      const vm = this;

      const slideImages = slide.querySelectorAll("img");
      
      this.currentCarouselItem.galleryItem = this.galleryItems[i];;
      this.currentCarouselItem.lowResImg = slideImages[0];
      this.currentCarouselItem.highResImg = slideImages[1];

      if (!slide.isInitialized) {
        vm.isSpinnerVisible = true;

        // set gallery modal size
        this.currentCarouselItem.lowResImg.addEventListener('load', function () {
          let img = this;
          let hiResImg = img.nextSibling;

          if (img.width < img.height) {
            // portrait mode
            img.style.width = 'auto'
            img.style.height = vm.settings.maxHeight + 'px'

            if (img.width > vm.settings.maxWidth) {
              img.style.height = 'auto'
              img.style.width = vm.settings.maxWidth + 'px'
            }
          } else {
            // lendscape mode
            img.style.height = 'auto'
            img.style.width = vm.settings.maxWidth + 'px'

            if (img.height > vm.settings.maxHeight) {
              img.style.width = 'auto'
              img.style.height = vm.settings.maxHeight + 'px'
            }
          }

          vm.currentCarouselItem.imgWidth = img.width
          vm.currentCarouselItem.imgHeight = img.height

          hiResImg.width = img.width
          hiResImg.height = img.height

          // resize modal initialy
          vmModal.$refs.modal.setSize(vm.currentCarouselItem.imgWidth + 'px', vm.currentCarouselItem.imgHeight + 'px');
        }, {once: true});

        // load high res image for zoom
        this.currentCarouselItem.highResImg.addEventListener('load', function () {
          vm.currentCarouselItem.lowResImg.style.display = 'none';
          vm.currentCarouselItem.highResImg.style.position = 'relative'
          vm.currentCarouselItem.highResImg.style.width = vm.currentCarouselItem.imgWidth + 'px'
          vm.currentCarouselItem.highResImg.style.height = vm.currentCarouselItem.imgHeight + 'px'

          window.wheelzoom(vm.currentCarouselItem.highResImg);

          setTimeout(() => {
            vm.isSpinnerVisible = false;
          }, 2000);
        }, {once: true});

        slide.isInitialized = true;
      }

      // resize modal
      vmModal.$refs.modal.setSize(this.currentCarouselItem.imgWidth + 'px', this.currentCarouselItem.imgHeight + 'px');
    },

    prev: function () {
      this.galleryCarousel.prev();
    },

    next: function () {
      this.galleryCarousel.next();
    },

    edit: function () {
      const vm = this;

      vmModal.$refs.modal.show({
        template: "imageEditor",
        style: {
          class: "image-editor",
          width: vm.currentCarouselItem.imgWidth + 'px',
          height: vm.currentCarouselItem.imgHeight + 110 + 'px',
          maxWidth: vm.currentCarouselItem.imgWidth + 'px',
          maxHeight: vm.currentCarouselItem.imgHeight + 110 + 'px',
          verticalyCentered: true
        },
        args: {
          gallery: vm.gallery,
          entityType: this.entityType,
          cssMaxWidth: vm.currentCarouselItem.imgWidth,
          cssMaxHeight: vm.currentCarouselItem.imgHeight,
          includeUI: {
            loadImage: {
              path: vm.currentCarouselItem.galleryItem[3],
              name: vm.gallery + "_img",
            },
            uiSize: {
              width: vm.currentCarouselItem.imgWidth + 'px',
              height: vm.currentCarouselItem.imgHeight + 110 + 'px',
            },
            menu: ["crop", "rotate", "draw", "shape", "icon", "text"],
          },
        },
      });
    },

    delete: function () {}
  },
};
</script>
