<template>
  <th @click="reorder" :class="['vue-sorter', orderClass]">
    <slot></slot>
  </th>
</template>

<script>
  export default {
    props: {
      currentSort: Object,
      sortBy: String,
      updateUiState: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      sortDirection: function () {
        if (this.sortBy == this.currentSort.field) {
          return this.currentSort.direction == "ASC" ? "DESC" : "ASC"
        } else {
          return "ASC"
        }
      },
      orderClass: function () {
        if (this.sortBy == this.currentSort.field) {
          return "sort-order-" + this.currentSort.direction.toLowerCase()
        }
      }
    },
    methods: {
      reorder: function () {
        let grid = (this.$parent.$data.hasOwnProperty("grid")) ? this.$parent.$data.grid : "default",
            sortBy = this.sortBy,
            sortOrder = this.sortDirection;

        this.$emit('sort', { field: sortBy, direction: sortOrder });

        // Update UI state
        if (this.updateUiState) {
          app.ui.state.update({
            "v-grid": grid,
            "v-sortby": sortBy.toLowerCase(),
            "v-sortorder": sortOrder.toLowerCase(),
            "v-page": 1
          });
        }
      }
    }
  }
</script>
