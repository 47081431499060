<template>
  <div class="table-wrapper related-items">
    <div class="table-responsive table-scroll">

      <div
        :class="['table-body', { 'spinner-is-visible': isSpinnerVisible }]"
        style="height: 353px;"
      >
        <table class="table">
          <tbody>
            <Finding
              v-for="(item, index) in listedFindings"
              v-bind="item"
              :key="item.id"
              :index="index"
              :show-finding-name-column="showFindingNameColumn"
              @remove-finding="removeFinding"
            />
          </tbody>
        </table>

        <div class="spinner"></div>

        <NoRecords 
          label="There is no related findings"
        />
      </div>

      <Pagination
        v-bind="pageInfo"
        @navigate-to="paginate"
        :update-ui-state="false"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Grid from "../shared/grid/grid";
import SortableHeader from "../shared/grid/sortable_header"
import Finding from "./form_related_finding"
import Pagination from "../shared/pagination"

export default {
  extends: Grid,

  components: {
    SortableHeader,
    Finding,
    Pagination
  },

  props: {
    remediationId: String,
    showFindingNameColumn: Boolean,
    findingsFilter: Object,
    listedFindings: Array
  },

  data: function () {
    return {
      items: [],

      grid: "related-findings",

      defaultOrderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 100,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      showAllFindings: false,

      isSpinnerVisible: true,

      selectedItems: [],

      isInitialized: false
    }
  },

  computed: {
    filterParams: function () {
      this.filter.remediationId = this.remediationId;
      this.filter.status = ["approved", "draft", "review"];
      this.filter.historical = true;

      return this.filter;
    },

    hasItems: function() {
      return this.listedFindings.length > 0;
    }
  },

  watch: {
    items: function() {
      if (!this.isInitialized) {
        let listedItems = this.items.map((item) => {          
          return item;
        });
        
        this.$emit("findings-listed", listedItems);
          
        this.isInitialized = true;
      }
    }
  },

  methods: {
    removeFinding: function(finding) {
      this.$emit("remove-finding", finding);
    },

    fetch: function (params, success) {
      app.graphql.get("grid.findings",
        params,

        (data) => {
          let findings = data.data.findings,
              pageInfo = findings.pageInfo;

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch findings!"
          })
        }
      )
    },
  }
}
</script>