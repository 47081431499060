export default {
  methods: {
    getStateParam: function(param) {
      this.stateParams = new URLSearchParams(window.location.search);

      return this.stateParams.get(param);
    },

    getFilterState: function() {
      return this.getStateParam("v-filter");
    },

    initStateFilter: function() {
      // Handle default state filter
      let stateFilter = this.getFilterState();

      if (stateFilter) {
        stateFilter = stateFilter.replace("findingDetails", "details");

        stateFilter = JSON.parse(stateFilter);

        if (stateFilter.details) {
          if (stateFilter.details.constructor === Object && Object.keys(stateFilter.details).length >= 0) {
            stateFilter.details = JSON.stringify(stateFilter.details);
          }
        }
      }

      this.filter = (stateFilter) ? stateFilter : this.defaultFilter || {};

      this.filter.historical = this.filter.historical || false;
    },

    setFilterState: function() {
      if (this.isInitialized) {
        let filterState = Object.assign({}, this.filter);

        Object.keys(filterState).forEach((key) => {
          var el = filterState[key];

          if (el && el.constructor === Array && el.length <= 0) {
            delete filterState[key];
          }

          if (el.constructor === Object && Object.keys(el).length <= 0) {
            delete filterState[key];
          }
        });

        app.ui.state.update({
          "v-filter": JSON.stringify(filterState),
          "v-page": null
        });

        // Trigger UI stateupdade event
        window.dispatchEvent(new Event("updatestate"));
      }
    },

    setFilter: function() {
      if (this.isInitialized) {
        Object.keys(this.filter.findingDetails).forEach((key) => {
          if (this.filter.findingDetails[key].length <= 0) {
            delete this.filter.findingDetails[key];
          }
        });

        this.setFilterOptions();

        this.setFilterState();
      }
    },
  }
}