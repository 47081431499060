<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div v-if="lastActivityDate">
            <DamageRating 
              :rating="isFilterInPreviewMode ? site.latestSummary.remediationPreviewRating : site.latestSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            <a
              :href="'/sites/' + site.id"
              tile="Site details"
            >{{site.name}}</a>
            <div v-if="site.description" class="note">{{site.description}}</div>
          </div>
        </header>
        <header v-if="(!isFilterInPreviewMode && site.latestLeadingEdgeSummary.approvedDate) || (isFilterInPreviewMode && site.latestLeadingEdgeSummary.previewDate)">
          <div>
            <DamageRating
              :rating="isFilterInPreviewMode ? site.latestLeadingEdgeSummary.remediationPreviewRating : site.latestLeadingEdgeSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            Leading Edge Condition
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">GPS Location</label>
          {{ site.latitude }}, {{ site.longitude }}
        </div>
        <div v-if="site.windZone" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Wind Zone (Vmph)</label>
          {{ site.windZone }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Created on</label>
          {{ h$.formatDate.short(site.createdAt) }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last update</label>
          {{ h$.formatDate.short(site.updatedAt) }}
        </div>
        <div v-if="lastActivityDate" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last activity</label>
          {{ h$.formatDate.short(lastActivityDate) }}
        </div>
      </section>

      <section v-if="lastActivityDate">
        <header>
          <div><h3>Damage Distribution</h3></div>
        </header>
        <div
          v-for="(value, key) in site.statistics.componentWorstCaseDamageCounts"
          :key="key"
          class="entity-detail form-group"
        >
          <label class="placeholder placeholder-static">{{key}}</label>
          <div class="damage-distribution">
            <div 
              v-for="(value, key) in value"
              :key="key"
              :class="['damage-status', 'rating-mark', 'rating-' + key]">
              <span>{{value}}</span>
            </div>
          </div>
        </div>
      </section>

      <section v-if="lastActivityDate && hasClientRemediatedRating">
        <header>
          <div><h3>Damage Overview</h3></div>
        </header>
        <div class="stats">
          <SiteStatistics
            ref="charts"
            :statistics="site.statistics"
          />
        </div>
      </section>
      
      <div class="entity-details-actions">
        <div>
          <a
            :href="'/sites/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/sites/' + entityId + '/edit'"
            title="Edit"
            class="btn-expandable btn-expandable-edit btn-expandable-sm"
          >
            <span class="icon" data-icon="edit"></span>
            <span>Edit</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/sites/' + entityId"
            title="Remove"
            class="btn-expandable btn-expandable-delete btn-expandable-sm"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <span class="icon" data-icon="delete"></span>
            <span>Remove</span>
          </a>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import helpers from "../../../helpers.js";

import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating"
import SiteStatistics from "./site_statistics"

export default {
  extends: EntityDetails,

  components: {
    DamageRating,
    SiteStatistics
  },

  setup () {
    return {
      h$: helpers
    }
  },

  data() {
    return {
      site: {
        statistics: {},
        latestSummary: {},
        latestLeadingEdgeSummary: {}
      }
    }
  },

  watch: {
    entityId: function() {
      this.fetchSiteDetails()
    }
  },

  computed: {
    lastActivityDate: function() {
      return this.isFilterInPreviewMode ? this.site.latestSummary.previewDate : this.site.latestSummary.approvedDate;
    },

    hasClientRemediatedRating: function() {
      return this.site.latestSummary.clientRemediatedRating && this.site.latestSummary.clientRemediatedRating > 0;
    }
  },

  mounted: function() {
    this.fetchSiteDetails()
  },

  methods: {
    fetchSiteDetails: function() {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.site",

        {
          filter: {
            id: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true

          this.site = data.data.sites.edges[0].node;

          if (this.lastActivityDate) {
            if (this.site.statistics.componentWorstCaseDamageCounts) {
              this.site.statistics.componentWorstCaseDamageCounts = JSON.parse(this.site.statistics.componentWorstCaseDamageCounts)
            }


            if (this.hasClientRemediatedRating) {
              this.$nextTick(() => {
                this.$refs.charts.getCharts();
              })
            }
          }

          setTimeout(() => {
            this.isSpinnerVisible = false
          }, 500)
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Site Details",
            message : "Something went wrong! Site cannot be found or doesn't exist!"
          })
        }
      );
    }
  }
}

</script>
