<template>
  <div class="modal-body">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">

        <form 
            :ref="formId"
            :id="formId" 
            :action="formUrl" 
            enctype="multipart/form-data"
            accept-charset="UTF-8"
            method="post"
            role="form" 
            autocomplete="off"
            novalidate="novalidate"
          >
          <input type="hidden" name="utf8" value="✓">
          <input type="hidden" name="_method" value="patch">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">

          <div class="row">
            <div v-if="!isPendingUser" class="col-sm-12">
              <div class="form-group">
                <input
                  name="user[current_password]"
                  id="user_current_password"
                  type="password"
                  :class="['form-control', {'error': v$.currentPassword.$error}]"
                  placeholder="Current Password" 
                  v-model.trim="currentPassword"
                  @keydown.enter.prevent="submitForm"
                >
                <div v-if="v$.currentPassword.$error" class="error">Current password is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input
                  name="user[password]"
                  id="user_password"
                  type="password"
                  :class="['form-control', {'error': v$.newPassword.$error}]"
                  placeholder="New Password" 
                  v-model.trim="newPassword"
                  @keydown.enter.prevent="submitForm"
                >
                <div v-if="v$.newPassword.$error" class="error">Minimal 3 character length password is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <input
                  name="user[password_confirmation]"
                  id="user_password_confirmation"
                  type="password"
                  :class="['form-control', {'error': v$.confirmPassword.$error}]"
                  placeholder="Repeat Password" 
                  v-model.trim="confirmPassword"
                  @keydown.enter.prevent="submitForm"
                >
                <div v-if="v$.confirmPassword.$error" class="error">Password should be equal with new password</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <form-button
                  ref="submitButton"
                  label="Set password"
                  class="btn btn-primary"
                  @submit="submitForm"
                ></form-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import formButton from "../form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      formId: '',
      formUrl: '',
      isPendingUser: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },

  validations () {
    return {
      currentPassword: {
        required
      },
      newPassword: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword)
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formId = this.args.formId;
    this.formUrl = this.args.formUrl;
    this.isPendingUser = JSON.parse(this.args.userPandingActivation);

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    submitForm: function() {
      const form = document.forms[this.formId];

      if (!this.isPendingUser) {
        this.v$.currentPassword.$touch();
      }
      
      this.v$.newPassword.$touch();
      this.v$.confirmPassword.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>