<template>
  <toast
    v-for="(toast, index) in toasts" 
    v-bind="toast"
    :key="toast.id"
    :user-can-update="userCanUpdate"
    @shown="markAsShown"
    @hidden="markAsHidden"
    ref="toast"
  />
</template>

<script>
import Toast from "./toast"

export default {
  components: {
    Toast
  },

  props: {
    userCanUpdate: Boolean
  },

  data() {
    return {
      toasts: []
    }
  },

  watch: {
    toasts: {
      handler(val, oldValue) {
        // handle toast changes
      }, 
      immediate: true,
      deep: true
    }
  },

  mounted: function() {
    // Handle server side notifications that needs to be presented as toasts
    this.$nextTick(() => {
      document.querySelectorAll("[data-action='notification']").forEach((toast) => {
        app.ui.toast.add({
          priority: toast.dataset.type,
          title : toast.dataset.title,
          message : toast.dataset.message
        });
      })
    });
  },

  methods: {
    randomHash: function (length = 8) {
      return Array.from({ length }, () =>
        Math.floor(Math.random() * 36).toString(36)
      ).join('');
    },

    add: function(t) {
      const toast = {
        id: this.randomHash(),
        title: t.title || '',
        message: t.message || '',
        priority: t.priority || 'info',
        isShown: false,
        isHidden: false
      }

      this.toasts.unshift(toast)
    },

    markAsShown: function(id) {
      let toast = this.toasts.find(x => x.id === id)

      toast.isShown = true
    },
    
    markAsHidden: function(id) {      
      let toast = this.toasts.find(x => x.id === id)

      toast.isHidden = true
    }
  }
}

</script>
