<template>
  <span
    class="info-badge icon" 
    data-icon="info_outline"
    @mouseleave="hideInfo"
    @click.stop.prevent="showInfo"
  ></span>
</template>

<script>
export default {
  props: {
    content: String,
    placement: {
      type: String,
      default: 'top'
    }
  },

  data() {
    return {
      badge: {}
    }
  },

  watch: {
    content: {
      handler(newValue, oldValue) {        
        if (this.badge && this.badge.popover) {
          this.badge.popover.dispose();
        }
        
        setTimeout(() => {
          this.setBadgePopover()
        }, 500);
      }, 
      immediate: true
    }
  },

  methods: {
    setBadgePopover: function() {
      const vm = this

      this.badge.popover = new bootstrap.Popover(this.$el, {
        title: '',
        content: vm.content || '',
        html: true,
        placement: vm.placement ,
        container: 'body',
        trigger: 'manual'
      })
    },
    
    showInfo: function(event) {
      if (this.badge.popover) this.badge.popover.show()
    },

    hideInfo: function(event) {
      if (this.badge.popover) this.badge.popover.hide()
    }
  }
}
</script>