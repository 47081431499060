<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          :placeholder="searchPlaceholder"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-left"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Project</SortableHeader>

            <SortableHeader
              style="width: 400px"
              v-if="showClient"
              :current-sort="orderBy"
              :sort-by="'CLIENT_COMPANY_NAME'"
              @sort="reorder"
            >Client</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 110px"
              :current-sort="orderBy"
              :sort-by="'DRAFT_FINDINGS'"
              @sort="reorder"
            >In Draft</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 110px"
              :current-sort="orderBy"
              :sort-by="'REVIEW_FINDINGS'"
              @sort="reorder"
            >In Review</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 110px"
              :current-sort="orderBy"
              :sort-by="'APPROVED_FINDINGS'"
              @sort="reorder"
            >Approved</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Project
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :show-client="showClient"
            :policy-update="policyUpdate"
            @update-status="approveProjectFindings"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="According to the search criteria, there are no projects found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="projects"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Project from "./project";

export default {
  extends: Grid,

  components: {
    Project
  },

  props: {
    showClient: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "projects",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      if (this.textSearch) {
        return {
          text: {
            value: this.textSearch,
            fields: this.textSearchFields
          }
        };
      }
    },

    textSearchFields: function () {
      if (this.showClient) {
        return ["NAME", "DESCRIPTION", "CLIENT_COMPANY_NAME"];
      } else {
        return ["NAME", "DESCRIPTION"];
      }
    },

    searchPlaceholder: function () {
      if (this.showClient) {
        return "Search for projects by name, description, or company name";
      } else {
        return "Search for projects by name or description";
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.projects",
        params,

        (data) => {
          let projects = data.data.projects,
              pageInfo = projects.pageInfo;

          success(projects, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch projects!"
          })
        }
      )
    },

    approveProjectFindings: function (updatedProject) {
      let project = this.items.find(function (item) {
        return item.id === updatedProject.id;
      });

      project.findingStatusCounts.approved = updatedProject.findingStatusCounts.approved;
      project.findingStatusCounts.review = 0;
    }
  }
}
</script>
