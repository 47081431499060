<template>
  <li 
    v-if="currentPage + offset <= totalPageCount"
    class="page-item page-next-block"
  >
    <a
      href="#"
      :title="'+ ' + showPages"
      class="page-link"
      @click.prevent="$emit('navigate-to', linkPage)"
    >
      <span aria-hidden="true" class="icon" data-icon="more_horiz"></span>
    </a>
  </li>

  <li 
    v-else 
    class="page-item page-next-block disabled"
  >
    <a class="page-link" href="#" aria-disabled="true">
      <span aria-hidden="true" class="icon" data-icon="more_horiz"></span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },

    showPages: {
      type: Number,
      required: true
    },

    totalPageCount: {
      type: Number,
      required: true
    }
  },

  computed: {
    offset: function () {
      return Math.ceil(this.showPages / 2)
    },

    linkPage: function () {
      return Math.min(this.currentPage + this.showPages, this.totalPageCount)
    }
  }
}
</script>
