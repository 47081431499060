<template>
  <div
    v-bind:class="[{ 'spinner-is-visible': isSpinnerVisible }]"
  >
    <ul 
      class="asset-qr-labels"
    >
      <li
        v-for="(qrLabel, index) in qrLabels"
        v-bind="qrLabel"
        :label="qrLabel"
        :key="qrLabel.id"
        :index="index"
        :policy-update="policyUpdate"
        class="qr-label"
      >
        <div>
          <template v-if="qrLabel.primary">
            <strong>{{qrLabel.labelNumber}}</strong>
          </template>
          <template v-else>
            <span>{{qrLabel.labelNumber}}</span>
          </template>
        </div>
        <div 
          v-if="policyUpdate"
        >
          <ActionButton
            v-if="!qrLabel.primary"
            action="edit"
            icon="check"
            text="Set as primary"
            title="Set QR Label as primary"
            size="xs"
            @clickAction="setAsPrimaryQRLabel(qrLabel)"
          />
          <ActionButton
            action="delete"
            icon="delete"
            text="Remove"
            title="Unnasign QR Label"
            size="xs"
            @clickAction="unassignQRLabel(qrLabel)"
          />
        </div>
      </li>
    </ul>

    <div class="spinner"></div>

    <NoRecords 
      label="There are no QR labels associated to this asset."
    />
  </div>
</template>

<script>
import ActionButton from "../shared/action_button";
import NoRecords from "../shared/grid/no_records";

export default {
  components: {
    ActionButton,
    NoRecords
  },

  props: {
    physicalAssetId: String,
    policyUpdate: Boolean
  },

  data: function () {
    return {
      qrLabels: [],

      assetId: this.physicalAssetId,

      isSpinnerVisible: true
    }
  },

  created: function() {
    this.getQrLabels();
  },

  computed: {
    hasItems: function() {
      return this.qrLabels.length > 0;
    }
  },

  methods: {
    refresh: function(assetId) {
      if (assetId) {
        this.assetId = assetId
      }

      this.getQrLabels();
    },

    getQrLabels: function () {
      app.graphql.get("details.asset",
        {
          filter: {
            id: this.assetId
          }
        },

        (data) => {
          let physicalAssets = data.data.physicalAssets.edges;
          
          if (physicalAssets.length > 0) {
            let physicalAsset = physicalAssets[0].node;

            this.qrLabels = physicalAsset.qrLabels.edges.map((edge) => { return edge.node });
          }

          this.isSpinnerVisible = false;
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch QR labels!"
          })
        }
      )
    },

    setAsPrimaryQRLabel: function(qrLabel) {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "update.asset.assignQR",

        {
          input: {
            qrLabelId: qrLabel.id,
            physicalAssetId: this.physicalAssetId,
            primary: true
          }
        },

        (data) => {
          const response = data.data.assignQrLabel;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "QR Label cannot be assigned as primary",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title : "QR Label",
              message : "QR Label is successfuly assigned as primary to an asset"
            });

            let currentPrimaryQr = this.qrLabels.find(function (qrLabel) {
              return qrLabel.primary === true;
            });
            
            currentPrimaryQr.primary = false;

            qrLabel.primary = true;

            if (window.location.href.includes("physical_assets")) {
              // Update QR Label indicator on asset page
              $("#asset-qr-indicator").text(qrLabel.labelNumber);

              // Refresh asset details sidebar
              const assetDetailsPane = vmDetailsPane.$refs.detailsPane.$refs.entityDetails;

              if ( assetDetailsPane && 'fetchAssetDetails' in assetDetailsPane) {
                assetDetailsPane.fetchAssetDetails()
              }
            }
          }

          this.isSpinnerVisible = false;
        }
      );
    },

    unassignQRLabel: function(qrLabel) {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "update.asset.unassignQR",

        {
          input: {
            qrLabelId: qrLabel.id
          }
        },

        (data) => {
          const response = data.data.unassignQrLabel;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "QR Label cannot be unassigned",
                message: error
              });
            })
          } else {
            if (window.location.href.includes("physical_assets")) {
              if (this.qrLabels.length <= 1) {
                app.ui.state.update({
                  "tab": null
                });
              }

              // Update QR Label indicator on asset page
              window.location.reload(true);
            }
            else {
              app.ui.toast.add({
                priority: "success",
                title : "QR Label",
                message : "QR Label is successfuly unassigned"
              });

              let removedQR = this.qrLabels.find(function (qrLabel) {
                return qrLabel.id === qrLabel.id;
              });

              this.qrLabels = this.qrLabels.filter(qrLabel => qrLabel !== removedQR);
            }
          }

          this.isSpinnerVisible = false;
        }
      );
    }
  }
}
</script>
