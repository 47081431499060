<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Component</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="component_name" class="placeholder placeholder-static">Component name</label>
                  <input 
                    :class="['form-control', {'error': v$.componentName.$error}]"
                    placeholder="Name" 
                    type="text" 
                    name="component[name]" 
                    id="component_name"
                    v-model.trim="v$.componentName.$model"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.componentName.$error" class="error">Component name is required</div>
                </div>
              </div>
            </div>

            <template v-if="!currentComponentId && !currentPhysicalAsset">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Client Company</label>
                  <v-select
                    placeholder="Select client company"
                    ref="clientCompanies"
                    :class="[{'vs-error': v$.clientCompanyId.$error}]"
                    :options="clientCompanies"
                    label="name"
                    @update:modelValue="setClientCompany"
                  />
                  <div v-if="v$.clientCompanyId.$error" class="error">Client company is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Site</label>
                  <v-select
                    placeholder="Select Site"
                    ref="sites"
                    :class="[{'vs-error': v$.siteId.$error}]"
                    :options="sites"
                    :disabled="!clientCompany"
                    label="name"
                    @update:modelValue="setSite"
                  />
                  <div v-if="v$.siteId.$error" class="error">Site is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Physical Asset</label>
                  <v-select
                    placeholder="Select Physical Asset"
                    ref="physicalAssets"
                    :class="[{'vs-error': v$.physicalAssetId.$error}]"
                    :options="physicalAssets"
                    :disabled="!site"
                    label="name"
                    @update:modelValue="setPhysicalAsset"
                  />
                  <input 
                    type="hidden" 
                    id="component_physical_asset_id" 
                    name="component[physical_asset_id]"
                    :value="physicalAssetId"
                  />
                  <div v-if="v$.physicalAssetId.$error" class="error">Physical Asset is required</div>
                </div>
              </div>
            </div>
            </template>
            <template v-else>
             <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Physical Asset</label>
                  <input 
                    placeholder="Physical Asset"
                    class="form-control"
                    type="text"
                    readonly
                    :value="physicalAssetName"
                  />
                   <input 
                    type="hidden" 
                    id="component_physical_asset_id" 
                    name="component[physical_asset_id]"
                    :value="physicalAssetId"
                  />
                </div>
              </div>
            </div>
            </template>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="component_description">Component description</label>
                  <textarea 
                    rows="4" 
                    class="form-control" 
                    placeholder="Description" 
                    name="component[description]" 
                    id="component_description"
                    v-model.trim="componentDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <form-component-types
            v-if="isLoaded"
            ref="componentTypeDetails"
            :component="component"
          ></form-component-types>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formFieldDate from "../shared/form/field_date";
import formButton from "../shared/form/form_button";
import formComponentTypes from "./form_component_types";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formFieldDate,
    formButton,
    formComponentTypes
  },

  props: {
    formId: String,
    currentComponentId: String,
    currentPhysicalAsset: Object,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      component: null,
      componentName: "",
      componentDescription: "",
      clientCompanies: this.clientCompanies,
      clientCompany: null,
      clientCompanyId: "",
      sites: this.sites,
      site: null,
      siteId: "",
      siteName: "",
      physicalAssets: this.physicalAssets,
      physicalAsset: null,
      physicalAssetId: "",
      physicalAssetName: "",
      componentTypes: this.componentTypes,
      componentType: null,
      componentTypeId: "",
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  validations () {
    return {
      componentName: {
        required
      },
      clientCompanyId: {
        required
      },
      siteId: {
        required
      },
      physicalAssetId: {
        required
      }
    }
  },

  mounted: function() {
    if (this.currentPhysicalAsset) {
      this.physicalAssetId = this.currentPhysicalAsset.id;
      this.physicalAssetName = this.currentPhysicalAsset.name;
    }

    if (this.currentComponentId) {
      this.fetchComponent();
    }
    else {
      if (this.currentPhysicalAsset) {
        this.component = {
          physicalAsset: this.currentPhysicalAsset
        }

        this.component.physicalAsset.physicalAssetType = {
          id: this.currentPhysicalAsset.physical_asset_type_id
        }
      }

      this.fetchClientCompanies();

      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  computed: {
    submitButtonLabel: function() {
      return ((this.currentComponentId != "")) ? "Update Component" : "Create Component";
    }
  },

  methods: {
    setClientCompany: function(opt) {
      if (opt) {
        this.clientCompany = opt;
        this.clientCompanyId = opt.id;

        this.fetchSites();
      }
      else {
        this.clientCompany = null;
        this.clientCompanyId = "";

        this.site = null;
        this.siteName = "";
        this.siteId = "";
        this.sites = [];

        this.physicalAsset = null;
        this.physicalAssetId = "";
        this.physicalAssetName = "";
        this.physicalAssets = [];
      }

      this.$refs.sites.clearSelection();
      this.$refs.physicalAssets.clearSelection();
    },

    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    setSite: function(opt) {
      if (opt) {
        this.site = opt;
        this.siteId = opt.id;
        this.siteName = opt.name;

        this.fetchPhysicalAssets();
      }
      else {
        this.site = null;
        this.siteId = "";
        this.siteName = "";

        this.physicalAsset = null;
        this.physicalAssetId = "";
        this.physicalAssetName = "";
        this.physicalAssets = [];
      }

      this.$refs.physicalAssets.clearSelection();
    },

    fetchSites: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    setPhysicalAsset: function(opt) {
      if (opt) {
        this.physicalAsset = opt;
        this.physicalAssetId = opt.id;
        this.physicalAssetName = opt.name;

        this.component = {
          physicalAsset: opt
        }
      } 
      else {
        this.physicalAsset = null;
        this.physicalAssetId  = "";
        this.physicalAssetName  =  "";

        this.component = null;
      }
    },

    fetchPhysicalAssets: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          siteId: this.site.id
        }
      };

      this.physicalAssets = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assets",
          fetchParams,

          (data) => {
            let physicalAssets = data.data.physicalAssets,
                pageInfo = physicalAssets.pageInfo;

            success(physicalAssets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Physical Assets!"
            });
          }
        )
      };

      let getPhysicalAssets = (cursor) => {
        fetch(
          cursor,

          (physicalAssets, pageInfo) => {
            physicalAssets.edges.forEach((edge) => this.physicalAssets.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssets(pageInfo.endCursor))
            }
          }
        )
      };

      getPhysicalAssets(window.btoa(0));
    },

    fetchComponent: function(callback) {
      app.graphql.get("details.component",
        {
          filter: {
            id: [this.currentComponentId]
          }
        },
        (data) => {
          let currentcomponent = data.data.components.edges[0].node;

          this.component = currentcomponent;
          this.componentName = currentcomponent.name;
          this.componentDescription = currentcomponent.description;

          this.component.details = JSON.parse(this.component.details);

          this.physicalAsset = currentcomponent.physicalAsset;

          this.isLoaded = true;

          if (callback) {
            callback();
          }

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Component!"
          });
        }
      );
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      if (!this.currentComponentId && !this.currentPhysicalAsset) {
        this.v$.clientCompanyId.$touch();
        this.v$.siteId.$touch();
      }
      
      this.v$.componentName.$touch();
      this.v$.physicalAssetId.$touch();

      let dataTypesAreValid = this.$refs.componentTypeDetails.isValid;

      if (!this.v$.$error && dataTypesAreValid) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    },
  }
}

</script>