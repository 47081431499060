<template>
<div class="site-statistics">
  <div id="siteDetailsDamageCharts" class="carousel carousel-dark slide" data-ride="carousel" data-interval="false">
    <div class="carousel-inner" role="listbox">

      <div class="carousel-item active" data-slide="0">
        <div class="damage-chart-wrapper">
          <div class="damage-chart">
            <GChart
              type="PieChart"
              :data="ratingFrequencyChart.data"
              :options="ratingFrequencyChart.options"
            />
          </div>
          <div class="damage-chart-title">Rating Frequency</div>
        </div>
      </div>
      <div class="carousel-item" data-slide="1">
        <div class="damage-chart-wrapper">
          <div class="damage-chart">
            <GChart
              type="PieChart"
              :data="worstCaseDamageFrequencyChart.data"
              :options="worstCaseDamageFrequencyChart.options"
            />
          </div>
          <div class="damage-chart-title">Worst Case Damage Across Site</div>
        </div>
      </div>
      <div 
        v-if="hasDefectTypeFrequencyStats"
        class="carousel-item" 
        data-slide="2"
      >
        <div class="damage-chart-wrapper">
          <div class="damage-chart">
            <GChart
              type="PieChart"
              :data="defectTypeFrequencyChart.data"
              :options="defectTypeFrequencyChart.options"
            />
          </div>
          <div class="damage-chart-title">Damage Types</div>
        </div>
      </div>
      <div 
        v-if="hasLocationFrequencyStats"
        class="carousel-item" 
        data-slide="3"
      >
        <div class="damage-chart-wrapper">
          <div class="damage-chart">
            <GChart
              type="PieChart"
              :data="locationFrequencyChart.data"
              :options="locationFrequencyChart.options"
            />
          </div>
          <div class="damage-chart-title">Damage Locations</div>
        </div>
      </div>
    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#siteDetailsDamageCharts" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#siteDetailsDamageCharts" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
</template>

<script>
import { GChart } from "vue-google-charts"

import helpers from "../../../helpers.js"

export default {
  components: {
    GChart
  },

  props: {
    statistics: Object
  },

  data: function () {
    return {
      ratingFrequencyChart: {
        data: [],
        options: {},
      },

      worstCaseDamageFrequencyChart: {
        data: [],
        options: {},
      },

      defectTypeFrequencyChart: {
        data: [],
        options: {},
      },

      locationFrequencyChart: {
        data: [],
        options: {},
      },

      isReady: false
    }
  },

  created: function() {
    window.google = undefined;
  },

  mounted: function() {
    const damageChartsCarousel = new bootstrap.Carousel('#siteDetailsDamageCharts')
  },

  computed: {
    hasRatingFrequencyStats: function() {
      return this.ratingFrequencyChart.data.length > 1
    },

    hasWorstCaseDamageFrequencyStats: function() {
      return this.worstCaseDamageFrequencyChart.data.length > 1
    },

    hasDefectTypeFrequencyStats: function() {
      return this.defectTypeFrequencyChart.data.length > 1
    },

    hasLocationFrequencyStats: function() {
      return this.locationFrequencyChart.data.length > 1
    },
  },

  methods: {
    setRatingFrequencyChart() {
      let vmStats = this

      let ratingFrequency = Object.entries(JSON.parse(this.statistics.ratingFrequency))

      ratingFrequency.unshift(["Rating", "Frequency"])

      this.ratingFrequencyChart.data = ratingFrequency.map(function(rating) {
        if (typeof rating[1] == "number") {
          return [helpers.damageMarks[rating[0]], rating[1]]
        }
        else {
          return rating
        }
      });

      this.ratingFrequencyChart.options = {
        legend: "none",
        pieSliceText: "label",
        pieHole: 0.4,
        width: 250,
        height: 250,
        colors: ratingFrequency.reduce(function(colors, rating){
          if (typeof rating[1] == "number") {
            colors.push(helpers.damageColors[rating[0]]);
          }
          return colors;
        }, [])
      }
    },

    setWorstCaseDamageFrequencyChart() {
      let vmStats = this

      let worstCaseDamageFrequency = Object.entries(JSON.parse(this.statistics.worstCaseDamageFrequency))

      worstCaseDamageFrequency.unshift(["Worst Case Damage", "Accross Site"])

      this.worstCaseDamageFrequencyChart.data = worstCaseDamageFrequency.map(function(rating) {
        if (typeof rating[1] == "number") {
          return [helpers.damageMarks[rating[0]], rating[1]]
        }
        else {
          return rating
        }
      });

      this.worstCaseDamageFrequencyChart.options = {
        legend: "none",
        pieSliceText: "label",
        pieHole: 0.4,
        width: 250,
        height: 250,
        colors: worstCaseDamageFrequency.reduce(function(colors, rating) {
          if (typeof rating[1] == "number") {
            colors.push(helpers.damageColors[rating[0]])
          }
          return colors
        }, [])
      };
    },

    setDefectTypeFrequencyChart() {
      let vmStats = this

      let defectTypeFrequency = Object.entries(JSON.parse(this.statistics.defectTypeFrequency))

      defectTypeFrequency.unshift(["Damage", "Type"])

      this.defectTypeFrequencyChart.data = defectTypeFrequency
      
      this.defectTypeFrequencyChart.options = {
        legend: "none",
        pieSliceText: "none",
        pieHole: 0.4,
        width: 250,
        height: 250,
        colors: helpers.chartColors
      };
    },

    setLocationFrequencyChart() {
      let vmStats = this

      let locationFrequency = Object.entries(JSON.parse(this.statistics.locationFrequency))

      locationFrequency.unshift(["Damage", "Location"])

      this.locationFrequencyChart.data = locationFrequency

      this.locationFrequencyChart.options = {
        legend: "none",
        pieSliceText: "none",
        pieHole: 0.4,
        width: 250,
        height: 250,
        colors: helpers.chartColors
      };
    },

    getCharts: function() {
      // Set rating frequency chart props
      this.setRatingFrequencyChart()

      // Set worst case damage frequency chart props
      this.setWorstCaseDamageFrequencyChart()

      // Set worst case damage frequency chart props
      this.setDefectTypeFrequencyChart()

      // Set worst case damage frequency chart props
      this.setLocationFrequencyChart()
    }
  }
}
</script>