<template>
  <div class="modal-body">
    <form
      ref="templatesForm"
      :action="bulkTemplateUrl" 
      accept-charset="UTF-8" 
      method="post" 
      novalidate="novalidate"
    >
      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token" :value="authenticityToken">
      <input type="hidden" name="type" :value="template">
    </form>

    <div class="row">
      <div class="col-sm-12">
        <form 
          ref="bulkUpdateForm"
          enctype="multipart/form-data" 
          :action="bulkUploadUrl" 
          accept-charset="UTF-8"
          method="post"
          novalidate="novalidate"
        >
          <input name="utf8" type="hidden" value="✓">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">
          <div class="row">
            <div class="col-sm-6">
              <a
                @click="getTemplate('asset')"
                title="Get Site Assets Bulk Update template"
              ><span class="icon small-icon" data-icon="file-spreadsheet"></span> Assets update template</a>
            </div>
            <div class="col-sm-6">
              <a
                @click="getTemplate('component')"
                title="Get Site Components Bulk Update template"
              ><span class="icon small-icon" data-icon="file-spreadsheet"></span> Components update template</a>
            </div>
          </div>
          <div class="row" style="padding-top: 30px">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="file" class="placeholder placeholder-inactive">Bulk update file</label>
                <input 
                  type="file" 
                  name="file" 
                  id="file"
                  ref="file"
                  :class="['form-control', {'vs-error': v$.choosenFile.$error}]"
                  @change="setFile"
                />
                <div v-if="v$.choosenFile.$error" class="error">Bulk update file file is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <button name="button" type="button" class="btn btn-primary" @click="submitForm">
                Update Site
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: {
    
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      template: '',
      bulkTemplateUrl: '',
      bulkUploadUrl: '',
      choosenFile: ''
    }
  },

  validations () {
    return {
      choosenFile: {
        required
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.bulkTemplateUrl = this.args.bulkTemplateUrl
    this.bulkUploadUrl = this.args.bulkUploadUrl
  },

  methods: {
    getTemplate: function(template) {
      this.template = template

      this.$nextTick(() => {
        this.$refs.templatesForm.submit()
      });
    },

    setFile: function(field) {
      this.choosenFile = field.target.value;
    },

    submitForm: function() {
      this.v$.choosenFile.$touch();

      if (!this.v$.$error) {
        this.$refs.bulkUpdateForm.submit();
      }
    }
  }
}

</script>