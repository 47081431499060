<template>
<div 
  :class="['form-control-file', {'disabled': disabled}, css]">
  <input 
    :id="id" 
    :name="name" 
    type="file" 
    @change="onInput"
    :accept="accept"
  />
  <label  
    :for="id"
    :class="['btn-expandable', 'btn-expandable-add', {'disabled': disabled}]"
  >
    <span data-icon="file_upload" class="icon"></span>
    <span>{{ placeholder }}</span>
  </label>
  <div class="file-name">{{ fileName }}</div>
</div>
</template>

<script>
export default {
  props: {
    id: String,
    name: String,
    value: String,
    css: String,
    accept: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Select file"
    }
  },

  data: function () {
    return {
      fileName: ""
    }
  },

  methods: {
    onInput: function(event) {
      const [file] = (event.target.files.length > 0) ? event.target.files : [];
      
      this.fileName = (file && file.name && file.size) ? (file.name + " - " + (file.size / 1000).toFixed(2) + "KB") : "";
      
      this.$emit("change-file", file);
    }
  }
};
</script>
