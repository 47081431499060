<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for remediation types by name or description"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      :class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >
              Remediation Type
            </SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="'CATEGORY'"
              @sort="reorder"
            >
              Category
            </SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <RemediationType
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="No matching remediationTypes found"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="remediation types"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";
import SearchField from "../shared/search_field";
import SortableHeader from "../shared/grid/sortable_header";
import RemediationType from "./remediation_type";
import Pagination from "../shared/pagination";

export default {
  extends: Grid,

  components: {
    SearchField,
    SortableHeader,
    RemediationType,
    Pagination
  },

  data: function () {
    return {
      items: [],

      grid: "remediationTypes",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },

      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    };
  },

  computed: {
    filterParams: function () {
      if (this.textSearch) {
        return {
          text: {
            value: this.textSearch,
            fields: this.textSearchFields
          }
        };
      }
    },

    textSearchFields: function () {
      return ["NAME", "DESCRIPTION"];
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.remediationTypes",
        params,

        (data) => {
          const remediationTypes = data.data.remediationTypes;

          success(remediationTypes);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Remediation Types!"
          });
        }
      );
    }
  }
}
</script>
