<template>
<div 
  v-if="hasStats"
  v-bind:class="['damage-chart-wrapper', {'spinner-is-visible': isSpinnerVisible}]"
>
  <div class="spinner"></div>
  <div class="damage-chart">
    <GChart
      v-if="!isSpinnerVisible"
      type="PieChart"
      :data="worstCaseDamageFrequencyChart.data"
      :options="worstCaseDamageFrequencyChart.options"
    />
  </div>
  <div class="damage-chart-title">Summary of Worst Case Damage of Assets</div>
</div>
</template>

<script>
import { GChart } from "vue-google-charts"

import Filter from "../shared/filter/findings_filter_core";

import helpers from "../../helpers.js";

export default {
  extends: Filter,

  components: {
    GChart
  },

  props: {
    siteId: String
  },

  data: function () {
    return {
      filter: {},
      statistics: {},

      worstCaseDamageFrequencyChart: {
        data: [],
        chartOptions: {},
      },

      isSpinnerVisible: true
    }
  },

  created: function () {
    let vmFilter = this;

    this.initStateFilter();

    this.filter.siteId = this.siteId;
    this.filter.historical = this.filter.historical || false;

    // Listen for the event.
    window.addEventListener("updatestate", (event) => {
      let tmpFilter = vmFilter.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmFilter.filter = Object.assign({}, tmpFilter);
      }
    });
  },

  mounted: function() {
    setTimeout(() => {
      window.google = undefined;
      
      this.fetchStats();
    }, 2000);
  },

  computed: {
    isFilterEmpty: function() {    
      return (Object.keys(this.filter).filter(x => !["historical", "siteId"].includes(x))).length === 0;
    },

    isInPrevievMode: function() {
      return ['approved', 'draft', 'review'].every((val) => this.filter.status.includes(val));
    },

    filterParams: function () {
      let params = {
        filter: {}
      };

      if (this.filter.status) {
        params.preview = this.isInPrevievMode;
      }

      if (!this.isFilterEmpty) {
        params.filter = this.filter;
      }
      else {
        params.filter = {
          siteId: this.siteId,
          historical: this.filter.historical || false
        }
      }

      return params;
    },

    hasStats: function() {
      return this.worstCaseDamageFrequencyChart.data.length > 1;
    }
  },

  methods: {
    setWorstCaseDamageFrequencyChart(stats) {
      let vmStats = this;

      let worstCaseDamageFrequency = Object.entries(JSON.parse(stats));

      worstCaseDamageFrequency.unshift(["Rating", "Share"]);

      this.worstCaseDamageFrequencyChart.data = worstCaseDamageFrequency.map(function(rating) {
        if (typeof rating[1] == "number") {
          return ["Rating " + helpers.damageMarks[rating[0]], rating[1]];
        }
        else {
          return rating
        }
      });

      this.worstCaseDamageFrequencyChart.options = {
        legend: "none",
        pieSliceText: "label",
        pieHole: 0.4,
        width: 400,
        height: 400,
        colors: worstCaseDamageFrequency.reduce(function(colors, rating){
          if (typeof rating[1] == "number") {
            colors.push(helpers.damageColors[rating[0]]);
          }
          return colors;
        }, [])
      };
    },

    getCharts: function(statistics) {
      // Set worst case damage frequency chart props
      this.setWorstCaseDamageFrequencyChart(statistics.worstCaseDamageFrequency);

      this.isSpinnerVisible = false;
    },

    fetchStats: function() {
      app.graphql.get("statistics.finding",
        this.filterParams,
        
        (data) => {
          this.getCharts(data.data.findings.statistics);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch site findings!"
          });
        }
      )
    }
  }
}
</script>