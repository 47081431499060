<template>
<div class="form-field--element">
  <div class="element--form-group">
    <div class="element--form-control col-sm-6">
      <template v-if="isNewClientRole">
        <v-select
          placeholder="Set client company"
          ref="clientCompanies"
          :class="[{'vs-error': v$.clientCompanyId.$error}]"
          :options="clientCompanies"
          :disabled="shouldRevokeUserRole"
          :clearable="false"
          label="name"
          @update:modelValue="setClientCompany"
        />
      </template>
      <template v-else>
        <input 
          :class="['form-control', {'disabled': shouldRevokeUserRole}]"
          type="text"
          readonly
          :value="clientRole.clientCompany.name"
        />
      </template>
      <input 
        type="hidden" 
        :id="'user_client_roles_attributes_' + index + '_client_company_id'" 
        :name="'user[client_roles_attributes][' + index + '][client_company_id]'"
        :value="clientCompanyId"
      />
    </div>
    <div class="element--form-control col-sm-4">
      <v-select
        placeholder="Select user role"
        ref="userRoles"
        :class="[{'vs-error': v$.userRole.$error}]"
        :options="userRoles"
        :disabled="shouldRevokeUserRole"
        :clearable="false"
        label="value"
        @update:modelValue="setUserRole"
      />
      <input 
        type="hidden" 
        :id="'user_client_roles_attributes_' + index + '_role'" 
        :name="'user[client_roles_attributes][' + index + '][role]'"
        :value="userRole"
      />
    </div>
    <div class="element--form-control col-sm-1">
      <input 
        type="hidden" 
        :name="'user[client_roles_attributes][' + index + '][_destroy]'"
        :value="revokeValue"
      >
      <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
        <input
          :id="'user_client_roles_attributes_' + index + '__destroy'"
          type="checkbox"
          checked
          @change="revokeUserRole">
        <label 
          :for="'user_client_roles_attributes_' + index + '__destroy'"
          title="Revoke Role"
        >
          <span></span>
        </label>
      </div>
    </div>
    <div class="element--form-control col-sm-1">
      <a
        v-if="!isNewClientRole && !shouldRevokeUserRole && !isAdminClientRole"
        :href="userClientRoleSettingsUrl"
        class="btn-expandable btn-expandable-edit btn-expandable-sm float-end" 
      >
        <span class="icon" data-icon="settings"></span>
      </a>
    </div>
  </div>
  <input
    v-if="!isNewClientRole"
    type="hidden" 
    :value="clientRole.id"
    :id="'user_client_roles_attributes_' + index + '_id'" 
    :name="'user[client_roles_attributes][' + index + '][id]'"
  />
</div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect
  },

  props: {
    index: Number,
    clientCompanies: Array,
    clientRole: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userRoles: [
        {key: "admin", value: "Admin"},
        {key: "member", value: "Staff"}
      ],
      userRole: "",
      clientCompanyId: "",
      shouldRevokeUserRole: false
    }
  },

  validations () {
    return {
      clientCompanyId: {
        required
      },
      userRole: {
        required
      }
    }
  },

  mounted: function() {
    if (!this.isNewClientRole) {
      this.clientCompanyId = this.clientRole.clientCompany.id;
      
      let currentUserRole = this.userRoles.find(role => {
        return role.key === this.clientRole.role;
      });

      this.$refs.userRoles.updateValue(currentUserRole);
    }
  },

  computed: {
    isNewClientRole: function() {
      return this.clientRole.id ? false : true;
    },

    isAdminClientRole: function() {
      return this.clientRole && this.clientRole.role == "admin";
    },

    userClientRoleSettingsUrl: function () {
      return "/client_roles/" + this.clientRole.id + "/edit"
    },

    isValid: function() {
      if (this.shouldRevokeUserRole) {
        return true;
      }
      else {
        this.validate();

        return !this.v$.clientCompanyId.$error && !this.v$.userRole.$error;
      }
    },

    revokeValue: function() {
      return this.shouldRevokeUserRole ? "1" : "0";
    }
  },

  methods: {
    validate: function() {
      this.v$.clientCompanyId.$touch();
      this.v$.userRole.$touch();
    },

    setClientCompany: function(opt) {
      this.clientCompanyId  = (opt) ? opt.id : "";
    },

    setUserRole: function(opt) {
      this.userRole  = (opt) ? opt.key : "";
    },

    revokeUserRole: function() {
      this.shouldRevokeUserRole = !this.shouldRevokeUserRole;

      this.v$.$reset();
    }
  }
};
</script>
