<template>
<div class="modal-body">
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="{'hidden': !isLoaded }">
      <form 
        id="tocForm" 
        :action="formAction" 
        enctype="multipart/form-data"
        accept-charset="UTF-8"
        method="post"
        novalidate="novalidate"
      >
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token" :value="authenticityToken">
        <input type="hidden" name="user_agreement_id" :value="userAgreementId" >
      </form>
      <div v-html="tocContent"></div>
      <div class="spinner"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="form-control-toggle toggle-inline toggle-left toggle-sucess pe-4">
    <input 
      type="checkbox" 
      id="toc-accept" 
      @change="switchTocAcceptance">
    <label for="toc-accept">
      <span>I have read and agree to the Terms and Conditions</span><span></span>
    </label>
  </div>
  <form-button
    ref="submitButton"
    label="Accept"
    :disabled="!isAccepted"
    @submit="submitForm"
  ></form-button>
</div>
</template>

<script>
import formButton from "../form/form_button";

export default {
  components: {
    formButton
  },

  props: {
    args: Object
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      isAccepted: false,
      formAction: "",
      userAgreementId: "",
      content: ""
    }
  },

  computed: {
    tocContent: function() {
      // Convert markdown to HTML
      var converter = new showdown.Converter({
        underline: true
      });
        
      return converter && converter.makeHtml ? converter.makeHtml(this.content) : '';
    },

    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formAction = this.args.formAction;
    this.userAgreementId = this.args.userAgreementId;
    this.content = this.args.content.replaceAll('&quot;', '');

    this.isLoaded = true;

    let vm = this;

    setTimeout(() => {
      this.isSpinnerVisible = false;

      // Show new TOC notification
      app.ui.toast.add({
        priority: "warning",
        title : "Terms Of Conditions",
        message : "Please read our updated Terms of Conditions carefully before accessing or using portal"
      });
    }, 1000);
  },

  methods: {
    switchTocAcceptance: function($event) {
      this.isAccepted = $event.target.checked;
    },

    submitForm: function() {
      const form = document.forms["tocForm"];

      if (form) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>