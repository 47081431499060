<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Activity</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="inspection_name" class="placeholder placeholder-static">Activity name</label>
                  <input 
                    :class="['form-control', {'error': v$.inspectionName.$error}]"
                    placeholder="Name" 
                    type="text" 
                    name="inspection[name]" 
                    id="inspection_name"
                    v-model.trim="inspectionName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.inspectionName.$error" class="error">Activity name is required</div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-6">
                <form-field-date
                  placeholder="Activity date"
                  ref="inspectionDate"
                  name="inspection[date]"
                  id="inspection_date"
                  :value="inspectionDate"
                ></form-field-date>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Site</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Project</label>
                  <v-select
                    placeholder="Select project"
                    ref="projects"
                    :class="[{'vs-error': v$.inspectionProjectId.$error}]"
                    :options="projects"
                    label="name"
                    @update:modelValue="setProject"
                  />
                  <div v-if="v$.inspectionProjectId.$error" class="error">Project is required</div>
                  <input 
                    type="hidden" 
                    id="inspection_project_id" 
                    name="inspection[project_id]"
                    v-model="inspectionProjectId"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Site</label>
                  <v-select
                    placeholder="Select site"
                    ref="sites"
                    :class="[{'vs-error': v$.inspectionSiteId.$error}]"
                    :options="sites"
                    :disabled="!inspectionProjectId"
                    label="name"
                    @update:modelValue="setSite"
                  />
                  <div v-if="v$.inspectionSiteId.$error" class="error">Site is required</div>
                  <input 
                    type="hidden" 
                    id="inspection_site_id" 
                    name="inspection[site_id]"
                    v-model="inspectionSiteId"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Activity details</legend>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="inspection_weather">Weather</label>
                  <textarea 
                    class="form-control" 
                    placeholder="Weather" 
                    name="inspection[weather]" 
                    id="inspection_weather"
                    v-model="inspectionWeather"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="inspection_notes">Notes</label>
                  <textarea 
                    class="form-control" 
                    placeholder="Notes" 
                    name="inspection[notes]" 
                    id="inspection_notes" 
                    rows="5"
                    v-model="inspectionNotes"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from "vue-select";
import formFieldDate from "../shared/form/field_date";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formFieldDate,
    formButton
  },

  props: {
    formId: String,
    defaultInspectionId: String,
    defaultInspectionName: String,
    defaultInspectionDate: String,
    defaultInspectionWeather: String,
    defaultInspectionNotes: String,
    defaultInspectionProject: Object,
    defaultInspectionSite: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      projects: this.projects,
      sites: this.sites,
      inspectionName: "",
      inspectionDate: "",
      inspectionWeather: "",
      inspectionNotes: "",
      inspectionProject: null,
      inspectionProjectId: "",
      inspectionSite: null,
      inspectionSiteId: "",
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  validations () {
    return {
      inspectionName: {
        required
      },
      inspectionProjectId: {
        required
      },
      inspectionSiteId: {
        required
      }
    }
  },

  created: function() {
    this.inspectionName = this.defaultInspectionName;
    this.inspectionDate = this.defaultInspectionDate;
    this.inspectionWeather = this.defaultInspectionWeather;
    this.inspectionNotes = this.defaultInspectionNotes;

    if (this.defaultInspectionProject && Object.keys(this.defaultInspectionProject).length > 0) {
      this.inspectionProject = this.defaultInspectionProject;
      this.inspectionProjectId = this.defaultInspectionProject.id;
    }

    if (this.defaultInspectionSite && Object.keys(this.defaultInspectionSite).length > 0) {
      this.inspectionSite = this.defaultInspectionSite;
      this.inspectionSiteId = this.defaultInspectionSite.id;
    }
  },

  mounted: function() {
    if (this.defaultInspectionProject && Object.keys(this.defaultInspectionProject).length > 0) {
      this.$refs.projects.updateValue({
        id: this.defaultInspectionProject.id,
        name: this.defaultInspectionProject.name,
        clientCompany: {
          id: this.defaultInspectionProject.client_company_id
        }
      });
    }

    if (this.defaultInspectionSite && Object.keys(this.defaultInspectionSite).length > 0) {
      this.$refs.sites.updateValue(this.defaultInspectionSite);
    }

    this.fetchProjects();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  computed: {
    submitButtonLabel: function() {
      return ((this.defaultInspectionId != "")) ? "Update Activity" : "Create Activity";
    }
  },

  methods: {
    fetchProjects: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.projects = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.projects",
          fetchParams,

          (data) => {
            let projects = data.data.projects,
                pageInfo = projects.pageInfo;

            success(projects, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch projects!"
            });
          }
        )
      };

      let getProjects = (cursor) => {
        fetch(
          cursor,

          (projects, pageInfo) => {
            projects.edges.forEach((edge) => this.projects.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getProjects(pageInfo.endCursor))
            }
          }
        )
      };

      getProjects(window.btoa(0));
    },

    setProject: function(opt) {
      if (opt) {
        this.inspectionProject = opt;
        this.inspectionProjectId = opt.id;

        this.fetchSites();
      } else {
        this.inspectionProject = null;
        this.inspectionProjectId = "";

        this.sites = [];
      }

      this.inspectionSite = null;
      this.inspectionSitetId = "";

      this.$refs.sites.clearSelection();

      this.v$.inspectionSiteId.$reset();
    },

    fetchSites: function() {
      let fetchParams = {
        orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {}
      };

      if (this.inspectionProject || this.defaultInspectionProject) {
        fetchParams.filter.clientCompanyId = this.inspectionProject.clientCompany.id || this.defaultInspectionProject.client_company_id
      };
      
      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    setSite: function(opt) {
      if (opt) {
        this.inspectionSite = opt;
        this.inspectionSiteId = opt.id;
      } else {
        this.inspectionSite = null;
        this.inspectionSiteId = "";
      }
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.inspectionName.$touch();
      this.v$.inspectionProjectId.$touch();

      if (this.inspectionProjectId) {
        this.v$.inspectionSiteId.$touch();
      }

      if (form && this.v$.$errors.length <= 0 && this.$refs.inspectionDate.isValid) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>