<template>
  <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :rating="rating"
        :has-revision="hasRevision"
      />
    </td>

    <td>
      <DetailsButton
        title="Remediation Details"
        entity-type="remediationDetails"
        :entity="id"
      >
        <a
          :href="remediationUrl"
          title="Remediation"
        >{{remediationType.name}}</a>
        <div v-if="notes" class="note">{{notes}}</div>
      </DetailsButton>
    </td>

    <td>
      <a 
        :href="physicalAssetUrl"
        title="Asset details"
      >{{physicalAsset.displayName}}</a>
    </td>

    <transition name="status-change" mode="out-in">
      <td 
        v-if="showStatus" 
        :key="status" 
        class="text-center"
      >
        <span :class="'item-status-' + status">{{status}}</span>
      </td>
    </transition>

    <td class="text-center">
      <span
        v-if="photoCount > 0"
        class="indicator-images"
        title="Remediation Photos" 
        data-action="show-details" 
        data-entity-type="remediationPhotos"
        :data-entity="id"
      ></span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="remediationUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate && canToggleStatus"
        href="#"
        action="approve"
        :title="toggleStatusTitle"
        @click.prevent="toggleStatus"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    rating: Number,
    id: String,
    notes: String,
    physicalAsset: Object,
    inspection: Object,
    status: String,
    showStatus: Boolean,
    photoCount: Number,
    remediationType: Object,
    hasRevision: Boolean,
  },

  computed: {
    remediationUrl: function () {
      return "/remediations/" + this.id
    },

    remediationTypeUrl: function () {
      return "/remediation_types/" + this.remediationType.id
    },

    inspectionUrl: function () {
      return "/inspections/" + this.inspection.id
    },

    physicalAssetUrl: function () {
      return "/physical_assets/" + this.physicalAsset.id
    },

    canToggleStatus: function () {
      return this.showStatus && this.status !== "approved"
    },

    toggleStatusTitle: function () {
      if (this.status === "draft") {
        return "Ready for Review"
      } else {
        return "Approve"
      }
    }
  },

  methods: {
    toggleStatus: function () {
      app.graphql.get(
        "update.remediation.toggleStatus",

        {
          input: {
            remediationId: this.id
          }
        },

        (data) => {
          const response = data.data.toggleRemediationStatus
          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update remediation",
                message: error
              })
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Remediation Status",
              message: "Remediation status set to " + response.remediation.status
            })

            this.$emit("update-status", response.remediation);
          }
        }
      )
    }
  }
}
</script>
