<template>
 <div class="row">
  <div class="col-sm-12 col-xs-12">
    <div class="form-group">
      <label class="placeholder placeholder-static">
        {{placeholder}}
      </label>
      <v-datepicker 
        v-model="date"
        ref="datePicker"
        placeholder="Select date"
        :input-class-name="className"
        :auto-apply="true"
        :enable-time-picker="false"
        :text-input="true"
        @update:model-value="onUpdate"
      ></v-datepicker>
      <input
        v-if="fieldIsVisible"
        type="hidden"
        :id="id"
        :name="name"
        :value="fieldValue"
      >
      <div v-if="v$.fieldValue.$error" class="error">{{ placeholder }} in mm/dd/yyyy format is required</div>
    </div>
  </div>
</div>
</template>

<script>
import Helpers from "../../../helpers.js";

import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

import vDatepicker from '@vuepic/vue-datepicker';

export default {
  components: { vDatepicker },

  props: {
    id: String,
    name: String,
    value: String,
    placeholder: {
      type: String,
      default: "Select date"
    },
    shouldBeValidated: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    return {
      v$: useVuelidate(),
      h$: Helpers
    }
  },

  data: function () {
    return {
      date: null,
      fieldValue: ""
    }
  },

  validations () {
    return {
      fieldValue: {
        conditionalyRequired: isRequired(this.shouldBeValidated)
      }
    }
  },

  mounted: function() {
    if (this.value) {
      this.date = this.value;
      this.fieldValue = this.h$.formatDate.system(this.value);
    }
  },

  computed: {
    className: function() {
      return 'form-control' + (this.v$.$error ? ' error' : '');
    },

    isValid: function() {
      this.validate();

      return !this.v$.fieldValue.$error;
    },

    fieldIsVisible: function() {
      return (this.value && this.value != '') || (this.fieldValue && this.fieldValue != '');
    }
  },

  methods: {
    validate: function() {
      this.v$.fieldValue.$touch();
    },

    onUpdate: function(date) {
      if (date != null) {
        const dateStr = this.h$.formatDate.system(date);
        const utcDate = dateStr + "T12:00:00";

        this.fieldValue = dateStr;

        this.$emit("change", new Date(utcDate));
      } else {
        this.fieldValue = "";

        this.$emit("change", null);
      }

      this.validate();
    }, 
  }
}   
</script>