<template>
  <div 
    id="app-sidebar-details-content"
    :data-policy-update="userCanUpdate"
  ></div>
</template>

<script>
import EntityDetails from "./entity_details";

export default {
  extends: EntityDetails,
  
  props: {
    entityId: String,
    userCanUpdate: Boolean,
    action: String
  },

  watch: {
    entityId: function() {
      this.doAction();
    }
  },

  mounted: function() {
    this.doAction();
  },

  methods: {
    doAction: function() {
      if (this.action != undefined && this.action != '' && this.entityId != '') {
        window.execute(this.action, window, this.entityId);
      }
    }
  }
}

</script>