<template>
  <div @keydown.enter.prevent>
    <fieldset>
      <legend>Component type</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Component Type</label>
            <v-select
              placeholder="Select component type"
              ref="componentTypes"
              :class="[{'vs-error': v$.componentTypeId.$error}]"
              :options="componentTypes"
              :disabled="!component"
              label="name"
              @update:modelValue="setComponentType"
            />
            <div v-if="v$.componentTypeId.$error" class="error">Component type is required</div>
            <input
              type="hidden"
              id="component_component_type_id"
              name="component[component_type_id]"
              :value="componentTypeId"
            />
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset v-show="componentTypeId">
      <legend>Component details</legend>
      <template 
        v-for="(item, index) in componentTypeDetails"
        v-bind="item"
        :key="item.name"
        :index="index"
      >
        <component
          v-bind:is="item.formControl.type"
          :placeholder="item.name"
          :name="item.formControl.name"
          :id="item.formControl.id"
          :value = "item.defaultValue"
          :options="item.enum"
          ref="componentDetail"
        ></component>
      </template>
    </fieldset>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formSelect from "../shared/form/field_select";
import formMultiSelect from "../shared/form/field_multiselect";
import formText from "../shared/form/field_text";
import formBoolean from "../shared/form/field_boolean";
import formDate from "../shared/form/field_date";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formSelect,
    formMultiSelect,
    formText,
    formBoolean,
    formDate
  },

  props: {
    component: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      componentTypes: [],
      componentTypeId: null,
      componentTypeDetails: [],
      componentTypesAreValid: true
    }
  },

  validations () {
    return {
      componentTypeId: {
        required
      }
    }
  },

  watch: {
    component: function() {
      if (this.component) {
        this.fetchComponentTypes();
      }

      this.$refs.componentTypes.clearSelection();

      this.v$.$reset();
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.$error && this.componentTypesAreValid;
    }
  },

  mounted: function () {
    if (this.component) {
      this.fetchComponentTypes();
    }
  },

  methods: {
    setComponentType: function(opt) {
      this.componentTypeDetails = [];
      this.componentTypeId = null;

      if (opt) {
        this.componentTypeId = opt.id;

        if (opt.details) {
          this.setComponentTypeDetails(opt.details);
        }
      }

      this.$emit("change-component-type", opt);
    },

    fetchComponentTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {
          physicalAssetTypeId: this.component.physicalAsset ? this.component.physicalAsset.physicalAssetType.id : null
        }
      };

      this.componentTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.componentTypes",
          fetchParams,

          (data) => {
            let componentTypes = data.data.componentTypes,
                pageInfo = componentTypes.pageInfo;

            success(componentTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Component Types!"
            });
          }
        )
      };

      let getComponentTypes = (cursor) => {
        fetch(
          cursor,

          (componentTypes, pageInfo) => {
            componentTypes.edges.forEach((edge) => {
              if (edge.node.details) {
                edge.node.details = JSON.parse(edge.node.details)
              }
              
              this.componentTypes.push(edge.node)
            })

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getComponentTypes(pageInfo.endCursor))
            }
            else {
              if (this.component && this.component.componentType) {
                const currentComponentType = this.component.componentType;
                const currentComponentTypes = this.componentTypes.filter(componentType => componentType.id == currentComponentType.id);

                if (currentComponentTypes.length > 0) {
                  this.$refs.componentTypes.updateValue(currentComponentTypes[0]);
                }
              }
            }
          }
        )
      };

      getComponentTypes(window.btoa(0));
    },

    setComponentTypeDetails: function(details) {
      details.forEach((componentTypeDetail) => {
        if (this.component && this.component.componentType && this.component.componentType.id == this.componentTypeId) {
          if (Object.keys(this.component.details).includes(componentTypeDetail.name)) {
            componentTypeDetail.defaultValue = this.component.details[componentTypeDetail.name];
          }
        }

        componentTypeDetail.formControl = {}

        componentTypeDetail.formControl.type = 'formText'
        componentTypeDetail.formControl.name = "component[details][" + componentTypeDetail.name + "]";
        componentTypeDetail.formControl.id = "component_details_" + componentTypeDetail.name;

        switch(componentTypeDetail.type) {
          case "string":
            if (componentTypeDetail.format && componentTypeDetail.format == "date") {
              componentTypeDetail.formControl.type = 'formDate'
            }
            else if (componentTypeDetail.enum) {
              if (componentTypeDetail.multiple) {
                componentTypeDetail.formControl.type = 'formMultiSelect'
                componentTypeDetail.formControl.name += "[]";
                componentTypeDetail.formControl.id += "[]";
              }
              else {
                componentTypeDetail.formControl.type = 'formSelect'
              }
            }
            break;
          case "integer":
            componentTypeDetail.formControl.type = 'formText'
            break;
          case "number":
            componentTypeDetail.formControl.type = 'formText'
            break;
          case "boolean":
            componentTypeDetail.formControl.type = 'formBoolean'
            break;
        }

        this.componentTypeDetails.push(componentTypeDetail);
      });
    },

    validate: function() {
      this.v$.componentTypeId.$touch();
      
      this.componentTypesAreValid = true;

      if (this.$refs.componentDetail) {
        this.$refs.componentDetail.forEach(detail => {
          if (detail.v$) {
            detail.v$.$touch();

            if (detail.v$.$error) this.componentTypesAreValid = false;
          }
        })
      }

      this.$emit("validate-form");
    }
  }
}
</script>
