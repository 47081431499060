<template>
<div
  v-if="!this.$parent.hasItems"
  class="grid-info-no-records"
  v-bind:class="{ 'hidden': this.$parent.isSpinnerVisible }"
>{{label}}</div>
</template>

<script>
  export default {
    props: {
      label: String
    }
  }
</script>