export default {
  props: {
    policyUpdate: Boolean,
    selected: Boolean
  },

  data: function() {
    return {
      marked: false
    }
  },

  watch: {
    selected: function() {
      this.marked = this.selected;
    }
  },

  mounted: function() {
    this.marked = this.selected || false;
  },

  computed: {
    rowRef: function() {
      let row = {
        row: this.$.vnode.ref ? this.$.vnode.ref.r || null : null
      };

      if (this.$parent.$.vnode) {
        row.grid = this.$parent.$.vnode.ref ? this.$parent.$.vnode.ref.r || null : null;
      }

      return JSON.stringify(row);
    }
  }
}
