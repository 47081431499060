<template>
<div class="row">
  <div class="col-sm-12 col-xs-12">
    <div class="form-group">
      <label class="placeholder placeholder-static">
        {{placeholder}}
      </label>
      <input
        @input="onInput"
        :class="['form-control', {'error': v$.fieldValue.$error}]"
        ref="inputField"
        :placeholder="placeholder"
        :value="fieldValue"
        :type="type"
        :data-type="type"
      />
      <div v-if="v$.fieldValue.$error" class="error">This field is required</div>
      <input
        v-if="fieldIsVisible"
        type="hidden"
        :id="id"
        :name="name"
        :value="fieldValue"
      >
    </div>
  </div>
</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  props: {
    id: String,
    name: String,
    value: String,
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: "Text input"
    },
    shouldBeValidated: {
      type: Boolean,
      default: true
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      fieldValue: ""
    }
  },

  validations () {
    return {
      fieldValue: {
        conditionalyRequired: isRequired(this.shouldBeValidated)
      }
    }
  },

  mounted: function() {
    if (this.value != undefined) {
      this.fieldValue = this.value;
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.fieldValue.$error;
    },

    fieldIsVisible: function() {
      return (this.value && this.value != '') || (this.fieldValue && this.fieldValue != '');
    }
  },

  methods: {
    onInput: function(event) {
      let val = event.target.value;

      this.fieldValue = val;

      this.$emit("change", this.fieldValue, this);
    },

    validate: function() {
      this.v$.fieldValue.$touch();
    }
  }
};
</script>
