<template>
<div class="row">
  <div class="col-sm-12 col-xs-12">
    <div class="form-group" style="padding-top: 0;">
      <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
        <input 
          type="checkbox"
          ref="inputField"
          id="chkField"
          @change="onChange"
        >
        <label 
          class="checkbox" 
          for="chkField">
            <span>{{ placeholder }}</span><span></span>
        </label>
      </div>
    </div>
    <input 
      type="hidden"
      :name="name" 
      :id="id"
      :value="isSelected"
      >
  </div>
</div>
</template>

<script>
export default {
  props: {
    id: String,
    name: String,
    value: {
      type: String,
      default: "true"
    },
    placeholder: {
      type: String,
      default: "Selected"
    }
  },

  data: function () {
    return {
      isSelected: this.value
    }
  },

  mounted: function() {
    this.isSelected = false;

    if (this.value == true) {
      this.$refs.inputField.checked = true;
      this.isSelected = true;
    }
  },

  methods: {
    onChange: function() {
      this.isSelected = this.$refs.inputField.checked;
    }
  }
};
</script>
