<template>
  <li v-if="currentPage == pageNumber" class="page-item active" aria-current="page">
    <span class="page-link">
      {{ pageNumber }}
    </span>
  </li>

  <li v-else class="page-item">
    <a
      href="#"
      class="page-link"
      @click.prevent="$emit('navigate-to', pageNumber)"
    >{{ pageNumber }}</a>
  </li>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },

      index: {
        type: Number,
        required: true
      },

      showPages: {
        type: Number,
        required: true
      },

      totalPageCount: {
        type: Number,
        required: true
      }
    },

    computed: {
      pageNumber: function () {
        return Math.min(this.maxLeftDistance, this.maxRightDistance) + this.index
      },

      maxLeftDistance: function () {
        return Math.max(this.currentPage - this.minimumDistance, 1)
      },

      maxRightDistance: function () {
        return Math.max(this.totalPageCount - this.showPages + 1, 1)
      },

      minimumDistance: function () {
        return Math.floor(this.showPages / 2)
      }
    }
  }
</script>
