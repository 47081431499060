<template>
  <div :class="['findings-filter', {'spinner-is-visible': isSpinnerVisible }]">
    <div :class="{'hidden': !isInitialyLoaded }">

      <fieldset>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <damage-classification
              v-if="isInitialyLoaded"
              ref="ratings"
              placeholder="Rating"
              name="remediation[rating]"
              id="remediation_rating"
              :default-rating="defaultFilter.rating || []"
              :ratings="ratings"
              @changeRating="setRating"
            ></damage-classification>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">From date</label>
              <v-datepicker
                v-model="startDate"
                ref="startDate"
                placeholder="Select date"
                input-class-name="form-control filter-date-picker"
                position="left"
                :teleport="true"
                :auto-apply="true"
                :enable-time-picker="false"
                :text-input="true"
                :min-date="minimumDate"
                @update:model-value="setStartDate"
              ></v-datepicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">To date</label>
              <v-datepicker
                v-model="endDate"
                ref="endDate"
                placeholder="Select date"
                input-class-name="form-control filter-date-picker"
                position="left"
                :teleport="true"
                :auto-apply="true"
                :enable-time-picker="false"
                :text-input="true"
                :max-date="maximumDate"
                @update:model-value="setEndDate"
              ></v-datepicker>
            </div>
          </div>
        </div>
        <div 
          class="row"
          v-if="!isSingleCompany"
        >
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Companies</label>
              <v-select
                multiple
                placeholder="Select companies"
                ref="clientCompanies"
                :options="clientCompanies"
                :loading="isLoadingClientCompanies"
                label="name"
                @option:selected="selectClientCompany"
                @option:deselected="deselectClientCompany"
                @search:focus="getClientCompanies"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Projects</label>
              <v-select
                multiple
                placeholder="Select projects"
                ref="projects"
                :options="projects"
                :loading="isLoadingProjects"
                label="name"
                @option:selected="selectProject"
                @option:deselected="deselectProject"
                @search:focus="getProjects"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Sites</label>
              <v-select
                multiple
                :placeholder="isDisabledSites ? 'Select Company first' : 'Select sites'"
                ref="sites"
                :options="sites"
                :loading="isLoadingSites"
                :disabled="isDisabledSites"
                label="name"
                @option:selected="selectSite"
                @option:deselected="deselectSite"
                @search:focus="getSites"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Assets</label>
              <v-select
                multiple
                :placeholder="isDisabledAssets ? 'Select Site first' : 'Select assets'"
                ref="physicalAssets"
                :options="physicalAssets"
                :loading="isLoadingPhysicalAssets"
                :disabled="isDisabledAssets"
                label="name"
                @option:selected="selectPhysicalAsset"
                @option:deselected="deselectPhysicalAsset"
                @search:focus="getPhysicalAssets"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Asset Types</label>
              <v-select
                multiple
                placeholder="Select assets types"
                ref="physicalAssetTypes"
                :options="physicalAssetTypes"
                :loading="isLoadingPhysicalAssetTypes"
                label="name"
                @option:selected="selectedPhysicalAssetType"
                @option:deselected="deselectedPhysicalAssetType"
                @search:focus="getPhysicalAssetTypes"
              >
              </v-select>
            </div>
          </div>
        </div>
        
        <div
          v-if="isRemediationTypesVisible"
          class="row"
        >
          <div class="col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="placeholder">Remediation Types</label>
              <v-select
                multiple
                placeholder="Select remediation types"
                ref="remediationTypes"
                :options="remediationTypes"
                :loading="isLoadingRemediationTypes"
                label="name"
                @option:selected="selectRemediationType"
                @option:deselected="deselectRemediationType"
                @search:focus="getRemediationTypes"
              >
              </v-select>
            </div>
          </div>
        </div>
      </fieldset>

      <div style="display: flex; justify-content: end;">
        <ActionButton
          :css="isFilterEmpty ? 'disabled' : ''"
          text="Clear Filter"
          title="Reset Remediations Filter"
          icon="autorenew"
          size="md"
          action="default"
          @clickAction="clearFilter"
        />
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import helpers from "../../../helpers.js";

import vSelect from "vue-select";
import vDatepicker from '@vuepic/vue-datepicker';
import FieldMultiselect from "../form/field_multiselect";
import DamageClassification from "./damage_classification";
import ActionButton from "../action_button";
import Filter from "./remediations_filter_core";

export default {
  extends: Filter,

  components: {
    vSelect,
    vDatepicker,
    FieldMultiselect,
    DamageClassification,
    ActionButton
  },

  props: {
    isSingleCompany: Boolean
  },

  data() {
    return {
      defaultFilter: {},

      filter: {
        rating: [],
        projectId: [],
        clientCompanyId: [],
        siteId: [],
        physicalAssetId: [],
        physicalAssetTypeId: [],
        remediationTypeId: []
      },

      ratings: [0, 1, 2, 3, 4, 5],
      minimumDate: this.minimumDate,
      maximumDate: this.maximumDate,
      clientCompanies: this.clientCompanies,
      projects: this.projects,
      sites: this.sites,
      physicalAssets: this.physicalAssets,
      physicalAssetTypes: this.physicalAssetTypes,
      remediationTypes: this.remediationTypes,

      startDate: null,
      endDate: null,

      projectsLoaded: false,
      remediationTypesLoaded: false,
      
      isLoading: true,
      isInitialyLoaded: false,
      isSpinnerVisible: true,
      isInitialized: false,

      isLoadingClientCompanies: false,
      includeClientCompanies: false,
      isLoadingSites: false,
      isLoadingProjects: false,
      isLoadingPhysicalAssets: false,
      isLoadingPhysicalAssetTypes: false,
      isLoadingRemediationTypes: false,
      isFilterEmpty: true,
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  created: function() {
    const defaultFilter = this.getFilterState();

    if (defaultFilter) {
      this.defaultFilter = JSON.parse(defaultFilter);
    }

    this.filter.rating = this.defaultFilter.rating || [];
    this.filter.clientCompanyId = this.defaultFilter.clientCompanyId || [];
    this.filter.siteId = this.defaultFilter.siteId || [];
    this.filter.projectId = this.defaultFilter.projectId || [];
    this.filter.physicalAssetId = this.defaultFilter.physicalAssetId || [];
    this.filter.physicalAssetTypeId = this.defaultFilter.physicalAssetTypeId || [];
    this.filter.remediationTypeId = this.defaultFilter.remediationTypeId || [];

    if (this.defaultFilter.startDate) {
      this.startDate = this.defaultFilter.startDate;
      this.filter.startDate = this.defaultFilter.startDate;
    }

    if (this.defaultFilter.endDate) {
      this.endDate = this.defaultFilter.endDate;
      this.filter.endDate = this.defaultFilter.endDate;
    }
  },

  mounted: function() {
    this.initializeFilterOptions();
  },

  watch: {
    isInitialyLoaded: function() {
      if (this.isInitialyLoaded) {
        // Select defualt filter client companies
        if (this.defaultFilter.hasOwnProperty("clientCompanyId") && Object.keys(this.defaultFilter.clientCompanyId).length > 0) {
          let selectedClientCompanies = [];

          this.clientCompanies.forEach((clientCompany) => {
            if (this.defaultFilter.clientCompanyId.includes(clientCompany.id)) {
              selectedClientCompanies.push(clientCompany);
            };
          });

          this.$refs.clientCompanies.updateValue(selectedClientCompanies);
        }

        // Select defualt filter projects
        if (this.defaultFilter.hasOwnProperty("projectId") && Object.keys(this.defaultFilter.projectId).length > 0) {
          let selectedProjects = [];

          this.projects.forEach((project) => {
            if (this.defaultFilter.projectId.includes(project.id)) {
              selectedProjects.push(project);
            };
          });

          this.$refs.projects.updateValue(selectedProjects);
        }

        // Select defualt filter sites
        if (this.defaultFilter.hasOwnProperty("siteId") && Object.keys(this.defaultFilter.siteId).length > 0) {
          let selectedSites = [];

          this.sites.forEach((site) => {
            if (this.defaultFilter.siteId.includes(site.id)) {
              selectedSites.push(site);
            };
          });

          this.$refs.sites.updateValue(selectedSites);
        }

        // Select defualt filter assets
        if (this.defaultFilter.hasOwnProperty("physicalAssetId") && Object.keys(this.defaultFilter.physicalAssetId).length > 0) {
          let selectedPhysicalAssets = [];

          this.physicalAssets.forEach((physicalAsset) => {
            if (this.defaultFilter.physicalAssetId.includes(physicalAsset.id)) {
              selectedPhysicalAssets.push(physicalAsset);
            };
          });

          this.$refs.physicalAssets.updateValue(selectedPhysicalAssets);
        }

        // Select defualt filter assets types
        if (this.defaultFilter.hasOwnProperty("physicalAssetTypeId") && Object.keys(this.defaultFilter.physicalAssetTypeId).length > 0) {
          let selectedPhysicalAssetsTypes = [];

          this.physicalAssetTypes.forEach((physicalAssetType) => {
            if (this.defaultFilter.physicalAssetTypeId.includes(physicalAssetType.id)) {
              selectedPhysicalAssetsTypes.push(physicalAssetType);
            };
          });

          this.$refs.physicalAssetTypes.updateValue(selectedPhysicalAssetsTypes);
        }

        // Select defualt filter remediationTypes
        if (this.defaultFilter.hasOwnProperty("remediationTypeId") && Object.keys(this.defaultFilter.remediationTypeId).length > 0) {
          let selectedRemediationTypes = [];

          this.remediationTypes.forEach((remediationType) => {
            if (this.defaultFilter.remediationTypeId.includes(remediationType.id)) {
              selectedRemediationTypes.push(remediationType);
            };
          });

          this.$refs.remediationTypes.updateValue(selectedRemediationTypes);
        }

        // Hide spinner
        setTimeout(() => {
          this.isSpinnerVisible = false;

          this.isInitialized = true;

          this.checkIfFilterIsEmpty();
        }, 1000);
      }
    }
  },

  computed: {
    isDisabledSites: function() {
      return !this.isSingleCompany && !this.filter.clientCompanyId.length > 0 && this.filter.siteId.length == 0;
    },

    isDisabledAssets: function() {
      return !this.filter.siteId.length > 0 && this.filter.physicalAssetId.length == 0;
    },

    isRemediationTypesVisible: function() {
      return true
    },
  },

  methods: {
    checkIfFilterIsEmpty: function() {
      this.isFilterEmpty = this.filter.rating.length == 0 && this.filter.startDate == undefined && this.filter.endDate == undefined && this.filter.projectId.length == 0 && this.filter.clientCompanyId.length == 0 && this.filter.siteId.length == 0 && this.filter.physicalAssetId.length == 0 && this.filter.physicalAssetTypeId.length == 0 && this.filter.remediationTypeId.length == 0;
    },

    clearFilter: function() {
      this.isInitialized = false;

      this.filter = {
        projectId: [],
        rating: [],
        clientCompanyId: [],
        siteId: [],
        physicalAssetId: [],
        physicalAssetTypeId: [],
        remediationTypeId: []
      }

      this.$refs.ratings.reset();
      this.$refs.startDate.clearValue();
      this.$refs.endDate.clearValue();
      this.$refs.clientCompanies.clearSelection();
      this.$refs.projects.clearSelection();
      this.$refs.sites.clearSelection();
      this.$refs.physicalAssets.clearSelection();
      this.$refs.physicalAssetTypes.clearSelection();
      this.$refs.remediationTypes.clearSelection();

      this.isInitialized = true;
      this.isFilterEmpty = true;

      this.setFilter();

      setTimeout(() => {
        app.ui.state.update({
          "v-filter": null
        });
      }, 500);
    },

    setStartDate: function(date) {
      if (date != null) {
        this.filter.startDate = this.h$.formatDate.system(date);
      } else {
        delete this.filter.startDate;
      }

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    setEndDate: function(date) {
      if (date != null) {
        this.filter.endDate = this.h$.formatDate.system(date);
      } else {
        delete this.filter.endDate;
      }

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    setRating: function(selectedRating) {
      this.filter.rating = selectedRating;

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getClientCompanies: function() {
      this.setFilterOptions(["clientCompanies"]);
    },

    selectClientCompany: function(opt) {
      this.filter.clientCompanyId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    deselectClientCompany: function(opt) {
      this.filter.clientCompanyId = this.filter.clientCompanyId.filter(item => item !== opt.id)

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getProjects: function() {
      this.setFilterOptions(["projects"]);
    },

    selectProject: function(opt) {
      this.filter.projectId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectProject: function(opt) {
      this.filter.projectId = this.filter.projectId.filter(item => item !== opt.id)

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getSites: function() {
      this.setFilterOptions(["sites"]);
    },

    selectSite: function(opt) {
      this.filter.siteId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    deselectSite: function(opt) {
      this.filter.siteId = this.filter.siteId.filter(item => item !== opt.id)

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getPhysicalAssets: function() {
      this.setFilterOptions(["physicalAssets"]);
    },

    selectPhysicalAsset: function(opt) {
      this.filter.physicalAssetId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();
    },

    deselectPhysicalAsset: function(opt) {
      this.filter.physicalAssetId = this.filter.physicalAssetId.filter(item => item !== opt.id)

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getPhysicalAssetTypes: function() {
      this.setFilterOptions(["physicalAssetTypes"]);
    },

    selectedPhysicalAssetType: function(opt) {
      this.filter.physicalAssetTypeId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    deselectedPhysicalAssetType: function(opt) {
      this.filter.physicalAssetTypeId = this.filter.physicalAssetTypeId.filter(item => item !== opt.id)

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    getRemediationTypes: function() {
      this.setFilterOptions(["remediationTypes"]);
    },

    selectRemediationType: function(opt) {
      this.filter.remediationTypeId = opt.map((el) => {
        return el.id;
      });

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    deselectRemediationType: function(opt) {
      this.filter.remediationTypeId = this.filter.remediationTypeId.filter(id => id !== opt.id);

      this.setFilter();

      this.checkIfFilterIsEmpty();
    },

    setFilterOptions: function(filterOpts, callback) {

      filterOpts = filterOpts || [];

      let optionsFilter = Object.assign({}, this.filter);

      let queryParams = {
        filter: optionsFilter,
        skipBasics: this.isInitialized && filterOpts.length > 0
      };

      filterOpts.forEach((opt) => {
        let key = opt.charAt(0).toUpperCase() + opt.slice(1);

        // Set preloaders
        this["isLoading" + key] = true;

        // Set filter keys that should be included
        queryParams["include" + key] = true;
      });

      app.graphql.get("filter.select.remediationOptionsAll",

        queryParams,

        (data) => {
          let filterOptions = data.data.remediationFilterOptions;

          filterOpts.forEach((opt) => {
            this[opt] = filterOptions[opt];
          });

          if (!queryParams.skipBasics) {
            this.ratings = filterOptions.ratings;
            this.minimumDate = new Date(filterOptions.minimumDate);
            this.maximumDate = new Date(filterOptions.maximumDate);
          }

          this.isInitialyLoaded = true;

          // Hide selectbox spinner
          setTimeout(() => {
            filterOpts.forEach((opt) => {
              let key = opt.charAt(0).toUpperCase() + opt.slice(1);

              this["isLoading" + key] = false;
            });
          }, 500);

          if (callback) {
            callback();
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch filter options!"
          });
        }
      )
    },

    initializeFilterOptions: function() {
      let filterOpts = [];

      if (this.defaultFilter.hasOwnProperty("clientCompanyId")) {
        filterOpts.push("clientCompanies");
      };
      if (this.defaultFilter.hasOwnProperty("projectId")) {
        filterOpts.push("projects");
      };
      if (this.defaultFilter.hasOwnProperty("siteId")) {
        filterOpts.push("sites");
      };
      if (this.defaultFilter.hasOwnProperty("physicalAssetId")) {
        filterOpts.push("physicalAssets");
      };
      if (this.defaultFilter.hasOwnProperty("physicalAssetTypeId")) {
        filterOpts.push("physicalAssetTypes");
      };
      if (this.defaultFilter.hasOwnProperty("remediationTypeId")) {
        filterOpts.push("remediationTypes");
      };

      this.setFilterOptions(filterOpts);
    }
  }
}

</script>
