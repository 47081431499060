<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Remediation Type</legend>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="remediation_type_name" class="placeholder placeholder-static">Remediation Type name</label>
                  <input 
                    :class="['form-control', {'error': v$.remediationTypeName.$error}]"
                    placeholder="Name" 
                    type="text" 
                    name="remediation_type[name]" 
                    id="remediation_type_name"
                    v-model.trim="remediationTypeName"
                  />
                  <div v-if="v$.remediationTypeName.$error" class="error">Remediation Type name is required</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Category</label>
                  <v-select
                    placeholder="Select category"
                    ref="categories"
                    :class="[{'vs-error': v$.remediationTypeCategory.$error}]"  
                    :options="categories"
                    label="name"
                    @update:modelValue="setCategory"
                  />
                  <div v-if="v$.remediationTypeCategory.$error" class="error">Category is required</div>
                  <input 
                    type="hidden" 
                    id="remediation_type_category" 
                    name="remediation_type[category]"
                    v-model="remediationTypeCategory"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="remediation_type_description">Description</label>
                  <textarea 
                    rows="4"
                    class="form-control"
                    placeholder="Remediation Type description"
                    name="remediation_type[description]"
                    id="remediation_type_description"
                    v-model.trim="remediationTypeDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Finding Types</legend>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Asset Types</label>
                  <v-select
                    multiple
                    placeholder="Select asset types"
                    ref="physicalAssetTypes"
                    :class="[{'vs-error': v$.selectedPhysicalAssetTypes.$error}]"
                    :options="physicalAssetTypes"
                    label="name"
                    @option:selected="selectPhysicalAssetTypes"
                    @option:deselected="deselectPhysicalAssetTypes"
                  />
                  <div v-if="v$.selectedPhysicalAssetTypes.$error" class="error">Asset types are required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Finding Types</label>
                  <v-select
                    multiple
                    placeholder="Select finding types"
                    ref="findingTypes"
                    :class="[{'vs-error': v$.selectedFindingTypes.$error}]"
                    :options="findingTypes"
                    :get-option-label="findingTypeLabel"
                    :disabled="selectedPhysicalAssetTypes.length <= 0"
                    @option:selected="selectFindingTypes"
                    @option:deselected="deselectFindingTypes"
                  >
                    <template #selected-option="{ name, componentType }">
                      <div class="vs__formated">
                        <div>{{ name }}</div>
                        <small>{{ componentType.name }}</small>
                      </div>
                    </template>
                    <template #option="{ name, componentType }">
                      <div class="vs__formated">
                        <div>{{ name }}</div>
                        <small>{{ componentType.name }}</small>
                      </div>
                    </template>
                  </v-select>
                  <div v-if="v$.selectedPhysicalAssetTypes.$error" class="error">Finding types are required</div>
                </div>
                <input
                  v-for="(findingType, index) in selectedFindingTypes"
                  v-bind="findingType"
                  :key="findingType.id"
                  :index="index"
                  type="hidden"
                  name="remediation_type[finding_type_ids][]"
                  :value="findingType.id"
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>
        <div class="form-group">
          <form-button
            ref="submitButton"
            :label="submitButtonLabel"
            @submit="submitForm"
          ></form-button>
        </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formButton,
  },

  props: {
    formId: String,
    defaultRemediationTypeId: String,
    defaultRemediationTypeName: String,
    defaultRemediationTypeCategory: String,
    defaultRemediationTypeDescription: String,
    defaultFindingTypes: Array
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      remediationTypeName: "",
      remediationTypeCategory: "",
      physicalAssetTypes: [],
      selectedPhysicalAssetTypes: [],
      findingTypes: [],
      selectedFindingTypes: [],
      categories: [
        { name: "Repair", value: "repair" },
        { name: "Replace", value: "replace" }
      ],
      remediationTypeDescription: "",
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  validations () {
    return {
      remediationTypeName: {
        required
      },
      remediationTypeCategory: {
        required
      },
      selectedPhysicalAssetTypes: {
        required
      },
      selectedFindingTypes: {
        required
      }
    }
  },

  created: function() {
    this.remediationTypeName = this.defaultRemediationTypeName;
    this.remediationTypeCategory = this.defaultRemediationTypeCategory;
    this.remediationTypeDescription = this.defaultRemediationTypeDescription;
    this.selectedFindingTypes = this.defaultFindingTypes;
  },

  mounted: function() {
    this.fetchPhysicalAssetTypes();

    // Handle catrgory
    if (this.defaultRemediationTypeCategory != "") {
      let vm = this;

      let currentCategory = vm.categories.find(category => {
        return category.value === vm.defaultRemediationTypeCategory;
      });

      this.$refs.categories.updateValue(currentCategory);
    }

    // Set default physical asset
    if (this.defaultFindingTypes.length > 0) {
      this.setDefaultPhysicalAssetType();
    }
  },

  computed: {
    submitButtonLabel: function() {
      return ((this.defaultRemediationTypeId != "")) ? "Update Remediation Type" : "Create Remediation Type";
    }
  },

  watch: {
    isLoaded: function() {
      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  methods: {
    findingTypeLabel: function(findingType) {
      return findingType.name + ": " + findingType.componentType.name;
    },

    setDefaultFindingTypes: function() {
      const initialFindingTypes = this.findingTypes.filter((arrayEl) =>
        this.defaultFindingTypes.some((filterEl) => filterEl.id === arrayEl.id)
      );
      
      if (initialFindingTypes.length > 0) {
        this.$refs.findingTypes.updateValue(initialFindingTypes);
      }

      this.isLoaded = true;
    },

    selectPhysicalAssetTypes: function(opt) {
      this.findingTypes = [];

      this.$refs.findingTypes.clearSelection();

      this.v$.$reset();

      this.selectedPhysicalAssetTypes = opt ? opt : [];

      this.fetchFindingTypes();
    },

    deselectPhysicalAssetTypes: function(opt) {
      this.findingTypes = [];

      this.$refs.findingTypes.clearSelection();

      this.v$.$reset();

      this.selectedPhysicalAssetTypes = this.selectedPhysicalAssetTypes.filter(physicalAssetType => physicalAssetType !== opt);

      if (this.selectedPhysicalAssetTypes.length > 0) {
        this.fetchFindingTypes();
      }
    },

    setDefaultPhysicalAssetType: function () {
      app.graphql.get("details.componentType",
        {
          filter: {
            id: this.defaultFindingTypes.map(findingType => findingType.component_type_id) //[this.defaultFindingTypes[0].component_type_id]
          }
        },
        (data) => {
          this.selectedPhysicalAssetTypes = data.data.componentTypes.edges.map(componentType => componentType.node.physicalAssetType)

          this.$refs.physicalAssetTypes.updateValue(this.selectedPhysicalAssetTypes);

          this.fetchFindingTypes();
          
          setTimeout(() => {
            this.setDefaultFindingTypes();
          }, 1500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch physical asset type!"
          });
        }
      );
    },

    fetchPhysicalAssetTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.physicalAssetTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assetTypes",
          fetchParams,

          (data) => {
            let physicalAssetTypes = data.data.physicalAssetTypes,
                pageInfo = physicalAssetTypes.pageInfo;

            success(physicalAssetTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Asset Types!"
            });
          }
        )
      };

      let getPhysicalAssetTypes = (cursor) => {
        fetch(
          cursor,

          (physicalAssetTypes, pageInfo) => {
            physicalAssetTypes.edges.forEach((edge) => this.physicalAssetTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssetTypes(pageInfo.endCursor))
            }

            if (this.defaultFindingTypes.length == 0) {
              this.isLoaded = true;
            }
          }
        )
      };

      getPhysicalAssetTypes(window.btoa(0));
    },

    fetchFindingTypes: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: {
          field: "NAME"
        },
        filter: {
          physicalAssetTypeId: this.selectedPhysicalAssetTypes.map(asset => asset.id)
        }
      };

      this.findingTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.findingTypes",
          fetchParams,

          (data) => {
            let findingTypes = data.data.findingTypes,
                pageInfo = findingTypes.pageInfo;

            success(findingTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Finding Types!"
            });
          }
        )
      };

      let getFindingTypes = (cursor) => {
        fetch(
          cursor,

          (findingTypes, pageInfo) => {
            findingTypes.edges.forEach((edge) => this.findingTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getFindingTypes(pageInfo.endCursor));
            }
          }
        )
      };

      getFindingTypes(window.btoa(0));
    },

    selectFindingTypes: function(opt) {
      this.v$.$reset();

      this.selectedFindingTypes = opt ? opt : [];
    },

    deselectFindingTypes: function(opt) {
      this.selectedFindingTypes = this.selectedFindingTypes.filter(findingType => findingType !== opt);
    },

    setCategory: function(opt) {
      this.remediationTypeCategory = (opt) ? ((opt.name) ? opt.value : opt) : "";
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.$touch();

      if (!this.v$.$error) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>