<template>
  <div
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}</h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      
      <div class="chart-timeline">
        <div 
          v-for="(stat, index) in stats"
          :key="index"
          :class="'mark rating-' + stat.rating " 
          :style="'--position: ' + stat.position + '%'"
          :ref="'mark-' + index"
          @mouseover="hoverAction(stat, $event)"
        ></div>
      </div>
      <div class="chart-timeline-info">
        <div><strong>{{ firstDate }}</strong> - First Inspection</div>
        <div>Last Inspection - <strong>{{ lastDate }}</strong></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: Array,
    css: {
      type: String,
      default: ''
    },
    showTooltips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stats: []
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {        
        this.setData()
      }, 
      immediate: true
    }
  },

  computed: {
    firstDate: function() {
      return this.stats.length > 0 ? this.stats[0].date : ''
    },

    lastDate: function() {
      return this.stats.length > 0 ? this.stats[this.stats.length - 1].date : ''
    }
  },

  methods: {
    setData: function() {
      this.stats = this.data.map((s) => {
        return {
          date: s[0],
          type: s[1],
          rating: s[2],
          position: s[3]
        }
      })
    },

    hoverAction: function(stat, e) {
      if (this.showTooltips) {
        const tooltip = new bootstrap.Tooltip(e.target, {
          title: stat.date + ' - ' + stat.type,
          html: true,
          placement: 'top',
          container: 'body'
        })

        tooltip.show()
      }
    }
  }
}

</script>