<template>
<div class="form-field--element">
  <div class="element--form-group">
    <div class="element--form-control col-sm-7">
      <input 
        class="form-control"
        type="text"
        readonly
        :value="site.name"
      />
    </div>
    <div class="element--form-control col-sm-5">
      <v-select
        placeholder="Select user role"
        ref="userSiteRoles"
        :class="[{'vs-error': v$.userSiteRole.$error}]"
        :options="userSiteRoles"
        :clearable="false"
        label="value"
        @update:modelValue="setUserSiteRole"
      />

      <template v-if="userSiteRole == '_destroy'">
        <input
          type="hidden" 
          :name="'client_role[site_roles_attributes][' + index + '][_destroy]'" 
          :id="'client_role_site_roles_attributes_' + index + '_destroy'"
          :value="userSiteRole"
        />
      </template>
      <template v-else>
        <input
          type="hidden" 
          :name="'client_role[site_roles_attributes][' + index + '][role]'" 
          :id="'client_role_site_roles_attributes_' + index + '_role'"
          :value="userSiteRole"
        />
      </template>
      <input
        v-if="id"
        type="hidden" 
        :name="'client_role[site_roles_attributes][' + index + '][id]'"
        :id="'client_role_site_roles_attributes_' + index + '_id'"
        :value="id"
      />
      <input 
        type="hidden"  
        :name="'client_role[site_roles_attributes][' + index + '][site_id]'" 
        :id="'client_role_site_roles_attributes_' + index + '_site_id'"
        :value="site.id"
      />
    </div>
  </div>
</div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect
  },

  props: {
    index: Number,
    id: String,
    role: String,
    site: Object,
  },

  validations () {
    return {
      userSiteRole: {
        required
      }
    }
  },

  watch: {
    role: function() {
      this.setRole();
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userSiteRole: "",
      userSiteRoles: [
        {key: "_destroy", value: "None"},
        {key: "read", value: "Read Only"},
        {key: "write", value: "Write"},
      ],
    }
  },

  mounted: function() {
    this.setRole();
  },

  methods: {
    setRole() {
      this.userSiteRole = this.role || "_destroy";

      let currentUserSiteRole = this.userSiteRoles.find(role => {
        return role.key === this.userSiteRole.toLowerCase();
      });

      this.$refs.userSiteRoles.updateValue(currentUserSiteRole);
    },

    setUserSiteRole: function(opt) {
      this.userSiteRole  = (opt) ? opt.key : "";
    },
  }
};
</script>
