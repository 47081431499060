<template>
  <div class="table-wrapper">
    <div 
      v-if="policyUpdate"
      class="d-flex align-items-center justify-content-between"
    >
      <div>
        <findings-status-label
          ref="findingStatus"
          :inspection-id="inspectionId"
          :show-finding-status="true"
          :load-on-init="loadOnInit"
          @update="handleFindingsStatusButtons"
        />
      </div>
      <div>
        <div>
          <findings-reviewal-button
            v-if="showFindingsReviewalButton"
            :inspection-id="inspectionId"
            text="Review"
            title="Review All In Draft Findings"
            confirm="All Activity In Draft Findings will be reviewed?"
            size="sm"
            @reviewed="refreshGrid"
          />
          <findings-approval-button
            v-if="showFindingsApprovalButton"
            :inspection-id="inspectionId"
            text="Approve"
            title="Approve All In Review Findings"
            confirm="All Activity In Review Findings will be approved?"
            size="sm"
            @approved="refreshGrid"
          />
          <a
            :href="importFindingsUrl"
            title="Import Findings"
            class="btn-expandable btn-expandable-primary btn-expandable-sm">
              <span data-icon="cloud_download" class="icon"></span> <span>Import</span>
          </a>
          <a 
            :href="addFindingUrl" 
            title="Add New Finding" 
            class="btn-expandable btn-expandable-add btn-expandable-sm mr-0">
              <span class="icon" data-icon="add"></span>
          </a>
        </div>
      </div>
    </div>
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 115px"
              :current-sort="orderBy"
              :sort-by="'CLIENT_REMEDIATED_RATING'"
              @sort="reorder"
            >
              <InfoBadge
                :content="ratingsInfoBadgeContent"
              />
              Rating
            </SortableHeader>

            <SortableHeader
              v-if="showFindingNameColumn"
              :current-sort="orderBy"
              :sort-by="'DISPLAY_NAME'"
              @sort="reorder"
            >Finding</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'FINDING_TYPE_NAME'"
              @sort="reorder"
            >Finding Type</SortableHeader>
            
            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'SITE_NAME'"
              @sort="reorder"
            >Site</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >Asset</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'COMPONENT_NAME'"
              @sort="reorder"
            >Component</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 110px"
              v-if="showStatus"
              :current-sort="orderBy"
              :sort-by="'STATUS'"
              @sort="reorder"
            >Status</SortableHeader>

            <th
              class="text-center"
              style="width: 100px"
            >Images</th>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Finding
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :show-status="showStatus"
            :show-finding-name-column="showFindingNameColumn"
            :policy-update="policyUpdate"
            @update-status="refreshGrid"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="There is no related findings"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="findings"
        @navigate-to="paginate"
      />
      
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Finding from "./finding";
import InfoBadge from "../shared/info_badge";
import FindingsStatusLabel from "./inspection_status_label";
import FindingsApprovalButton from "../findings/findings_approval_button";
import FindingsReviewalButton from "../findings/findings_reviewal_button";

export default {
  extends: Grid,

  components: {
    Finding,
    InfoBadge,
    FindingsStatusLabel,
    FindingsApprovalButton,
    FindingsReviewalButton
  },

  props: {
    showStatus: Boolean,
    policyUpdate: Boolean,
    showFindingNameColumn: Boolean,
    inspectionId: String,
    projectId: String,
  },

  data: function () {
    return {
      items: [],

      grid: "inspection-findings",

      defaultOrderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true,

      showFindingsApprovalButton: false,
      showFindingsReviewalButton: false
    }
  },

  watch: {
    shouldLoad: function() {
      if (this.shouldLoad) {
        const statusLabel = this.$refs.findingStatus;

        if (statusLabel) {
          statusLabel.shouldLoad = true;
          statusLabel.refresh();
        }
      }
    }
  },

  computed: {
    filterParams: function () {
      return { inspectionId: this.inspectionId, status: this.statusFilter }
    },

    statusFilter: function () {
      return this.showStatus ? ["approved", "draft", "review"] : "approved"
    },

    ratingsInfoBadgeContent: function() {
      let content = "";

      content += "<div class='rating-info-popup'>";
      content += "  <div class='rating-info-mark'>";
      content += "    <div class='rating-mark'>0";
      content += "      <span class='rating-mark-sub rating-mark-sub-0'>1</span>";
      content += "      <span class='rating-mark-sub rating-mark-sub-1'>2</span>";
      content += "      <span class='rating-mark-sub rating-mark-sub-2'>3</span>";
      content += this.policyUpdate ? "<span class='rating-mark-sub rating-mark-sub-3'>4</span>" : "";
      content += "    </div>";
      content += "  </div>";
      content += "  <div class='rating-info-legend'>";
      content += "    <ol>";
      content += this.policyUpdate ? "<li>Preview Rating</li>" : "<li>Current Rating</li>";
      content += "      <li>Exo inspection rating</li>";
      content += "      <li>Inspection Override rating</li>";
      content += "      <li>Remediated rating</li>";
      content += this.policyUpdate ? "<li>Remediation Override rating</li>" : "";
      content += "    </ol>";
      content += "  </div>";
      content += "</div>";

      return content;
    },

    addFindingUrl: function () {
      return "/findings/new?inspection_id=" + this.inspectionId; 
    },

    importFindingsUrl: function () {
      return "/finding_imports/new?project_id=" + this.projectId; 
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.inspection.findings",
        params,

        (data) => {
          let findings = data.data.findings,
              pageInfo = findings.pageInfo;

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch findings!"
          })
        }
      )
    },

    handleFindingsStatusButtons: function (obj) {
      this.showFindingsApprovalButton = obj.findingsInReview > 0;
      this.showFindingsReviewalButton = obj.findingsInDraft > 0;
    },

    refreshGrid: function() {
      this.refresh();
      this.$refs.findingStatus.refresh();
    }
  }
}
</script>
