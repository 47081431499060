<template>
  <div class="modal-body" v-html="content"></div>
</template>

<script>
export default {
  components: {
    
  },

  props: {
    args: Object
  },

  data() {
    return {
      
    }
  },

  computed: {
    content: function() {
      return this.args.content || ''
    }
  }
}

</script>