<template>
  <div 
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}</h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      <div 
        :class="['row-chart', {'bar-count': includeCounts}]"
        :style="[{'width': graphWidth}]"
      >
        <div 
          v-for="(stat, index) in stats"
          :key="index"
          :class="['bar', 'rating-' + stat.rating]" 
          :style="[{'--count': stat.count}, {'--bar-width': stat.width}, {'min-width': this.barsMinWidth}]"
          :ref="'bar-' + index"
        >
          <span v-if="includeCounts">{{ stat.count }}</span>
        </div>
        <div
          v-if="includeTotal"
          class="chart-total"
        >{{ total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    
  },

  props: {
    title: String,
    data: Array,
    css: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    barsWidth: String,
    barsMinWidth: String,
    includeCounts: Boolean,
    includeTotal: Boolean,
    showTooltips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stats: [],
      total: null
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {
        this.setData()
      }, 
      immediate: true
    }
  },

  mounted: function() {
    if (this.showTooltips) {
      const vm = this;
      
      this.stats.forEach((stat, index) => {
        const bar = this.$refs['bar-' + index];

        const t = new bootstrap.Tooltip(bar[0], {
          title: 'Rating <strong>' + stat.mark + ' - ' + stat.count + '</strong> (' + stat.percentage + '%)',
          html: true,
          placement: 'top',
          trigger : 'hover'
        })
      })
    }
  },

  computed: {
    graphWidth: function() {
      return this.barsWidth ? '' : this.width;
    }
  },

  methods: {
    setData: function() {
      this.total = this.data.length > 0 ? this.data[0][4] : null;

      this.stats = this.data.map((s) => {
        return {
          rating: s[0],
          count: s[1],
          mark: s[2], 
          percentage: Math.round(s[3] * 10) / 10,
          width: this.barsWidth || Math.round(s[3] * 10) / 10 + '%'
        }
      })
    }
  }
}

</script>