<template>
<div>
<div class="row">
  <div class="col-sm-12">
    <client-role
      v-for="(clientRole, index) in userClientRoles" 
      :index="index"
      v-bind:clientRole="clientRole"
      :key="index"
      :client-companies="clientCompanies"
    />
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <button 
      type="button"
      class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
      @click="addUserClientRole()"
    >
      <span class="icon" data-icon="add"></span>
      <span>Add Client Role</span>
    </button>
  </div>
</div>
</div>
</template>

<script>
import clientRole from "./form_user_client_role";

export default {
  components: {
    clientRole,
  },

  props: {
    userClientRoles: Array
  },

  data() {
    return {
      areUserClientRolesValid: true,
      clientCompanies: this.clientCompanies
    }
  },

  mounted: function() {
    this.fetchClientCompanies();
  },

  computed: {
    isValid: function() {
      this.validate();

      return this.areUserClientRolesValid;
    }
  },

  methods: {
    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    validate: function() {
      const vUserClientRoles = this.$children;

      this.areUserClientRolesValid = true;

      if (vUserClientRoles) {
        vUserClientRoles.forEach((control) => {          
          if (!control.isValid) this.areUserClientRolesValid = false;
        });
      }
    },

    addUserClientRole: function(index) {          
      this.userClientRoles.push({
        id: null
      });
    },

    removeUserClientRole: function(index) {          
      this.userClientRoles.splice(index, 1);
    },
  }
};
</script>
