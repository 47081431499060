<template>
  <a 
    :title="title" 
    :class="['btn-expandable', 'btn-expandable-default', css]"
    :href="reportUrl"
  >
    <span class="icon" data-icon="assessment"></span>
    <span v-if="this.label">{{ label }}</span>
  </a>
</template>

<script>
import Filter from "../shared/filter/findings_filter_core";

export default {
  extends: Filter,

  props: {
    siteId: String,
    label: String,
    title: String,
    css: String
  },

  data: function () {
    return {
      filter: {}
    }
  },

  created: function () {
    let vmFilter = this;

    this.initStateFilter();

    if (this.siteId) {
      this.filter.siteId = this.siteId;
    }

    // Listen for the event.
    window.addEventListener("updatestate", (event) => {
      let tmpFilter = vmFilter.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmFilter.filter = Object.assign({}, tmpFilter);
      }
    });
  },

  computed: {
    reportSearchFilter: function() {
      let strSearch = "";

      Object.keys(this.filter).forEach((key) => {
        let filterItem = this.filter[key];

        if (filterItem.constructor === Array) {
          filterItem.forEach((el) => {
            strSearch += "&search[" + this.camelToSnake(key) + "][]=" + el;
          });
        }
        else if (filterItem.constructor === Object) {
          Object.keys(filterItem).forEach((detail) => {
            let detailItem = filterItem[detail];

            if (detailItem.constructor === Array) {
              detailItem.forEach((el) => {
                strSearch += "&search[" + this.camelToSnake(key) + "][" + detail + "][]=" + el;
              });
            }
          });
        }
        else if (filterItem.constructor === Boolean) {
          strSearch += "&search[" + this.camelToSnake(key) + "]=" + filterItem;
        }
        else {
          strSearch += "&search[" + this.camelToSnake(key) + "][]=" + filterItem;
        }
      });

      return strSearch;
    },

    reportUrl: function() {
      return "/reports?v-filter=" + JSON.stringify(this.filter) + this.reportSearchFilter;
    }
  },

  methods: {
    snakeToCamel: function(string) {
      return string.replace(/(_\w)/g, function(m) {
        return m[1].toUpperCase();
      });
    },

    camelToSnake: function(string) {
      return string.replace(/[\w]([A-Z])/g, function(m) {
        return m[0] + "_" + m[1];
      }).toLowerCase();
    }
  }
}
</script>