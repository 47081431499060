<template>
  <div class="table-wrapper">
    <div class="row">
      <div class="col-md-6 col-sm-12 grid-search-control">
        <SearchField
          v-model:textSearch="textSearch"
          placeholder="Search for component types by name"
          @input="isSpinnerVisible = true"
          @search="search"
        />
      </div>
    </div>

    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              style="width: 300px"
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Component Type</SortableHeader>

            <th>Details</th>

            <SortableHeader
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_TYPE_NAME'"
              @sort="reorder"
            >Physical Asset Type</SortableHeader>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <ComponentType
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :ref="item.id"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there are no component types found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="component types"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import ComponentType from "./component_type";

export default {
  extends: Grid,

  components: {
    ComponentType
  },

  data: function () {
    return {
      items: [],

      grid: "component-types",

      textSearch: '',

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      if (this.textSearch) {
        return {
          text: {
            value: this.textSearch,
            fields: this.textSearchFields
          }
        };
      }
    },

    textSearchFields: function () {
      return ["NAME"];
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.componentTypes",
        params,

        (data) => {
          let component_types = data.data.componentTypes,
              pageInfo = component_types.pageInfo;

          success(component_types, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch component types!"
          })
        }
      )
    }
  }
}
</script>
