<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Site</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive" for="client_company_name">Site name</label>
                  <input
                    :class="['form-control', {'error': v$.siteName.$error}]"
                    placeholder="Name"
                    type="text"
                    name="site[name]"
                    id="client_company_name"
                    v-model.trim="siteName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.siteName.$error" class="error">Site name is required</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Client Company</label>
                  <v-select
                    placeholder="Select client company"
                    ref="clientCompanies"
                    :class="[{'vs-error': v$.siteClientCompanyId.$error}]"
                    :options="clientCompanies"
                    label="name"
                    @update:modelValue="setClientCompany"
                  />
                  <div v-if="v$.siteClientCompanyId.$error" class="error">Client Company is required</div>
                  <input 
                    type="hidden" 
                    id="site_client_company_id" 
                    name="site[client_company_id]"
                    v-model="siteClientCompanyId"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="site_description">Site description</label>
                  <textarea 
                    rows="4" 
                    class="form-control" 
                    placeholder="Description" 
                    name="site[description]" 
                    id="site_description"
                    v-model.trim="siteDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Details</legend>
            <div class="row">
              <div class="col-sm-6 col-xs-6">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive" for="site_latitude">Latitude</label>
                  <input
                    :class="['form-control', {'error': v$.siteLatitude.$error}]"
                    placeholder="Latitude"
                    type="text"
                    name="site[latitude]"
                    id="site_latitude"
                    v-model.trim="siteLatitude"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.siteLatitude.$error" class="error">Site latitude is required</div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-6">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive" for="site_longitude">Longitude</label>
                  <input
                    :class="['form-control', {'error': v$.siteLongitude.$error}]"
                    placeholder="Longitude"
                    type="text"
                    name="site[longitude]"
                    id="site_longitude"
                    v-model.trim="siteLongitude"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.siteLongitude.$error" class="error">Site longitude is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 col-xs-6">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive" for="site_wind_zone">Wind Zone (Vpmnh)</label>
                  <input
                    class="form-control"
                    placeholder="Vpmnh"
                    type="text"
                    name="site[wind_zone]"
                    id="site_wind_zone"
                    v-model.trim="siteWindZone"
                    @keydown.enter.prevent="submitForm"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect
  },

  props: {
    formId: String,
    currentSiteId: String,
    currentSiteName: String,
    currentSiteDescription: String,
    currentSiteClientCompany: Object,
    currentSiteLatitude: String,
    currentSiteLongitude: String,
    currentSiteWindZone: String,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      siteName: "",
      siteDescription: "",
      siteClientCompany: null,
      siteClientCompanyId: "",
      clientCompanies: this.clientCompanies,
      siteLatitude: "",
      siteLongitude: "",
      siteWindZone: "",
    }
  },

  validations () {
    return {
      siteName: {
        required
      },
      siteClientCompanyId: {
        required
      }
      ,siteLatitude: {
        required,
        decimal
      },
      siteLongitude: {
        required,
        decimal
      }
    }
  },

  created: function() {
    this.siteName = this.currentSiteName;
    this.siteDescription = this.currentSiteDescription;
    this.siteLatitude = this.currentSiteLatitude;
    this.siteLongitude = this.currentSiteLongitude;
    this.siteWindZone = this.currentSiteWindZone;

    if (this.currentSiteClientCompany && Object.keys(this.currentSiteClientCompany).length > 0) {
      this.siteClientCompany = this.currentSiteClientCompany;
      this.siteClientCompanyId = this.currentSiteClientCompany.id;
    }
  },

  mounted: function() {
    if (this.currentSiteClientCompany && Object.keys(this.currentSiteClientCompany).length > 0) {
      this.$refs.clientCompanies.updateValue(this.currentSiteClientCompany);
    }

    this.fetchClientCompanies();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  computed: {
    submitButtonLabel: function() {
      return (this.currentSiteId) ? "Update Site" : "Create Site";
    }
  },

  methods: {
    setClientCompany: function(opt) {
      this.siteClientCompany = (opt) ? opt : null;
      this.siteClientCompanyId = (opt) ? opt.id : "";
    },

    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.siteName.$touch();
      this.v$.siteClientCompanyId.$touch();
      this.v$.siteLatitude.$touch();
      this.v$.siteLongitude.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>