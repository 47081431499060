<template>
<div>
  <div class="row" style="padding-bottom: 15px">
    <div class="col-sm-12">
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('_destroy')"
      >
        Revoke All
      </button>
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('write')"
      >
        Grant Write to All
      </button>
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('read')"
      >
        Grant Read to All
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <site-permission
        v-for="(site, index) in entities" 
        v-bind:id="site.userRoleId"
        v-bind:role="site.userRole"
        v-bind:site="site"
        :index="index"
        :key="index"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('_destroy')"
      >
        Revoke All
      </button>
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('write')"
      >
        Grant Write to All
      </button>
      <button 
        type="button"
        class="btn-expandable btn-expandable-add btn-expandable-sm float-end" 
        @click="setAll('read')"
      >
        Grant Read to All
      </button>
    </div>
  </div>
</div>
</template>

<script>
import Vue from "vue";

import sitePermission from "./form_user_site_permission";

export default {
  components: {
    sitePermission,
  },

  props: {
    sites: Array
  },

  data() {
    return {
      areUserSitePermissionsValid: true,
      entities: [],
    }
  },

  watch: {
    sites: function() {
      this.entities = this.sites;
    }
  },

  mounted: function() {
    this.entities = this.sites;
  },

  computed: {
    isValid: function() {
      this.validate();

      return this.areUserSitePermissionsValid;
    }
  },

  methods: {
    setAll: function(role) {
      const vm = this;

      this.sites.forEach((site, i) => {
        site.userRole = role;

        this.entities['i'] = site;
      });
    },

    validate: function() {
      const vUserSitePermissions = this.$children;

      this.areUserSitePermissionsValid = true;

      if (vUserSitePermissions) {
        vUserSitePermissions.forEach((control) => {          
          if (!control.isValid) this.areUserSitePermissionsValid = false;
        });
      }
    },

    addUserSitePermission: function(index) {          
      this.userSitePermissions.push({
        id: null
      });
    },

    removeUserSitePermission: function(index) {          
      this.userSitePermissions.splice(index, 1);
    },
  }
};
</script>
