<template>
  <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :is-exo-user="policyUpdate"
        :has-revision="hasRevision"
        :inspectionRating="rating"
        :inspectionOverride="override"
        :remediation="remediation"
        :client-remediated-rating="clientRemediatedRating"
        :remediation-preview-rating="remediationPreviewRating"
      />
    </td>

    <td
      v-if="showFindingNameColumn"
    >
      <DetailsButton
        title="Finding Details"
        entity-type="findingDetails"
        :entity="id"
      >
        <a
          :href="findingUrl"
          title="Finding"
        >{{displayName}}</a>
      </DetailsButton>
    </td>

    <td>
      <template v-if="showFindingNameColumn">
        <a
          :href="findingTypeUrl"
          title="Finding"
        >{{findingType.name}}</a>
      </template>
      <template v-else>
        <DetailsButton
          title="Finding Details"
          entity-type="findingDetailsSimple"
          :entity="id"
        >
          <a
            :href="findingUrl"
            title="Finding"
          >{{findingType.name}}</a>
        </DetailsButton>
      </template>
    </td>

    <td>
      <a 
        :href="siteUrl" 
        title="Details"
      >{{component.physicalAsset.site.name}}</a>
    </td>

    <td>
      <a 
        :href="assetUrl" 
        title="Details"
      >{{component.physicalAsset.name}}</a>
    </td>

    <td>
      <a
        :href="componentUrl"
        title="Details"
      >{{component.name}}</a>
    </td>

    <td>
      <a 
        :href="inspectionUrl" 
        title="Details"
      >{{inspection.name}}</a>
    </td>

    <td class="text-center">
      <span
        v-if="photoCount > 0"
        class="indicator-images"
        title="Finding Photos" 
        data-action="show-details" 
        data-entity-type="findingPhotos"
        :data-entity="id"
        :data-row="rowRef"
      ></span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="findingUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    rating: Number,
    clientRating: Number,
    remediatedRating: Number,
    clientRemediatedRating: Number,
    remediationPreviewRating: Number,
    displayName: String,
    component: Object,
    inspection: Object,
    photoCount: Number,
    findingType: Object,
    showFindingNameColumn: Boolean,
    remediation: Object,
    override: Object,
    hasRevision: Boolean
  },

  computed: {
    findingUrl: function () {
      return "/findings/" + this.id
    },

    findingTypeUrl: function () {
      return "/finding_types/" + this.findingType.id
    },

    siteUrl: function () {
      return "/sites/" + this.component.physicalAsset.site.id
    },

    assetUrl: function () {
      return "/physical_assets/" + this.component.physicalAsset.id
    },

    inspectionUrl: function () {
      return "/inspections/" + this.inspection.id
    },

    componentUrl: function () {
      return "/components/" + this.component.id
    }
  }
}
</script>
