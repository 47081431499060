<template>
  <div class="modal-body">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">

        <form 
            ref="siteImportTemplateForm"
            :id="formId" 
            :action="findingsImportTemplateUrl" 
            enctype="multipart/form-data"
            accept-charset="UTF-8"
            method="post"
            novalidate="novalidate"
          >
          <input name="utf8" type="hidden" value="✓">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="placeholder placeholder-inactive">Project</label>
                <v-select
                  placeholder="Select project"
                  ref="projects"
                  :class="[{'vs-error': v$.projectId.$error}]"
                  :options="projects"
                  label="name"
                  @update:modelValue="setProject"
                />
                <input 
                  type="hidden" 
                  id="finding_import_project_id" 
                  name="finding_import[project_id]"
                  :value="projectId"
                />
                <div v-if="v$.projectId.$error" class="error">Project is required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="placeholder placeholder-inactive">Site</label>
                <v-select
                  placeholder="Select Site"
                  ref="sites"
                  :class="[{'vs-error': v$.siteId.$error}]"
                  :options="sites"
                  :disabled="!projectId"
                  label="name"
                  @update:modelValue="setSite"
                />
                <input 
                  type="hidden" 
                  id="finding_import_site_id" 
                  name="finding_import[site_id]"
                  :value="siteId"
                />
                <div v-if="v$.siteId.$error" class="error">Site is required</div>
              </div>
            </div>
          </div>
                
          <div class="form-group">
            <form-button
              ref="submitButton"
              label="Get Findings Import Template"
              @submit="submitForm"
            ></form-button>
          </div>

        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import formButton from "../form/form_button";
import formComponentTypes from "../../site_imports/form_component_types";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect,
    formComponentTypes
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      projects: this.projects,
      project: null,
      projectId: "",
      sites: this.sites,
      site: null,
      siteId: "",
      formId: "",
      findingsImportTemplateUrl: ""
    }
  },

  validations () {
    return {
      projectId: {
        required
      },
      siteId: {
        required
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formId = this.args.findingsImportTemplateFormId
    this.findingsImportTemplateUrl = this.args.findingsImportTemplateUrl

    this.fetchProjects();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    setProject: function(opt) {
      this.project = (opt) ? opt : null;
      this.projectId  = (opt) ? opt.id : "";

      this.site = null;
      this.siteId = "";
      this.sites = [];

      this.$refs.sites.clearSelection();

      this.v$.$reset();

      if (opt) {
        this.fetchSites();
      }
    },

    fetchProjects: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.projects = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.projects",
          fetchParams,

          (data) => {
            let projects = data.data.projects,
                pageInfo = projects.pageInfo;

            success(projects, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Projects!"
            });
          }
        )
      };

      let getProjects = (cursor) => {
        fetch(
          cursor,

          (projects, pageInfo) => {
            projects.edges.forEach((edge) => this.projects.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getProjects(pageInfo.endCursor))
            }
          }
        )
      };

      getProjects(window.btoa(0));
    },

    setSite: function(opt) {
      this.site = (opt) ? opt : null;
      this.siteId  = (opt) ? opt.id : "";
      
      this.v$.$reset();
    },

    fetchSites: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          clientCompanyId: this.project.clientCompany.id
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.siteId.$touch();
      this.v$.projectId.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>