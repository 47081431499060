<template>
<div>
  <template v-if="isComplexDetails">
    <ul 
      :class="['entity-details', {'is-archived': item.archived || archived}]"
      v-for="(item, index) in items"
      :key="index"
    >
      <li class="header">
        {{ item.name }} <span :class="['label', typeCssClass(item.archived || archived)]">{{ dataTypeName(item) }}</span> <span v-if="item.archived" class="label label-warning">archived</span>
        <div
          v-if="item.fulcrumDataName"
          class="alternate-label">
          Fulcrum: <strong>{{ item.fulcrumDataName }}</strong>
        </div>
      </li>
      <li 
        v-if="item.enum"
        class="detail"
      >
        <strong>options</strong> {{ item.enum.join(", ") }}
      </li>
    </ul>
  </template>
  <template v-else>
    <ul class="entity-details">
      <li
        class="header" 
        v-for="(value, key) in items"
        :key="key"
      >
        {{ key }} <span class="label label-active">{{ formatValue(value) }}</span>
      </li>
    </ul>
  </template>
</div>
</template>

<script>
import helpers from "../../../helpers.js";

export default {
  props: {
    details: String,
    archived: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      items: {}
    }
  },

  mounted: function () {
    this.items = this.getDetails();
  },

  computed: {
    isComplexDetails: function() {
      return Array.isArray(this.items);
    }
  },

  methods: {
    getDetails: function () {
      return (this.details) ? JSON.parse(this.details) : {};
    },

    typeCssClass: function(isArchived) {
      return isArchived ? 'label-default' : 'label-active';
    },

    formatValue: function (val) {
      let value = "";

      if (val) {
        if (Array.isArray(val)) {
          value = val.join(", ");
        }
        else {
          value = val.toString();

          if (/(\d{4}-\d{2}-\d{2})/.test(value)) {
            value = helpers.formatDate.short(value);
          }
        }
      }

      return value;
    },

    dataTypeName: function(item) {
      let type;

      switch(item.type) {
        case "string":
          if (item.format && item.format == "date") {
            type = "date";
          }
          else if (item.enum) {
            type = item.multiple ? "multiselect" : "select";
          }
          else {
            type = "text";
          }
          break;
        case "integer":
            type = "rounded number";
          break;
        case "number":
            type = "decimal number";
          break;
        case "boolean":
            type = "true / false";
          break;
      }

      return type;
    }
  }
}
</script>
