<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <DetailsButton
        title="Site Details"
        entity-type="siteDetails"
        :entity="id"
      >
        <a
          :href="siteUrl"
        >{{name}}</a>
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="siteUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="siteUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="siteUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";

export default {
  extends: Row,

  components: {
    RowAction,
    DetailsButton
  },

  props: {
    id: String,
    name: String,
    description: String,
    clientCompany: Object
  },

  computed: {
    siteUrl: function () {
      return "/sites/" + this.id;
    },

    clientCompanyUrl: function () {
      return "/client_companies/" + this.clientCompany.id;
    }
  }
}
</script>
