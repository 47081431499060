<template>
  <div @keydown.enter.prevent>
    <fieldset>
      <legend>Activity</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Project</label>
            <v-select
              placeholder="Select project"
              ref="projects"
              :class="[{'vs-error': v$.projectId.$error}]"
              :options="projects"
              label="name"
              @update:modelValue="setProject"
            />
            <div v-if="v$.projectId.$error" class="error">Project is required</div>
            <input 
              type="hidden" 
              id="finding_project_id"
              name="finding[project_id]"
              :value="projectId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Activity</label>
            <v-select
              placeholder="Select activity"
              ref="inspections"
              :class="[{'vs-error': v$.inspectionId.$error && projectId}]"
              :disabled="!projectId"
              :options="inspections"
              :get-option-label="getLabel"
              label="name"
              @update:modelValue="setInspection"
            />
            <div v-if="v$.inspectionId.$error && projectId" class="error">Activity is required</div>
            <input 
              type="hidden" 
              id="finding_inspection_id" 
              name="finding[inspection_id]"
              :value="inspectionId"
            />
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Component</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Site</label>
            <input 
              placeholder="Acivity site"
              class="form-control"
              type="text"
              readonly
              :disabled="!inspectionId"
              :value="siteName"
            />
            <input 
              type="hidden" 
              id="finding_site_id" 
              name="finding[site_id]"
              :value="siteId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Asset</label>
            <v-select
              placeholder="Select asset"
              ref="assets"
              :class="[{'vs-error': v$.assetId.$error && siteId}]"
              :disabled="!siteId"
              :options="assets"
              label="name"
              @update:modelValue="setPhysicalAsset"
            />
            <div v-if="v$.assetId.$error && siteId" class="error">Physical Asset is required</div>
            <input 
              type="hidden" 
              id="finding_physical_asset_id" 
              name="finding[physical_asset_id]"
              :value="assetId"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Component</label>
            <v-select
              placeholder="Select component"
              ref="components"
              :class="[{'vs-error': v$.componentId.$error && assetId}]"
              :disabled="!assetId"
              :options="components"
              label="name"
              @update:modelValue="setComponent"
            />
            <div v-if="v$.componentId.$error && assetId" class="error">Asset Component is required</div>
            <input 
              type="hidden" 
              id="finding_component_id" 
              name="finding[component_id]"
              :value="componentId"
            />
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect
  },

  props: {
    defaultFinding: Object,
    defaultClientCompany: Object,
    defaultProject: Object,
    defaultInspection: Object,
    defaultSite: Object,
    defaultAsset: Object,
    defaultComponent: Object,
    defaultProjectId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      projects: this.projects,
      inspections: this.inspections,
      sites: this.sites,
      assets: this.assets,
      components: this.components,
      clientCompanyId: null,
      projectId: null,
      inspectionId: null,
      siteId: null,
      siteName: null,
      assetId: null,
      componentId: null
    }
  },

  validations () {
    return {
      projectId: {
        required
      },
      inspectionId: {
        required
      },
      assetId: {
        required
      },
      componentId: {
        required
      }
    }
  },

  computed: {
    sortOrder: function() {
      return {
        field: "NAME",
        direction: "ASC"
      }
    },

    isValid: function() {
      this.validate();

      return !this.v$.$error;
    }
  },

  mounted: function () {
    this.fetchProjects();

    if (Object.keys(this.defaultClientCompany).length > 0) {
      this.clientCompanyId = this.defaultClientCompany.id
    }

    if (Object.keys(this.defaultProject).length > 0) {
      this.projectId = this.defaultProject.id;

      this.$refs.projects.updateValue(this.defaultProject);
    }

    if (Object.keys(this.defaultInspection).length > 0) {
      this.inspectionId = this.defaultInspection.id;

      this.siteId = this.defaultSite.id;
      this.siteName = this.defaultSite.name;

      this.defaultInspection.site = {
        id: this.defaultSite.id,
        name: this.defaultSite.name
      };

      this.$refs.inspections.updateValue(this.defaultInspection);
    }

    if (Object.keys(this.defaultAsset).length > 0) {
      this.assetId = this.defaultAsset.id;

      this.$refs.assets.updateValue(this.defaultAsset);
    }

    if (Object.keys(this.defaultComponent).length > 0) {
      this.componentId = this.defaultComponent.id;

      this.$refs.components.updateValue(this.defaultComponent);
    }
    
    if (this.defaultProjectId != "") {
      this.getSingleProject();
    }
  },

  methods: {
    getLabel: function(obj) {
      return obj.name;
    },

    setProject: function(opt) {
      if (opt) {
        this.projectId = opt.id;

        this.fetchInspections();
      }
      else {
        this.projectId = null;
        this.inspectionId = null;
        this.siteId = null;
        this.siteName = null;

        this.inspections = [];
      }

      this.$refs.inspections.clearSelection();

      this.v$.$reset();
    },

    setInspection: function(opt) {
      if (opt) {
        this.inspectionId = opt.id;

        if (opt.site && (this.siteId != opt.site.id)) {
          this.siteId = opt.site.id;
          this.siteName = opt.site.name;
        }

        this.fetchAssets();
      }
      else {
        this.inspectionId = null;
        this.siteId = null;
        this.siteName = null;

        this.assets = [] ;
      }

      this.$refs.assets.clearSelection();

      this.v$.$reset();
    },

    setPhysicalAsset: function(opt) {
      if (opt) {
        this.assetId = opt.id;

        this.fetchComponents();
      }
      else {
        this.assetId = null;
        this.componentId = null;

        this.components = [] ;
      }

      this.$refs.components.clearSelection();

      this.v$.$reset();
    },

    setComponent: function(opt) {
      this.componentId = (opt) ? opt.id : null;

      this.$emit("change-component", opt);
    },

    getSingleProject: function () {
      app.graphql.get("select.projects",
        {
          filter: {
            id: this.defaultProjectId
          }
        },

        (data) => {
          if (data.data.projects.totalCount > 0) {
            let project = data.data.projects.edges[0].node;
            
            this.projectId = project.id;
            this.$refs.projects.updateValue(project);
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch project!"
          });
        }
      )
    },

    fetchProjects: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      if (this.clientCompanyId) {
        fetchParams.filter = {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.projects = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.projects",
          fetchParams,

          (data) => {
            let projects = data.data.projects,
                pageInfo = projects.pageInfo;

            success(projects, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch projects!"
            });
          }
        )
      };

      let getProjects = (cursor) => {
        fetch(
          cursor,

          (projects, pageInfo) => {
            projects.edges.forEach((edge) => this.projects.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getProjects(pageInfo.endCursor))
            }
          }
        )
      };

      getProjects(window.btoa(0));
    },

    fetchInspections: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {
          projectId: this.projectId
        }
      };

      this.inspections = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.inspections",
          fetchParams,

          (data) => {
            let inspections = data.data.inspections,
                pageInfo = inspections.pageInfo;

            success(inspections, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch project activities!"
            });
          }
        )
      };

      let getInspections = (cursor) => {
        fetch(
          cursor,

          (inspections, pageInfo) => {
            inspections.edges.forEach((edge) => this.inspections.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getInspections(pageInfo.endCursor))
            }
          }
        )
      };

      getInspections(window.btoa(0));
    },

    fetchAssets: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        filter: {
          siteId: this.siteId
        },
        orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.assets = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assets",
          fetchParams,

          (data) => {
            let assets = data.data.physicalAssets,
                pageInfo = assets.pageInfo;

            success(assets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site assets!"
            });
          }
        )
      };

      let getAssets = (cursor) => {
        fetch(
          cursor,

          (assets, pageInfo) => {
            assets.edges.forEach((edge) => this.assets.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getAssets(pageInfo.endCursor))
            }
          }
        )
      };

      getAssets(window.btoa(0));
    },

    fetchComponents: function() {
      let fetchParams = {
        pageSize: 100,
        physicalAssetIDs: this.assetId,
        orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.components = [];

      let currentPage = 1,
          maxPage = 100;
      
      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.components",
          fetchParams,

          (data) => {
            let components = data.data.components,
                pageInfo = components.pageInfo;

            success(components, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch asset components!"
            });
          }
        )
      };

      let getComponents = (cursor) => {
        fetch(
          cursor,

          (components, pageInfo) => {
            components.edges.forEach((edge) => this.components.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getComponents(pageInfo.endCursor))
            }
          }
        )
      };

      getComponents(window.btoa(0));
    },

    validate: function() {
      this.v$.projectId.$touch();
      this.v$.inspectionId.$touch();
      this.v$.assetId.$touch();
      this.v$.componentId.$touch();
    }
  }
}
</script>
