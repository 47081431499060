<template>
  <tr
    :class="{'is-marked': marked}"
    @click="showDetailsModal"
  >
    <td class="text-center" style="vertical-align: middle;">
      <DamageRating
        :rating="latestSummary.clientRemediatedRating"
      />
    </td>

    <td>
      <DetailsButton
        title="Site Statistics"
        css="btn-details-statistics"
        @click="showDetailsModal"
        :entity="id"
      >
        {{name}}
        <div v-if="description" class="note">{{description}}</div>
        <RowChart
          ref="worstCaseDamageFrequencyBar"
          :data="worstCaseDamageFrequency"
          :include-total="true"
          :include-counts="true"
          :width="width"
          :show-tooltips="false"
          bars-width="70px"
          css="site-overview-row-chart"
        />
      </DetailsButton>
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";
import RowChart from "../shared/stats/row_chart";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    DetailsButton,
    DamageRating,
    RowChart
  },

  props: {
    id: String,
    name: String,
    description: String,
    latestSummary: Object,
    statistics: Object,
    clientStatistics: Object
  },

  data: function () {
    return {
      worstCaseDamageFrequencyBars: {
        data: []
      }
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    width: function() {
      const minWidth = 50;

      const fSpan = this.clientStatistics.maxAssets - this.clientStatistics.minAssets;

      const x = (this.statistics.totalRatedAssets - this.clientStatistics.minAssets) / fSpan;

      return ((minWidth * x) + minWidth) + '%';
    },
    
    worstCaseDamageFrequency: function () {
      let worstCaseDamageFrequency = Object.entries(JSON.parse(this.statistics.worstCaseDamageFrequency))

      let total = 0

      worstCaseDamageFrequency.forEach((r) => {
        total += r[1]
      })

      worstCaseDamageFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push((r[0] != 0) ? this.h$.damageMarks[r[0]] : '0')
        r.push(percentage)
        r.push(total)
      });

      return worstCaseDamageFrequency;
    },
  },

  created: function() {
    // Set worst case damage frequency bars props
    this.setWorstCaseDamageFrequencyBars();
  },

  methods: {
    setWorstCaseDamageFrequencyBars() {
      let worstCaseDamageFrequency = Object.entries(JSON.parse(this.statistics.worstCaseDamageFrequency))

      let total = 0

      worstCaseDamageFrequency.forEach((r) => {
        total += r[1]
      })

      worstCaseDamageFrequency.forEach((r) => {
        let percentage = r[1] / total * 100

        r.push(this.h$.damageMarks[r[0]])
        r.push(percentage)
      });

      this.worstCaseDamageFrequencyBars.data = worstCaseDamageFrequency;
    },

    showDetailsModal: function() {
      vmModal.$refs.modal.show({
        template: 'siteDetailsModal',
        args: {
          siteId: this.id,
        },
        style: {
          size: 'xl',
          class: 'modal-site-details',
          verticalyCentered: true
        }
      })
    } 
  }
}
</script>
