<template>
  <div class="table-wrapper related-items">
    <div class="table-responsive table-scroll">

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center no-label"
              style="width: 40px"
              :current-sort="orderBy"
              :sort-by="'CLIENT_REMEDIATED_RATING'"
              :update-ui-state="false"
              @sort="reorder"
            ></SortableHeader>

            <SortableHeader
              v-if="showFindingNameColumn"
              :current-sort="orderBy"
              :sort-by="'DISPLAY_NAME'"
              :update-ui-state="false"
              @sort="reorder"
            >Finding</SortableHeader>
          </tr>
        </thead>
      </table>

      <div
        :class="['table-body', { 'spinner-is-visible': isSpinnerVisible }]"
        style="margin-bottom: 5px; max-height: 250px;"
      >
        <table class="table">
          <tbody>
            <Finding
              v-for="(item, index) in items"
              v-bind="item"
              :key="item.id"
              :filter="filter"
              :index="index"
              :show-status="policyUpdate"
              :show-finding-name-column="showFindingNameColumn"
              :track-history="trackHistory"
              :policy-update="policyUpdate"
            />
          </tbody>
        </table>

        <div class="spinner"></div>

        <NoRecords
          label="There is no related findings"
        />
      </div>

      <Pagination
        v-bind="pageInfo"
        @navigate-to="paginate"
        :update-ui-state="false"
      />

    </div>
  </div>
</template>

<script>
import Grid from "../../shared/grid/grid";

import Finding from "./related_finding"

export default {
  extends: Grid,

  components: {
    Finding
  },

  props: {
    policyUpdate: Boolean,
    showFindingNameColumn: Boolean,
    defaultFilter: Object,
    trackHistory: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      items: [],

      grid: "related-findings",

      defaultOrderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "CLIENT_REMEDIATED_RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  watch: {
    defaultFilter: function() {
      this.filter = this.defaultFilter;
    }
  },

  computed: {
    filterParams: function () {
      this.filter = this.defaultFilter;
      
      this.filter.status = this.statusFilter;
      this.filter.historical = true;

      return this.filter;
    },

    statusFilter: function () {
      return this.policyUpdate ? ["approved", "draft", "review"] : "approved"
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.findings",
        params,

        (data) => {
          let findings = data.data.findings,
              pageInfo = findings.pageInfo;

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch findings!"
          })
        }
      )
    }
  }
}
</script>
