<template>
  <div class="pagination-picker">
    Show
    <span class="dropup">
      <span class="dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown">{{ pageSize }}</span>
      <ul class="dropdown-menu">
        <li>
          <a 
            href="#"
            @click.prevent="$emit('navigate-to', 1, 20)"
          >20</a>
        </li>
        <li v-if="totalCount > 20">
          <a 
            href="#"
            @click.prevent="$emit('navigate-to', 1, 50)"
          >50</a>
        </li>
        <li v-if="totalCount > 50">
          <a 
            href="#"
            @click.prevent="$emit('navigate-to', 1, 100)"
          >100</a>
        </li>
      </ul>
    </span> 
    {{ entityType }}
  </div>
</template>

<script>
export default {
  props: {
    entityType: {
      type: String,
      required: true
    },

    totalCount: {
      type: Number,
      required: true
    },

    pageSize: {
      type: Number,
      required: true
    }
  }
}
</script>
