<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Component type</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="component_type_name" class="placeholder placeholder-static">Name</label>
                  <input 
                    :class="['form-control', {'error': v$.componentTypeName.$error}]"
                    placeholder="Component type name" 
                    type="text" 
                    name="component_type[name]" 
                    id="component_type_name"
                    v-model.trim="componentTypeName"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.componentTypeName.$error" class="error">Component type name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Asset Type</label>
                  <v-select
                    placeholder="Select asset type"
                    ref="physicalAssetTypes"
                    :class="[{'vs-error': v$.physicalAssetTypeId.$error}]"
                    :options="physicalAssetTypes"
                    label="name"
                    @update:modelValue="setPhysicalAssetType"
                  />
                  <div v-if="v$.physicalAssetTypeId.$error" class="error">Aset type is required</div>
                  <input 
                    type="hidden" 
                    id="component_type_physical_asset_type_id" 
                    name="component_type[physical_asset_type_id]"
                    :value="physicalAssetTypeId"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="component_type_description">Description</label>
                  <textarea 
                    rows="4" 
                    class="form-control"
                    placeholder="Component type description" 
                    name="component_type[description]" 
                    id="component_type_description"
                    v-model="componentTypeDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Component type Details</legend>
            <form-data-types
              ref="dataTypes"
              :default-data-types="componentTypeDetails"
              entity-type="component_type"
            />
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formDataTypes from "../shared/data_types/form_data_types";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formDataTypes,
    formButton
  },

  props: {
    formId: String,
    componentTypeId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      componentTypeName: "",
      componentTypeDescription: "",
      componentTypeDetails: null,
      physicalAssetTypes: this.physicalAssetTypes,
      physicalAssetType: null,
      physicalAssetTypeId: "",
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  validations () {
    return {
      componentTypeName: {
        required
      },
      physicalAssetTypeId: {
        required
      }
    }
  },

  computed: {
    submitButtonLabel: function() {
      return (this.isExistingcomponentType) ? "Update Component type" : "Create Component type";
    },

    isExistingcomponentType: function() {
      return this.componentTypeId != "";
    }
  },

  mounted: function() {
    this.fetchPhysicalAssetTypes();

    if (this.isExistingcomponentType) {
      this.fetchcomponentType();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  methods: {
    fetchcomponentType: function(callback) {
      app.graphql.get("details.componentType",
        {
          filter: {
            id: [this.componentTypeId]
          }
        },
        (data) => {
          let currentcomponentType = data.data.componentTypes.edges[0].node;

          this.componentTypeName = currentcomponentType.name;
          this.componentTypeDescription = currentcomponentType.description;
          
          this.componentTypeDetails = currentcomponentType.details ? JSON.parse(currentcomponentType.details) : [];

          this.componentTypeDetails.map((detail) => {
            if (detail.enum) {   
              let enumOptions = []

              if (detail.enum) {
                enumOptions = detail.enum.map((opt, index) => {
                  return {
                    name: opt
                  }
                });

                delete detail.enum;
              }

              detail.options = [...enumOptions];
            }

            return detail;
          });

          this.physicalAssetType = currentcomponentType.physicalAssetType;
          this.physicalAssetTypeId = currentcomponentType.physicalAssetType.id;
          
          this.$refs.physicalAssetTypes.updateValue(this.physicalAssetType);
          
          this.isLoaded = true;

          if (callback) {
            callback();
          }

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Component type!"
          });
        }
      );
    },

    setPhysicalAssetType: function(opt) {
      this.physicalAssetType = (opt) ? opt : null;
      this.physicalAssetTypeId  = (opt) ? opt.id : "";
      
      this.v$.$reset();
    },

    fetchPhysicalAssetTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.physicalAssetTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assetTypes",
          fetchParams,

          (data) => {
            let physicalAssetTypes = data.data.physicalAssetTypes,
                pageInfo = physicalAssetTypes.pageInfo;

            success(physicalAssetTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Asset Types!"
            });
          }
        )
      };

      let getPhysicalAssetTypes = (cursor) => {
        fetch(
          cursor,

          (physicalAssetTypes, pageInfo) => {
            physicalAssetTypes.edges.forEach((edge) => this.physicalAssetTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssetTypes(pageInfo.endCursor))
            }
          }
        )
      };

      getPhysicalAssetTypes(window.btoa(0));
    },
    
    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.componentTypeName.$touch();
      this.v$.physicalAssetTypeId.$touch();

      this.$refs.dataTypes.validate();

      let dataTypesAreValid = this.$refs.dataTypes.isValid;

      if (!this.v$.$error && dataTypesAreValid) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}

</script>
