<template>
  <a
    :href="href"
    :class="['btn-expandable', 'btn-expandable-xs', actionClass]"
    :title="title"
    @click="clickActionCall"
  >
    <span
      v-if="label"
      class="label"
    >{{label}}</span>
    <span
      v-if="icon"
      class="icon"
      :data-icon="icon"
    ></span>
    <span
      v-if="text"
    >{{text}}</span>
  </a>
</template>

<script>
export default {
  props: {
    text: String,
    title: String,
    label: String,
    icon: String,
    confirm: String,
    href: {
      type: String,
      default: "#"
    },
    action: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "primary",
          "add",
          "preview",
          "review",
          "info",
          "delete",
          "edit",
          "approve"
        ].indexOf(value) !== -1
      }
    }
  },

  computed: {
    actionClass: function () {
      return "btn-expandable-" + this.action
    }
  },

  methods: {
    clickActionCall: function (event) {
      if (this.href == "#") {
        event.preventDefault();

        let approve = true;

        if (this.action == "delete" || (this.confirm && this.confirm.length > 0)) {
          approve = confirm(this.confirm.length > 0 ? this.confirm : "Are you sure?");
        }

        if (approve == true) {
					this.$emit("clickAction", event);
				}
      }
    }
  }
}
</script>
