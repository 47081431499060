<template>
  <tr>
    <td>{{user.email}}</td>

    <td>{{user.name}}</td>

    <td>{{role.toLowerCase()}}</td>

    <td 
      v-if="policyUpdate"
      class="text-center">
      <span :class="'item-status-' + user.activationState.toLowerCase()">
        {{user.activationState}}
      </span>
    </td>

    <td 
      v-if="policyUpdate"
      class="text-right">
      {{lastActive}}
    </td>

    <td class="table-row-actions">
      <RowAction
        action="update"
        :href=" editUserRoleUrl"
      />

      <RowAction
        action="block"
        rel="nofollow"
        :href=" deactivateUserRoleUrl"
      />
    </td>
  </tr>
</template>

<script>
import RowAction from "../shared/grid/row_action";
import Helpers from "../../helpers";

export default {
  components: {
    RowAction
  },

  props: {
    id: String,
    role: String,
    user: Object,
    policyUpdate: Boolean
  },

  computed: {
    lastActive: function () {
      if (this.user.lastActivity) {
        return Helpers.formatDate.short(this.user.lastActivity);
      } else {
        return "None";
      }
    },

     editUserRoleUrl: function () {
      return "/client_roles/" + this.id + "/edit";
    },

     deactivateUserRoleUrl: function () {
      return "/client_roles/" + this.id;
    }
  }
}
</script>
