<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="componentTypeUrl"
      >{{name}}</a>
      <div v-if="description" class="note">{{description}}</div>
    </td>

    <td>
      <Details 
        :details="details" 
      />
    </td>

    <td>
      <a
        :href="physicalAssetTypeUrl"
      >{{physicalAssetType.name}}</a>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="componentTypeUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentTypeUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentTypeUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";

export default {
  extends: Row,

  components: {
    RowAction,
    Details
  },

  props: {
    id: String,
    name: String,
    description: String,
    details: String,
    physicalAssetType: Object
  },

  computed: {
    componentTypeUrl: function () {
      return "/component_types/" + this.id;
    },

    physicalAssetTypeUrl: function () {
      return "/physical_asset_types/" + this.physicalAssetType.id;
    }
  }
}
</script>
