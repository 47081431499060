
<template>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-xs-12">
        <div
          v-if="progress > 0"
          class="progress"
        >
          <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
        </div>
        <div :class="['form-control', 'upload', {'focus':$refs.uploadDocuments && $refs.uploadDocuments.dropActive}, {'is-uploading':isUploading}]">
          <files-upload
            class="btn-expandable btn-expandable-add btn-expandable-sm"
            input-id="fileUploadDocuments"
            post-action="/documents"
            extensions="pdf,csv,doc,docx,xls,xlsx,jpg,jpeg,jfif,txt"
            accept="application/pdf,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpeg,text/plain"
            :headers="{'X-CSRF-TOKEN': authenticityToken}"
            :multiple="true"
            :drop="true"
            :size="100000000"
            v-model="uploadDocuments"
            @input-file="inputFile"
            ref="fileUploadDocuments">
              <span class="icon" data-icon="cloud_upload"></span>
              <span>Pick documents</span>
          </files-upload>

          <p>Or Drag Documents Here</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div>
        <div class="form-group col-xs-12 upload-options">
          <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
            <input 
              type="checkbox" 
              id="chkInternalDocument" 
              @change="setDocumentAsInternal">
            <label for="chkInternalDocument">
              <span>Internal document</span><span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilesUpload from 'vue-upload-component'

export default {
  components: {
    FilesUpload
  },

  props: {
    args: Object
  },

  data() {
    return {
      entityId: String,
      entityType: String,
      entityTypeCaption: String,
      areInternalDocuments: false,
      progress: 0,
      isReadyForUpload: false,
      isUploading: false,
      isInFocus: false,
      uploadDocuments: []
    }
  },

  created: function() {
    this.entityId = this.args.entityId;
    this.entityType = this.args.entityType;
    this.entityTypeCaption = this.args.entityTypeCaption;
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  methods: {
    setDocumentAsInternal: function($event) {
      this.areInternalDocuments = $event.target.checked;
    },

    setFocus: function() {
      this.isReadyForUpload = true;
    },

    removeFocus: function() {
      this.isReadyForUpload = false;
    },

    resetUpload: function() {
      this.isReadyForUpload = false;
      this.isUploading = false;
      this.progress = 0;
    },

    inputFile: function(newFile, oldFile) {
      let vm = this;

      if (newFile && !oldFile) {
        newFile.data['document[file]'] = newFile.file;
        newFile.data['document[caption]'] = newFile.name;
        newFile.data['document[' + vm.entityType + '_id]'] = vm.entityId;

        if (!vm.$refs.fileUploadDocuments.active) {
          vm.$refs.fileUploadDocuments.active = true
        }
      }

      if (newFile && oldFile) {
        // Start upload
        if (newFile.active !== oldFile.active) {
          vm.isUploading = true;
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          vm.progress = newFile.progress;
        }

        // Upload error
        if (newFile.error !== oldFile.error) {

          this.resetUpload();

          vmModal.$refs.modal.hide();

          app.ui.toast.add({
            priority: "danger",
            title: "Document upload",
            message: "Document was not uploaded!</br>" + newFile.error
          })
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          // Set document as internal if needed
          if (vm.areInternalDocuments && (window.vmDocuments)) {
            let documentGrid = window.vmDocuments.$refs[vm.entityType + 'Documents'];

            if (documentGrid) {
              documentGrid.setDocumentAsInternal(
                newFile.response.caption,
                newFile.response.id,
                true
              );
            }
          }

          vm.finishUpload();

          app.ui.toast.add({
            priority: "success",
            title : "Documents upload",
            message : "Document <strong>" + newFile.name + "</strong> was successfuly added!"
          });
        }
      }
    },

    finishUpload: function() {
      if (vmDocuments) {
        const documentGrid = vmDocuments.$refs[this.entityType + 'Documents'];

        if (documentGrid) {
          documentGrid.refresh();
        }
      };

      this.resetUpload();

      vmModal.$refs.modal.hide();
    }
  }
}

</script>