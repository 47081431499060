<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <form 
          ref="siteImportTemplateForm"
          :id="formId" 
          :action="siteImportTemplateUrl" 
          enctype="multipart/form-data"
          accept-charset="UTF-8"
          method="post"
          novalidate="novalidate"
        >
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="authenticity_token" :value="authenticityToken">

        <div class="row">
          <div class="col-sm-6 col-xs-12" @keydown.enter.prevent>
            <fieldset>
              <legend>Asset Types Filter</legend>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-inactive">Asset Types</label>
                    <v-select
                      placeholder="Select asset type"
                      ref="physicalAssetTypes"
                      :class="[{'vs-error': v$.physicalAssetTypeId.$error}]"
                      :options="physicalAssetTypes"
                      label="name"
                      @update:modelValue="setPhysicalAssetType"
                    />
                    <div v-if="v$.physicalAssetTypeId.$error" class="error">Aset type is required</div>
                    <input 
                      type="hidden" 
                      id="physical_asset_type_id" 
                      name="physical_asset_type_id"
                      :value="physicalAssetTypeId"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-sm-6 col-xs-12" @keydown.enter.prevent>
            <fieldset>
              <legend>Component types</legend>
              <form-component-types
                ref="componentTypes"
                :currentPhysicalAssetType="physicalAssetType"
              />
            </fieldset>
          </div>
        </div>
              
        <div class="form-group">
          <form-button
            ref="submitButton"
            label="Download Site Import Template"
            @submit="submitForm"
          ></form-button>
        </div>

      </form>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";
import formComponentTypes from "./form_component_types";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formButton,
    vSelect,
    formComponentTypes
  },

  props: {
    formId: String,
    siteImportTemplateUrl: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      physicalAssetTypes: this.physicalAssetTypes,
      physicalAssetType: null,
      physicalAssetTypeId: ""
    }
  },

  validations () {
    return {
      physicalAssetTypeId: {
        required
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.fetchPhysicalAssetTypes();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    setPhysicalAssetType: function(opt) {
      this.physicalAssetType = (opt) ? opt : null;
      this.physicalAssetTypeId  = (opt) ? opt.id : "";
      
      this.v$.$reset();
    },

    fetchPhysicalAssetTypes: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.physicalAssetTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assetTypes",
          fetchParams,

          (data) => {
            let physicalAssetTypes = data.data.physicalAssetTypes,
                pageInfo = physicalAssetTypes.pageInfo;

            success(physicalAssetTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Asset Types!"
            });
          }
        )
      };

      let getPhysicalAssetTypes = (cursor) => {
        fetch(
          cursor,

          (physicalAssetTypes, pageInfo) => {
            physicalAssetTypes.edges.forEach((edge) => this.physicalAssetTypes.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getPhysicalAssetTypes(pageInfo.endCursor))
            }
          }
        )
      };

      getPhysicalAssetTypes(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.physicalAssetTypeId.$touch();

      this.$refs.componentTypes.validate();

      let componentTypesAreValid = this.$refs.componentTypes.isValid;

      if (form && this.v$.$errors.length <= 0 && componentTypesAreValid) {
        this.$refs.submitButton.loadingOn();

        form.submit();

        setTimeout(() => {
          this.$refs.submitButton.loadingOff();
        }, 3000);
      }
    }
  }
}

</script>