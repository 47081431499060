<template>
  <div class="table-wrapper">
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 100px"
              :current-sort="orderBy"
              :sort-by="'RATING'"
              @sort="reorder"
            >Rating</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REMEDIATION_TYPE_NAME'"
              @sort="reorder"
            >Remediation</SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'INSPECTION_NAME'"
              @sort="reorder"
            >Activity</SortableHeader>

            <th>Site</th>

            <th 
              class="text-center"
              style="width: 100px"
            >Category</th>

            <SortableHeader
              class="text-center"
              style="width: 100px"
              v-if="showStatus"
              :current-sort="orderBy"
              :sort-by="'STATUS'"
              @sort="reorder"
            >Status</SortableHeader>

            <th 
              class="text-center"
              style="width: 100px"
            >Images</th>
            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Remediation
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :show-status="showStatus"
            :policy-update="policyUpdate"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="There is no related remediations"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="remediations"
        @navigate-to="paginate"
      />
      
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";
import SortableHeader from "../shared/grid/sortable_header"
import Remediation from "./remediation"
import Pagination from "../shared/pagination"

export default {
  extends: Grid,

  components: {
    SortableHeader,
    Remediation,
    Pagination
  },

  props: {
    showStatus: Boolean,
    searchFilter: Object
  },

  data: function () {
    return {
      items: [],

      grid: "remediations",

      defaultOrderBy: {
        field: "RATING",
        direction: "DESC"
      },

      orderBy: {
        field: "RATING",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 50,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      delete this.filter.historical

      return this.filter
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.remediations",
        params,

        (data) => {
          let remediations = data.data.remediations,
              pageInfo = remediations.pageInfo;

          success(remediations, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch remediations!"
          })
        }
      )
    }
  }
}
</script>
