<template>
  <header class="sidebar-details-header">
    <h2>{{ title }}</h2>
    <div 
      v-if="hasHistory"
      class="sidebar-back" 
      title="Back" 
      @click="back()"
    >
      <span class="icon" data-icon="arrow_back"></span>
    </div>
    <div 
      class="sidebar-close" 
      title="Close" 
      @click="close()"
    >
      <span class="icon" data-icon="close"></span>
    </div>
  </header>
  <div 
    class="sidebar-details-content"
    ref="detailsContent"
  >
    <component
      v-bind:is="template"
      ref="entityDetails"
      :entity-id="entity"
      :action="action"
      :user-can-update="userCanUpdate"
      :args="args"
    ></component>
  </div>
</template>

<script>
import { ref, getCurrentInstance, defineExpose } from 'vue';

import { GChart } from "vue-google-charts";
import blankDetails from "./blank_details";
import findingDetails from "./finding_details";
import findingDetailsSimple from "./finding_details_simple";
import findingPhotos from "./finding_photos";
import findingRevisionDetails from "./finding_revision_details";
import remediationDetails from "./remediation_details";
import remediationPhotos from "./remediation_photos";
import remediationRevisionDetails from "./remediation_revision_details";
import physicalAssetDetails from "./physical_asset_details";
import physicalAssetDetailsSimple from "./physical_asset_details_simple";
import siteDetails from "./site_details";

export default {
  components: {
    GChart,
    blankDetails,
    findingDetails,
    findingDetailsSimple,
    findingPhotos,
    findingRevisionDetails,
    remediationDetails,
    remediationPhotos,
    remediationRevisionDetails,
    physicalAssetDetails,
    physicalAssetDetailsSimple,
    siteDetails
  },

  props: {
    userCanUpdate: Boolean
  },

  data() {
    return {
      title: 'Details',
      entity: '',
      template: 'blankDetails',
      action: '',
      trackHistory: false,
      history: [],
      args: {}
    }
  },

  computed: {
    hasHistory: function() {
      return this.history.length > 0;
    }
  },

  methods: {
    open: function(opts) {
      if (opts.trackHistory) {
        this.history = [];

        this.history.push({
          title: this.title,
          entity: this.entity,
          template: this.template,
          action: this.action,
          args: this.args,
        });
      }

      this.title = opts.title;
      this.entity = opts.entity;
      this.template = opts.entityType;
      this.action = opts.action;
      this.args = opts.args;
      
      let vmSidebar = this.$root.$el.parentElement;

      vmSidebar.classList.add("is-expanded");

      window.openSidebar = window.setTimeout(function () {
        vmSidebar.classList.add("in");
      }, 50);
    },

    close: function() {
      let vmSidebar = this.$root.$el.parentElement;

      vmSidebar.classList.remove("in");

      app.ui.grid.unmarkRows();

      window.openSidebar = window.setTimeout(function () {
        vmSidebar.classList.remove("is-expanded");
      }, 500);

      this.title = "Details";
      this.entity = "";
      this.template = "";
      this.action = "";
      this.trackHistory = false;
      this.history = [];
      this.args = {};
    },

    back: function() {
      let h = this.history.pop();

      this.trackHistory = false;

      this.open({
        title: h.title,
        entity: h.entity,
        entityType: h.template,
        action: h.action,
        args: h.args,
      });
    }
  }
}

</script>
