<template>
  <li 
    v-if="currentPage > 1"
    class="page-item page-prev"
  >
    <a
      href="#"
      class="page-link" 
      aria-label="Previous"
      @click.prevent="$emit('navigate-to', currentPage - 1)"
    >
      <span aria-hidden="true" class="icon" data-icon="navigate_before"></span>
    </a>
  </li>

  <li 
    v-else 
    class="page-item page-prev disabled"
  >
    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
      <span aria-hidden="true" class="icon" data-icon="navigate_before"></span>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    }
  }
}
</script>