<template>
  <div class="row" @keydown.enter.prevent>
    <div class="col-sm-12 col-xs-12">
      <div class="form-group">
        <label class="placeholder placeholder-static">Inspectors</label>
        <v-select
          multiple
          placeholder="Select inspectors"
          ref="inspectors"
          :class="[{'vs-error': v$.selectedInspectors.$error}]"
          :options="inspectors"
          label="name"
          @option:selected="selectInspectors"
          @option:deselected="deselectInspectors"
        />
        <div v-if="v$.selectedInspectors.$error" class="error">At least one inspector should be defined</div>
        <input
          v-for="(inspector, index) in selectedInspectors"
          v-bind="inspector"
          :key="inspector.id"
          :index="index"
          type="hidden"
          name="finding[inspector_ids][]"
          :value="inspector.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  components: {
    vSelect,
  },

  props: {
    defaultInspectors: Array,
    shouldBeValidated: Boolean
  },
  
  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      inspectors: [],
      selectedInspectors: this.defaultInspectors
    }
  },

  validations () {
    return {
      selectedInspectors: {
        conditionalyRequired: isRequired(this.shouldBeValidated)
      }
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.$error;
    }
  },

  mounted: function() {
    this.fetchInspectors();

    if (this.selectedInspectors && this.selectedInspectors.length > 0) {
      this.$refs.inspectors.updateValue(this.selectedInspectors);
    }
  },

  methods: {
    fetchInspectors: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {
          active: true
        }
      };

      this.inspectors = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.inspectors",
          fetchParams,

          (data) => {
            let inspectors = data.data.inspectors,
                pageInfo = inspectors.pageInfo;

            success(inspectors, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch inspectors!"
            });
          }
        )
      };

      let getInspectors = (cursor) => {
        fetch(
          cursor,

          (inspectors, pageInfo) => {
            inspectors.edges.forEach((edge) => this.inspectors.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getInspectors(pageInfo.endCursor));
            }

            this.isLoaded = true;
          
            setTimeout(() => {
              this.isSpinnerVisible = false;
            }, 1000);
          }
        )
      };

      getInspectors(window.btoa(0));
    },

    selectInspectors: function(opt) {
      this.v$.$reset();

      this.selectedInspectors = opt ? opt : [];
    },

    deselectInspectors: function(opt) {
      this.selectedInspectors = this.selectedInspectors.filter(inspector => inspector !== opt);
    },

    validate: function() {
      this.v$.selectedInspectors.$touch();
    }
  }
}

</script>
