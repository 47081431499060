<template>
    <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :rating="currentClassification" 
      />
    </td>

    <td class="text-center">
      <DamageRating
        v-if="changes.classification"
        :rating="original.classification" 
      />
    </td>

    <td>
      <DetailsButton
        title="Finding Details"
        entity-type="findingDetails"
        :entity="finding.id"
      >
        <a
          :href="findingUrl"
          title="Finding"
        >{{finding.displayName}}</a>
      </DetailsButton>
    </td>

    <td>
      <DetailsButton
        title="Revision Details"
        style="max-width: 350px"
        entity-type="findingRevisionDetails"
        :entity="id"
      >
        {{category}}
        <div v-if="description" class="note">{{description}}</div>
      </DetailsButton>
    </td>

    <td>
      <a 
        :href="siteUrl" 
        title="Details"
      >
        {{finding.component.physicalAsset.site.name}}
      </a>
    </td>

    <td>
      <a 
        :href="assetUrl" 
        title="Details"
      >
        {{finding.component.physicalAsset.name}}
      </a>
    </td>

    <td>
      <a
        :href="componentUrl" 
        title="Details"
      >
        {{finding.component.name}}
      </a>
    </td>

    <td>
      {{user.name}}
    </td>

    <td class="text-right">
      {{ h$.formatDate.short(createdAt) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="findingUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl + '/edit'"
        action="update"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    finding: Object,
    createdAt: String,
    description: String,
    category: String,
    user: Object,
    objectChanges: String,
    originalObject: String
  },

  data: function () {
    return {
      changes: {},
      original: {}
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  created: function () {
    this.changes = JSON.parse(this.objectChanges);
    this.original = JSON.parse(this.originalObject);
  },

  methods: {
    ratingClass: function (rating) {
      return "rating-" + rating;
    }
  },

  computed: {
    findingUrl: function () {
      return "/findings/" + this.finding.id;
    },

    siteUrl: function () {
      return "/sites/" + this.finding.component.physicalAsset.site.id;
    },

    assetUrl: function () {
      return "/physical_assets/" + this.finding.component.physicalAsset.id;
    },

    componentUrl: function () {
      return "/components/" + this.finding.component.id;
    },

    findingDetailsTitle: function () {
      let control = "Alt+Click";

      if (navigator.platform.toLowerCase().indexOf("win") > -1) {
        control = "Ctrl+Click";
      }

      return control + " for details page";
    },

    currentClassification: function() {
      return (this.changes.classification) ? this.changes.classification[1] : this.original.classification;
    }
  }
}
</script>