<template>
  <section :class="['form-control-related-items', { 'error': v$.listedFindings.$error }]">
    <ul class="nav nav-tabs" role="tablist" data-active-tab="related-findings">
      <li class="nav-item" role="presentation">
        <a href="#all-findings" class="nav-link" aria-controls="all-findings" role="tab" data-bs-toggle="tab">Findings</a>
      </li>
      <template v-if="relatedFindingsFilter">
        <li class="nav-item" role="presentation">
          <a href="#related-findings" class="nav-link active" aria-controls="related-findings" role="tab" data-bs-toggle="tab">Related Findings <span v-if="listedFindings.length > 0" class="badge">{{ listedFindings.length }}</span></a>
        </li>
      </template>
      <template v-else>
        <li class="nav-item">
          <a href="#" class="nav-link disabled">Related Findings</a>
        </li>
      </template>
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="card-content tab-pane active" id="all-findings" style="height: 400px">
        <filtered-findings
          v-if="relatedFindingsFilter"
          v-bind="{ 
            'findingsFilter': relatedFindingsFilter,
            'listedFindings': listedFindings
          }"
          ref="filteredFindings"
          :show-finding-name-column="true"
          :show-all-findings="true"
          :default-findings="defaultFindings"
          @toggle-finding="toggleFinding"
        ></filtered-findings>

        <div 
          v-if="!relatedFindingsFilter"
          class="grid-info-no-records"
        >Set Remediation Type and search for possible related Findings</div>
      </div>

      <div role="tabpanel" class="card-content tab-pane" id="related-findings" style="height: 400px">
        <related-findings
          v-if="relatedFindingsFilter"
          v-bind="{ 
            'listedFindings': listedFindings
          }"
          ref="relatedFindings"
          :show-finding-name-column="true"
          :show-all-findings="true"
          :remediation-id="remediationId"
          @remove-finding="removeFinding"
          @findings-listed="listFindings"
        ></related-findings>
      </div>
    </div>

    <div v-if="v$.listedFindings.$error" class="error">At least one related finding is required</div>

    <input
      type="hidden"
      name="remediation[finding_ids][]"
      v-for="(finding, index) in listedFindings" 
      :index="index"
      :key="finding.id"
      :value="finding.id"
    >
    
  </section>
</template>

<script>
import Vue from "vue"
import relatedFindings from "./form_related_findings";
import filteredFindings from "./form_filtered_findings";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    relatedFindings,
    filteredFindings
  },

  props: {
    remediationId: String,
    findingsFilter: Object,
    newFindingId: String,
    defaultFindings: Array,
    showFindingNameColumn: Boolean
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      relatedFindingsFilter: null,
      listedFindings: []
    }
  },

  validations () {
    return {
      listedFindings: {
        required
      }
    }
  },

  created: function() {
    if (this.newFindingId != "") {
      // List Finding if new Remediation is created directly from Finding
      this.defaultFindings.push(this.newFindingId);
    }
  },

  watch: {
    findingsFilter: function(filter) {
      this.relatedFindingsFilter = (filter.remediationTypeId) ? filter: null;
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.$error;
    }
  },

  methods: {
    listFindings: function(findings) {
      this.listedFindings = findings;
    },

    removeFinding: function(finding) {
      let i = this.listedFindings.findIndex(x => x.id === finding.id);

      if (i > -1) {
        this.listedFindings.splice(i, 1);
      }
    },

    toggleFinding: function(finding) {
      let i = this.listedFindings.findIndex(x => x.id === finding.id);

      if (i > -1) {
        this.listedFindings.splice(i, 1);
      }
      else {
        this.listedFindings.push(finding);
      }
    },

    validate: function() {
      this.v$.listedFindings.$touch();
    }
  }
}

</script>
