<template>
  <div class="modal-body">
    <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
      <div v-bind:class="{'hidden': !isLoaded }">

        <form 
            :ref="formId"
            :id="formId" 
            :action="formUrl" 
            enctype="multipart/form-data"
            accept-charset="UTF-8"
            method="post"
            novalidate="novalidate"
          >
          <input type="hidden" name="utf8" value="✓">
          <input type="hidden" name="_method" value="patch">
          <input type="hidden" name="authenticity_token" :value="authenticityToken">

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="form-control-toggle toggle-inline toggle-left toggle-warning">
                  <input 
                    type="checkbox" 
                    id="chkUserOtpEnable" 
                    :checked="isUserOtpEnabled"
                    @change="setFindingTypeArchive">
                  <label for="chkUserOtpEnable">
                    <span>Enable Dual Authentication</span><span></span>
                  </label>
                </div>

                <input
                  name="user[otp_enabled]" 
                  type="hidden" 
                  :value="isUserOtpEnabled ? '1' : '0'"
                >
                <input
                  v-if="!isUserOtpEnabled"
                  name="user[otp_code_token]"
                  id="user_otp_code_token"
                  type="hidden"
                  value="">
              </div>
            </div>
          </div>
          <div 
            v-if="isUserOtpEnabled"
            class="row"
          >
            <div class="col-sm-12" @keydown.enter.prevent>
              <div class="form-group">
                <input
                  name="user[otp_code_token]"
                  id="user_otp_code_token"
                  type="text"
                  :class="['form-control', {'error': v$.userAuthToken.$error}]"
                  placeholder="Token" 
                  v-model.trim="userAuthToken">
                <div v-if="v$.userAuthToken.$error" class="error">Authentication token is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <form-button
                  ref="submitButton"
                  label="Set Dual Authentication"
                  class="btn btn-primary"
                  @submit="submitForm"
                ></form-button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import formButton from "../form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  components: {
    formButton,
  },

  props: {
    args: Object
  },
  

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      formId: "",
      formUrl: "",
      isUserOtpEnabled: false,
      userAuthToken: ""
    }
  },

  validations () {
    return {
      userAuthToken: {
        required
      }
    }
  },

  computed: {
    authenticityToken: function () {
      const metaCsrfToken = document.querySelector('meta[name="csrf-token"]')
      
      return metaCsrfToken ? metaCsrfToken.content : '';
    },
  },

  mounted: function() {
    this.formId = this.args.formId;
    this.formUrl = this.args.formUrl;
    this.isUserOtpEnabled = JSON.parse(this.args.userOtpEnabled);

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    setFindingTypeArchive: function($event) {
      this.isUserOtpEnabled = $event.target.checked;

      this.v$.$reset();
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      if (this.isUserOtpEnabled) {
        this.v$.userAuthToken.$touch();
      }

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>