<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="componentUrl + filterQueryParams"
        :title="name"
        :data-entity="id"
      >{{name}}</a>
      <div v-if="description" class="note">{{description}}</div>
    </td>

    <td class="text-center">
      <DamageRating
        v-if="(!showInPreviewMode && latestSummary.approvedDate) || (showInPreviewMode && latestSummary.previewDate)"
        :rating="showInPreviewMode ? latestSummary.remediationPreviewRating : latestSummary.clientRemediatedRating" 
      />
    </td>

    <td 
      v-if="showLeCondition"
      class="text-center"
    >
      <DamageRating
        v-if="(!showInPreviewMode && latestLeadingEdgeSummary.approvedDate) || (showInPreviewMode && latestLeadingEdgeSummary.previewDate)"
        :rating="showInPreviewMode ? latestLeadingEdgeSummary.remediationPreviewRating : latestLeadingEdgeSummary.clientRemediatedRating"
      />
    </td>

    <td class="text-right">
      {{lastActivityDate}}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="componentUrl + filterQueryParams"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";
import DamageRating from "../shared/damage_rating";
import DetailsButton from "../shared/grid/details_button";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    Details,
    DamageRating,
    DetailsButton
  },

  props: {
    filter: Object,
    id: String,
    name: String,
    description: String,
    latestSummary: Object,
    latestLeadingEdgeSummary: Object,
    showLeCondition: Boolean,
    showInPreviewMode: Boolean
  },

  computed: {
    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["siteId", "physicalAssetId"].includes(x))).length === 0;
    },

    filterQueryParams: function() {
      let filterParams = Object.assign({}, this.filter);
      
      delete filterParams.physicalAssetId;

      filterParams.componentId = this.id;
      
      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(filterParams) : "";
    },

    componentUrl: function () {
      return "/components/" + this.id;
    },

    lastActivityDate: function() {
      const activityDate = this.showInPreviewMode ? this.latestSummary.previewDate : this.latestSummary.approvedDate;

      return activityDate ? helpers.formatDate.short(activityDate) : "N/A";
    },
  }
}
</script>
