<template>
  <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :is-exo-user="policyUpdate"
        :has-revision="hasRevision"
        :inspectionRating="rating"
        :inspectionOverride="override"
        :remediation="remediation"
        :client-remediated-rating="clientRemediatedRating"
        :remediation-preview-rating="remediationPreviewRating"
        :show-in-preview-mode="showInPreviewMode"
      />
    </td>

    <td
      v-if="showFindingNameColumn"
    >
      <DetailsButton
        title="Finding Details"
        entity-type="findingDetails"
        :entity="id"
        :args="{showInPreviewMode: this.showInPreviewMode}"
      >
        <a
          :href="findingUrl"
          title="Finding"
        >{{displayName}}</a>
      </DetailsButton>
    </td>

    <td>
      <template v-if="showFindingNameColumn">
        <a
          :href="findingTypeUrl"
          title="Details"
        >{{findingType.name}}</a>
      </template>
      <template v-else>
        <DetailsButton
          title="Finding Details"
          entity-type="findingDetailsSimple"
          :entity="id"
        >
          <a
            :href="findingUrl"
            title="Finding"
          >{{findingType.name}}</a>
        </DetailsButton>
      </template>
    </td>

    <td>
      <a :href="componentUrl" title="Details">{{component.name}}</a>
    </td>

    <transition name="status-change" mode="out-in">
      <td 
        v-if="showStatus" 
        :key="status" 
        class="text-center"
      >
        <span :class="'item-status-' + status">{{status}}</span>
      </td>
    </transition>

    <td class="text-center">
      <span
        v-if="photoCount > 0"
        class="indicator-images"
        title="Finding Photos" 
        data-action="show-details" 
        data-entity-type="findingPhotos"
        :data-entity="id"
        :data-row="rowRef"
      ></span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="findingUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate && canToggleStatus"
        href="#"
        action="approve"
        :title="toggleStatusTitle"
        @click.prevent="toggleStatus"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    RowAction,
    DetailsButton,
    DamageRating
  },

  props: {
    filter: Object,
    rating: Number,
    clientRating: Number,
    remediatedRating: Number,
    clientRemediatedRating: Number,
    remediationPreviewRating: Number,
    id: String,
    displayName: String,
    component: Object,
    findingType: Object,
    status: String,
    showStatus: Boolean,
    showFindingNameColumn: Boolean,
    photoCount: Number,
    index: Number,
    remediation: Object,
    override: Object,
    hasRevision: Boolean,
    policyUpdate: Boolean,
    showInPreviewMode: Boolean
  },

  computed: {
    isFilterEmpty: function() {    
      return (Object.keys(this.filter).filter(x => !["historical", "siteId", "physicalAssetId", "status"].includes(x))).length === 0;
    },

    findingUrl: function () {
      return "/findings/" + this.id
    },

    findingTypeUrl: function () {
      return "/finding_types/" + this.findingType.id
    },

    componentUrl: function () {
      return "/components/" + this.component.id + this.componentFilterQueryParams
    },

    componentFilterQueryParams: function() {
      let filterParams = Object.assign({}, this.filter)
      
      delete filterParams.physicalAssetId

      filterParams.componentId = this.component.id

      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(filterParams) : ""
    },

    canToggleStatus: function () {
      return this.showStatus && this.status !== "approved"
    },

    toggleStatusTitle: function () {
      if (this.status === "draft") {
        return "Ready for Review"
      } else {
        return "Approve"
      }
    }
  },

  methods: {
    toggleStatus: function () {
      app.graphql.get(
        "update.finding.toggleStatus",

        {
          input: {
            findingId: this.id
          }
        },

        (data) => {
          const response = data.data.toggleFindingStatus
          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              })
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Finding Status",
              message: "Finding status set to " + response.finding.status
            })

            this.$emit("update-status", response.finding)
          }
        }
      )
    }
  }
}
</script>
