<template>
  <div class="card">
    <section class="card-content">
      <div class="row">
        <div class="col-sm-6">
          <fieldset>
            <legend>Physical Asset</legend>
            <div @keydown.enter.prevent>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-inactive">Client Company</label>
                    <v-select
                      placeholder="Select Client"
                      ref="clientCompanies"
                      :options="clientCompanies"
                      label="name"
                      @update:modelValue="setClientCompany"
                    />
                    <input 
                      type="hidden" 
                      id="qr_label_client_company_id" 
                      name="qr_label[client_company_id]"
                      :value="clientCompanyId"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-inactive">Site</label>
                    <v-select
                      placeholder="Select Site"
                      ref="sites"
                      :disabled="!clientCompanyId"
                      :options="sites"
                      label="name"
                      @update:modelValue="setSite"
                    />
                    <input 
                      type="hidden" 
                      id="qr_label_site_id" 
                      name="qr_label[site_id]"
                      :value="siteId"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-inactive">Asset</label>
                    <v-select
                      placeholder="Select Asset"
                      ref="assets"
                      :class="[{'vs-error': v$.assetId.$error}]"
                      :disabled="!siteId"
                      :options="assets"
                      label="name"
                      @update:modelValue="setAsset"
                    />
                    <div v-if="v$.assetId.$error" class="error">Physical Asset is required</div>
                    <input 
                      type="hidden" 
                      id="qr_label_physical_asset_id" 
                      name="qr_label[physical_asset_id]"
                      :value="assetId"
                    />
                  </div>
                </div>
              </div>
              <div 
                class="row"
                v-if="assetHasAssignedQRLabels"
              >
                <div class="form-group col-xs-12">
                  <input type="checkbox" id="qr-primary" v-model="isPrimary"><label class="checkbox" for="qr-primary">Set QR label <strong>{{qrLabel.label_number}}</strong> as primary for selected Asset</label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6">
          <fieldset v-if="assetId">
            <legend>Associated QR labels</legend>
            <div class="asset-entity-details">
              <physical-asset-qr-labels
                ref="assetQrLabels"
                :policy-update="policyUpdate"
                :physical-asset-id="assetId"
              />
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <form-button
              ref="submitButton"
              :label="buttonLabel"
              @submit="submitForm"
            ></form-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vSelect from "vue-select";
import physicalAssetQrLabels from "../physical_assets/qr_labels"
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    physicalAssetQrLabels,
    formButton
  },

  props: {
    qrLabel: Object,
    defaultClientCompany: Object,
    defaultSite: Object,
    defaultAsset: Object,
    policyUpdate: Boolean
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data: function () {
    return {
      clientCompanies: this.clientCompanies,
      sites: this.sites,
      assets: this.assets,
      
      clientCompanyId: null,
      siteId: null,
      assetId: null,
      asset: null,

      isPrimary: true,

      isSpinnerVisible: true
    }
  },

  validations () {
    return {
      assetId: {
        required
      }
    }
  },

  computed: {
    sortOrder: function() {
      return {
        field: "NAME",
        direction: "ASC"
      }
    },

    assetHasAssignedQRLabels: function() {
      return (this.asset && this.asset.qrLabels && this.asset.qrLabels.edges.length > 0);
    },

    buttonLabel: function() {      
      return (Object.keys(this.defaultAsset).length > 0) ? "Update QR Label" : "Assign QR Label"
    }
  },

  mounted: function () {
    if (Object.keys(this.defaultClientCompany).length > 0) {
      this.clientCompanyId = this.defaultClientCompany.id

      this.$refs.clientCompanies.updateValue(this.defaultClientCompany);
    }

    if (Object.keys(this.defaultSite).length > 0) {
      this.siteId = this.defaultSite.id;

      this.$refs.sites.updateValue(this.defaultSite);
    }

    if (Object.keys(this.defaultAsset).length > 0) {
      this.assetId = this.defaultAsset.id;

      this.$refs.assets.updateValue(this.defaultAsset);
    }
    
    this.fetchClientCompanies();

    this.isSpinnerVisible = false;
  },

  methods: {
    setClientCompany: function(opt) {
      if (opt) {
        this.clientCompanyId = opt.id;

        this.fetchSites();
      }
      else {
        this.clientCompanyId = null;
        this.siteId = null;
        this.assetId = null;

        this.sites = [];
      }

      this.$refs.sites.clearSelection();
    },

    setSite: function(opt) {
      if (opt) {
        this.siteId = opt.id;

        this.fetchAssets();
      }
      else {
        this.siteId = null;
        this.assetId = null;

        this.assets = [] ;
      }

      this.$refs.assets.clearSelection();
    },

    setAsset: function(opt) {
      if (opt) {
        this.asset = opt;
        this.assetId = opt.id;
      }
      else {
        this.asset = null;
        this.assetId = null;
      }
      
      if (this.$refs.assetQrLabels) {
        this.$refs.assetQrLabels.refresh(this.assetId);
      }
    },

    fetchClientCompanies: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    fetchSites: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        filter: {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    fetchAssets: function() {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        filter: {
          siteId: this.siteId
        }
      };

      this.assets = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.assets",
          fetchParams,

          (data) => {
            let assets = data.data.physicalAssets,
                pageInfo = assets.pageInfo;

            success(assets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site assets!"
            });
          }
        )
      };

      let getAssets = (cursor) => {
        fetch(
          cursor,

          (assets, pageInfo) => {
            assets.edges.forEach((edge) => this.assets.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getAssets(pageInfo.endCursor))
            }
          }
        )
      };

      getAssets(window.btoa(0));
    },

    updateQrLabel: function() {
      this.isSpinnerVisible = true;

      app.graphql.get(
        "update.asset.assignQR",

        {
          input: {
            qrLabelId: this.qrLabel.id,
            physicalAssetId: this.assetId,
            primary: this.isPrimary
          }
        },

        (data) => {
          const response = data.data.assignQrLabel;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "QR Label cannot be assigned as primary",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title : "QR Label",
              message : "QR Label is successfuly assigned to an asset"
            });
          }

          this.isSpinnerVisible = false;

          window.setTimeout(function() {
            window.history.back();
          }, 1000);
        }
      );
    },

    submitForm: function() {
      this.v$.$touch();

      if (this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        this.updateQrLabel()
      }
    }
  }
}
</script>
