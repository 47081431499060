<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div v-if="(!isFilterInPreviewMode && asset.latestSummary.approvedDate) || (isFilterInPreviewMode && asset.latestSummary.previewDate)">
            <DamageRating
              :rating="isFilterInPreviewMode ? asset.latestSummary.remediationPreviewRating : asset.latestSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            <a
              :href="'/physical_assets/' + asset.id"
              tile="Physical Asset"
            >{{asset.name}}</a>
            <div v-if="asset.description" class="note">{{asset.description}}</div>
          </div>
        </header>
        <header v-if="(!isFilterInPreviewMode && asset.latestLeadingEdgeSummary.approvedDate) || (isFilterInPreviewMode && asset.latestLeadingEdgeSummary.previewDate)">
          <div>
            <DamageRating
              :rating="isFilterInPreviewMode ? asset.latestLeadingEdgeSummary.remediationPreviewRating : asset.latestLeadingEdgeSummary.clientRemediatedRating" 
            />
          </div>
          <div>
            Leading Edge Condition
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">GPS Location</label>
          {{asset.latitude}}, {{asset.longitude}}
        </div>
        <div v-if="asset.windZone" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Wind Zone (Vmph)</label>
          {{asset.windZone}}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Created on</label>
          {{ h$.formatDate.short(asset.createdAt) }}
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last update</label>
          {{ h$.formatDate.short(asset.updatedAt) }}
        </div>
        <div v-if="lastActivityDate" class="entity-detail form-group">
          <label class="placeholder placeholder-static">Last activity</label>
          {{ h$.formatDate.short(lastActivityDate) }}
        </div>
      </section>

      <section class="asset-entity-details">
        <h3>Components</h3>
        <related-components
          :physical-asset-id="entityId"
          :default-filter="defaultFilter"
          :policy-update="userCanUpdate"
          :track-history="true"
        />
      </section>

      <section class="asset-entity-details">
        <header>
          <div>
            <h3>QR Labels</h3>
          </div>
          <div class="actions">
            <button 
              title="Assign QR Label" 
              class="btn-expandable btn-expandable-xs btn-expandable-add"
              @click="showQRModal"
            >
              <span data-icon="qrcode" class="icon"></span><span>Assign</span>
            </button>
          </div>
        </header>
        <template v-if="asset.qrLabels.length">
          <ul class="asset-qr-labels">
            <li
              v-for="(label, index) in asset.qrLabels"
              :key="index"
              class="qr-label"
            >
              <template v-if="label.primary">
                <div>
                  <strong>{{label.labelNumber}}</strong>
                </div>
                <div>
                  <button 
                    title="Unnasign QR Label" 
                    class="btn-expandable btn-expandable-xs btn-expandable-delete"
                    @click="removeQRLabel(label.id)"
                  >
                    <span data-icon="delete" class="icon"></span>
                  </button>
                </div>
              </template>
              <template v-else>
                <div>
                  <span>{{label.labelNumber}}</span>
                </div>
                <div>
                  <button 
                    title="Set QR Label as primary" 
                    class="btn-expandable btn-expandable-xs btn-expandable-edit"
                    @click="setQRLabelAsPrimary(label.id)"
                  >
                    <span data-icon="check" class="icon"></span><span>Set as primary</span>
                  </button>
                  <button 
                    title="Unnasign QR Label" 
                    class="btn-expandable btn-expandable-xs btn-expandable-delete"
                    @click="removeQRLabel(label.id)"
                  >
                    <span data-icon="delete" class="icon"></span>
                  </button>
                </div>
              </template>
            </li>
          </ul>
        </template>
        <template v-else>
          <p>
            <small>There are no QR labels associated to this asset</small>
          </p>
        </template>  
      </section>
      
      <div class="entity-details-actions">
        <div>
          <a
            :href="'/physical_assets/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/physical_assets/' + entityId + '/edit'"
            title="Edit"
            class="btn-expandable btn-expandable-edit btn-expandable-sm"
          >
            <span class="icon" data-icon="edit"></span>
            <span>Edit</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/physical_assets/' + entityId"
            title="Remove"
            class="btn-expandable btn-expandable-delete btn-expandable-sm"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <span class="icon" data-icon="delete"></span>
            <span>Remove</span>
          </a>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import RelatedComponents from "./related_components";
import DamageRating from "../damage_rating";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating,
    RelatedComponents
  },

  data() {
    return {
      asset: {
        physicalAssetType: {},
        qrLabel: {},
        qrLabels: [],
        latestSummary: {},
        latestLeadingEdgeSummary: {},
        site: {}
      },
      progress: 0
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    entityId: function() {
      this.fetchAssetDetails();
    }
  },

  mounted: function() {
    this.fetchAssetDetails();
  },

  computed: {
    lastActivityDate: function() {
      return this.isFilterInPreviewMode ? this.asset.latestSummary.previewDate : this.asset.latestSummary.approvedDate;
    },

    defaultFilter: function() {
      return {
        historical: false,
        physicalAssetId: [this.entityId],
        status: ["approved"]
      }
    }
  },

  methods: {
    fetchAssetDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.asset",

        {
          filter: {
            id: this.entityId
          }
        },

        (data) => {
          this.isLoaded = true

          this.asset = data.data.physicalAssets.edges[0].node

          // Set QR Labels
          let assetQRLabels = [];

          if (this.asset.qrLabels.edges.length > 0) {
            assetQRLabels = this.asset.qrLabels.edges.map(function(qrLabel) {
              return qrLabel.node;
            });
          }

          this.asset.qrLabels = assetQRLabels;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Physical Asset Details",
            message : "Something went wrong! Asset cannot be found or doesn't exist!"
          });
        }
      );
    },

    removeQRLabel: function(qrLabelId) {
      let vm = this;

      app.graphql.get(
        "update.asset.unassignQR",
        {
          input: {
            qrLabelId: qrLabelId
          }
        },
        function(data) {
          if (data.data.unassignQrLabel.errors) {
            app.ui.toast.add({
              priority: "warning",
              title : "QR Label",
              message : data.data.unassignQrLabel.errors[0]
            });
          }
          else if(data.data.unassignQrLabel.qrLabel) {
            app.ui.toast.add({
              priority: "success",
              title : "QR Label",
              message : "QR Label is successfuly unassigned from an asset"
            });

            if (window.vmPhysicalAssets && vmPhysicalAssets.$refs.physicalAssets) {
              vmPhysicalAssets.$refs.physicalAssets.refresh()
            }
            if (window.vmSitePhysicalAssets && vmSitePhysicalAssets.$refs.sitePhysicalAssets) {
              vmSitePhysicalAssets.$refs.sitePhysicalAssets.refresh()
            }
            if (window.vmPhysicalAssetQrLabels && vmPhysicalAssetQrLabels.$refs.physicalAssetQrLabels) {
              vmPhysicalAssetQrLabels.$refs.physicalAssetQrLabels.refresh()
            }

            vm.fetchAssetDetails();
          }
        },
        function(error) {
          app.ui.toast.add({
            priority: "danger",
            title : "Something went wrong!",
            message : "QR Label cannot be unassigned!"
          });
        }
      );
    },

    setQRLabelAsPrimary: function(qrLabelId) {
      let vm = this;

      app.graphql.get(
        "update.asset.assignQR",
        {
          input: {
            qrLabelId: qrLabelId,
            physicalAssetId: this.asset.id,
            primary: true
          }
        },
        function(data) {
          app.ui.toast.add({
            priority: "success",
            title : "QR Label",
            message : "QR Label is successfuly assigned as primary for an asset"
          });

          if (window.vmPhysicalAssets && vmPhysicalAssets.$refs.physicalAssets) {
              vmPhysicalAssets.$refs.physicalAssets.refresh()
            }
          if (window.vmSitePhysicalAssets && vmSitePhysicalAssets.$refs.sitePhysicalAssets) {
            vmSitePhysicalAssets.$refs.sitePhysicalAssets.refresh()
          }
          if (window.vmPhysicalAssetQrLabels && vmPhysicalAssetQrLabels.$refs.physicalAssetQrLabels) {
            vmPhysicalAssetQrLabels.$refs.physicalAssetQrLabels.refresh()
          }

          vm.fetchAssetDetails();
        },
        function(data) {
          app.ui.toast.add({
            priority: "danger",
            title : "Something went wrong!",
            message : "QR Label cannot be assigned as primary"
          });
        }
      );
    },

    showQRModal: function() {
      const vm = this;

      vmModal.$refs.modal.show({
        template: 'physicalAssetQR',
        title: 'Asset QR Label',
        args: {
          asset: vm.asset,
          callback: () => {
            // Refresh assets grid
            if (window.vmPhysicalAssets && vmPhysicalAssets.$refs.physicalAssets) {
              vmPhysicalAssets.$refs.physicalAssets.refresh()
            }
            if (window.vmSitePhysicalAssets && vmSitePhysicalAssets.$refs.sitePhysicalAssets) {
              vmSitePhysicalAssets.$refs.sitePhysicalAssets.refresh()
            }

            // Refresh asset details sidebar
            const assetDetailsPane = vmDetailsPane.$refs.detailsPane.$refs.entityDetails;

            if ( assetDetailsPane && 'fetchAssetDetails' in assetDetailsPane) {
              assetDetailsPane.fetchAssetDetails()
            }
          }
        }
      })
    }
  }
}

</script>
