<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-12">
          <fieldset>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input 
                    :class="['form-control', {'error': v$.userName.$error}]"
                    placeholder="Username" 
                    type="text" 
                    name="email" 
                    id="email"
                    v-model.trim="userName"
                    @keydown.enter="submitForm"
                  />
                  <label for="email" class="placeholder">Username</label>
                  <div v-if="v$.userName.$error" class="error">Don't forget to login with your username</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input 
                    :class="['form-control', {'error': v$.userPassword.$error}]"
                    placeholder="Password" 
                    type="password" 
                    name="password" 
                    id="password"
                    autocomplete="off"
                    v-model.trim="userPassword"
                    @keydown.enter="submitForm"
                  />
                  <label for="password" class="placeholder">Password</label>
                  <div v-if="v$.userPassword.$error" class="error">Check your password</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input 
                    class="form-control"
                    placeholder="Dual Authentication Token" 
                    type="password" 
                    name="otp_code_token" 
                    id="otp_code_token"
                    autocomplete="off"
                    v-model.trim="userOtp"
                    @focus="showOtpInfo"
                    @keydown.enter="submitForm"
                  />
                  <label for="otp_code_token" class="placeholder">Dual Authentication Token</label>
                </div>
              </div>
            </div>
          </fieldset>
          <footer>
            <div class="d-grid py-3">
              <form-button
                ref="submitButton"
                class="btn-lg justify-content-center"
                label="Log in"
                @submit="submitForm"
              ></form-button>
            </div>
            <div class="pt-2 text-center">
              <a 
                href="/password_resets/new"
                @click.prevent="showForgottenPasswordModal"
              >Forgotten password?</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import formButton from "../shared/form/form_button";

export default {
  components: {
    formButton
  },

  props: {
    formId: {
      type: String
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      userName: "",
      userPassword: "",
      userOtp: "",
      isLoaded: false,
      isSpinnerVisible: true,
      isOtpInfoPresented: false,
    }
  },

  validations () {
    return {
      userName: {
        required,
        email
      },
      userPassword: {
        required
      }
    }
  },

  mounted: function() {
    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    showOtpInfo: function() {
      if (!this.isOtpInfoPresented) {
        app.ui.toast.add({
          priority: "info",
          title : "Dual Authentication Token",
          message : "This field is only required if Dual Authentication has been enabled for this account.",
          timeout: {"info": 15000}
        });
      }

      this.isOtpInfoPresented = true;
    },

    showForgottenPasswordModal: function() {
      vmModal.$refs.modal.show({
        template: 'forgotPasswordModal',
        title: 'Forgotten password?',
        style: {
          size: 'md',
          verticalyCentered: true
        }
      })
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.userName.$touch();
      this.v$.userPassword.$touch();

      if (!this.v$.$error) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}
</script>
