<template>
  <div @keydown.enter.prevent>
    <fieldset>
      <legend>Finding type</legend>
      <div class="row">
        <div class="col-sm-12 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Finding Type</label>
            <v-select
              placeholder="Select finding type"
              ref="findingTypes"
              :class="[{'vs-error': v$.findingTypeId.$error && defaultComponentTypeId}]"
              :options="findingTypes"
              :disabled="!defaultComponentTypeId"
              label="name"
              @update:modelValue="setFindingType"
            />
            <div v-if="v$.findingTypeId.$error && defaultComponentTypeId" class="error">Finding type is required</div>
            <input
              type="hidden"
              id="finding_finding_type_id"
              name="finding[finding_type_id]"
              :value="findingTypeId"
            />
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset v-show="defaultComponentTypeId && findingTypeId">
      <legend>Finding details</legend>
      <template 
        v-for="(item, index) in findingTypeDetails"
        v-bind="item"
        :key="item.name"
        :index="index"
      >
        <component
          v-bind:is="item.formControl.type"
          ref="findingDetail"
          :placeholder="item.name"
          :name="item.formControl.name"
          :id="item.formControl.id"
          :value = "item.defaultValue"
          :options="item.enum"
          :shouldBeValidated="item.shouldBeValidated"
          @change="findingTypeDetailChange"
          @selected="findingTypeDetailChange"
          @deselected="findingTypeDetailChange"
        ></component>
      </template>
    </fieldset>
  </div>
</template>

<script>
import vSelect from "vue-select";

import formSelect from "../shared/form/field_select";
import formMultiSelect from "../shared/form/field_multiselect";
import formText from "../shared/form/field_text";
import formBoolean from "../shared/form/field_boolean";
import formDate from "../shared/form/field_date";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formSelect,
    formMultiSelect,
    formText,
    formBoolean,
    formDate
  },

  props: {
    defaultFinding: Object,
    defaultFindingType: Object,
    defaultComponentTypeId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },  

  data: function () {
    return {
      findingTypes: [],
      findingTypeId: null,
      findingTypeDetails: [],
      componentTypeId: null,
      findingTypesAreValid: false
    }
  },

  validations () {
    return {
      findingTypeId: {
        required
      }
    }
  },

  computed: {
    sortOrder: function() {
      return {
        field: "NAME",
        direction: "ASC"
      }
    },

    isValid: function() {
      this.validate();

      return !this.v$.$error && this.findingTypesAreValid;
    }
  },

  watch: {
    defaultComponentTypeId: function(componentTypeId) {
      if (componentTypeId == null) {
        this.$refs.findingTypes.clearSelection();

        this.findingTypes = [];
      }
      else {
        if (this.componentTypeId != null) {
          this.$refs.findingTypes.clearSelection();
        }

        if (componentTypeId != this.componentTypeId) {
          this.componentTypeId = componentTypeId;
        }

        this.fetchFindingTypes();
      }

       this.v$.$reset();
    },

    findingTypes: function() {
      // If this is new finding and there is only one finding type it should be selected by default
      if (Object.keys(this.defaultFindingType).length == 0 && Object.keys(this.findingTypes).length == 1) {
        this.$refs.findingTypes.updateValue(this.findingTypes[0]);
      }
    }
  },

  mounted: function () {
    if (Object.keys(this.defaultFindingType).length > 0) {
      this.findingTypeId = this.defaultFindingType.id;

      this.$refs.findingTypes.updateValue(this.defaultFindingType);
    }
  },

  methods: {
    setFindingType: function(opt) {
      this.findingTypeDetails = [];
      this.findingTypeId = null;

      if (opt) {
        this.findingTypeId = opt.id;

        if (opt.details) {
          this.setFindingTypeDetails(opt.details);
        }
      }

      this.$emit("change-finding-type", opt);
    },

    fetchFindingTypes: function () {
      let fetchParams = {
        pageSize: 100,
        orderBy: this.sortOrder,
        orderBy: {
          field: "NAME",
          direction: "ASC"
        },
      };

      if (this.componentTypeId) {
        fetchParams.filter = {
          componentTypeId: this.componentTypeId,
          archived: false
        }
      };

      this.findingTypes = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.findingTypes",
          fetchParams,

          (data) => {
            let findingTypes = data.data.findingTypes,
                pageInfo = findingTypes.pageInfo;

            success(findingTypes, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Finding Types!"
            });
          }
        )
      };

      let getFindingTypes = (cursor) => {
        fetch(
          cursor,

          (findingTypes, pageInfo) => {
            findingTypes.edges.forEach((edge) => {
              if (edge.node.details) {
                edge.node.details = JSON.parse(edge.node.details)
              }

              this.findingTypes.push(edge.node)
            })

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getFindingTypes(pageInfo.endCursor));
            }
          }
        )
      };

      getFindingTypes(window.btoa(0));
    },

    setFindingTypeDetails: function(details) {
      details.forEach((findingTypeDetail) => {
        if (this.defaultFinding.findingType.id == this.findingTypeId) {
          if (Object.keys(this.defaultFinding.details).includes(findingTypeDetail.name)) {
            findingTypeDetail.defaultValue = this.defaultFinding.details[findingTypeDetail.name];
          }
        }

        findingTypeDetail.formControl = {}

        findingTypeDetail.formControl.type = 'formText'
        findingTypeDetail.formControl.name = "finding[details][" + findingTypeDetail.name + "]";
        findingTypeDetail.formControl.id = "finding_details_" + findingTypeDetail.name;

        switch(findingTypeDetail.type) {
          case "string":
            if (findingTypeDetail.format && findingTypeDetail.format == "date") {
              findingTypeDetail.formControl.type = 'formDate'
            }
            else if (findingTypeDetail.enum) {
              if (findingTypeDetail.multiple) {
                findingTypeDetail.formControl.type = 'formMultiSelect'
                findingTypeDetail.formControl.name += "[]";
                findingTypeDetail.formControl.id += "[]";
              }
              else {
                findingTypeDetail.formControl.type = 'formSelect'
              }
            }
            break;
          case "integer":
            findingTypeDetail.formControl.type = 'formText'
            break;
          case "number":
            findingTypeDetail.formControl.type = 'formText'
            break;
          case "boolean":
            findingTypeDetail.formControl.type = 'formBoolean'
            break;
        }

        // field should be validated if (new finding) || (existing finding && detail is not archived && detail has value))
        findingTypeDetail.shouldBeValidated = (this.defaultFinding.id == undefined) || (this.defaultFinding.id !== undefined && !findingTypeDetail.archived && findingTypeDetail.defaultValue !== undefined)

        // add detail if (new finding && detail is not archived) || (existing finding && (detail is not archived || detail has value))
        if ((this.defaultFinding.id == undefined && !findingTypeDetail.archived) || (this.defaultFinding.id !== undefined && (!findingTypeDetail.archived || (findingTypeDetail.defaultValue && findingTypeDetail.defaultValue !== undefined)))) {
          this.findingTypeDetails.push(findingTypeDetail);
        }
      });
    },

    findingTypeDetailChange: function(val, el) {
      if (el && el.$props && el.$props.placeholder.toLowerCase() == "description") {
        this.$emit("change-finding-description", val);
      }
    },

    validate: function() {
      this.v$.findingTypeId.$touch();

      this.findingTypesAreValid = true;

      this.$emit("validate-form");
    }
  }
}
</script>
