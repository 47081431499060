<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <remediation-findings-filter
            v-if="isLoaded"
            ref="relatedFindingsFilter"
            :remediation-id="remediationId"
            :default-remediation-type="defaultRemediationType"
            :default-client-company="defaultClientCompany"
            :default-project="defaultProject"
            :default-inspection="defaultInspection"
            :default-site="defaultSite"
            :default-physical-asset="defaultPhysicalAsset"
            :new-project-id="newProjectId"
            :new-inspection-id="newInspectionId"
            :new-finding-id="newFindingId"
            @change-findings-filter="filterFindings"
            @change-remediation-type="checkIfRemediationTypeRevision"
          ></remediation-findings-filter>
        </div>
        <div class="col-sm-4 col-xs-12">
          <fieldset>
            <legend>Remediation classification</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <remediation-classification
                  v-if="isLoaded"
                  ref="remediationClassification"
                  placeholder="Classification"
                  name="remediation[classification]"
                  id="remediation_classification"
                  :default-classification="defaultClassification"
                  @change="checkIfClassificationRevision"
                ></remediation-classification>
              </div>
            </div>
            <div @keydown.enter.prevent>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-static">Remediation Status</label>
                    <v-select
                      placeholder="Select remediation status"
                      ref="remediationStatus"
                      :class="[{'vs-error': v$.remediationStatus.$error}]"
                      :options="remediationStatuses"
                      label="key"
                      @update:modelValue="setRemediationStatus"
                    />
                    <div v-if="v$.remediationStatus.$error" class="error">Remediation status is required</div>
                    <input
                      type="hidden"
                      id="remediation_status"
                      name="remediation[status]"
                      :value="currentRemediationStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="remediation_notes">Remediation Notes</label>
                  <textarea rows="4" class="form-control" placeholder="Remediation notes" name="remediation[notes]" id="remediation_notes" v-model="remediation.notes"></textarea>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Remediated by</legend>
            <remediators
              ref="remediators"
              :default-remediators="defaultRemediators"
            ></remediators>
          </fieldset>

          <fieldset v-if="isRevision">
            <legend>Revision info</legend>
            <div @keydown.enter.prevent>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-static">Revision Category</label>
                    <v-select
                      placeholder="Select revision category"
                      ref="revisionCategory"
                      :class="[{'vs-error': v$.revisionCategory.$error}]"
                      :options="revisionCategories"
                      @update:modelValue="setRevisionCategory"
                    />
                    <div v-if="v$.revisionCategory.$error" id="remediation_revision_attributes_category-error" class="error">This field is required.</div>
                    <input
                      type="hidden"
                      id="remediation_revision_attributes_category"
                      name="remediation[revision_attributes][category]"
                      :value="revisionCategory"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="remediation_revision_attributes_description">Revision description</label>
                  <textarea rows="4" :class="['form-control', {'error': v$.revisionDescription.$error}]" placeholder="Revision description" name="remediation[revision_attributes][description]" id="remediation_revision_attributes_description" v-model.trim="v$.revisionDescription.$model"></textarea>
                  <div v-if="v$.revisionDescription.$error" id="remediation_revision_attributes_description-error" class="error">This field is required.</div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-4 col-xs-12">
          <div @keydown.enter.prevent>
            <fieldset>
              <legend>Related Findings</legend>

              <remeditation-findings
                v-bind="{ 
                  'findingsFilter': relatedFindingsFilter, 
                  'remediationId' : remediationId
                }"
                ref="remediationFindings"
                :show-finding-name-column="true"
                :show-all-findings="true"
                :default-findings="defaultRelatedFindings"
                :new-finding-id="newFindingId"
              ></remeditation-findings>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import remediationClassification from "./form_classifications";
import remediationFindingsFilter from "./form_findings_filter";
import remediators from "./form_remediators";
import remeditationFindings from "./form_findings"
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  components: {
    vSelect,
    remediationClassification,
    remediationFindingsFilter,
    remediators,
    remeditationFindings,
    formButton
  },

  props: {
    formId: String,
    remediationId: String,
    defaultClassification: String,
    defaultStatus: String,
    defaultClientCompany: Object,
    defaultProject: Object,
    defaultInspection: Object,
    defaultSite: Object,
    defaultPhysicalAsset: Object,
    defaultRemediationType: Object,
    defaultRemediationNotes: String,
    defaultRemediators: Array,
    defaultRelatedFindings: Array,
    newProjectId: String,
    newInspectionId: String,
    newFindingId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      remediation: {
        remediationType: {},
        rating: "",
        inspection: {
          project: {}
        },
        component: {
          componentType: {},
          physicalAsset: {
            site: {
              clientCompany: {}
            },
          }
        },
        details: {},
        notes: ""
      },
      isLoaded: false,
      isSpinnerVisible: true,
      isRevision: false,
      isClassificationRevision: false,
      isRemediationTypeRevision: false,
      relatedFindingsFilter: null,
      siteId: null,
      remediationStatuses: [
        {key: "Draft", value: "draft"},
        {key: "Review", value: "review"},
        {key: "Approved", value: "approved"}
      ],
      remediationStatus: "",
      revisionCategories: [
        "Entry error",
        "Photos do not match report",
        "Rating criteria modified",
        "2nd review modification",
        "Final review modification",
      ],
      revisionCategory: "",
      revisionDescription: ""
    }
  },

  validations () {
    return {
      revisionCategory: {
        conditionalyRequired: isRequired(this.isRevision)
      },
      revisionDescription: {
        conditionalyRequired: isRequired(this.isRevision)
      },
      remediationStatus: {
        required
      }
    }
  },

  mounted: function() {
    if (this.defaultStatus != "") {
      let vm = this;

      let currentStatus = vm.remediationStatuses.find(status => {
        return status.value === vm.defaultStatus;
      });

      this.$refs.remediationStatus.updateValue(currentStatus.key);
    }

    if (this.remediationId != "") {
      this.fetchRemediation();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  computed: {
    currentRemediationStatus: function () {
      return this.remediationStatus.toLowerCase();
    },

    submitButtonLabel: function() {
      return ((this.remediationId != "")) ? "Update Remediation" : "Create Remediation";
    }
  },

  methods: {
    checkIfClassificationRevision: function(selectedClassification) {
      this.isClassificationRevision = this.defaultClassification != "" && this.defaultClassification != selectedClassification;

      this.checkIfRevision();
    },

    checkIfRemediationTypeRevision: function(selectedRemediationType) {
      if (selectedRemediationType) {
        this.isRemediationTypeRevision = (this.defaultRemediationType.id != "" && this.defaultRemediationType.id != undefined) && this.defaultRemediationType.id != selectedRemediationType.id;

        this.checkIfRevision();
      }
    },

    checkIfRevision: function() {
      this.isRevision = this.isClassificationRevision || this.isRemediationTypeRevision;
    },

    setRevisionCategory: function(opt) {
      this.revisionCategory = (opt) ? opt : "";
    },

    setRemediationStatus: function(opt) {
      this.remediationStatus = (opt) ? ((opt.key) ? opt.value : opt) : "";
    },

    fetchRemediation: function() {
      app.graphql.get("details.remediation",
        {
          filter: {
            id: [this.remediationId],
            status: ["review", "draft", "approved"]
          }
        },
        (data) => {
          let currentRemediation = data.data.remediations.edges[0].node;

          this.remediation = {
            remediationType: currentRemediation.remediationType,
            classification: currentRemediation.rating.toString(),
            inspection: currentRemediation.inspection,
            notes: currentRemediation.notes
          }

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch remediation!"
          });
        }
      );
    },

    filterFindings: function(filter) {
      this.relatedFindingsFilter = filter;

      if (this.remediationId) {
        this.relatedFindingsFilter.remediationId = this.remediationId;
      }
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.$touch();

      if (form && this.v$.$errors.length <= 0) {  
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>
