<template>
  <div 
    :class="['stats-chart', css]"
  >
    <div 
      v-if="title"
      class="chart-header"
    >
      <h3 class="chart-title">{{ title }}: <strong>{{ total }}</strong></h3>
      <div class="chart-legend"></div>
    </div>
    <div class="chart-content">
      
      <div 
        class="bar-chart"
      >
        <div
          v-for="(stat, index) in stats"
          :key="index"
          class="bar-chart-row"
          :ref="'bar-' + index"
        >
          <div class="bar-description">
            {{ stat.description }}
          </div>
          <div class="bar-progress">
            <div
              class="bar"
              :style="[{'--bar-width': stat.width + '%'}, {'min-width': this.barsMinWidth}]"
            ></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: Array,
    barsMinWidth: String,
    css: {
      type: String,
      default: ''
    },
    relativeToHighestValue: {
      type: Boolean,
      default: true
    },
    showTooltips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stats: [],
      total: 0,
      highestValue: 0,
      tooltip: null
    }
  },

  watch: {
    data: {
      handler(newValue, oldValue) {        
        this.setData()
      }, 
      immediate: true
    }
  },

  mounted: function() {
    if (this.showTooltips) {
      const vm = this;
      
      this.stats.forEach((stat, index) => {
        const bar = this.$refs['bar-' + index];

        const t = new bootstrap.Tooltip(bar[0], {
          title: '<strong>' + stat.count + '</strong> (' + stat.percentage + '%)',
          html: true,
          placement: 'top',
          trigger : 'hover'
        })
      })
    }
  },

  methods: {
    setData: function() {
      this.data.forEach((r) => {
        this.total += r[1]

        this.highestValue = r[1] > this.highestValue ? r[1] : this.highestValue;
      })

      this.stats = this.data.map((s) => {
        return {
          description: s[0], 
          count: s[1],
          percentage: parseFloat((s[1] / this.total) * 100).toFixed(2),
          width: Math.round(s[1] / (this.relativeToHighestValue ? this.highestValue : this.total) * 100)
        }
      })
    }
  }
}

</script>