<template>
  <div class="pagination-counter">
    Showing <span>{{ currentCount }}</span> of <span>{{ totalCount }}</span> {{ entityType }}
  </div>
</template>

<script>
export default {
  props: {
    entityType: {
      type: String,
      required: true
    },

    currentPage: {
      type: Number,
      required: true
    },

    pageSize: {
      type: Number,
      required: true
    },

    totalCount: {
      type: Number,
      required: true
    }
  },

  computed: {
    currentCount: function() {
      let countFrom = (this.currentPage * this.pageSize) - this.pageSize + 1;
      let countTo = this.currentPage * this.pageSize;

      countTo = (countTo > this.totalCount) ? this.totalCount : countTo;

      return countFrom + "-" + countTo;
    }
  },
}
</script>
