<template>
  <nav
    v-if="totalCount > 0"
    class="pagination-wrapper"
    aria-label="Table navigation"
  >
    <page-size-picker
      v-if="totalCount > 20"
      :entity-type="entityType"
      :total-count="totalCount"
      :page-size="pageSize"
      @navigate-to="navigateTo"
    />
    
    <ul 
      v-if="totalPageCount > 1"
      class="pagination pagination-sm"
    >
      <Previous
        v-if="totalPageCount > 1"
        :current-page="currentPage"
        @navigate-to="navigateTo"
      />

      <PreviousBlock
        v-if="showPages < totalPageCount"
        :current-page="currentPage"
        :show-pages="showPages"
        @navigate-to="navigateTo"
      />

      <Page
        v-for="index in Math.min(showPages, totalPageCount)"
        :key="index"
        :current-page="currentPage"
        :index="index - 1"
        :show-pages="showPages"
        :total-page-count="totalPageCount"
        @navigate-to="navigateTo"
      />

      <NextBlock
        v-if="showPages < totalPageCount"
        :current-page="currentPage"
        :show-pages="showPages"
        :total-page-count="totalPageCount"
        @navigate-to="navigateTo"
      />

      <Next
        v-if="totalPageCount > 1"
        :current-page="currentPage"
        :total-page-count="totalPageCount"
        @navigate-to="navigateTo"
      />
    </ul>
    
    <items-counter
      :entity-type="entityType"
      :current-page="currentPage"
      :page-size="pageSize"
      :total-count="totalCount"
      @navigate-to="navigateTo"
    />
  </nav>
</template>

<script>
import Previous from "./pagination/previous"
import PreviousBlock from "./pagination/previous_block"
import Page from "./pagination/page"
import NextBlock from "./pagination/next_block"
import Next from "./pagination/next"
import PageSizePicker from "./pagination/page_size"
import ItemsCounter from "./pagination/items_counter"

export default {
  components: {
    Previous,
    PreviousBlock,
    Page,
    NextBlock,
    Next,
    PageSizePicker,
    ItemsCounter
  },

  props: {
    entityType: {
      type: String,
      default: "entries"
    },

    pageSize: {
      type: Number,
      default: 20
    },

    showPages: {
      type: Number,
      default: 9
    },

    totalCount: {
      type: Number,
      default: 0
    },

    totalPageCount: {
      type: Number,
      default: 0
    },

    currentPage: {
      type: Number,
      default: 1
    },

    updateUiState: {
        type: Boolean,
        default: true
      }
  },

  methods: {
    navigateTo: function (newPage, pageSize) {
      let grid = (this.$.parent && this.$.parent.data.hasOwnProperty("grid")) ? this.$.parent.data.grid : "default";

      this.$emit("navigate-to", newPage, pageSize);

      app.ui.state.update({
        "v-grid": grid,
        "v-pagesize": pageSize,
        "v-page": newPage
      });
    }
  }
}
</script>
