<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <template v-if="currentPhysicalAsset">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Site</label>
              <input 
                placeholder="Site"
                class="form-control"
                type="text"
                readonly
                :value="siteName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label class="placeholder placeholder-inactive">Physical Asset</label>
              <input 
                placeholder="Physical Asset"
                class="form-control"
                type="text"
                readonly
                :value="physicalAssetName"
              />
            </div>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="file" class="placeholder placeholder-inactive">Component file</label>
            <form-field-file
              id="file"
              name="file"
              placeholder="Select Component import file"
              :class="[{'file-error': v$.choosenFile.$error}]"
              accept=".csv"
              @change-file="setFile"
            ></form-field-file>
            <div v-if="v$.choosenFile.$error" class="error">Component import file is required</div>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          label="Import Component Assets"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";
import formFieldFile from "../shared/form/field_file";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formFieldFile,
    formButton,
    vSelect,
  },

  props: {
    formId: String,
    physicalAssetId: String
  },
  
  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      choosenFile: "",
      currentPhysicalAsset: null,
      siteName: "",
      physicalAssetName: ""
    }
  },

  validations () {
    return {
      choosenFile: {
        required
      }
    }
  },

   mounted: function() {
    if (this.physicalAssetId) {
      this.fetchPhysicalAsset(); 
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  methods: {
    fetchPhysicalAsset: function(callback) {
      app.graphql.get("details.asset",
        {
          filter: {
            id: [this.physicalAssetId]
          }
        },
        (data) => {
          let currentPhysicalAsset = data.data.physicalAssets.edges[0].node;

          this.currentPhysicalAsset = currentPhysicalAsset;
          this.physicalAssetName = currentPhysicalAsset.name;
          this.siteName = currentPhysicalAsset.site.name;

          this.isLoaded = true;
          this.isSpinnerVisible = false;
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Physical Asset!"
          });
        }
      );
    },

    setFile: function(file) {
      this.choosenFile = (file && file.name) ? file.name : "";
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.choosenFile.$touch();

      if (form && this.v$.$errors <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>