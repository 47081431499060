<template>
<div class="form-group">
  <label
    class="placeholder placeholder-inactive"
  >
    {{placeholder}}
  </label>

  <div class="radio-group ratings-group">
    <div 
      v-for="(classification, index) in classifications" 
      v-bind:key="classification.value"
      class="rating-group"
    >
      <input 
        type="radio"
        :value="classification.value" 
        name="rb-rating" 
        :id="'rb-rating-' + index"
        :checked="classification.value == defaultRating"
        @change="onChange(classification.value)"
        v-model="selectedRating"
      >
      <label 
        :for="'rb-rating-' + index" 
        :class="'radio rating-' + index"
      >
        {{classification.label}}
      </label>
    </div>
  </div>

  <div v-if="v$.selectedRating.$error" class="error">Finding rating is required</div>

  <input
    class="form-control"
    :name="name"
    :id="id"
    :value="selectedRating"
    type="hidden"
  />
</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  props: {
    defaultRating: String,
    id: String,
    name: String,
    value: String,
    type: {
      type: String,
      default: "radio"
    },
    placeholder: {
      type: String,
      default: "Rating"
    }
  },

  validations () {
    return {
      selectedRating: {
        required
      }
    }
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      selectedRating: 3,
      classifications: [
        { label: '-', value: "0" },
        { label: 'A', value: "1" },
        { label: 'B', value: "2" },
        { label: 'C', value: "3" },
        { label: 'D', value: "4" },
        { label: 'F', value: "5" }
      ]
    }
  },

  computed: {
    isValid: function() {
      this.validate();

      return !this.v$.selectedRating.$error;
    }
  },

  mounted: function() {
    this.selectedRating = this.defaultRating;
  },

  methods: {
    onChange: function(val) {
      this.$emit("change", this.selectedRating);
    },

    validate: function() {
      this.v$.selectedRating.$touch();
    }
  }
};
</script>
