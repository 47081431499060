<template>
  <div 
    :class="['rating-mark', ratingClass(currentRating), revisionRatingClass(currentRating)]"
    @mouseover.stop="tooltipShow"
    @mouseleave.stop="tooltipHide"
  >
    {{ h$.damageMarks[currentRating] }}
    
    <span v-if="Number.isInteger(exoInspectionOverrideRating)" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-1', ratingClass(exoInspectionOverrideRating)]"></span>
    <template v-if="showInPreviewMode">
      <span v-if="Number.isInteger(exoInspectionRating) && (exoInspectionOverrideRating || remediationOverrideRating || remediatedPreviewRating)" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-0', ratingClass(exoInspectionRating)]"></span>
      <span v-if="Number.isInteger(remediatedPreviewRating)" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-2', ratingClass(remediatedPreviewRating)]"></span>
    </template>
    <template v-else>
      <span v-if="Number.isInteger(exoInspectionRating) && (Number.isInteger(exoInspectionOverrideRating) || Number.isInteger(remediationOverrideRating) || Number.isInteger(remediatedRating))" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-0', ratingClass(exoInspectionRating)]"></span>
      <span v-if="Number.isInteger(remediatedRating)" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-2', ratingClass(remediatedRating)]"></span>
    </template>
    <span v-if="isExoUser && Number.isInteger(remediationOverrideRating)" @mouseover.stop="" :class="['rating-mark-sub', 'rating-mark-sub-3', ratingClass(remediationOverrideRating)]"></span>
  </div>
</template>

<script>
import helpers from '../../helpers.js'

export default {
  props: {
    isExoUser: Boolean,
    hasRevision: Boolean,
    rating: Number,
    inspectionRating: Number, // Exo inspection rating
    inspectionOverride: Object, // Inspection Override
    remediation: Object, // Remediated rating
    remediationOverride: Object, // Remediation Override - Not implemented yet
    clientRemediatedRating: Number, // Current rating
    remediationPreviewRating: Number, // Current rating in a "preview" filter mode
    showInPreviewMode: Boolean
  },

  data: function () {
    return {
      exoInspectionRating: null,
      exoInspectionOverrideRating: null,
      remediatedRating: null,
      remediatedPreviewRating: null,
      remediationOverrideRating: null,
      badge: {}
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  mounted: function() {
    this.exoInspectionRating = this.inspectionRating;
    this.exoInspectionOverrideRating = (this.inspectionOverride && 'rating' in this.inspectionOverride) ? this.inspectionOverride.rating : null;
    this.remediatedRating = (this.remediation && this.remediation.status == 'approved' && 'rating' in this.remediation) ? this.remediation.rating : null;
    this.remediatedPreviewRating = (this.remediation && 'rating' in this.remediation) ? this.remediation.rating : null;
    this.remediationOverrideRating = (this.remediationOverride && 'rating' in this.remediationOverride) ? this.remediationOverride.rating : null;
  },

  computed: {
    currentRating: function() {
      let rating = null;

      if (Number.isInteger(this.clientRemediatedRating) || Number.isInteger(this.remediationPreviewRating)) {
        rating = this.showInPreviewMode ? this.remediationPreviewRating : this.clientRemediatedRating;
      }
      
      if (Number.isInteger(this.rating)) rating = this.rating;

      return rating;
    }
  },

  methods: {
    ratingClass: function(rating) {
      return "rating-" + rating;
    },

    revisionRatingClass: function(rating) {
      return this.isExoUser && this.hasRevision ? "revision-rating-0" : "";
    },

    tooltipShow: function($event) {
      let mark = $($event.target);

      let content = '';

      const vm = this;

      if (this.exoInspectionRating && (Number.isInteger(this.exoInspectionOverrideRating) || Number.isInteger(this.remediationOverrideRating) || (!this.showInPreviewMode && Number.isInteger(this.remediatedRating)) || (this.showInPreviewMode && Number.isInteger(this.remediatedPreviewRating)))) {
        content += '<ul class="ratings-list">'
        
        if(this.showInPreviewMode) {
          content += (Number.isInteger(this.exoInspectionRating) && (Number.isInteger(this.exoInspectionOverrideRating) || Number.isInteger(this.remediationOverrideRating) || Number.isInteger(this.remediatedPreviewRating))) ? '<li class="' + this.ratingClass(this.exoInspectionRating) + '">Exo Inspection</li>' : '';
        }
        else {
          content += (Number.isInteger(this.exoInspectionRating) && (Number.isInteger(this.exoInspectionOverrideRating) || Number.isInteger(this.remediationOverrideRating) || Number.isInteger(this.remediatedRating))) ? '<li class="' + this.ratingClass(this.exoInspectionRating) + '">Exo Inspection</li>' : '';
        }
        
        content += Number.isInteger(this.exoInspectionOverrideRating) ? '<li class="' + this.ratingClass(this.exoInspectionOverrideRating) + '">Inspection Override</li>' : '';
        
        if(this.showInPreviewMode) {
          content += this.remediatedPreviewRating ? '<li class="' + this.ratingClass(this.remediatedPreviewRating) + '">Remediation</li>' : '';
        }
        else {
          content += this.remediatedRating ? '<li class="' + this.ratingClass(this.remediatedRating) + '">Remediation</li>' : '';
        }
        
        content += this.isExoUser && Number.isInteger(this.remediationOverrideRating) ? '<li class="' + this.ratingClass(this.remediationOverrideRating) + '">Remediation Override</li>' : '';
        
        content += '</ul>'

        this.badge.tooltip = new bootstrap.Tooltip(this.$el, {
          title: content,
          html: true,
          placement: 'top',
          container: 'body'
        })

        this.badge.tooltip.show()
      }
    },

    tooltipHide: function($event) {
      if (this.badge.tooltip) this.badge.tooltip.hide()
    }
  }
}
</script>