<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 200px;">
    <div v-bind:class="{'hidden': !isLoaded }">

      <div class="row">
        <div class="col-sm-3 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Client Company</label>
            <v-select
              placeholder="Select client company"
              ref="clientCompanies"
              :options="clientCompanies"
              label="name"
              @update:modelValue="setClientCompany"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label class="placeholder placeholder-inactive">Site</label>
            <v-select
              placeholder="Select Site"
              ref="sites"
              :class="[{'vs-error': v$.siteId.$error}]"
              :options="sites"
              label="name"
              @update:modelValue="setSite"
            />
            <input 
              type="hidden" 
              id="site_id" 
              name="site_id"
              :value="siteId"
            />
            <div v-if="v$.siteId.$error" class="error">Site is required</div>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <div class="form-group">
            <label for="file" class="placeholder placeholder-inactive">Site file</label>
            <form-field-file
              id="file"
              name="file"
              placeholder="Select Site import file"
              :class="[{'file-error': v$.choosenFile.$error}]"
              accept=".csv"
              @change-file="setFile"
            ></form-field-file>
            <div v-if="v$.choosenFile.$error" class="error">Site import file is required</div>
          </div>
        </div>
      </div>
            
      <div class="form-group">
        <form-button
          ref="submitButton"
          label="Import Site Assets"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formFieldFile from "../shared/form/field_file";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
  components: {
    formFieldFile,
    formButton,
    vSelect,
  },

  props: {
    formId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      isLoaded: false,
      isSpinnerVisible: true,
      choosenFile: "",
      siteClientCompany: null,
      siteClientCompanyId: "",
      clientCompanies: this.clientCompanies,
      site: null,
      siteId: "",
      sites: this.sites
    }
  },

  validations () {
    return {
      siteId: {
        required
      },
      choosenFile: {
        required
      }
    }
  },

  mounted: function() {
    this.fetchClientCompanies();

    this.isLoaded = true;

    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  },

  methods: {
    setClientCompany: function(opt) {
      if (opt) {
        this.siteClientCompany = (opt) ? opt : null;
        this.siteClientCompanyId = (opt) ? opt.id : "";

        this.fetchSites();
      }
      else {
        this.site = null;
        this.siteId = "";

        this.sites = [] ;
      }

      this.$refs.sites.clearSelection();

      this.v$.$reset();
    },

    fetchClientCompanies: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC"
        }
      };

      this.clientCompanies = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.clientCompanies",
          fetchParams,

          (data) => {
            let clientCompanies = data.data.clientCompanies,
                pageInfo = clientCompanies.pageInfo;

            success(clientCompanies, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Client Companies!"
            });
          }
        )
      };

      let getClientCompanies = (cursor) => {
        fetch(
          cursor,

          (clientCompanies, pageInfo) => {
            clientCompanies.edges.forEach((edge) => this.clientCompanies.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getClientCompanies(pageInfo.endCursor))
            }
          }
        )
      };

      getClientCompanies(window.btoa(0));
    },

    setSite: function(opt) {
      this.site = (opt) ? opt : null;
      this.siteId = (opt) ? opt.id : "";
    },

    fetchSites: function() {
      let fetchParams = {
        orderBy: {
          field: "NAME",
          direction: "ASC"
        },
        filter: {
          clientCompanyId: this.siteClientCompanyId
        }
      };
      
      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    setFile: function(file) {
      this.choosenFile = (file && file.name) ? file.name : "";
    },

    submitForm: function() {
      const form = document.forms[this.formId];

      this.v$.siteId.$touch();
      this.v$.choosenFile.$touch();

      if (form && this.v$.$errors.length <= 0) {
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>