<template>
<div style="display: flex">
  <div class="nav-help-content">
    <div 
      :class="['nav-help-topics', {'spinner-is-visible': isHelpTopicsSpinnerVisible}]"
    >
      <div class="spinner"></div>
      <div
        v-for="(domain, index) in qna"
        :key="index"
      >
        <h2>{{ domain.domain }}</h2>
        <ul class="nav-menu">
          <li
            v-for="(pair, index) in domain.pairs"
            :key="index"
          >
            <a href="#" @click="getAnswer(pair)" v-html="pair.question"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="nav-help-results" id="nav-help-results">
    <div class="help-results-content">
      <h2>Help Topics</h2>
      <div 
        :class="['help-results', {'spinner-is-visible': isHelpAnswerSpinnerVisible}]"
      >
        <div class="spinner"></div>
        <article 
          v-if="currentQna.answer"
        >
          <h3 v-html="currentQna.question"></h3>
          <section>
            <p v-html="currentQna.answer"></p>
          </section>
        </article>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';


export default {
  components: {
    
  },

  props: {
    url: String
  },

  data() {
    return {
      qna: [],
      currentQna: {},

      isHelpTopicsSpinnerVisible: true,
      isHelpAnswerSpinnerVisible: false
    }
  },

  methods: {
    init() {
      this.getQnA();
    },

    getQnA: function() {
      const vm = this;

      app.remote.call({
        
        url: this.url,
        
        success: function(data) {
          setTimeout(() => {
            vm.qna = data

            vm.isHelpTopicsSpinnerVisible = false
          }, 500)
        }
      })
    },

    getAnswer: function(pair) {
      document.body.classList.add('sidebar-expander-is-active')

      this.isHelpAnswerSpinnerVisible = true

      setTimeout(() => {
        this.currentQna = pair

        this.isHelpAnswerSpinnerVisible = false
      }, 500)
    },
  }
}

</script>
