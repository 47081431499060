<template>
  <tr>
    <template v-if="physicalAsset">
      <td>
        <a 
          :href="assignLabelUrl" 
          title="Details"
        >{{labelNumber}}</a>
      </td>
      <td
        v-if="showBatchColumn"
      >
        <template v-if="batch">
          <a 
            :href="batchUrl" 
            title="Details"
          >{{batchNumber}}</a>
        </template>
        <template v-else>
          {{batchNumber}}
        </template>
      </td>
      <td>
        <a 
          :href="clientCompanyUrl" 
          title="Details"
        >{{physicalAsset.site.clientCompany.name}}</a>
      </td>
      <td>
        <a
          :href="siteUrl"
          title="Details"
        >{{physicalAsset.site.name}}</a>
      </td>
      <td>
        <a 
          :href="physicalAssetUrl" 
          title="Details"
        >{{physicalAsset.name}}</a>
      </td>
    </template>
    <template v-else>
      <td>
        {{labelNumber}}
      </td>
      <td
        v-if="showBatchColumn"
      >
        <template v-if="batch">
          <a 
            :href="batchUrl" 
            title="Details"
          >{{batchNumber}}</a>
        </template>
        <template v-else>
          {{batchNumber}}
        </template>
      </td>
      <td></td>
      <td></td>
      <td>
        <CellAction
          action="edit"
          text="Assign"
          title="Assign to Asset"
          icon="memory"
          :href="assignLabelUrl"
        />
      </td>
    </template>
    
    <td class="table-row-actions">
      <RowAction
        :href="assignLabelUrl"
        action="update"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";

export default {
  extends: Row,
  
  components: {
    CellAction,
    RowAction
  },

  props: {
    id: String,
    labelNumber: Number,
    batch: Object,
    physicalAsset: Object,
    showBatchColumn: Boolean,
  },

  computed: {
    batchUrl: function() {
      return "/qr_label_batches/" + this.batch.id;
    },

    clientCompanyUrl: function() {
      return "/client_companies/" + this.physicalAsset.site.clientCompany.id;
    },

    siteUrl: function() {
      return "/sites/" + this.physicalAsset.site.id;
    },
    
    physicalAssetUrl: function() {
      return "/physical_assets/" + this.physicalAsset.id;
    },

    assignLabelUrl: function() {
      return "/qr_labels/" + this.id + "/edit";
    },

    batchNumber: function() {
      return (this.batch) ? this.batch.batchNumber : "-";
    }
  },

  methods: {
    
  }
}
</script>