<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <fieldset>
            <legend>Asset</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="physical_asset_name" class="placeholder placeholder-static">Asset name</label>
                  <input 
                    :class="['form-control', {'error': v$.physicalAssetName.$error}]"
                    placeholder="Name" 
                    type="text" 
                    name="physical_asset[name]" 
                    id="physical_asset_name"
                    v-model.trim="v$.physicalAssetName.$model"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.physicalAssetName.$error" class="error">Asset name is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-6">
                <div class="form-group">
                  <label for="physical_asset_latitude" class="placeholder placeholder-static">Location latitude</label>
                  <input 
                    :class="['form-control', {'error': v$.physicalAssetLatitude.$error}]"
                    placeholder="Latitude" 
                    type="text" 
                    name="physical_asset[latitude]" 
                    id="physical_asset_latitude"
                    v-model.trim="v$.physicalAssetLatitude.$model"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.physicalAssetLatitude.$error" class="error">Latitude is required</div>
                </div>
              </div>
              <div class="col-sm-6 col-xs-6">
                <div class="form-group">
                  <label for="physical_asset_longitude" class="placeholder placeholder-static">Location longitude</label>
                  <input 
                    :class="['form-control', {'error': v$.physicalAssetLongitude.$error}]"
                    placeholder="Longitude" 
                    type="text" 
                    name="physical_asset[longitude]" 
                    id="physical_asset_longitude"
                    v-model.trim="v$.physicalAssetLongitude.$model"
                    @keydown.enter.prevent="submitForm"
                  />
                  <div v-if="v$.physicalAssetLongitude.$error" class="error">Longitude is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Site</label>
                  <v-select
                    placeholder="Select Site"
                    ref="sites"
                    :class="[{'vs-error': v$.siteId.$error}]"
                    :options="sites"
                    label="name"
                    @update:modelValue="setSite"
                  />
                  <input 
                    type="hidden" 
                    id="physical_asset_site_id" 
                    name="physical_asset[site_id]"
                    :value="siteId"
                  />
                  <div v-if="v$.siteId.$error" class="error">Site is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="physical_asset_description">Asset description</label>
                  <textarea 
                    rows="4" 
                    class="form-control" 
                    placeholder="Description" 
                    name="physical_asset[description]" 
                    id="physical_asset_description"
                    v-model.trim="physicalAssetDescription"
                  ></textarea>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-6 col-xs-12">
          <form-physical-asset-types
            v-if="isLoaded"
            ref="physicalAssetTypeDetails"
            :physical-asset="physicalAsset"
          ></form-physical-asset-types>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import formButton from "../shared/form/form_button";
import formPhysicalAssetTypes from "./form_physical_asset_types";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formButton,
    formPhysicalAssetTypes
  },

  props: {
    formId: String,
    physicalAssetId: String,
    defaultSiteId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      physicalAsset: null,
      physicalAssetName: "",
      physicalAssetLatitude: "",
      physicalAssetLongitude: "",
      physicalAssetDescription: "",
      sites: this.sites,
      site: null,
      siteId: "",
      physicalAssetTypes: this.physicalAssetTypes,
      physicalAssetType: null,
      physicalAssetTypeId: "",
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  validations () {
    return {
      physicalAssetName: {
        required
      },
      physicalAssetLatitude: {
        required
      },
      physicalAssetLongitude: {
        required
      },
      siteId: {
        required
      }
    }
  },

  created: function() {
    
  },

  mounted: function() {
    this.fetchSites();

    if (this.defaultSiteId != "") {
      this.getSingleSite();
    }

    if (this.physicalAssetId) {
      this.fetchPhysicalAsset();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  computed: {
    submitButtonLabel: function() {
      return ((this.physicalAssetId != "")) ? "Update Asset" : "Create Asset";
    }
  },

  methods: {
    fetchPhysicalAsset: function(callback) {
      app.graphql.get("details.asset",
        {
          filter: {
            id: [this.physicalAssetId]
          }
        },
        (data) => {
          let currentphysicalAsset = data.data.physicalAssets.edges[0].node;

          this.physicalAsset = currentphysicalAsset;
          this.physicalAssetName = currentphysicalAsset.name;
          this.physicalAssetDescription = currentphysicalAsset.description;
          this.physicalAssetLongitude = currentphysicalAsset.longitude;
          this.physicalAssetLatitude = currentphysicalAsset.latitude;

          this.physicalAsset.details = JSON.parse(this.physicalAsset.details);

          this.site = currentphysicalAsset.site;

          this.$refs.sites.updateValue(this.site);

          this.isLoaded = true;

          if (callback) {
            callback();
          }

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch Physical Asset type!"
          });
        }
      );
    },

    setSite: function(opt) {
      this.site = (opt) ? opt : null;
      this.siteId  = (opt) ? opt.id : "";
      
      this.v$.$reset();
    },

    getSingleSite: function () {
      app.graphql.get("select.sites",
        {
          filter: {
            id: this.defaultSiteId
          }
        },

        (data) => {
          if (data.data.sites.totalCount > 0) {
            let project = data.data.sites.edges[0].node;
            
            this.projectId = project.id;
            this.$refs.sites.updateValue(project);
          }
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch site!"
          });
        }
      )
    },

    fetchSites: function () {
      let fetchParams = {
         orderBy: {
          field: "NAME",
          direction: "ASC",
        },
        filter: {
          projectId: this.projectId
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch Sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor))
            }
          }
        )
      };

      getSites(window.btoa(0));
    },

    submitForm: function() {
      const form = document.forms[this.formId];
      
      this.v$.physicalAssetName.$touch();
      this.v$.physicalAssetLatitude.$touch();
      this.v$.physicalAssetLongitude.$touch();
      this.v$.siteId.$touch();

      let dataTypesAreValid = this.$refs.physicalAssetTypeDetails.isValid;

      if (!this.v$.$error && dataTypesAreValid) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}

</script>