<template>
  <div class="table-wrapper">
    <div
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >

      <table class="table findings-overrides-list">
        <thead>
          <tr>
            <SortableHeader
              class="text-center"
              style="width: 60px;"
              :current-sort="orderBy"
              :sort-by="'RATING'"
              @sort="reorder"
            >
              Rating
            </SortableHeader>

            <SortableHeader
              class="text-center"
              :current-sort="orderBy"
              :sort-by="'EXO_RATING'"
              @sort="reorder"
            >
              Original rating
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'FINDING_DISPLAY_NAME'"
              @sort="reorder"
            >
              Finding
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'SITE_NAME'"
              @sort="reorder"
            >
              Site
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'PHYSICAL_ASSET_NAME'"
              @sort="reorder"
            >
              Asset
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'COMPONENT_NAME'"
              @sort="reorder"
            >
              Component
            </SortableHeader>

            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'REVISED_BY'"
              @sort="reorder"
            >
              Overriden By
            </SortableHeader>

            <SortableHeader
              class="text-right"
              style="width: 120px"
              :current-sort="orderBy"
              :sort-by="'CREATED_AT'"
              @sort="reorder"
            >Date</SortableHeader>

            <th 
              class="text-center"
              style="width: 100px"
            >Images</th>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <Override
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :index="index"
            :policy-update="policyUpdate"
            :project-id="projectId"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords
        label="There is no related overrides"
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="overrides"
        @navigate-to="paginate"
      />

    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import Override from "./override"

export default {
  extends: Grid,

  components: {
    Override
  },

  props: {
    projectId: String
  },

  data: function () {
    return {
      items: [],

      grid: "project-overrides",

      textSearch: '',

      defaultOrderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      orderBy: {
        field: "CREATED_AT",
        direction: "DESC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  computed: {
    filterParams: function () {
      let params = {
        findings: {
          projectId: this.projectId
        }
      }

      return params
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get("grid.project.findingOverrides",
        params,

        (data) => {
          let findings = data.data.findingOverrides
          let pageInfo = findings ? findings.pageInfo : {}

          success(findings, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch overrides!"
          })
        }
      )
    }
  }
}
</script>
