<template>
  <tr :class="'row-group-' + activationState.toLowerCase()">
    <td>
      <a
        title="Edit"
        :href="editUserUrl"
        :data-entity="id"
      >
        {{email}}
      </a>
    </td>

    <td>{{name}}</td>

    <td>{{role.toLowerCase()}}</td>

    <td class="text-center">
      <span :class="'item-status-' + activationState.toLowerCase()">
        {{activationState}}
      </span>
    </td>

    <td class="text-right">
      {{lastActive}}
    </td>

    <td class="table-row-actions">
      <RowAction
        action="update"
        :href="editUserUrl"
      />

      <RowAction
        v-if="activationState === 'DEACTIVATED'"
        action="unblock"
        rel="nofollow"
        :href="reactivateUserUrl"
      />

      <RowAction
        v-else
        action="block"
        rel="nofollow"
        :href="deactivateUserUrl"
      />
    </td>
  </tr>
</template>

<script>
import RowAction from "../shared/grid/row_action";
import Helpers from "../../helpers";

export default {
  components: {
    RowAction
  },

  props: {
    id: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    activationState: {
      type: String,
      required: true
    },
    name: String,
    lastActivity: String
  },

  computed: {
    lastActive: function () {
      if (this.lastActivity) {
        return Helpers.formatDate.short(this.lastActivity);
      } else {
        return "None";
      }
    },

    editUserUrl: function () {
      return "/users/" + this.id + "/edit";
    },

    deactivateUserUrl: function () {
      return "/users/" + this.id;
    },

    reactivateUserUrl: function () {
      return "/users/" + this.id + "?user%5Bactivation_state%5D=active";
    }
  }
}
</script>
