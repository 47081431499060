<template>
  <div @keydown.enter="setInspector">     
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="placeholder placeholder-static">Inspector</label>
          <input
            :class="['form-control', {'error': v$.inspectorName.$error}]"
            placeholder="Inspector name"
            type="text" 
            id="txt-inspector-name" 
            name="txt-inspector-name"
            v-model="inspectorName"
          />
          <div v-if="v$.inspectorName.$error" class="error">Inspector name is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <button class="btn btn-primary" @click="setInspector">{{ editFormButtonLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  props: {
    currentInspectorId: String,
    currentInspectorName: String,
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      inspectorId: "",
      inspectorName: "",
      modal: null
    }
  },

  validations () {
    return {
      inspectorName: {
        required
      }
    }
  },

  mounted: function() {
    if (this.isEditForm) {
      this.inspectorId = this.currentInspectorId;
      this.inspectorName = this.currentInspectorName;
    }

    this.$el.getElementsByTagName("input")[0].focus();
  },

  computed: {
    isEditForm: function() {
      return this.currentInspectorId;
    },

    editFormButtonLabel: function() {
      return (this.isEditForm) ? "Update Inspecor" : "Add Inspector";
    }
  },

  methods: {
    setInspector: function() {
      if (this.isEditForm) {
        this.updateInspector();
      }
      else {
        this.createInspector();
      }
    },

    createInspector: function() {
      let vm = this;

      this.v$.inspectorName.$touch();
      
      if (!this.v$.$error) {
        app.graphql.get(
          "create.inspector",

          {
            input: {
              name: vm.inspectorName
            }
          },

          (data) => {
            const response = data.data.createInspector;

            if (response.errors) {
              response.errors.forEach((error) => {
                app.ui.toast.add({
                  priority: "warning",
                  title: "Unable to add new Inspector",
                  message: error
                })
              })
            } else {
              app.ui.toast.add({
                priority: "success",
                title: "Inspector",
                message: "Inspector <strong>" + response.inspector.name + "</strong> was successfully added!"
              })

              if (vmInspectors) {
                vmInspectors.$refs.inspectors.refresh();
              }

              vmModal.$refs.modal.hide();
            }
          }
        );
      }
    },

    updateInspector: function() {
      let vm = this;

      this.v$.inspectorName.$touch();
      
      if (!this.v$.$error) {
        app.graphql.get(
          "update.inspector",

          {
            input: {
              id: vm.inspectorId,
              name: vm.inspectorName
            }
          },

          (data) => {
            const response = data.data.updateInspector;

            if (response.errors) {
              response.errors.forEach((error) => {
                app.ui.toast.add({
                  priority: "warning",
                  title: "Unable to update Inspector",
                  message: error
                })
              })
            } else {
              app.ui.toast.add({
                priority: "success",
                title: "Inspector",
                message: "Inspector <strong>" + response.inspector.name + "</strong> was successfully updated!"
              })

              if (vmInspectors && vmInspectors.$refs.inspectors) {
                vmInspectors.$refs.inspectors.update(response.inspector);
              }

              vmModal.$refs.modal.hide();
            }
          }
        );
      }
    }
  }
}

</script>