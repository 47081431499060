<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div
      v-if="progress > 0"
      class="progress entity-details-progress"
    >
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>
            <DamageRating
              :rating="remediation.rating"
            />
          </div>
          <div>
            {{remediation.remediationType.name}}
          </div>
          <div>
            <span :class="categoryStyle">{{remediation.remediationType.category}}</span> 
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Remediation Type</label>
          <a :href="'/remediation_types/' + remediation.remediationType.id">{{remediation.remediationType.name}}</a>
        </div>
        <div class="row entity-detail form-group">
          <div class="col-sm-8">
            <label class="placeholder placeholder-static">Physical Asset</label>
            <a :href="'/physical_assets/' + remediation.physicalAsset.id">{{remediation.physicalAsset.displayName}}</a>
          </div>
          <div v-if="remediation.physicalAsset.qrLabel" class="col-sm-4 text-right">
            <label class="placeholder placeholder-static" style="padding-right: 0;">QR Label</label>
            {{remediation.physicalAsset.qrLabel.labelNumber}}
          </div>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity</label>
          <a :href="'/inspections/' + remediation.inspection.id">{{remediation.inspection.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity date</label>
          {{ h$.formatDate.short(remediation.inspection.date) }}
        </div>
        <div 
          v-if="remediation.inspectorNames && remediation.inspectorNames.length > 0"
          class="entity-detail form-group"
        >
          <label class="placeholder placeholder-static">Remediated by</label>
          {{remediation.inspectorNames.join (",")}}
        </div>
      </section>

      <section>
        <h3>Related Findings</h3>
        <related-findings
          :default-filter="defaultFilter"
          :policy-update="userCanUpdate"
          :show-finding-name-column="userCanUpdate"
          :track-history="true"
        />
      </section>

      <section>
        <h3 style="padding-bottom: 7px;">Photos</h3>
        <photos
          ref="photos"
          :photos="photos"
          :gallery="entityId"
          :user-can-update="userCanUpdate"
          entity-type="remediation"
          @update-photo="fetchRemediationPhotos"
          @progress="uploadProgress"
        ></photos>
      </section>

      <section
        v-if="userCanUpdate && showActivity"
        class="entity-details-activities"
      >
        <h3 style="padding-bottom: 7px;">Activity Log</h3>
        <ol>
          <li
            v-for="(activity, index) in remediation.activities"
            :key="index"
          >
            <ul>
              <li><strong>{{activity.user.name}}</strong> <span v-html="logEntry(activity.objectChanges.changes)"></span></li>
            </ul>
            <time :datetime="activity.createdAt">{{ h$.formatDate.full(activity.createdAt) }}</time>
          </li>
        </ol>
      </section>

      <div class="entity-details-actions">
        <div>
          <a
            :href="'/remediations/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/remediations/' + entityId + '/edit'"
            title="Edit"
            class="btn-expandable btn-expandable-edit btn-expandable-sm"
          >
            <span class="icon" data-icon="edit"></span>
            <span>Edit</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/remediations/' + entityId"
            title="Remove"
            class="btn-expandable btn-expandable-delete btn-expandable-sm"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <span class="icon" data-icon="delete"></span>
            <span>Remove</span>
          </a>
        </div>
      </div>

    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating";
import Photos from "./photos";
import RelatedFindings from "./related_findings";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating,
    Photos,
    RelatedFindings
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data() {
    return {
      remediation: {
        remediationType: {},
        inspection: {},
        physicalAsset: {}
      },
      progress: 0,
      photos: [],
      s3UploadUrl: window._s3UploadUrl,
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    entityId: function() {
      this.fetchRemediationDetails();
    }
  },

  mounted: function() {
    this.fetchRemediationDetails();
  },

  computed: {
    categoryStyle: function() {
      return (this.remediation.remediationType.category == "replace") ? "label-unset" : "label-default";
    },
    
    showActivity: function() {
      return this.remediation.activities && this.remediation.activities.length > 0;
    },

    defaultFilter: function() {
      return {
        historical: true,
        remediationId: [this.entityId],
        status: ["approved", "draft", "review"]
      }
    }
  },

  methods: {
    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchRemediationDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.remediation",

        {
          filter: {
            id: [this.entityId],
            status: ["draft", "review", "approved"]
          }
        },

        (data) => {
          this.isLoaded = true;

          this.remediation = data.data.remediations.edges[0].node;

          this.fetchRemediationPhotos();

          this.fetchRemediationHistory();

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Remediation details",
            message : "Something went wrong! Remediation cannot be found or doesn't exist!"
          });
        }
      );
    },

    fetchRemediationPhotos: function() {
      app.graphql.get(
        "details.remediationPhotos",

        {
          filter: {
            remediationId: this.entityId
          }
        },

        (data) => {
          this.photos = data.data.remediationPhotos.edges.map((photo) => {return photo.node});
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Remediation photos",
            message : "Something went wrong! Remediation photos cannot be found!"
          });
        }
      );
    },

    fetchRemediationHistory: function() {
      // Match and remove [null,""] changes
      var r = /,?\"[\w]+\":\[null,""\]/g;

      // Get remediation versions
      app.graphql.get(
        "details.remediationVersions",

        {
          filter: {
            remediationId: this.entityId
          }
        },

        (versions) => {
          var remediationVersions = versions.data.remediationVersions.edges;

          let remediationActivities = versions.data.remediationVersions.edges.map((remediationVersion) => {
            let activity = {
              createdAt: remediationVersion.node.createdAt,
              objectChanges: JSON.parse(remediationVersion.node.objectChanges.replace(r, "").replace("{,", "{")),
              originalObject: JSON.parse(remediationVersion.node.originalObject),
              user: remediationVersion.node.user
            };

            let remediationHistoryActivitites = [];

            Object.keys(activity.objectChanges).forEach((key) => {
              if (key != "updated_at") remediationHistoryActivitites.push(key);
            });

            activity.objectChanges.changes = remediationHistoryActivitites;

            return activity;
          });

          // Sort changes
          remediationActivities.reverse();

          this.remediation['activities'] = remediationActivities;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Remediation versions",
            message : "Something went wrong! Remediation history data cannot be found or doesn't exist!"
          });
        }
      );
    },
    
    change: function (prop) {
      const changes = {
        created_at: 'create date',
        component_id: 'component',
        inspection_id: 'activity',
        reported_by_id: 'reporter',
        reviewed_by_id: 'reviewer',
        approved_by_id: 'approver',
        created_by_id: 'created',
        finding_type_id: 'finding type',
        remediation_id: 'remediation',
        fulcrum_record_id: 'Fulcrum record'
      }

      if (changes.hasOwnProperty(prop)){
       return changes[prop];
      }
      else{
        return prop;
      }
    },

    logEntry: function(changes) {
      let logEntry = '';

      if (changes.includes('id')) {
        logEntry = '<strong>created finding</strong>';
      }
      else {
        logEntry = 'changed finding ';
        
        for (let i = 0; i < changes.length; i++) {
          if (typeof changes[i] == 'object') {
            logEntry = logEntry + '<strong>status from ' + changes[i].status[0] + ' to ' + changes[i].status[1] + '</strong>';
          }
          else {
            logEntry = logEntry + '<strong>' + this.change(changes[i]) + '</strong>';
          }

          if (i == 0 && changes.length > 1 && i+2 < changes.length) {
            logEntry = logEntry + ', ';
          }
          else if (i+2 < changes.length) {
            logEntry = logEntry + ', ';
          }
          else if (i+2 == changes.length) {
            logEntry = logEntry + ' and ';
          }
        }
      }
    
      return logEntry; 
    }
  }
}

</script>
