<template>
  <tr>
    <td>
      <a 
        :href="batchUrl" 
        title="Details"
      >{{batchNumber}}</a>
    </td>

    <td>
      {{notes}}
    </td>

    <td class="text-center">
      {{labelCount}}
    </td>

    <td>
      <a 
        :href="userUrl"
        :title="userTitle"
      >{{createdBy.email}}</a>
    </td>

    <td>
      {{ h$.formatDate.short(createdAt) }}
    </td>
    
    <td class="table-row-actions">
      <RowAction
        :href="batchUrl"
        action="read"
      />

      <RowAction
        :href="batchUrl + '/edit'"
        action="update"
      />

      <RowAction
        :href="batchUrl + '.csv'"
        action="download"
        target="_blank"
        download="download"
        rel="noreferrer nofollow"
        title="Export Batch" 
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction
  },

  props: {
    id: String,
    batchNumber: Number,
    notes: String,
    createdAt: String,
    createdBy: Object,
    labelCount: Number
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    batchUrl: function() {
      return "/qr_label_batches/" + this.id;
    },

    userUrl: function() {
      return "/users/" + this.createdBy.id + "/edit";
    },

    userTitle: function() {
      return "Details: " + this.createdBy.name;
    }
  }
}
</script>