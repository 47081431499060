<template>
  <tr :class="{'is-marked': marked}">
    <td class="text-center">
      <DamageRating
        :rating="rating"
        :has-revision="hasRevision"
      />
    </td>

    <td>
      <DetailsButton
        title="Remediation Details"
        entity-type="remediationDetails"
        :entity="id"
      >
        <a
          :href="remediationUrl"
          title="Remediation"
        >{{remediationType.name}}</a>
        <div v-if="notes" class="note">{{notes}}</div>
      </DetailsButton>
    </td>

    <td>
      <a 
        :href="inspectionUrl" 
        title="Details"
      >{{inspection.name}}</a>
    </td>

    <td>
      <DetailsButton
        title="Site Details"
        entity-type="siteDetails"
        :entity="inspection.site.id"
      >
        <a
          :href="siteUrl"
          title="Site"
        >{{inspection.site.name}}</a>
        <div v-if="inspection.site.description" class="note">{{inspection.site.description}}</div>
      </DetailsButton>
    </td>

    <td class="text-center">
      <span 
        :class="categoryStyle"
      >{{remediationType.category}}</span>
    </td>

    <transition name="status-change" mode="out-in">
      <td 
        v-if="showStatus" 
        :key="status" 
        class="text-center"
      >
        <span :class="'item-status-' + status">{{status}}</span>
      </td>
    </transition>

    <td class="text-center">
      <span
        v-if="photoCount > 0"
        class="indicator-images"
        title="Remediation Photos" 
        data-action="show-details" 
        data-entity-type="remediationPhotos"
        :data-entity="id"
        :data-row="rowRef"
      ></span>
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="remediationUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="remediationUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    RowAction,
    DamageRating,
    DetailsButton
  },

  props: {
    rating: Number,
    id: String,
    notes: String,
    inspection: Object,
    status: String,
    showStatus: Boolean,
    photoCount: Number,
    remediationType: Object,
    hasRevision: Boolean,
  },

  computed: {
    categoryStyle: function() {
      return (this.remediationType.category == "replace") ? "label-unset" : "label-default";
    },

    remediationUrl: function () {
      return "/remediations/" + this.id
    },

    remediationTypeUrl: function () {
      return "/remediation_types/" + this.remediationType.id
    },

    inspectionUrl: function () {
      return "/inspections/" + this.inspection.id
    },

    siteUrl: function () {
      return "/sites/" + this.inspection.site.id
    }
  }
}
</script>
