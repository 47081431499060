
<template>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-sm-12">
        <v-select
          placeholder="Let us know how we can improve the site"
          ref="messageType"
          :options="messageTypes"
          @update:modelValue="setMessageType"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="message-content" class="placeholder">Message</label>
        <textarea 
          id="message-content"
          :class="['form-control', {'error': v$.message.$error}]"
          style="height: 100px"
          placeholder="Message"
          v-model="message"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6 d-grid">
        <form-button
          ref="submitButton"
          label="Send question"
          class="btn btn-primary"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import formButton from "../form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  components: {
    vSelect,
    formButton
  },

  props: {
    args: Object
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      user: "",
      messageType: "",
      messageTypes: [
        "Permissions/Access Issue",
        "Browser Issue",
        "Error Message",
        "Reports Issue",
        "Finding Filter Issue",
        "General Issue"
      ],
      message: "",
    }
  },

  validations () {
    return {
      message: {
        required
      }
    }
  },

  created: function() {
    if (this.args) {
      this.user = this.args.user;
      this.messageType = this.args.messageType || "";
    }
  },

  mounted: function() {
    this.$el.getElementsByTagName("textarea")[0].focus();

    if (this.messageType != "") {
      this.$refs.messageType.updateValue(this.messageType);
    }
  },

  methods: {
    setMessageType: function(opt) {
      this.messageType = opt;
    },

    sendMessage: function() {
      let vm = this;
      let subject = "AssetPortal Message: " + (vm.messageType ? vm.messageType + ' - ' : '') + vm.user;

      app.remote.call({
        url: "/feedback.json",
        type: 'POST',
        dataType: 'json',
        data: {
          feedback: {
            subject: subject,
            body: vm.message
          }
        },
        success: function(data) {
          app.ui.toast.add({
            priority: "success", 
            title: "Ask for support", 
            message : "Your message was successfuly sent."
          });

          vmModal.$refs.modal.hide();
        },
        error: function (e, data) {
          app.ui.toast.add({
            priority: "danger", 
            title: "Something went worng!", 
            message : "Your question to support service was not sent."
          });

          vmModal.$refs.modal.hide();
        }
      });
    },

    submitForm: function() {
      this.v$.message.$touch();

      if (!this.v$.$error) {
        this.$refs.submitButton.loadingOn();

        this.sendMessage();
      }
    }
  }
}

</script>