<template>
  <tr>
    <td style="width: 30px">
      <input 
        type="checkbox"
        ref="checkboxField"
        :checked="selected"
        :id="'related-finding-' + id"
        @change="toggleFinding"
      ><label class="checkbox" :for="'related-finding-' + id"></label>
    </td>

    <td class="text-center" style="width: 40px">
      <DamageRating
        :rating="rating" 
      />
    </td>

    <td>
      <DetailsButton
        title="Finding Details"
        entity-type="findingDetails"
        :entity="id"
      >
        {{component.name}}: <a
          :href="findingUrl"
          title="Finding"
        >{{displayName}}</a>
        <div class="note">
          <div>{{findingType.name}}</div>
          <div><strong>Project:</strong> {{inspection.project.name}}</div>
        </div>
      </DetailsButton>
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import DetailsButton from "../shared/grid/details_button";
import DamageRating from "../shared/damage_rating";

export default {
  extends: Row,

  components: {
    DetailsButton,
    DamageRating
  },

  props: {
    rating: Number,
    id: String,
    displayName: String,
    findingType: Object,
    inspection: Object,
    component: Object,
    index: Number,
    selected: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ratingClass: function () {
      return "rating-" + this.rating
    },

    ratingText: function () {
      return ["-", "A", "B", "C", "D", "F"][this.rating]
    },

    findingUrl: function () {
      return "/findings/" + this.id
    }
  },

  methods: {
    toggleFinding: function() {
      this.isSelected = this.$refs.checkboxField.checked;

      this.$emit("toggle-finding", this);
    }
  }
}
</script>