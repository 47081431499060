<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-5 col-xs-12" @keydown.enter.prevent>
          <fieldset>
            <legend>User</legend>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Client</label>
                  <input 
                    class="form-control"
                    type="text"
                    readonly
                    :value="clientCompanyName"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">User</label>
                  <input 
                    class="form-control"
                    type="text"
                    readonly
                    :value="userName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-inactive">Client Role</label>
                  <v-select
                    placeholder="Select client role"
                    ref="clientRoles"
                    :options="clientRoles"
                    :clearable="false"
                    label="value"
                    @update:modelValue="setUserRole"
                  />
                  <input 
                    type="hidden" 
                    id="client_role_role" 
                    name="client_role[role]"
                    :value="clientRole"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-7 col-xs-12">
          <fieldset
            v-if="clientRole != 'admin'"
          >
            <legend>Sites and Permissions</legend>
            <form-user-site-permissions
              ref="userSitePermissions"
              :sites="sitesPermissions"
            />
          </fieldset>
        </div>
      </div>
      <div class="form-group">
        <form-button
          ref="submitButton"
          label="Set User Permissions"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from "vue-select";

import formUserSitePermissions from "./form_user_site_permissions.vue";
import formButton from "../shared/form/form_button";

export default {
  components: {
    vSelect,
    formUserSitePermissions,
    formButton
  },

  props: {
    formId: String,
    userClientRole: Object,
    userId: String,
    userName: String,
    clientCompanyId: String,
    clientCompanyName: String,
  },

  data() {
    return {
      clientRole: "",
      clientRoles: [
        {key: "admin", value: "Admin"},
        {key: "member", value: "Staff"}
      ],
      sites: [],
      userSitePermissions: [],
      sitesPermissions: [],
      isLoaded: false,
      isSpinnerVisible: true,
    }
  },

  mounted: function() {
    this.fetchSites();

    let currentUserClientRole = this.clientRoles.find(role => {
      return role.key === this.userClientRole.role.toLowerCase();
    });

    this.$refs.clientRoles.updateValue(currentUserClientRole);
  },

  methods: {
    setSitesPermissions: function() {
      this.sites.forEach((site, i) => {
        site.userRole = null;

        this.userSitePermissions.forEach((sitePermission, j) => {
          if (sitePermission.site.id == site.id) {
            site.userRoleId = sitePermission.id;
            site.userRole = sitePermission.role;
          }
        })
      });

      this.sitesPermissions = this.sites;
    },

    fetchSites: function() {
      let fetchParams = {
        pageSize: 100,
        filter: {
          clientCompanyId: this.clientCompanyId
        }
      };

      this.sites = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.sites",
          fetchParams,

          (data) => {
            let sites = data.data.sites,
                pageInfo = sites.pageInfo;

            success(sites, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site sites!"
            });
          }
        )
      };

      let getSites = (cursor) => {
        fetch(
          cursor,

          (sites, pageInfo) => {
            sites.edges.forEach((edge) => this.sites.push(edge.node));

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSites(pageInfo.endCursor));
            }
            else {
              this.fetchUserSitePermissions();
            }
          }
        );
      };

      getSites(window.btoa(0));
    },

    fetchUserSitePermissions: function () {
      let fetchParams = {
         orderBy: {
          field: "SITE_NAME",
          direction: "ASC",
        },
        filter: {
          userId: this.userId
        }
      };

      this.userSitePermissions = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get("select.user.clientSitesRoles",
          fetchParams,

          (data) => {
            let sitePermissions = data.data.siteRoles,
              pageInfo = sitePermissions.pageInfo;

            success(sitePermissions, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch User Site Roles!"
            });
          }
        )
      };

      let getSitePermissions = (cursor) => {
        fetch(
          cursor,

          (sitePermissions, pageInfo) => {
            sitePermissions.edges.forEach((edge) => this.userSitePermissions.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getSitePermissions(pageInfo.endCursor))
            }
            else {
              this.setSitesPermissions();

              this.isLoaded = true;

              setTimeout(() => {
                this.isSpinnerVisible = false;
              }, 1000);
            }
          }
        )
      };

      getSitePermissions(window.btoa(0));
    },

    setUserRole: function(opt) {
      this.clientRole  = (opt) ? opt.key : "";
    },
    
    submitForm: function() {
      const form = document.forms[this.formId];

      if (form) {
        this.$refs.submitButton.loadingOn();
        
        form.submit();
      }
    }
  }
}
</script>
