<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="componentUrl"
        :title="name"
        :data-entity="id"
      >{{name}}</a> <span class="label label-default">{{ componentType.name }}</span>
      <div v-if="description" class="note">{{description}}</div>
    </td>

    <td>
      <DetailsButton
        title="Physical Asset Details"
        entity-type="physicalAssetDetails"
        :entity="physicalAsset.id"
      >
        <a
          :href="physicalAssetUrl"
        >{{physicalAsset.name}}</a>
        <div v-if="physicalAsset.description" class="note">{{physicalAsset.description}}</div>
        <div class="note">Site: {{physicalAsset.site.name}}</div>
      </DetailsButton>
    </td>

    <td>
      <Details 
        :details="details" 
      />
    </td>

    <td class="text-center">
      <DamageRating 
        v-if="latestSummary.approvedDate || latestSummary.previewDate"
        :rating="latestSummary.clientRemediatedRating" 
      />
    </td>

    <td class="text-right">
      {{lastActivityDate}}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="componentUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="componentUrl"
        action="delete"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import RowAction from "../shared/grid/row_action";
import Details from "../shared/grid/details";
import DamageRating from "../shared/damage_rating";
import DetailsButton from "../shared/grid/details_button";

import helpers from "../../helpers.js";

export default {
  extends: Row,

  components: {
    RowAction,
    Details,
    DamageRating,
    DetailsButton
  },

  props: {
    id: String,
    name: String,
    description: String,
    latestSummary: Object,
    details: String,
    componentType: Object,
    physicalAsset: Object
  },

  computed: {
    componentUrl: function () {
      return "/components/" + this.id;
    },

    physicalAssetUrl: function () {
      return "/physical_assets/" + this.physicalAsset.id;
    },

    lastActivityDate: function() {
      const activityDate = this.latestSummary.approvedDate;

      return activityDate ? helpers.formatDate.short(activityDate) : "N/A";
    },
  }
}
</script>
