import NoRecords from "./no_records";
import SortableHeader from "./sortable_header";
import Pagination from "../pagination";
import SearchField from "../search_field";

export default {
  components: {
    NoRecords,
    SortableHeader,
    Pagination,
    SearchField
  },

  props: {
    policyUpdate: Boolean,
    defaultFilter: Object,
    loadOnInit: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      filter: {},
      stateParams: [],
      markedRows: [],
      shouldLoad: false
    }
  },

  created: function () {
    let vmGrid = this;

    this.shouldLoad = this.loadOnInit;

    this.stateParams = new URLSearchParams(window.location.search);

    // Handle default state filter
    let stateFilter = this.getFilterState();

    if (stateFilter) {
      stateFilter = stateFilter.replace("findingDetails", "details");

      stateFilter = JSON.parse(stateFilter);

      if (stateFilter.details) {
        if (stateFilter.details.constructor === Object && Object.keys(stateFilter.details).length >= 0) {
          stateFilter.details = JSON.stringify(stateFilter.details);
        }
      }
    }

    this.filter = (stateFilter) ? stateFilter : this.defaultFilter || {};

    this.filter.historical = this.filter.historical || false;

    // Handle current grid state
    let vGrid = this.getStateParam("v-grid");

    if (vGrid === this.grid) {
      this.refreshState();
    }

    // Listen for the event.
    window.addEventListener("updatestate", (eventt) => {
      let tmpFilter = vmGrid.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmGrid.filter = Object.assign({}, tmpFilter);
      }
    });

    // Handle browser history change
    window.addEventListener("popstate", (event) => {
      const vGrid = this.getStateParam("v-grid");

      const shouldRefresh = (event.state || event.isTrusted) && (vGrid === this.grid || vGrid === null || event.state === null);

      if (shouldRefresh) {
        this.refresh();
      }
    });
  },

  watch: {
    filter: function() {
      if (this.shouldLoad) {
        this.refresh();
      }
    }
  },

  computed: {
    hasItems: function() {
      return this.items.length > 0;
    },

    // Components can override this method for more complex sorting
    queryOrder: function () {
      return this.orderBy;
    },
  },

  methods: {
    getItems: function (page, pageSize) {
      let currentPage = page || this.pageInfo.currentPage,
          cursor = window.btoa((currentPage - 1) * (pageSize || this.pageInfo.pageSize));

      this.fetch(
        {
          cursor: cursor,
          pageSize: pageSize || this.pageInfo.pageSize,
          orderBy: this.queryOrder,
          filter: this.filterParams
        },

        (items) => {
          if (items) {
            this.items = items.edges.map((edge) => { return edge.node });

            this.pageInfo = {
              pageSize: pageSize || this.pageInfo.pageSize,
              currentPage: currentPage,
              totalCount: items.totalCount,
              totalPageCount: items.totalPageCount
            }
          }

          this.isSpinnerVisible = false;

          this.onGetItems(this.items);
        }
      )
    },

    onGetItems: function (items) {
      this.$emit("get-items", items);
    },

    reorder: function (newOrder) {
      this.isSpinnerVisible = true;
      this.orderBy = newOrder;

      setTimeout(() => {
        this.getItems(1);
      }, 200);
    },

    paginate: function (page, pageSize) {
      this.isSpinnerVisible = true;

      setTimeout(() => {
        this.getItems(page, pageSize);
      }, 200);
    },

    search: function () {
      this.getItems(1);

      app.ui.state.update({
        "v-grid": this.grid,
        "v-page": 1,
        "v-search": this.textSearch
      });
    },

    refresh: function() {
      this.isSpinnerVisible = true;

      // Handle current grid only state
      let vGrid = this.getStateParam("v-grid");

      if (vGrid === this.grid) {
        this.refreshState();
      }

      setTimeout(() => {
        this.getItems();
      }, 200);
    },

    refreshState: function () {
      const param = (name) => { return this.getStateParam(name) };

      this.orderBy.field = (param("v-sortby")) ? param("v-sortby").toUpperCase() : this.defaultOrderBy.field;

      this.orderBy.direction = (param("v-sortorder")) ? param("v-sortorder").toUpperCase() : this.defaultOrderBy.direction;

      this.pageInfo.pageSize = (param("v-pagesize")) ? Number(param("v-pagesize")) : this.pageInfo.pageSize;
      
      this.pageInfo.currentPage = (param("v-page")) ? Number(param("v-page")) : 1;

      if (param("v-search")) {
        const searchText = param("v-search");
        const isBlank = searchText === true;

        if (!isBlank) {
          this.textSearch = searchText;
        }
      }
    },

    getFilterState: function() {
      return this.getStateParam("v-filter");
    },

    getStateParam: function(param) {
      this.stateParams = new URLSearchParams(window.location.search);

      return this.stateParams.get(param);
    },

    markRow: function(row) {
      this.markedRows = [];

      this.markedRows.push(row);
    },

    unmarkRows: function() {
      this.markedRows = [];
    },

    isItemMarked: function(item) {
      return this.markedRows.includes(item);
    }
  }
}
