<template>
  <div 
    :class="['modal-body', {'is-loading': isLoading}]"
  >
    <div class="media-gallery-edit-actions">
      <a 
        title="Save photo" 
        class="icon" 
        data-icon="save"
        @click="save"
      ></a>
    </div>

    <image-editor 
      :include-ui="useDefaultUI" 
      :options="options"
      ref="imageEditor"
    />

    <div class="modal-upload-form">
      <div
        v-if="progress > 0"
        class="progress"
      >
        <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
      </div>

      <file-upload
          input-id="fileEditedPhoto"
          :post-action="uploadUrl"
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          @input-file="inputFile"
          ref="upload">
      </file-upload>

      <form
        id="form-photo-save"
        :action="uploadUrl"
        method="post"
        enctype="multipart/form-data"
        ref="editPhotoUploadForm"
      >
        <input
          v-for="(k, index) in uploadFileKeys"
          :key="index"
          type="hidden"
          :name="k.key"
          :value="k.value"
        />

        <input
          id="form-field-photo-save"
          ref="editPhotoUploadField"
          type="file"
          name="file"
          multiple
        />
      </form>
    </div>

    <div 
      v-if="isLoading"
      class="modal-body-overlay spinner-is-visible"
    >
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import ImageEditor from '../photo_editor'
import FileUpload from 'vue-upload-component'

export default {
  components: {
    ImageEditor,
    FileUpload
  },

  props: {
    args: Object
  },

  data() {
    return {
      entityType: '',
      useDefaultUI: true,
      options: {},
      gallery: '',
      uploadUrl: window._s3UploadUrl,
      entityPhotos: [],
      uploadFiles: [],
      uploadFileKeys: [],
      uploadSize: 0,
      progress: 0,
      isUploading: false,
      isLoading: true
    }
  },

  created: function() {
    this.options = this.args

    this.gallery = this.options.gallery

    this.entityType = this.options.entityType

    this.options.usageStatistics = false
  },

  mounted: function() {
    let vm = this
    
    let image = new Image()

    image.crossOrigin = "Anonymous"
    
    image.src = this.options.includeUI.loadImage.path
 
    image.onload = function() {
      vm.isLoading = false
    }
  },

  methods: {
    save: function() {
      function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || ''
        sliceSize = sliceSize || 512

        let byteCharacters = atob(b64Data)
        let byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize)

          let byteNumbers = new Array(slice.length)

          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i)
          }

          let byteArray = new Uint8Array(byteNumbers)

          byteArrays.push(byteArray)
        }

        let blob = new Blob(byteArrays, {type: contentType})

        return blob
      }

      let ImageURL = this.$refs.imageEditor.invoke('toDataURL', {
        format: 'jpeg',
        quality: 0.9
      })
      
      let block = ImageURL.split(";")
      let contentType = block[0].split(":")[1]
      let realData = block[1].split(",")[1]

      let blob = b64toBlob(realData, contentType)

      const photo = new File([blob], this.entityType + ".jpeg");

      this.$refs.upload.add(photo)
    },

    resetPhotoUpload: function() {
      this.isUploading = false
      this.progress = 0
      this.uploadFileKeys = []
      this.uploadFiles = []
    },

    inputFile: function(newFile, oldFile) {
      let vm = this;

      if (newFile && !oldFile) {
        // save photo
        vm.isUploading = true

        app.remote.call({
          type: "POST",
          url: "/s3_uploads",
          dataType: "json",
          data: {
            [vm.entityType + "_id"]: vm.gallery,
            file_name: newFile.name
          },
          success: function(response) {
            Object.keys(response).forEach((k) => {
              newFile.data[k] = response[k]
            })

            if (!vm.$refs.upload.active) {
              vm.$refs.upload.active = true
            }
          },
          error: function(e, data) {
            vm.isUploading = false

            vm.resetPhotoUpload()

            app.ui.toast.add({
              priority: "danger",
              title: "Photo upload",
              message: "Photo was succesfully uploaded!"
            })
          }
        });
      }

      if (newFile && oldFile) {
        // upload progress
        if (newFile.progress !== oldFile.progress) {
          vm.progress = newFile.progress
        }

        // upload success
        if (newFile.success) {
          var s3Key = newFile.data.key,
              caption = s3Key.split("/").slice(-1)[0],
              gallery = vm.gallery

          // assign photo to entity type
          app.remote.call({
            url: "/" + vm.entityType + "_photos",
            type: "POST",
            dataType: "json",
            data: {
              s3_key: s3Key,
              [vm.entityType + "_photo"]: {
                [vm.entityType + "_id"]: gallery,
                caption: caption
              }
            },
            success: function(response) {
              vm.$emit("update-photo");

              app.ui.toast.add({
                priority: "success",
                title: "Photo upload",
                message:
                  "Photo <strong>" + caption + "</strong> was added!"
              });

              vm.isUploading = false

              vm.resetPhotoUpload()

              const sidebarDetails = vmDetailsPane.$refs.detailsPane.$refs.entityDetails

              if (typeof sidebarDetails !== "undefined" && sidebarDetails.$refs && sidebarDetails.$refs.photos) {
                // refresh sidebar details photo list
                sidebarDetails.$refs.photos.refresh()
              }
              
              if (typeof vmPhotoGallery !== "undefined") {
                // refresh entity photo list
                vmPhotoGallery.$refs.photos.refresh()
              }
            },
            error: function(e, data) {
              vm.isUploading = false

              vm.resetPhotoUpload()

              app.ui.toast.add({
                priority: "danger",
                title: "Photo upload",
                message: "Photo was not associated!"
              });
            }
          });
        }
      }
    }
  }
}

</script>