<template>
<div class="search-control">
  <input
    ref="search"
    :placeholder="placeholder"
    :value="textSearch"
    @input="input"
    @keyup.esc="clearSearch"
  />
  <a 
    href="#"
    class="search-cancel"
    @click.prevent="clearSearch"
  >
    <span 
      class="icon" 
      data-icon="cancel">
    </span>
  </a>
</div>



</template>

<script>
export default {
  props: {
    textSearch: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },

  data: function () {
    return {
      timeout: null
    };
  },

  methods: {
    input: function (event) {
      this.$emit('update:textSearch', event.target.value);
      this.debounceSearch();
    },

    clearSearch: function() {
      this.$refs.search.focus();
      this.$emit('update:textSearch', '');
      this.debounceSearch();
    },

    debounceSearch: function () {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$emit('search');
      }, 400);
    }
  }
};
</script>
